import styled from "styled-components";

export const Page = styled.div`
display:flex;
flex-direction:column;
background:#F2F2F2;
padding-top:50px;
padding-left:3.472222222vw;
padding-right:3.472222222vw;
height: auto;
padding-bottom: 20px;
p{
    margin-left: -10vw; // coloquei essa margem estranha pq estava ficando torto devido o padding da página
    
}
@media (max-width: 912px) {
  padding-left:0vw;
  padding-right:0vw;
  padding-top:0px;
  padding-bottom: 0px;
}
/* @media(max-width: 1000px){
     background-color: white;
} */
`
export const StepCounterIcon = styled.img`
height: 26px;
width:256px;
display:flex;
margin-bottom:3.33vh;
@media (max-width: 600px){
    margin-top: 12%;
}
@media (max-width: 912px){
    margin-left: 0;
}
`
export const TopInputWrapper = styled.div`
display:flex;
height: fit-content;
flex-direction:row;
margin-bottom:5.5vh;
gap:1.73vw;
@media (max-width:1000px){
    flex-direction:column;  
}
@media (max-width:1000px){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 50px;
}
`
export const CubaTypeWrapper = styled.div`
display:flex;
flex-direction:row;
margin-bottom:5.5vh;
justify-content:space-between;
gap:1.73vw;
overflow-x: auto;
height: 200px;
position: relative;
scroll-snap-type: x mandatory;
scroll-padding: 50px;
::-webkit-scrollbar{display:none;}
@media (max-width:1200px){
    width: 99vw;
    left: -30px;
    align-self:center;
}
@media (max-width:425px){
    width: 102vw;
}
`
export const CubaAxisWrapper = styled.div`
display:flex;
flex-direction:row;
margin-bottom:5.5vh;
justify-content:space-between;
gap:1.73vw;
height: 200px;
position: relative;
scroll-snap-type: x mandatory;
scroll-padding: 50px;
::-webkit-scrollbar{display:none;}
@media (max-width:1200px){
    overflow-x:auto;
    align-self:center;
    width: 99vw;
    left: -30px;
}
@media (max-width:425px){
    width: 102vw;
}
`
export const Counter = styled.img`
width:256px;
display:flex;
align-self:center;
margin-bottom:3.33vh;
`
export const DistanceInputWrapper = styled.div`
display:flex;
flex-direction:row;
margin-bottom:5.5vh;
gap:1.73vw;
@media (max-width:1000px){
    flex-direction:column; 
    align-self:center;
}

`
export const BottomSection = styled.div`
display:flex;
justify-content:space-between;
align-items: center;
padding-bottom: 20px;
@media (max-width: 912px) {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* @media (max-width:1200px){
    width:100%;
    height: fit-content;
    flex-direction: row;
} */

`
export const InputTitle1 = styled.div`
    display: none;
@media (max-width:1000px){
    padding-top:15px;
    display: flex;
}

`
export const InputTitle2 = styled.div`
padding-bottom: 15px;
@media (max-width:1000px){
    display: none;
}

`
export const Div = styled.div`

@media (max-width:1000px) {
  display:flex;
  background-color: #f2f2f2;    
  width:93%;
  height:30px;
  align-content: center;
  padding-top: 5px;
  position:relative;
  border-radius: 5px;
}
`