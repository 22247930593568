
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import React, { useContext } from "react";

import BenchSizing from "./pages/BenchSizing";
import Estimate from "./pages/Estimate";
import CounterTopSelection from "./pages/CounterTopSelect";
import CubaStyle from "./pages/CubaStyle";
import FaucetStyle from "./pages/FaucetStyle";
import Home from './pages/Home'
import Installation from "./pages/Installation";
import Login from './pages/Login'
import MaterialSelect from './pages/MaterialSelect'
import OvenStyle from "./pages/OvenStyle";
import ProductSelect from './pages/ProductSelect'
import ResetPassword from './pages/ResetPassword'
import RoomSelect from './pages/RoomSelect'
import Register from "./pages/Register";
import AuthProvider, { AuthContext } from "./Contexts/auth";
import BenchL from "./pages/BenchL";
import Address from "./pages/Address";
import Purchase from "./pages/FinishPurchase";
import Identify from "./pages/Identify";
import Summary from "./pages/Summary";
import BenchU from "./pages/BenchU";
import CompletedOrder from "./pages/CompletedOrder";
import AddressRegistration from "./pages/AddressRegistration";
import 'react-toastify/dist/ReactToastify.css'

function PrivateRoute() {
  const { signed } = useContext(AuthContext);

  return signed ? <Outlet /> : <Navigate to="/login" />
}

function Router() {
  return (
    <BrowserRouter>
    <ToastContainer/>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/cadastre-se" element={<Register />} />
          <Route path="/redefinir-senha" element={<ResetPassword />} />
          
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/selecionar-ambiente" element={<RoomSelect />} />
            <Route path="/selecionar-material" element={<MaterialSelect />} />
            <Route path="/selecionar-produto" element={<ProductSelect />} />
            <Route path="/cuba" element={<CubaStyle />} />
            <Route path="/tipo-de-torneira" element={<FaucetStyle />} />
            <Route path="/tipo-de-fogao" element={<OvenStyle />} />
            <Route path="/bancada-L" element={<BenchL />} />
            <Route path="/bancada-U" element={<BenchU />} />
            <Route path="/instalacao" element={<Installation />} />
            <Route path="/selecionar-bancada" element={<CounterTopSelection />} />
            <Route path="/bancada-tamanho" element={<BenchSizing />} />
            <Route path="/estimativa" element={<Estimate />} />
            <Route path="/cadastrar-endereco" element={<AddressRegistration/>} />
            <Route path="/endereco-obra" element={<Address />} />
            <Route path="/finalize-compra" element={<Purchase />} />
            <Route path="/identifique-obra" element={<Identify />} />
            <Route path="/resumo" element={<Summary />} />
            <Route path="/pedido-finalizado" element={<CompletedOrder />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default Router;