import { saveAs } from 'file-saver';

import * as S from './styles';

interface DownloadPricesProps {
  productName: string | null;
  countertopType: string | null;
  materialName: string | null;
  materialPrice: number;
  wetBasePrice: number;
  dryBasePrice: number;
  frontPrice: number;
  stripPrice: number;
  flankPrice: number;
  productPrice: number;
  amount: number;
  pricePerAmount: number;
  installationPrice: number;
  totalInstallationPrice: number;
  totalPrice: number;
  wetBaseEstimateWidth: number;
  wetBaseEstimateDepth: number;
  dryBaseEstimateWidth1?: number;
  dryBaseEstimateDepth1?: number;
  dryBaseEstimateWidth2?: number;
  dryBaseEstimateDepth2?: number;
  stripEstimateHeight: number;
  frontEstimateHeight: number;
  vatType: string;
  vatAxle: string;
  vatEstimateAxleDistance?: number;
  vatEstimateEdgeDistance?: number;
  faucetType: string;
  faucetPosition: string;
  faucetWall: string;
  stoveType: string;
  shaft?: string;
  shaftDistance?: number;
  shaftFrontDistance?: number;
  typeOfInstallation: string;
  flankWidth?: number;
  flankJunctionType?: string;
  flankFinishType?: string;
  countertopDisposition: string;
  vatPosition: string;
}

export function DownloadPrices(estimate: DownloadPricesProps) {
  function saveDocument(): void {
    const data = new Date();
    if (estimate.countertopType === 'Bancada Reta') {
      const blob = new Blob(
        [
          `
        Produto:                       ${estimate.productName}
        Tipo da bancada:               ${estimate.countertopType}
        Material escolhido:            ${estimate.materialName}
        ====Bancada================================================
        Largura bancada molhada:       ${estimate.wetBaseEstimateWidth}
        Profu. bancada molhada:        ${estimate.wetBaseEstimateDepth}
        ====Cuba===================================================
        Altura Saia/Tira:              ${estimate.stripEstimateHeight}
        Altura Frontspício:            ${estimate.frontEstimateHeight}
        Tipo de cuba:                  ${estimate.vatType}
        Eixo de cuba:                  ${estimate.vatAxle}
        Dist. do eixo:                 ${estimate.vatEstimateAxleDistance}
        Dist. entre Cuba e Saia:       ${estimate.vatEstimateEdgeDistance}
        ====Torneira===============================================
        Tipo de torneira:              ${estimate.faucetType}
        Posição da torneira:           ${estimate.faucetPosition}
        Torneira de parede:            ${estimate.faucetWall}
        ====Fogão==================================================
        Tipo de fogão:                 ${estimate.stoveType}
        Eixo do fogão na bancada:      ${estimate.shaft}
        Dist. do eixo:                 ${estimate.shaftDistance}
        Dist. entre o corte e saia:    ${estimate.shaftFrontDistance}
        ====Instalação=============================================
        Tipo de Instalação:            ${estimate.typeOfInstallation}
        ====PREÇOS=================================================
        Preço do material (m²):        ${estimate.materialPrice}
        Preço da bancada molhada:      ${estimate.wetBasePrice}
        Preço do frontispício:         ${estimate.frontPrice}
        Preço da tira:                 ${estimate.stripPrice}\n
        Preço por unidade:             ${estimate.productPrice}
        Quantidade:                    ${estimate.amount}
        Preço pela quantidade:         ${estimate.pricePerAmount}\n
        Preço do serviço:              ${estimate.installationPrice}
        Serviço pela quantidade:       ${estimate.totalInstallationPrice}\n
                      PREÇO TOTAL:     ${estimate.totalPrice}\n        
                    Orçamento feito em ${data.toLocaleString('pt-BR', {
          timeZone: 'America/Sao_Paulo',
        })}\n
        `,
        ],
        {
          type: 'text/plain;charset=utf-8',
        }
      );
      saveAs(blob, 'Detalhes_do_preco_orcamento.txt');
    } else if (estimate.countertopType === 'Bancada em L') {
      const blob = new Blob(
        [
          `
        Produto:                       ${estimate.productName}
        Tipo da bancada:               ${estimate.countertopType}
        Material escolhido:            ${estimate.materialName}
        ====Bancada================================================
        Posição da cuba:               ${estimate.vatPosition}
        Disposição da bancada:         ${estimate.countertopDisposition}
        Largura bancada molhada:       ${estimate.wetBaseEstimateWidth}
        Profu. bancada molhada:        ${estimate.wetBaseEstimateDepth}
        Largura bancada seca:          ${estimate.dryBaseEstimateWidth1}
        Profu. bancada seca:           ${estimate.dryBaseEstimateDepth1}
        ====Ilharga================================================
        Acabamento ilharga:            ${estimate.flankFinishType}
        Largura frente ilharga:        ${estimate.flankWidth}
        Junção de ilharga:             ${estimate.flankJunctionType}
        ====Cuba===================================================
        Altura Saia/Tira:              ${estimate.stripEstimateHeight}
        Altura Frontspício:            ${estimate.frontEstimateHeight}
        Tipo de cuba:                  ${estimate.vatType}
        Eixo de cuba:                  ${estimate.vatAxle}
        Dist. do eixo:                 ${estimate.vatEstimateAxleDistance}
        Dist. entre Cuba e Saia:       ${estimate.vatEstimateEdgeDistance}
        ====Torneira===============================================
        Tipo de torneira:              ${estimate.faucetType}
        Posição da torneira:           ${estimate.faucetPosition}
        Torneira de parede:            ${estimate.faucetWall}
        ====Fogão==================================================
        Tipo de fogão:                 ${estimate.stoveType}
        Eixo do fogão na bancada:      ${estimate.shaft}
        Dist. do eixo:                 ${estimate.shaftDistance}
        Dist. entre o corte e saia:    ${estimate.shaftFrontDistance}
        ====Instalação=============================================
        Tipo de Instalação:            ${estimate.typeOfInstallation}
        ====PREÇOS=================================================
        Preço do material (m²):        ${estimate.materialPrice}
        Preço da bancada molhada:      ${estimate.wetBasePrice}
        Preço da bancada seca:         ${estimate.dryBasePrice}
        Preço do frontispício:         ${estimate.frontPrice}
        Preço da tira:                 ${estimate.stripPrice}
        Preço da(s) ilharga(s):        ${estimate.flankPrice}\n
        Preço por unidade:             ${estimate.productPrice}
        Quantidade:                    ${estimate.amount}
        Preço pela quantidade:         ${estimate.pricePerAmount}\n
        Preço do serviço:              ${estimate.installationPrice}
        Serviço pela quantidade:       ${estimate.totalInstallationPrice}\n
                      PREÇO TOTAL:     ${estimate.totalPrice}\n                      
                    Orçamento feito em ${data.toLocaleString('pt-BR', {
                      timeZone: 'America/Sao_Paulo',
                    })}\n
        `,
        ],
        {
          type: 'text/plain;charset=utf-8',
        }
      );
      saveAs(blob, 'Detalhes_do_preco_orcamento.txt');
    } else if (estimate.countertopType === 'Bancada em U') {
      const blob = new Blob(
        [
          `
        Produto:                       ${estimate.productName}
        Tipo da bancada:               ${estimate.countertopType}
        Material escolhido:            ${estimate.materialName}
        ====Bancada================================================
        Posição da cuba:               ${estimate.vatPosition}
        Disposição da bancada:         ${estimate.countertopDisposition}
        Largura bancada molhada:       ${estimate.wetBaseEstimateWidth}
        Profu. bancada molhada:        ${estimate.wetBaseEstimateDepth}
        Largura bancada seca 1:        ${estimate.dryBaseEstimateWidth1}
        Profu. bancada seca 1:         ${estimate.dryBaseEstimateDepth1}      
        Largura bancada seca 2:        ${estimate.dryBaseEstimateWidth2}
        Profu. bancada seca 2:         ${estimate.dryBaseEstimateDepth2}
        ====Cuba===================================================
        Altura Saia/Tira:              ${estimate.stripEstimateHeight}
        Altura Frontspício:            ${estimate.frontEstimateHeight}
        Tipo de cuba:                  ${estimate.vatType}
        Eixo de cuba:                  ${estimate.vatAxle}
        Dist. do eixo:                 ${estimate.vatEstimateAxleDistance}
        Dist. entre Cuba e Saia:       ${estimate.vatEstimateEdgeDistance}
        ====Torneira===============================================
        Tipo de torneira:              ${estimate.faucetType}
        Posição da torneira:           ${estimate.faucetPosition}
        Torneira de parede:            ${estimate.faucetWall}
        ====Fogão==================================================
        Tipo de fogão:                 ${estimate.stoveType}
        Eixo do fogão na bancada:      ${estimate.shaft}
        Dist. do eixo:                 ${estimate.shaftDistance}
        Dist. entre o corte e saia:    ${estimate.shaftFrontDistance}
        ====Instalação=============================================
        Tipo de Instalação:            ${estimate.typeOfInstallation}     
        ====PREÇOS=================================================
        Preço do material (m²):        ${estimate.materialPrice}
        Preço da bancada molhada:      ${estimate.wetBasePrice}
        Preço das bancadas secas:      ${estimate.dryBasePrice}
        Preço do frontispício:         ${estimate.frontPrice}
        Preço da tira:                 ${estimate.stripPrice}\n
        Preço por unidade:             ${estimate.productPrice}
        Quantidade:                    ${estimate.amount}
        Preço pela quantidade:         ${estimate.pricePerAmount}\n
        Preço do serviço:              ${estimate.installationPrice}
        Serviço pela quantidade:       ${estimate.totalInstallationPrice}\n
                      PREÇO TOTAL:     ${estimate.totalPrice}\n    
                    Orçamento feito em ${data.toLocaleString('pt-BR', {
                      timeZone: 'America/Sao_Paulo',
                    })}\n
        `,
        ],
        {
          type: 'text/plain;charset=utf-8',
        }
      );
      saveAs(blob, 'Detalhes_do_preco_orcamento.txt');
    }
  }

  return (
    <S.DownloadButton onClick={saveDocument}>
      Baixar detalhes do valor
    </S.DownloadButton>
  );
}
