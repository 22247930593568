
import { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { RiSearchLine } from 'react-icons/ri';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import CardButton from '../../components/CardButton';
import { Buttons, CloseBtn, Container, Input, InputBox, Line, MainBox, MainText, TopBox } from './style';
import { MobileContainer } from '../ProductSelect/style';
import BackBtn from '../../components/BackBtn';
import RoomService from '../../services/RoomService';


function RoomSelect() {

    const [search, setSearch] = useState('');
    // const [room, setRoom] = useState<any[]>([]);
    const roomArray = [ 'Cozinha',
    'Banheiro',
    'Suíte',
    'Lavabo',
    'Espaço Gourmet',
    'Área de Serviço',
    'Área de Lazer',
    'Sala de Estar',
    'Sala de Jantar',
    'Quarto',
    'Hall',
    'Churrasqueira',
    'Varanda',
    'Playground',
    'Terraço',
    'Portaria',
    'Fachada',
    'Corredor',
    'Mezanino',
    'Sótão',
    'Sauna',
    'Spa',
    'Academia',
    'Salão de Festas',
    'Escritório',
    'Closet',
    'Mesanino',
    'Biblioteca',]
    const lowerCaseSearch = search.toLocaleLowerCase();
    const filteredRoom = roomArray.filter((room) => room.toLocaleLowerCase().includes(lowerCaseSearch));
    const navigate = useNavigate();

    const passData = (roomName: string) => {
        sessionStorage.setItem("roomName", roomName)
        navigate('/selecionar-produto')
    }
    
    const addRoomToEstimate = sessionStorage.getItem('addRoomToEstimate');
    const paginaAnterior =
        addRoomToEstimate != undefined
            ? "estimativa"
            : ""

    // useEffect(() => {
    //     RoomService.getRoom().then(res => {
    //         setRoom(res?.data.rooms)
    //         console.log(res?.data.rooms)
    //     })
    // }, [])

    sessionStorage.removeItem("roomName")

    return (
        <MobileContainer>
            <span>Orçamento</span>
            <Container>
                <Link 
                    to={`/${paginaAnterior}`}
                    style={{
                        position: "absolute",
                        top: 109,
                        height: "3.5%",
                        zIndex: 100
                    }}
                >
                    <BackBtn isMobile={true}/>
                </Link>
                <TopBox>
                    <MainText>Selecione o ambiente</MainText>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <InputBox>
                            <RiSearchLine style={{
                                color: "#828282",
                                marginLeft: "5px"
                            }} />
                            <Input /* type="search" */
                                placeholder='Buscar'
                                value={search}
                                onChange={(event: any) => setSearch(event.target.value)}
                            />
                        </InputBox>

                    </div>
                </TopBox>

                <MainBox>
                    <Buttons>
                        {/* botões alimentados com os nomes dados na varíavel "room" */}
                        {filteredRoom.map((item: string, index: number) =>
                        (
                            <CardButton name={item}
                                thereIsImage={false}
                                onClick={() => passData(item)}
                                key={index}
                            />

                        ))}
                    </Buttons>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '50px'
                    }}>
                        <Link to={`/${paginaAnterior}`}>
                            <CloseBtn>
                                <AiOutlineClose style={{
                                    fontSize: '20px',
                                    marginRight: '2px',
                                    color: '#828282'
                                }} />
                                Fechar
                            </CloseBtn>
                        </Link>
                    </div>
                </MainBox>

            </Container >
        </MobileContainer>
    );
}

export default RoomSelect;
