import styled from "styled-components";

export const Main = styled.main`

@media (max-width: 1000px){
display:flex;
padding:3.23% 5%;
width:90%;
background-color: #fff;
flex-direction: column;
border-top-left-radius:20px;
border-top-right-radius: 20px;
margin-top:4vh;
.Title{
  align-self: center;
  
  font-size:1rem;
  font-weight: 700;
  color:#bdbdbd;
  margin-bottom: 2rem;
}
}

@media (min-width: 600px){
height: 100vh;
  
}

`
export const Div = styled.div`

@media (max-width:1000px) {
  display:flex;
  background-color: #f2f2f2;
  width:93%;
  height:30px;
  align-content: center;
  padding-top: 5px;
}
@media (max-width: 1000px){
  left: 4%;
  position: relative; 
}
`

export const BoxU = styled.div`
display: flex;
flex-direction: row;

@media (max-width:1000px){
  flex-direction: column;
  align-self: center;
  gap:10px;
  width: 100%; 
}

`

export const WallWrapper0 = styled.button`
position:absolute;
left: 35%;
bottom:38%;
width:5rem;
background:#F2F2F2;
display:flex;
border:none;
padding: 3px;
/* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25); */
justify-content: space-evenly;
align-items: center;
border-radius:5px;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
}
@media(max-width:2198px){
  left:41%
}
@media(max-width:1920px){
  left:40%
}
@media(max-width:1800px){
  left:39.5%
}
@media(max-width:1700px){
  left:36%
}
@media(max-width:1300px){
  left:35%
}
@media (max-width:1000px){
  background-color: #fff;
  width:84px;
  height:26px;
  display:flex;
  align-items:center;
  justify-content: space-evenly;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  border-radius:5px;
  img{
    width:16px
  }
  top:55%;
  left:20%;
}
@media (max-width:600px){
    left:5%;
}
`

export const WallWrapper1 = styled.button`
position:absolute;
left: 35%;
bottom:42%;
width:5rem;
background:#F2F2F2;
display:flex;
border:none;
padding: 3px;
/* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25); */
justify-content: space-evenly;
border-radius:5px;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
}
@media(max-width:2198px){
  left:58%
}
@media(max-width:1920px){
  left:59%
}
@media(max-width:1800px){
  left:60%
}
@media(max-width:1700px){
  left:63%
}
@media(max-width:1300px){
  left:64%;
}

@media (max-width:1000px){
  background-color: #fff;
  width:84px;
  height:26px;
  display:flex;
  align-items:center;
  justify-content: space-evenly;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  border-radius:5px;
  img{
    width:16px
  }
  top:50%;
  left:70%;
}
@media (max-width:500px){
    left:75%;
}
`
export const WallWrapper2 = styled.button`
position:absolute;
left: 35%;
bottom:42%;
width:5rem;
background:#F2F2F2;
display:flex;
border:none;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
}
@media(max-width:2198px){
  left:58%
}
@media(max-width:1920px){
  left:59%
}
@media(max-width:1800px){
  left:60%
}
@media(max-width:1700px){
  left:63%
}
@media(max-width:1300px){
  left:64%;
}


@media (max-width:1000px){
  background-color: #fff;
  width:84px;
  height:26px;
  display:flex;
  align-items:center;
  justify-content: space-evenly;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  border-radius:5px;
  img{
    width:16px
  }
  top:68%;
  left:45%;
}
@media (max-width:500px){
    top:65%;
    left:42%;
}
`

export const CooktopWrapper1 = styled.button`
position:absolute;
left: 44%;
bottom:75%;
width:5rem;
background:#F2F2F2;
display:flex;
border:none;
padding: 3px;
justify-content: space-evenly;
/* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25); */
border-radius:5px;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
}
@media(max-width:2198px){
  left:47%
}

@media(max-width:1920px){
  left:46%
}
@media(max-width:1600px){
  left:44%
}


@media (max-width:1000px){
  background-color: #fff;
  width:84px;
  height:26px;
  display:flex;
  align-items:center;
  justify-content: space-evenly;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  border-radius:5px;
  img{
    width:16px
  }
  top:20%;
  left:33%;
}

@media (max-width:500px){
    left:25%;
}

`

export const CooktopWrapper0 = styled.button`
position:absolute;
left: 44%;
bottom:50%;
width:5rem;
background:#F2F2F2;
display:flex;
border:none;
padding: 3px;
justify-content: space-evenly;
/* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25); */
border-radius:5px;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
}
@media(max-width:2198px){
  left:45%
}

@media(max-width:1920px){
  left:44%
}
@media(max-width:1600px){
  left:41%
}


@media (max-width:1000px){
  background-color: #fff;
  width:84px;
  height:26px;
  display:flex;
  align-items:center;
  justify-content: space-evenly;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  border-radius:5px;
  img{
    width:16px
  }
  top:45%;
  left:30%;
}

@media (max-width:500px){
    left:20%;
}

`
export const MobileContainer = styled.div`
display:none;
@media(max-width:1000px){
    display:flex;
    flex:1;
    flex-direction:column;
    align-items:center;
    background:#2566C8;
    span{
        padding-top:5.5%;
        display:flex;
        
        color:white;
        font-size:20px;
        font-weight: 700;
    
    }  
}
`

interface benchDetailsProps {
  onAdvance?: boolean;
}

export const TitleInput = styled.h2`

font-weight: 700;
font-size:1rem;
color:#828282;
margin-bottom:0.5rem;
@media (max-width:1000px){
  display: none;
}
`

export const Page = styled.div`
display:flex;
flex-direction:column;
background:#F4F4F4;
padding-top:50px;
padding-left:3.472222222vw;
padding-right:3.472222222vw;
height: auto;
padding-bottom: 20px;
@media (max-width:1000px){
  display: none;
}
`

export const StepCounterIcon = styled.img`
height: 26px;
width:256px;
display:flex;
margin-bottom:3.33vh;
margin-left: 35vw;
@media (max-width: 600px){
    margin-top: 12%;
}
@media (max-width: 1000px){
    margin-left: 0;
}
`
export const Title = styled.h1`
margin-top:3.3vh;
margin-bottom:3.3vh;


font-weight: 700;
font-size:1.5rem;
color:#828282;
@media (max-width:1000px){
  display: none;
}
`
export const ProductBox = styled.div`
display:flex;
align-items:center;
justify-content:center;
background:white;
height: 309px;
width:100%;
border-radius:8px;
margin-bottom:3.33vh;
position:relative;
@media (max-width: 912px) {
    img {
      width: 100%;
    }
  }
`

export const SbmtBtn = styled.button`
height: 40px;
width: 150px;
border:none;
color: #FFF;

font-size: 16px;
background:#2566C8;
border-radius:8px;
&:hover{
  opacity:0.5;
  cursor:pointer;
}
@media (max-width: 1000px) {
  margin: auto;
  width: 340px;
} 
`

export const Box = styled.div<benchDetailsProps>`
display:flex;
flex-direction:row;
justify-content:flex-end;
flex-flow:wrap;
margin-bottom:3.33vh;
gap:${props => props.onAdvance ? "1vw" : "1.4vw"};
@media(max-width:1840px){
padding-right:0.25vw;
}
@media(max-width:1330px){
  padding-right:0px;
  gap:1.39vw;
}
@media(max-width:1255px){
  gap:1.3vw;
}
@media(max-width:1209px){
  gap:1.2vw;
}
@media (max-width:1000px){
  flex-direction: column;
  align-self: center;
  gap:10px;
  width: 100%; 
  p{
    margin-left: -40px;
  }
}
`
export const BoxBot = styled.div`
display:flex;
flex-direction:row;
justify-content:flex-end;
gap:1.1vw;


`
export const CooktopWrapper = styled.button`
position:absolute;
left:36.25vw;
bottom:161px;
background:#F2F2F2;
width:5rem;
display:flex;
border:none;
padding: 6px;
box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
border-radius:5px;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
  
}
@media (max-width:1000px){
  background-color: #fff;
  width:84px;
  height:26px;
  display:flex;
  align-items:center;
  justify-content: space-evenly;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  border-radius:5px;
  img{
    width:16px
  }
  top:25%;
  left:30%;
}
`
export const Parede0wrapper = styled.button`
position:absolute;
left:35%;
bottom:30%;
width:5rem;
background:#F2F2F2;
display:flex;
border:none;
padding: 3px;
justify-content: space-evenly;
/* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25); */
border-radius:5px;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
}
@media(max-width:1300px){
  left:32%
}
@media (max-width:1000px){
  background-color: #fff;
  width:84px;
  height:26px;
  display:flex;
  align-items:center;
  justify-content: space-evenly;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  border-radius:5px;
  img{
    width:16px
  }
  top:60%;
  left:18%;
}
@media(max-width:600px){
  left: 10%;
}
`

export const Icon = styled.img`
height:1rem;
width:1rem;
`

export const OptionWrapper = styled.div`
display:flex;
flex-direction:column;
align-self:flex-start;
gap:30px;
@media (max-width:1000px){
  align-self: center;
  width:100%;
}
`
export const BtnWrapper = styled.div`
display:flex;
flex-direction:row;
gap:25px;
@media (max-width:1000px){
  flex-direction: column;
  
  width:100%;
}
`
export const OptionBtn = styled.button`
width:26.875rem;
height:9.375rem;
border:none;
background:white;
justify-content:space-between;
border-radius:8px;
align-items:center;
display:flex;
padding: 0px 3.75rem 0px 3.75rem;
position:relative;
@media (max-width:1000px){
  display:flex;
  align-self:center;
  width:100%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}
`
export const SecTitle = styled.label`

font-size:20px;
font-weight: 700;
color:#828282;
@media (max-width:1000px){
  font-size: 1rem;
  color:#bdbdbd;
  align-self: center;
}
`
export const Letter = styled.label`

font-size:16px;
font-weight: 700;
color:#828282;
`
export const Option = styled.div`
display:flex;
flex-direction:column;
gap:30px;
align-self:flex-start;
@media (max-width:1000px){
  width:100%;
}
`
export const SelectIcon = styled.img`
position: absolute;
width:22px;
top:11px;
right:11px;
`
export const DisabledBtn = styled.div`
height: 40px;
width: 150px;
border:none;
color: #FFF;

font-size: 16px;
background:#D2D2D2;
border-radius:8px;
display:flex;
justify-content:center;
align-items:center;
@media (max-width: 1000px) {
   margin: auto;
   width: 340px;
}
`
export const Footer = styled.footer`
display:flex;
justify-content:space-between;
margin-top:4.44vh;
@media(max-width:600px){
  justify-content: center;
}
`

export const MobilePage = styled.div`
display: none;

@media (max-width:1000px){
  /* background:#ffffff; */
  background-color: black;
  height:100%;
  display:flex;
  flex-direction:column;
  padding-top:50px;
  padding-left:3.472222222vw;
  padding-right:3.472222222vw;
  padding-bottom: 20px;
}
`
