import * as React from 'react';
import LoginLeftSection from '../../components/LoginLeftSection';
import ResetPasswordCard from '../../components/ResetPasswordCard';
import { Container, ResetRightSection } from './style';

export default function ResetPassword() {
    
    return (
        <Container>
            <LoginLeftSection position={'relative'} />

            <ResetRightSection>
                <ResetPasswordCard position={'absolute'} />
            </ResetRightSection>

        </Container>

    )
}
