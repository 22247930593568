import styled from 'styled-components';

export const DownloadButton = styled.h6`
  align-self: center;
  
  font-size: 0.7rem;
  color: #828282;
  margin-top: 1vh;
  cursor: pointer;
  :hover {
    color: #2566C8
  }
`
