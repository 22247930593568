import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { GoBackBtn, MobileBtn } from './style'

export interface mobileVersion {
    isMobile?: boolean
    style?: React.CSSProperties | undefined
    onClick?: () => void
}

export default function BackBtn({ isMobile, style, onClick }: mobileVersion) {
    const navigate = useNavigate()
    return (
        isMobile ?
            (<MobileBtn type='button' style={style} onClick={onClick}>
                <BiArrowBack
                    style={{
                        color: '#BDBDBD',
                        fontSize: '20px',
                    }} />
            </MobileBtn>) :
            (<GoBackBtn type='button'>
                <BsChevronLeft style={{
                    fontSize: '20px', marginRight: '2px',
                    color: '#828282'
                }} />
                Voltar
            </GoBackBtn >)
    )
}
