import styled from 'styled-components';
export const Page = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
  @media (max-width: 912px) {
    background-color: #ffffff;
  }
`;
export const Info = styled.p`
  display: flex;
  color: #828282;
  font-size: 16px;
  
  font-weight: 300;
  margin-bottom: 30px;
  align-self: center;
  line-height: 18px;
  @media (max-width: 912px) {
    max-width: 85vw;
  }
`;
