import styled from 'styled-components';

export const ButtonAdressDiv = styled.div`
background-color: white;
@media(max-width: 912px) {
margin-right: 2%;
}

`;

export const ButtonAdress = styled.button`
background: transparent;
color: gray;
border: none;
cursor: pointer;
margin-top: 48px;

  &:hover {
    color:#2566C8;
  }

`;

export const Page = styled.div`
display:flex;
flex-direction:column;
padding: 0px 3.47222222222222vw 8% 3.47222222222222vw;
background:#F2f2f2;
height: 100%;
padding-bottom: 40vh;

@media(max-width: 945px) {
  /* padding: 0 0 40vh 0; */
}

@media (max-width: 912px) {
  background-color: #ffffff;
}

`
export const NameDiv = styled.div`
display: flex;
flex-direction: row;
align-items:center;
padding-top:40px;

`
export const Icon = styled.h1`
padding-right:10px;
font-size: 14px;
height: 25px;
width: 24.51px;
align-self:center;
`
export const IdentifyText = styled.h1`
display: contents;
height: 27px;
color: #828282;

font-size: 24px;
font-weight: 700;
margin-left:10px;
`
export const NameText = styled.h1`
height: 20px;
width: 120px;
color: #828282;

font-size: 18px;
font-weight: 300;
`
export const Columns = styled.div`
display:flex;
flex-flow:wrap;
justify-content:flex-start;
gap:1.875rem;
@media(max-width:1397px){
    align-items:column;
    justify-content:center;
  }
@media(max-width:768px){
    width:50%;
  }
`
export const ColumnAmbient = styled.div`
display: column;
width: 65%;
/* justify-content: center;
align-items: center; */
@media(max-width:950px){  
  align-self: center;
  margin-left: 12%;
  
}
@media(max-width:720px){  
  margin-left: 0%;
}
@media(max-width:650px){  
  margin-left: 11%;
  align-self: normal;
}
`
export const NameDivPurchase = styled.div`
display: flex;
padding-top:40px;
width: 10%;
`
export const NameDivAddress = styled.div`
display: flex;
padding-top:40px;
width: auto;
`
export const EsqColumn = styled.div`
float: left;
`
export const DirColumn = styled.div`
float: right;
@media(max-width:1400px){  
float: left;
}
`
export const DirColumnBottom = styled.div`
margin-top:280px;
display: block;
@media(max-width: 1280px) {
  float: left;
  margin-top: 60px;
  display: grid;
}
@media(max-width: 680px) {
  justify-self: center;
}
`
export const ButtonDiv = styled.div`
margin-top:1.25rem;
align-items: center;
height: 3.938rem;
width: 428px;
border-radius: 8px;
background-color: #FFFFFF;
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
display: flex;
flex-direction: row;
&:hover{
}
@media(max-width: 912px) {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px;
}
@media(max-width:550px){
    width: 75vw;
}
`
export const IconCard = styled.h1`
font-size: 14px;
height: 25px;
width: 24.51px;
align-items: center;
margin-left:1.125rem;

`
export const AddProduct = styled.h1`
width: 220px;
color: #BDBDBD;

font-size: 14px;
font-weight: 700;
margin-left:1.156rem;
align-self:center;
&:active{
}
@media(max-width: 391px){
   font-size: 12px;
}
`
interface AddProduct3Props {
  selected?: boolean
}

export const AddProduct3 = styled.h1<AddProduct3Props>`
width: 220px;
color: ${(props) => (props.selected ? '#2566C8': '#BDBDBD')};

font-size: 14px;
font-weight: 700;
margin-left:1.156rem;
align-self:center;
&:active{
  color:#2566C8;
}
`
export const AddAmbientDiv = styled.div`
height: 40px;
width: 210px;
border-radius: 5px;
background-color: #FFFFFF;
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
margin-top:2.5rem;
display:inline-block;
margin-left:330px;
`
export const AddAmbient = styled.div`
color: #BDBDBD;

font-size: 14px;
font-weight: 700;
text-align:center;
padding-top:13px;
`
export const TotalDiv = styled.div`
height: 400px;
width: 400px;
border-radius: 8px;
background-color: #FFFFFF;
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
position: absolute;
right: 40px;
top:124px;
@media(max-width: 1200px) {
  margin-top: 40px;
}
@media(max-width:1397px){
  box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(0, 0, 0, 0.16) 0px 3px 6px;
}
@media(max-width: 950px) {
  position: relative;
  margin-left: 29.6%;
}
@media(max-width: 740px) {
  margin-top: -80px;
  margin-left: 25%;
}
@media(max-width: 705px) {
  margin-left: 25%;
}

@media(max-width: 645px) {
  margin-left: 20.4%;
}

@media(max-width:507px) {
   margin-left: 12.2%;
}
@media(max-width: 450px){
    width: 90vw; 
    min-height: 340px;
    margin-left: 13.1%;
}
@media(max-width: 375px){
    width: 90vw;
    height: 10vh;
    margin-left: 12.9%;
    padding-bottom: 32px;
}


`
export const NumbersDiv = styled.div`
height: 388px;
margin:47px 0px 0px 40px;
@media(max-width: 376px){
  height: 290px;
}
`
export const TitleDiv = styled.div`
display: flex;
flex-direction: row;
height: 27px;
align-items:center;
padding-bottom:2.563rem;
justify-content:space-between;
width:320px;
@media(max-width:450px){
  width: 70vw;
}
`
export const TextTotal = styled.h1`
width: 80px;
color: #BDBDBD;

font-size: 14px;
font-weight: 300;
`
export const PrecoTotal = styled.h1`
color: #828282;

font-size: 25px;
font-weight: 700;
text-align: right;
width: 15rem;
`
export const Title2Div = styled.div`
display: flex;
flex-direction: row;
align-items:center;
padding-top:0.938rem;
justify-content:space-between;
width:320px;
@media(max-width:450px){
  width: 70vw;
}

`
export const Text2Total = styled.h1`
width: 160px;
color: #BDBDBD;

font-size: 14px;
font-weight: 300;
`
export const Preco2Total = styled.h1`
width: 160px;
color: #BDBDBD;

font-size: 15px;
font-weight: 700;
text-align: right;
align-items:center;
`
export const EntregaDiv = styled.div`
display: flex;
flex-direction: row;
height: 20px;
padding-top:2.563rem;
@media(max-width:450px){
  width: 70vw;
}
`
export const EntregaText = styled.h1`
width: 160px;
color: #BDBDBD;

font-size: 14px;
font-weight: 300;
@media(max-width: 375px) {
  margin-top: -18px;
}

`
export const DiaText = styled.h1`
width: 160px;
color: #BDBDBD;

font-size: 15px;
font-weight: 700;
text-align: right;
@media(max-width: 375px) {
  margin-top: -18px;
}
`
export const Mensagem = styled.h1`
width: 226px;
color: #BDBDBD;

font-size: 15px;
font-weight: 700;
text-align: center;
margin: 0 auto 0.938rem;
margin-top: -84px;
@media(max-width: 375px){
  margin-top:  -68px;
}

`

export const Button = styled.button`
border:none;
height: 60px;
width: 320px;
border-radius: 8px;
background-color: #2566C8;
box-shadow: 0 8px 30px 0 rgba(0,44,113,0.25);
display: block;
margin-left: auto; 
margin-right: auto; 
margin-top: -114px;
&:hover{
    cursor:pointer;};

@media(max-width:450px){
  width: 60vw;
  height: 48px;
}
@media(max-width: 376px) {
  margin-top: -52px;
  margin-top: -25px;
}
`

export const Text = styled.h1`

font-size: 20px;
color:#FFFFFF;
text-align:center;
line-height: 24px;
`
export const AddressDiv = styled.div`
padding: 8px 20px 8px 20px;
border-radius: 8px;
background-color: #FFFFFF; 
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
display: flex;
flex-direction: row;
flex-wrap: wrap;
margin-top:1.25rem;
min-height: 63px;
box-sizing: border-box;
color: #828282;
font-size: 14px;
font-weight: 300;
line-height:20px;
width: 428px;
word-break: break-all;
@media(max-width: 912px) {
  width: 75vw;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(0, 0, 0, 0.16) 0px 3px 6px;
  max-width: 425px;
}
@media(max-width: 550px) {
  padding: 10px 20px 10px 20px;
  height: fit-content;
  /* min-width: 250px; */
}
/* @media(max-width: 550px) {
  max-width: 420px;
} */
/* @media(max-width: 500px) {
  max-width: 410px;
  margin-left: 10%;
}

@media(max-width: 450px) {
  max-width: 328px;
  margin-bottom: 10px;
  margin-left: 2%;
}

@media(max-width: 414px) {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px;
  max-width: 320px;
  margin-left: -6%;
} */

`
export const Div = styled.div`
width:344px;
display: flex;
flex-direction: row;
align-items: center;
box-sizing: border-box;
color: #828282;
font-size: 14px;
font-weight: 300;
line-height:20px;

height: fit-content;
*border: solid 2px green;

`
export const InfoDiv = styled.div`
padding-left: 14px;
flex: 1;
@media (max-width: 912px) {
  padding-left: 8px;
}

`
export const Info = styled.h1`


`
export const Input = styled.input`
padding-left:20px;
font-size:1rem;
font-weight: 400;
display:flex;
flex:2;
border:none;
color: #7d7c7c;
&::placeholder{
  color:#7d7c7c
 }
&:focus{
    outline:none;
}
@supports (-webkit-touch-callout: none) {
  /* Atualmente, apenas dispositivos iOS possuem a feature -webkit-touch-callout,
  logo esse código testa se o dispositivo é iOS ou não. Caso a Apple mude algo,
  basta consultar
  https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariCSSRef/Articles/StandardCSSProperties.html
  e buscar por "Safari on iOS only"*/
  font-size: 16px;
  width: 95%;
  }
`

export const InputContainer = styled.div`
display:contents;
flex-direction:row;
margin-bottom:20px;
height:40px;
border-radius:5px;
align-items:center;
img{
    height:40px;
    width:20rem;
}
`
export const Container = styled.div`
  height: 3.125rem;
  max-width: 420px;
  width: 420px;
  justify-content:space-between;
  align-items:center;
  display:flex;
  background:white;
  margin-top:10px;
  h6{
    font-size:16px;
    
    margin-left:10px;
    }
  box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
  border-radius: 8px;
  color: #828282;
@media(max-width: 912px) {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px;
}

@media(max-width: 550px) {
  max-width: 370px;
  width: 75vw;
}

@media(max-width: 450px) {
  max-width: 385px;
  margin-bottom: 10px;
}

@media(max-width: 414px) {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px;
  max-width: 290px;

}
`
export const MPInput = styled.div`
height: 3.125rem;
width: 420px;
padding-left:20px;
font-size:1rem;

display:flex;
/* flex:2; */
border:none;
color: #BDBDBD;
&::placeholder{
  color:#BDBDBD
 }
&:focus{
    outline:none;
}

`