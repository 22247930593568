import * as React from "react";
import BackBtn from "../../components/BackBtn";
import CardButton from "../../components/CardButton";
import MaterialService from "../../services/MaterialService";
import { useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { RiSearchLine } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { colors, IconArray } from "../../mocks/MaterialIcon";
import { BoxName, MobileContainer, Name } from "../ProductSelect/style";
import { Input, InputBox } from "../RoomSelect/style";
import {
    Buttons,
    Container,
    MainBox,
    MainText,
    TopBox,
} from "./style";

function MaterialSelect() {
    const navigate = useNavigate();

    //trazer info do produto selecionado

    let pushingArray: any[] = [];
    let i = 0;
    const chooseMaterial = (
        materialName: string,
        productName: string | null
    ) => {
        sessionStorage.setItem("materialName", materialName);
        navigate(editingSummary || editingProduct ? "/resumo" : "/selecionar-bancada");
    };

    const productName = sessionStorage.getItem("productName");

    const editingSummary = sessionStorage.getItem("editing summary") === 'true' ? true: false
    const editingProduct = sessionStorage.getItem("editing product") === 'true' ? true: false

    // const [data, setData] = useState(false);
    // const [array, setArray] = useState<any[]>([]);

    // sessionStorage.removeItem("materialName");


    //puxando info do back
    // React.useEffect(() => {
    //     MaterialService.getMaterial().then((res) => {
    //         while (i < 20) {
    //             pushingArray.push({
    //                 name: res?.data.materials.map((item: any) => item.name)[i],
    //                 url: IconArray[i],
    //                 color: colors[i],
    //                 available: availableArray[i],
    //             });
    //             i++;
    //             if (i == 20) {
    //                 setData(true);
    //                 setArray(pushingArray);
    //             }
    //         }
    //     });
    // }, []);

    //lógica da barra de pesquisa
    const materialArray = [
        { name: 'Granito Preto São Gabriel', available: 1, color: 'grey', url: IconArray[0] },
        { name: 'Granito Branco Itaúnas', available: 1, color: 'grey' , url: IconArray[1] },
        { name: 'Quartzo Super White', available: 1, color: 'grey' , url: IconArray[2] },
        { name: 'Quartzo Cinza', available: 1, color: 'grey' , url: IconArray[3] },
        { name: 'Quartzo White Star', available: 1, color: 'grey' , url: IconArray[4] },
        { name: 'Quartzo Black Star', available: 0, color: 'grey' , url: IconArray[5] },
        { name: 'Quartzo Brown', available: 0, color: 'grey' , url: IconArray[6] },
        { name: 'Prime Branco', available: 0, color: 'grey' , url: IconArray[7] },
        { name: 'Prime Cinza', available: 0, color: 'grey' , url: IconArray[8] },
        { name: 'Mármore Branco Comum', available: 0, color: 'grey', url: IconArray[9] },
        { name: 'Granito Verde Ubatuba', available: 0, color: 'grey', url: IconArray[10] },
        { name: 'Granito Branco Dallas', available: 0, color: 'grey', url: IconArray[11] },
        { name: 'Granito Amarelo Icaraí', available: 0, color: 'grey', url: IconArray[12] },
        { name: 'Mármore Carrara', available: 0, color: 'grey' , url: IconArray[13] },
        { name: 'Travertino Navona Bruto', available: 0, color: 'grey', url: IconArray[14] },
        { name: 'Nanoglass', available: 1, color: 'grey', url: IconArray[15] },
        { name: 'Laminatto Carrarra Lugano', available: 0, color: 'grey', url: IconArray[16] },
        { name: 'Granito Preto Absoluto', available: 1, color: 'grey', url: IconArray[17] },
        { name: 'Mármore Nero Marquina', available: 0, color: 'grey', url: IconArray[18] },
        { name: 'Mármore Bege Bahia', available: 0, color: 'grey', url: IconArray[19] },];
    const [search, setSearch] = useState("");
    const lowerCaseSearch = search.toLowerCase();
    const filteredMaterial = materialArray.filter((item) =>
        item.name.toLocaleLowerCase().includes(lowerCaseSearch)
    );

    return (
        <MobileContainer>
            <span>Orçamento</span>
            <Container style={{ position: "relative", height: '115vh' }}>
                <Link
                    to={editingSummary || editingProduct ? "/resumo" : "/selecionar-produto"}
                    style={{
                        position: "absolute",
                        top: 20,
                        height: "3.5%",
                        zIndex: 100
                    }}
                >
                    <BackBtn
                        isMobile={true}
                    />
                </Link>
                <BoxName>
                    <Name
                        style={{
                            alignItems: "center",
                        }}
                    >
                        {productName}
                    </Name>
                </BoxName>
                <TopBox>
                    <MainText>Selecione o material</MainText>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                        }}
                    >
                        <InputBox>
                            <RiSearchLine
                                style={{
                                    marginLeft: "5px",
                                    color: "#828282"
                                }}
                            />
                            <Input
                                /* type="search" */
                                placeholder="Buscar"
                                value={search}
                                onChange={(event) =>
                                    setSearch(event.target.value)
                                }
                            />
                        </InputBox>
                    </div>
                </TopBox>
                <MainBox>
                    <Buttons>
                        {filteredMaterial.map((object: any, index: number) => (
                            <CardButton
                                onClick={() =>
                                    object.available
                                        ? chooseMaterial(
                                            object.name,
                                            productName
                                        )
                                        : null
                                }
                                key={index}
                                name={object.name}
                                url={object.url}
                                color={object.color}
                                thereIsImage={true}
                                available={object.available}
                            />
                        ))}
                    </Buttons>
                </MainBox>
                <div style={{ paddingTop: '48px', width: 'fit-content' }}>
                    <Link to={editingSummary || editingProduct ? "/resumo" : "/selecionar-produto"}>
                        <BackBtn />
                    </Link>

                </div>
            </Container>
        </MobileContainer>
    );
}

export default MaterialSelect;
