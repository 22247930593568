import * as React from "react";
import InstallationCard, { InstallationCardProps } from "../InstallationCard";
import UploadButton from "../UploadButton";
import { CardBox, Section, Title, Description } from "./style";
import NotMarked from "../../assets/NotMarked.svg";
import Marked from "../../assets/Marked.svg";
import { useState, useEffect } from "react";
import { DisabledBtn, SbmtBtn } from "../../pages/BenchL/style";
import { Link, useNavigate } from "react-router-dom";
import { BottomContainer } from "../../pages/Installation/style";
import BackBtn from "../BackBtn";

interface sectionProps {
    activeButtons: (options: boolean[]) => void;
}

function InstallationSection({ activeButtons }: sectionProps) {

    function sendToSessionStorage() {
        sessionStorage.setItem("installation", active ? "true" : "false");
        if (active) sessionStorage.setItem(
            "typeOfInstallation",
            active2
                ? "Apoiada em Armário"
                : active3
                    ? "Apoiada em Tubos"
                    : "Apoiada em Alvenaria"
        );
    }
    const editingSummary = sessionStorage.getItem("editing summary") === 'true' ? true: false
    const editingProduct = sessionStorage.getItem("editing product") === 'true' ? true: false

    const navigate = useNavigate();
    //instalacao
    const [active, setActive] = useState<boolean>(false);
    const [active1, setActive1] = useState<boolean>(false);

    //tipo de instalacao
    const [active2, setActive2] = useState<boolean>(false);
    const [active3, setActive3] = useState<boolean>(false);
    const [active4, setActive4] = useState<boolean>(false);
    const [file, setFile] = useState<any>(null);

    activeButtons([active, active1, active2, active3]);

    const installation = [
        { name: "Incluir instalação", status: active },
        { name: "Não incluir instalação", status: active1 },
    ];

    const installationTypes = [
        { name: "Bancada, apoiada em armário", status: active },
        { name: "Bancada apoiada em tubos", status: active },
        { name: "Bancada apoiada em alvenaria", status: active },
    ];

    function activate(index: number) {
        if (index == 0) {
            setActive(!active);

            if (active1) {
                setActive1(!active1);
            }
        } else {
            setActive1(!active1);

            if (active) {
                setActive(!active);
            }
        }
    }
    function activate1(index: number) {
        switch (index) {
            case 0:
                setActive2(!active2);
                setActive3(false);
                setActive4(false);
                break;
            case 1:
                setActive2(false);
                setActive3(!active3);
                setActive4(false);
                break;
            case 2:
                setActive2(false);
                setActive3(false);
                setActive4(!active4);
                break;
        }
    }

    const installationStorage = sessionStorage.getItem("installation");
    const typeOfInstallationStorage = sessionStorage.getItem("typeOfInstallation")

    useEffect(() => {
        if(installationStorage === 'true') {
             setActive(true)
             setActive1(false)
        } 

        if(installationStorage === 'false') {
            setActive(false)
            setActive1(true)
        }

        if(typeOfInstallationStorage === 'Apoiada em Armário') {
            setActive2(true);
            setActive3(false);
            setActive4(false);
        }

        if(typeOfInstallationStorage === 'Apoiada em Tubos') {
            setActive2(false);
            setActive3(true);
            setActive4(false);
        }

        if(typeOfInstallationStorage === 'Apoiada em Alvenaria') {
            setActive2(false);
            setActive3(false);
            setActive4(true);
        }

        if (active1) {
            setActive2(false);
            setActive3(false);
            setActive4(false);
        }
    }, [])


    const activeArray = [active, active1];
    const activeArray1 = [active2, active3, active4];





    return (
        <Section>
            <div>
                <Title>Instalação</Title>
                <CardBox>
                    {installation.map((object, index) => (
                        <InstallationCard
                            {...object}
                            full={true}
                            onClick={() => activate(index)}
                            src={activeArray[index] ? Marked : NotMarked}
                            active={object.status}
                            key={index}
                        />
                    ))}
                </CardBox>
            </div>


            {active &&
                < div style={{ display: active1 ? 'none' : '' }}>
                    <Title>Tipo de Instalação</Title>
                    <CardBox>
                        {installationTypes.map((object, index) => (
                            <InstallationCard
                                {...object}
                                full={true}
                                onClick={() => activate1(index)}
                                src={activeArray1[index] ? Marked : NotMarked}
                                active={object.status}
                                key={index}
                            />
                        ))}
                    </CardBox>
                </div>
            }
            <BottomContainer>
                <Link to={editingSummary || editingProduct ? "/resumo" : "/tipo-de-fogao"}>
                    <BackBtn />
                </Link>
                {active && (active2 || active3 || active4) || (active1) ? (
                    <SbmtBtn
                        onClick={() => {
                            sendToSessionStorage();
                            navigate("/resumo");
                        }}
                    >
                     {editingSummary ? 'Voltar para o Resumo' : 'Avançar'}   
                    </SbmtBtn>
                ) : (
                    <DisabledBtn>{editingSummary ? 'Voltar para o Resumo' : 'Avançar'}</DisabledBtn>
                )}
            </BottomContainer>

            {/* <div>
                <Title>
                    Anexar projeto do Item
                </Title>
                <CardBox>
                    <UploadButton setFile={setFile} />
                    <Description>JPEG, PDF e PNG.</Description>
                </CardBox>
                <div>{file?.name}</div>
            </div> */}
        </Section >
    );
}

export default InstallationSection;
