import styled from 'styled-components';

export const Header = styled.header`
height:80px;
background-color: #FFF;
display:flex;
flex-direction: row;
box-shadow: 0 2px 10px 0 rgba(147,147,147,0.25);
align-items:center;
flex-flow:wrap;
justify-content:space-between;
padding: 0px 25px 0px 25px;
@media (max-width:1200px){
    justify-content: center;
    gap:10px;
    height:100px;
    padding-top:20px;   
}
@media (max-width:912px){
    display: flex;
    position:relative;
    height:auto;
    box-shadow: none;
    padding: 64px 20px 0px 20px;
}
`

interface heightInfo {
  count?:string
}
export const Page = styled.div`
display:flex;
flex-direction:column;
padding: 30px 3.47222222222222vw 0px 3.47222222222222vw;
background: #f2f2f2;
height:120vh;
@media(max-width:1396px){
  height:140vh;
}
@media(max-width: 912px) {
  background-color: #ffffff;

}

@media(max-width:768px){
  height:135vh;

}
@media(max-width:376px){
  height:145vh;

}
`
export const RoomsWrapper = styled.div`
@media(max-width:912px){
    align-self: center;
  }
`
export const NameDiv = styled.div`
display: flex;
flex-direction: row;
align-items:center;
margin-top: 5vh;
@media(max-width:1410px){
  :first-child{
    margin-top: 2vh;
  }
  margin-top: 8vh;
}
`
export const AmbientText = styled.h1`
height: 27px;
width: fit-content;
color: #828282;

font-size: 24px;
font-weight: 700;

`
export const NameText = styled.h1`
height: 20px;
//width: 120px;
color: #828282;

font-size: 18px;
font-weight: 300;
`
export const Columns = styled.div`
display:flex;
flex-flow:wrap;
justify-content:flex-start;
gap:1.875rem;

@media(max-width:1397px){
    align-items:column;
    justify-content:center;
  }
@media(max-width:768px){
    width:50%;
  }

`
export const ColumnAmbient = styled.div`
display: flex;
flex-direction: row;
/* width:50%; */
gap:30px;
@media(max-width:1397px){
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 10vw;
  margin-bottom: 5vh;
}
@media(max-width:1025px){
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 5vw;
}
@media(max-width:1000px){
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0vw;
}
`
export const EsqColumn = styled.div<heightInfo>`
display: flex;
flex-direction: column;
flex-wrap: wrap;
column-gap: 20px;
height: ${prop => prop.count};
*border: solid 2px red;
@media(max-width:1396px){
flex-wrap: nowrap;
}
`
export const DirColumn = styled.div`

`
export const ButtonDiv = styled.div`
margin-top:1.25rem;
height: 3.938rem;
width: 26.25rem;
border-radius: 8px;
background-color: #FFFFFF;
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
display: flex;
flex-direction: row;
@media(max-width:1050px){
position:absolute;
box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px;
/* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */


}
@media(max-width: 912px){
/* box-shadow: 0 15px 20px 0 rgba(142,142,142,0.2); */
box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px;

}
@media(max-width: 525px){
width: 80vw;
/* box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px; */
box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px;

}
`
export const Icon = styled.h1`
font-size: 14px;
height: 25px;
width: 24.51px;
align-self:center;
margin-left:1.125rem;
`
export const AddProduct = styled.h1`
width: 220px;
color: #BDBDBD;

font-size: 14px;
font-weight: 700;
margin-left:1.156rem;
align-self:center;
`
export const AddAmbientDivMobile = styled.div`
display: none;
@media(max-width:1397px){
    display:flex;
    line-height: 20px;
    height: 40px;
    width: 210px;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
    margin-top:2.5rem;
    display:inline-block;
    margin-left:330px;
    flex-direction: column;
    margin: 2vw auto;
}
@media(max-width:1050px){
  margin-top: 10vw;
}
@media(max-width: 1025px){
    box-shadow: 0 15px 20px 0 rgba(142,142,142,0.2);
    margin-top: 14vw;
}
@media(max-width:650px){
  margin-top: 20vw;
}
@media(max-width:450px){
  margin-top: 30vw;
}
`
export const AddAmbientDiv = styled.div`
line-height: 20px;
height: 40px;
width: 210px;
border-radius: 5px;
background-color: #FFFFFF;
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
margin-top:2.5rem;
display:inline-block;
margin-left:330px;
@media(max-width:1397px){
display:  none;
}
`
export const AddAmbient = styled.div`
color: #BDBDBD;

font-size: 14px;
font-weight: 700;
text-align:center;
padding-top:10px;
@media(max-width:912px){
  box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(218, 218, 218, 0.121) 0px 3px 6px;
}

`
export const TotalDiv = styled.div`
height: 400px;
width: 400px;
border-radius: 8px;
background-color: #FFFFFF;
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
position: absolute; 
right: 40px;
top:124px;

@media(max-width:1000px){
  margin: 0 auto ;
  position: relative;
  right: 0px;   
  box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px;
}
@media(max-width:1201px){
  top:195px;
}
@media(max-width: 912px){
    /* box-shadow: 0 15px 20px 0 rgba(142,142,142,0.2); */
/* box-shadow: rgba(60, 64, 67, 0.2) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 4px 2px; */
top: 44px;
margin-top: 15vh;

}

@media(max-width: 450px){
    width: 84vw;
    height: 10vh;
    min-height: 320px;
}
@media(max-width: 375px){
    width: 90vw; 
    top: 4px; 
    min-height: 320px;
    margin-top: 20vh;
}
`
export const NumbersDiv = styled.div`
height: 180px;
margin:47px 0px 0px 40px;
@media(max-width: 450px){
  height: 120px;
}
`
export const TitleDiv = styled.div`
display: flex;
flex-direction: row;
height: 27px;
align-items:center;
padding-bottom:2.563rem;
justify-content:space-between;
width:320px;
@media(max-width:450px){
  width: 70vw;
  height: 14px;
}
`
export const TextTotal = styled.h1`
width: 80px;
color: #BDBDBD;

font-size: 14px;
font-weight: 300;
`
export const PrecoTotal = styled.h1`
color: #828282;

font-size: 25px;
font-weight: 700;
text-align: right;
width: 15rem;
`
export const Title2Div = styled.div`
display: flex;
flex-direction: row;
align-items:center;
padding-top:0.938rem;
justify-content:space-between;
width:320px;
@media(max-width:450px){
  width: 70vw;
}
`
export const Text2Total = styled.h1`
width: 160px;
color: #BDBDBD;

font-size: 14px;
font-weight: 300;
`
export const Preco2Total = styled.h1`
width: 160px;
color: #BDBDBD;

font-size: 15px;
font-weight: 700;
text-align: right;
align-items:center;
`
export const EntregaDiv = styled.div`
display: flex;
flex-direction: row;
height: 20px;
padding-top:2.563rem;
@media(max-width:450px){
  width: 70vw;
}
`
export const EntregaText = styled.h1`
width: 160px;
color: #BDBDBD;

font-size: 14px;
font-weight: 300;
`
export const DiaText = styled.h1`
width: 160px;
color: #BDBDBD;

font-size: 15px;
font-weight: 700;
text-align: right;
`
export const Mensagem = styled.h1`
width: 226px;
color: #BDBDBD;

font-size: 15px;
font-weight: 700;
text-align: center;
margin: 0 auto 0.938rem;
@media (max-width: 450px) {
  margin-top: 2rem;
}

`
export const ButtonFazerPedidoDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
`;

export const Button = styled.button`
/* display:flex;
align-items: center;
justify-content: center; */
border:none;
height: 60px;
width: 320px;
border-radius: 8px;
background-color: #2566C8;
box-shadow: 0 8px 30px 0 rgba(0,44,113,0.25);
display: block;
/* margin-left: auto; */
/* margin-right: auto; */

&:hover{
    cursor:pointer;};

@media(max-width:450px){
  width: 60vw;
  height: 48px;
}
`

export const Text = styled.h1`

font-size: 20px;
color:#FFFFFF;
text-align:center;
line-height: 24px;
`

export const PopUpEstimate = styled.div`
position: absolute;
z-index: 1;
right: 0;
width: 8.6%;
padding: 12px;
border: none;
background-color: white;
margin-top: 0.2rem;
height: 18%;
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
display: flex;
justify-content: center;
flex-direction: column;
gap: 16px;
border: 0.5px solid lightgray;
@media (min-width: 914px) and (max-width:1200px) {
  margin-right: 24%;
}


@media (min-width: 913px) and (max-width: 981px) {
    margin-right: 21%;
    border: 0.5px solid lightgray;
}

@media (max-width: 912px) {
    height: 100vh;
    width: 240px;
    top: 0;
    left: 0;
    text-align: center;
    gap: 64px;
    margin-top: -1rem;
    justify-content: start;
    padding-top: 86px;

}

@media (min-width:200px) and (max-width:912px) {
    height: 140vh;
    width: 200px;
}

@media (min-width:389px) and (max-width:414px) {
    height: 120vh;
}
`;