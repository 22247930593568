import * as React from 'react';
import BenchIcon from '../../assets/BancadaL-D2-detalhes.svg';
import DetailsInput from '../../components/DetailsInput';
import { StepCounter } from '../../components/StepCounter';
import {
  Box,
  Option,
  CooktopWrapper,
  Page,
  Parede0wrapper,
  Parede1wrapper,
  ProductBox,
  SbmtBtn,
  Title,
  Icon,
  IlhargaWrapper0,
  IlhargaWrapper1,
  OptionWrapper,
  BtnWrapper,
  OptionBtn,
  SecTitle,
  Letter,
  SelectIcon,
  DisabledBtn,
  Footer,
  Main,
  TitleInput,
  IlhargaImage,
  Div,
  BoxL,
} from './style';
import { useEffect, useState } from 'react';
import Marked from '../../assets/Marked.svg';
import NotMarked from '../../assets/NotMarked.svg';
import IlhargaSimplesTopo from '../../assets/IlhargaSimplesTopo.svg';
import IlhargaSimplesChanfrado from '../../assets/IlhargaSimplesChanfrado.svg';
import IlhargaDuploChanfrado from '../../assets/IlhargaDuploChanfrado.svg';
import IlhargaDuploTopo from '../../assets/IlhargaDuploTopo.svg';
import { Link, useNavigate } from 'react-router-dom';
import BackBtn from '../../components/BackBtn';
import { MobileContainer } from './style';
import { useForm } from 'react-hook-form';
import { Container, Input } from '../../components/DetailsInput/style';
import { StyleErrorMessage } from '../../components/StyleErrorMessage';
import OptionBtnCard from '../../components/OptionBtnCard';

function BenchL() {
  //validação
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ mode: 'onBlur' });

  const onSubmit = () => {
    sendToSessionStorage();
    editingSummary ? navigate('/resumo') : navigate('/cuba');
  };

  //checks
  const [cooktop, setCooktop] = useState<any>(false);
  const [wallLeft, setWallLeft] = useState<any>(false);
  const [rightWall, setRightWall] = useState<any>(false);
  const [ilhargaLeft, setIlhargaLeft] = useState<any>(false);
  const [rightIlharga, setRightIlharga] = useState<any>(false);

  //advance é a variável q controla o tamanho da página
  const [advance, setAdvance] = useState<boolean>(false);
  //simples ou dupla
  const [single, setSingle] = useState<boolean>(false);
  const [double, setDouble] = useState<boolean>(false);
  //topo ou chanfrado
  const [top, setTop] = useState<boolean>(false);
  const [chanfrado, setChanfrado] = useState<boolean>(false);
  //inputs
  const [controller0, setController0] = useState<number>();
  const [controller1, setController1] = useState<number>();
  const [controller2, setController2] = useState<number>();
  const [controller3, setController3] = useState<number>();
  const [controller4, setController4] = useState<number>();
  const [ilhargaWidth, setIlhargaWidth] = useState<number>();

  console.log(advance);
  //verificações para o botão de avançar não ativar do nada, e para setar todas opções de ilharga como false caso selecione-se parede nas 2 opções
  useEffect(() => {
    if (
      (ilhargaLeft || rightIlharga) &&
      (controller0 &&
        controller1 &&
        controller2 &&
        controller3 &&
        controller4) !== (undefined && 0)
    ) {
      setAdvance(true);
    }
    if ((ilhargaLeft || rightIlharga) === false) {
      setAdvance(false);
    }
    if (wallLeft && rightWall) {
      setTop(false);
      setChanfrado(false);
      setDouble(false);
      setSingle(false);
      setIlhargaWidth(undefined);
    }
  }, [
    advance,
    controller0,
    controller1,
    controller2,
    controller3,
    controller4,
    ilhargaLeft,
    rightIlharga,
    rightWall,
    wallLeft,
  ]);

  //selecionar os checks
  function selectLeftWall() {
    setWallLeft(!wallLeft);
    setIlhargaLeft(false);
    setDouble(false);
    setSingle(false);
    setTop(false);
    setChanfrado(false);
  }
  function selectLeftIlharga() {
    setWallLeft(false);
    setIlhargaLeft(!ilhargaLeft);
    setDouble(false);
    setSingle(false);
    setTop(false);
    setChanfrado(false);
  }
  function selectRightWall() {
    setRightIlharga(false);
    setRightWall(!rightWall);
    setDouble(false);
    setSingle(false);
    setTop(false);
    setChanfrado(false);
  }
  function selectRightIlharga() {
    setRightIlharga(!rightIlharga);
    setRightWall(false);
    setDouble(false);
    setSingle(false);
    setTop(false);
    setChanfrado(false);
  }

  function selectSingle() {
    setSingle(!single);
    setDouble(false);
    setTop(false);
    setChanfrado(false);
  }
  function selectDouble() {
    setDouble(!double);
    setSingle(false);
    setTop(false);
    setChanfrado(false);
  }
  function selectTop() {
    setTop(!top);
    setChanfrado(false);
  }
  function selectChanfrado() {
    setChanfrado(!chanfrado);
    setTop(false);
  }

  const ilhargaStyle = [
    {
      title: 'Acabamento Ilharga',
      options: ['Simples', 'Dupla'],
      input: 'Largura Frente Ilharga',
      placeholder: 'Largura',
      placeholderMobile: 'Largura Frente Ilharga',
    },
    { title: 'Junção de Ilharga', options: ['Topo', 'Chanfrado'] },
  ];

  const inputs = [
    {
      type: 'Quantidade ',
      placeholder: 'Quantidade',
      unity: 'unidade',
    },
    {
      type: 'Largura Bancada Molhada',
      placeholder: 'Largura Bancada Molhada',
      unity: 'milímetros',
    },
    {
      type: 'Largura Bancada Seca',
      placeholder: 'Largura Bancada Seca',
      unity: 'milímetros',
    },
    {
      type: 'Profundidade Bancada Molhada',
      placeholder: 'Profundidade Bancada Molhada',
      unity: 'milímetros',
    },
    {
      type: 'Profundidade Bancada Seca',
      placeholder: 'Profundidade Bancada Seca',
      unity: 'milímetros',
    },
  ];

  const navigate = useNavigate();
  const editingSummary = sessionStorage.getItem('editing summary');
  const inputValue = [
    controller0,
    controller1,
    controller2,
    controller3,
    controller4,
  ];
  const setController = [
    setController0,
    setController1,
    setController2,
    setController3,
    setController4,
  ];

  console.log(inputValue);

  //armazenar dados pra session storage

  let position = "";

  if (ilhargaLeft && !rightIlharga) {
    position = "Ilharga na Esquerda";
  } else if (rightIlharga && ilhargaLeft) {
    position = "Ilharga em ambos lados";
  } else {
    position = "Ilharga na Direita";
  }

  let finishType = "";
  if (single) {
    finishType = "Simples";
  } else if (double) {
    finishType = "Dupla";
  }

  let junctionType = "";
  if (top) {
    junctionType = "Topo";
  } else if (chanfrado) {
    junctionType = "Chanfrado";
  }

  function sendToSessionStorage() {
    sessionStorage.setItem("cooktop", cooktop ? cooktop : "");
    sessionStorage.setItem("amount", controller0 ? controller0.toString() : "");
    sessionStorage.setItem(
      "dryBaseEstimateWidth",
      controller2 ? controller2.toString() : ""
    );
    sessionStorage.setItem(
      "dryBaseEstimateDepth",
      controller4 ? controller4.toString() : ""
    );

    sessionStorage.setItem(
      "wetBaseEstimateWidth",
      controller1 ? controller1.toString() : ""
    );
    sessionStorage.setItem(
      "wetBaseEstimateDepth",
      controller3 ? controller3.toString() : ""
    );
    if (ilhargaLeft || rightIlharga) {
      sessionStorage.setItem("flankPosition", position);
      sessionStorage.setItem(
        "flankWidth",
        ilhargaWidth ? String(ilhargaWidth) : ""
      );
      sessionStorage.setItem("flankFinishType", finishType);
      sessionStorage.setItem("flankJunctionType", junctionType);
    } else if (rightWall && wallLeft) {
      sessionStorage.removeItem("flankPosition");
      sessionStorage.removeItem("flankWidth");
      sessionStorage.removeItem("flankFinishType");
      sessionStorage.removeItem("flankJunctionType");
    }
    navigate("/cuba");
  }

  const amount = sessionStorage.getItem("amount");
  const wetBaseEstimateWidth = sessionStorage.getItem("wetBaseEstimateWidth");
  const wetBaseEstimateDepth = sessionStorage.getItem("wetBaseEstimateDepth");
  const dryBaseEstimateDepth = sessionStorage.getItem("dryBaseEstimateDepth");
  const dryBaseEstimateWidth = sessionStorage.getItem("dryBaseEstimateWidth");
  const flankPosition = sessionStorage.getItem("flankPosition");
  const flankWidth = sessionStorage.getItem("flankWidth");
  const flankFinishType = sessionStorage.getItem("flankFinishType");
  const flankJunctionType = sessionStorage.getItem("flankJunctionType");
  const cooktopType = sessionStorage.getItem("cooktop");

  useEffect(() => {
    if (amount) {
      setController0(Number(amount));
      setValue("quantidade", amount);
    }
    if (wetBaseEstimateWidth) {
      setController1(Number(wetBaseEstimateWidth));
      setValue("largura1", wetBaseEstimateWidth);
    }
    if (dryBaseEstimateWidth) {
      setController2(Number(dryBaseEstimateWidth));
      setValue("largura2", dryBaseEstimateWidth);
    }
    if (wetBaseEstimateDepth) {
      setController3(Number(wetBaseEstimateDepth));
      setValue("profundidade1", wetBaseEstimateDepth);
    }
    if (dryBaseEstimateDepth) {
      setController4(Number(dryBaseEstimateDepth));
      setValue("profundidade2", dryBaseEstimateDepth);
    }
    if (flankWidth) {
      setIlhargaWidth(Number(flankWidth));
      setValue("largura", flankWidth);
    }
    if (cooktopType) {
      setCooktop(cooktopType);
    }
  }, []);

  useEffect(() => {
    switch (flankPosition) {
      case "Ilharga em ambos lados":
        setRightIlharga(true)
        setIlhargaLeft(true)
        setWallLeft(false)
        setRightWall(false)
        break

      case "Ilharga na Esquerda":
        setRightIlharga(false)
        setIlhargaLeft(true)
        setWallLeft(false)
        setRightWall(true)
        break

      case "Ilharga na Direita":
        setRightIlharga(true)
        setIlhargaLeft(false)
        setWallLeft(true)
        setRightWall(false)
        break

      default:
        if (editingSummary) {
          setRightIlharga(false)
          setIlhargaLeft(false)
          setWallLeft(true)
          setRightWall(true)
          break
        }
        setRightIlharga(false)
        setIlhargaLeft(false)
        setWallLeft(false)
        setRightWall(false)
    }
  }, [])

  useEffect(() => {
    if (flankFinishType === "Simples") {
      setSingle(true);
      setDouble(false);
    }  
    
    if (flankFinishType === "Dupla") {
      setSingle(false);
      setDouble(true);
    }
  }, []);

  useEffect(() => {
    if (flankJunctionType === "Topo") {
      setTop(true);
      setChanfrado(false);
    }  
    
    if (flankJunctionType === "Chanfrado") {
      setTop(false);
      setChanfrado(true);
    }
  }, []);

  return (
    <>
      <Page>
        <StepCounter step={2} />

        <Title>Detalhes Bancada</Title>

        <ProductBox>
          <img
            src={BenchIcon}
            alt="Imagem da bancada"
            style={{ width: "450px" }}
          />
          <CooktopWrapper onClick={() => setCooktop(!cooktop)}>
            <Icon src={cooktop ? Marked : NotMarked} />
            <label>Cooktop</label>
          </CooktopWrapper>

          <Parede0wrapper onClick={() => selectLeftWall()}>
            <Icon src={wallLeft ? Marked : NotMarked} />
            <label>Parede</label>
          </Parede0wrapper>

          <Parede1wrapper onClick={() => selectRightWall()}>
            <Icon src={rightWall ? Marked : NotMarked} />
            <label>Parede</label>
          </Parede1wrapper>

          <IlhargaWrapper0 onClick={() => selectLeftIlharga()}>
            <Icon src={ilhargaLeft ? Marked : NotMarked} />
            <label>Ilharga</label>
          </IlhargaWrapper0>

          <IlhargaWrapper1 onClick={() => selectRightIlharga()}>
            <Icon src={rightIlharga ? Marked : NotMarked} />
            <label>Ilharga</label>
          </IlhargaWrapper1>
        </ProductBox>

        <form onSubmit={handleSubmit(onSubmit)}>
          <BoxL>
            <Box>
              <Div>
                <TitleInput>Quantidade</TitleInput>
                <Container largura={30}>
                  <Input
                    type="number"
                    {...register("quantidade", {
                      required: true,
                      max: 2300,
                      min: 1,
                    })}
                    placeholder={"Quantidade"}
                    value={inputValue[0]}
                    onChange={(event: any) => {
                      setValue("quantidade", event.target.value);
                      setController[0](event.target.value);
                    }}
                  />
                  <h6>unidade(s)</h6>
                </Container>
                {errors.quantidade?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 2300 unid</p>
                )}
                {errors.quantidade?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 1 unid</p>
                )}
                {errors.quantidade?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Div>
            </Box>

            <Box>
              <Div>
                <TitleInput>Largura Bancada Molhada</TitleInput>
                <Container largura={30}>
                  <Input
                    type="number"
                    {...register("largura1", {
                      required: true,
                      max: 2300,
                      min: 650,
                    })}
                    placeholder={"Largura"}
                    value={inputValue[1]}
                    onChange={(event: any) => {
                      setValue("largura1", event.target.value);
                      setController[1](event.target.value);
                    }}
                  />
                  <h6>milímetros</h6>
                </Container>
                {errors.largura1?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 2300 milímetros</p>
                )}
                {errors.largura1?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 650 milímetros</p>
                )}
                {errors.largura1?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Div>

              <Div>
                <TitleInput>Largura Bancada Seca</TitleInput>
                <Container largura={30}>
                  <Input
                    type="number"
                    {...register("largura2", {
                      required: true,
                      max: 2300,
                      min: 300,
                    })}
                    placeholder={"Largura"}
                    value={inputValue[2]}
                    onChange={(event: any) => {
                      setValue("largura2", event.target.value);
                      setController[2](event.target.value);
                    }}
                  />
                  <h6>milímetros</h6>
                </Container>
                {errors.largura2?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 2300 milímetros</p>
                )}
                {errors.largura2?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 300 milímetros</p>
                )}
                {errors.largura2?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Div>
            </Box>

            <Box>
              <Div>
                <TitleInput>Profundidade Bancada Molhada</TitleInput>
                <Container largura={30}>
                  <Input
                    type="number"
                    {...register("profundidade1", {
                      required: true,
                      max: 1000,
                      min: 500,
                    })}
                    placeholder={"Profundidade"}
                    value={inputValue[3]}
                    onChange={(event: any) => {
                      setValue("profundidade1", event.target.value);
                      setController[3](event.target.value);
                    }}
                  />
                  <h6>milímetros</h6>
                </Container>
                {errors.profundidade1?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 1000 milímetros</p>
                )}
                {errors.profundidade1?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 500 milímetros</p>
                )}
                {errors.profundidade1?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Div>

              <Div>
                <TitleInput>Profundidade Bancada Seca</TitleInput>
                <Container largura={30}>
                  <Input
                    type="number"
                    {...register("profundidade2", {
                      required: true,
                      max: 1000,
                      min: 500,
                    })}
                    placeholder={"Profundidade"}
                    value={inputValue[4]}
                    onChange={(event: any) => {
                      setValue("profundidade2", event.target.value);
                      setController[4](event.target.value);
                    }}
                  />
                  <h6>milímetros</h6>
                </Container>
                {errors.profundidade2?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 1000 milímetros</p>
                )}
                {errors.profundidade2?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 500 milímetros</p>
                )}
                {errors.profundidade2?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Div>
            </Box>
          </BoxL>

          {(ilhargaLeft || rightIlharga) &&
            (wallLeft || rightWall || (ilhargaLeft && rightIlharga)) &&
            controller0 &&
            controller1 &&
            controller2 &&
            controller3 &&
            controller4 ? (
            <>
              <Option>
                <OptionWrapper>
                  <SecTitle>{ilhargaStyle[0].title}</SecTitle>
                  <BtnWrapper>
                    <OptionBtnCard
                      label={"Simples"}
                      icon={IlhargaSimplesTopo}
                      selected={single ? Marked : NotMarked}
                      onClick={() => selectSingle()}
                      iconStyle={{ width: "55px" }}
                      btnStyle={{ width: "26.875rem" }}
                    />
                    <OptionBtnCard
                      label={"Dupla"}
                      icon={IlhargaDuploTopo}
                      selected={double ? Marked : NotMarked}
                      onClick={() => selectDouble()}
                      iconStyle={{ width: "55px" }}
                      btnStyle={{ width: "26.875rem" }}
                    />
                  </BtnWrapper>
                  <div>
                    <TitleInput>{ilhargaStyle[0].input}</TitleInput>
                    <Container largura={30}>
                      <Input
                        type="number"
                        {...register("largura", {
                          required: true,
                          max: 800,
                          min: 40,
                        })}
                        placeholder={ilhargaStyle[0].placeholder}
                        value={ilhargaWidth}
                        onChange={(event: any) => {
                          setValue("largura", event.target.value);
                          setIlhargaWidth(event.target.value);
                        }}
                      />
                      <h6>milímetros</h6>
                    </Container>
                    {errors.largura?.type === "max" && (
                      <p style={StyleErrorMessage}>Máximo de 800 milímetros</p>
                    )}
                    {errors.largura?.type === "min" && (
                      <p style={StyleErrorMessage}>Mínimo de 40 milímetros</p>
                    )}
                    {errors.largura?.type === "required" && (
                      <p style={StyleErrorMessage}>Campo obrigatório</p>
                    )}
                  </div>
                </OptionWrapper>

                {double && ilhargaWidth ? (
                  <OptionWrapper>
                    <SecTitle>{ilhargaStyle[1].title}</SecTitle>
                    <BtnWrapper>
                      <OptionBtnCard
                        label={"Topo"}
                        icon={IlhargaDuploTopo}
                        selected={top ? Marked : NotMarked}
                        onClick={() => selectTop()}
                        iconStyle={{ width: "55px" }}
                        btnStyle={{ width: "26.875rem" }}
                      />
                      <OptionBtnCard
                        label={"Chanfrado"}
                        icon={IlhargaDuploChanfrado}
                        selected={chanfrado ? Marked : NotMarked}
                        onClick={() => selectChanfrado()}
                        iconStyle={{ width: "55px" }}
                        btnStyle={{ width: "26.875rem" }}
                      />
                    </BtnWrapper>
                  </OptionWrapper>
                ) : (
                  ""
                )}
                {single && ilhargaWidth ? (
                  <OptionWrapper>
                    <SecTitle>{ilhargaStyle[1].title}</SecTitle>
                    <BtnWrapper>
                      <OptionBtnCard
                        label={"Topo"}
                        icon={IlhargaSimplesTopo}
                        selected={top ? Marked : NotMarked}
                        onClick={() => selectTop()}
                        iconStyle={{ width: "55px" }}
                        btnStyle={{ width: "26.875rem" }}
                      />
                      <OptionBtnCard
                        label={"Chanfrado"}
                        icon={IlhargaSimplesChanfrado}
                        selected={chanfrado ? Marked : NotMarked}
                        onClick={() => selectChanfrado()}
                        iconStyle={{ width: "55px" }}
                        btnStyle={{ width: "26.875rem" }}
                      />
                    </BtnWrapper>
                  </OptionWrapper>
                ) : (
                  ""
                )}
              </Option>
            </>
          ) : (
            ""
          )}

          <Footer>
            <Link to="/selecionar-bancada">
              <BackBtn />
            </Link>
            {
              // eslint-disable-next-line no-mixed-operators
              (wallLeft &&
                rightWall &&
                controller0 &&
                controller1 &&
                controller2 &&
                controller3 &&
                controller4) ||
                (single && (top || chanfrado) && ilhargaWidth) ||
                (double && (top || chanfrado) && ilhargaWidth) ? (
                <>
                  {editingSummary ? (
                    <SbmtBtn>Voltar para o Resumo</SbmtBtn>
                  ) : (
                    <SbmtBtn>Avançar</SbmtBtn>
                  )}
                </>
              ) : (
                <DisabledBtn style={{ textAlign: "center" }}>
                  {editingSummary ? "Voltar para o Resumo" : "Avançar"}
                </DisabledBtn>
              )
            }
          </Footer>
        </form>
      </Page>

      {/* responsividade mobile */}
      <MobileContainer>
        <span>Orçamento</span>
        <Main style={{ position: "relative", height: "100%" }}>
          <h1 className="Title">Bancada em L</h1>
          <BackBtn
            onClick={() => navigate("/selecionar-bancada")}
            isMobile={true}
            style={{ position: "absolute", top: 15, left: 0 }}
          />

          <StepCounter step={2} />

          <Title>Detalhes Bancada</Title>

          <ProductBox>
            <img
              style={{ maxWidth: "446.31px" }}
              src={BenchIcon}
              alt="Imagem da bancada"
            />

            <CooktopWrapper onClick={() => setCooktop(!cooktop)}>
              <Icon src={cooktop ? Marked : NotMarked} />
              <label>Cooktop</label>
            </CooktopWrapper>

            <Parede0wrapper onClick={() => selectLeftWall()}>
              <Icon src={wallLeft ? Marked : NotMarked} />
              <label>Parede</label>
            </Parede0wrapper>

            <Parede1wrapper onClick={() => selectRightWall()}>
              <Icon src={rightWall ? Marked : NotMarked} />
              <label>Parede</label>
            </Parede1wrapper>

            <IlhargaWrapper0 onClick={() => selectLeftIlharga()}>
              <Icon src={ilhargaLeft ? Marked : NotMarked} />
              <label>Ilharga</label>
            </IlhargaWrapper0>

            <IlhargaWrapper1 onClick={() => selectRightIlharga()}>
              <Icon src={rightIlharga ? Marked : NotMarked} />
              <label>Ilharga</label>
            </IlhargaWrapper1>
          </ProductBox>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box style={{ position: "relative" }} onAdvance={advance}>
              <DetailsInput
                {...register("quantidade", {
                  required: true,
                  max: 2300,
                  min: 1,
                })}
                largura={30}
                type={inputs[0].type}
                placeholder={inputs[0].placeholder}
                unity={inputs[0].unity}
                value={inputValue[0]}
                onChange={(event: any) => {
                  setValue("quantidade", event.target.value);
                  setController[0](event.target.value);
                }}
              />
              {errors.quantidade?.type === "max" && (
                <p style={StyleErrorMessage}>Máximo de 2300 unid</p>
              )}
              {errors.quantidade?.type === "min" && (
                <p style={StyleErrorMessage}>Mínimo de 1 unid</p>
              )}
              {errors.quantidade?.type === "required" && (
                <p style={StyleErrorMessage}>Campo obrigatório</p>
              )}

              <DetailsInput
                {...register("largura1", {
                  required: true,
                  max: 2300,
                  min: 650,
                })}
                largura={30}
                type={inputs[1].type}
                placeholder={inputs[1].placeholder}
                unity={inputs[1].unity}
                value={inputValue[1]}
                onChange={(event: any) => {
                  setValue("largura1", event.target.value);
                  setController[1](event.target.value);
                }}
              />
              {errors.largura1?.type === "max" && (
                <p style={StyleErrorMessage}>Máximo de 2300 milímetros</p>
              )}
              {errors.largura1?.type === "min" && (
                <p style={StyleErrorMessage}>Mínimo de 650 milímetros</p>
              )}
              {errors.largura1?.type === "required" && (
                <p style={StyleErrorMessage}>Campo obrigatório</p>
              )}

              <DetailsInput
                {...register("largura2", {
                  required: true,
                  max: 2300,
                  min: 300,
                })}
                largura={30}
                type={inputs[2].type}
                placeholder={inputs[2].placeholder}
                unity={inputs[2].unity}
                value={inputValue[2]}
                onChange={(event: any) => {
                  setValue("largura2", event.target.value);
                  setController[2](event.target.value);
                }}
              />
              {errors.largura2?.type === "max" && (
                <p style={StyleErrorMessage}>Máximo de 2300 milímetros</p>
              )}
              {errors.largura2?.type === "min" && (
                <p style={StyleErrorMessage}>Mínimo de 300 milímetros</p>
              )}
              {errors.largura2?.type === "required" && (
                <p style={StyleErrorMessage}>Campo obrigatório</p>
              )}

              <DetailsInput
                {...register("profundidade1", {
                  required: true,
                  max: 1000,
                  min: 500,
                })}
                largura={30}
                type={inputs[3].type}
                placeholder={inputs[3].placeholder}
                unity={inputs[3].unity}
                value={inputValue[3]}
                onChange={(event: any) => {
                  setValue("profundidade1", event.target.value);
                  setController[3](event.target.value);
                }}
              />
              {errors.profundidade1?.type === "max" && (
                <p style={StyleErrorMessage}>Máximo de 1000 milímetros</p>
              )}
              {errors.profundidade1?.type === "min" && (
                <p style={StyleErrorMessage}>Mínimo de 500 milímetros</p>
              )}
              {errors.profundidade1?.type === "required" && (
                <p style={StyleErrorMessage}>Campo obrigatório</p>
              )}
              <DetailsInput
                {...register("profundidade2", {
                  required: true,
                  max: 1000,
                  min: 500,
                })}
                largura={30}
                type={inputs[4].type}
                placeholder={inputs[4].placeholder}
                unity={inputs[4].unity}
                value={inputValue[4]}
                onChange={(event: any) => {
                  setValue("profundidade2", event.target.value);
                  setController[4](event.target.value);
                }}
              />
              {errors.profundidade2?.type === "max" && (
                <p style={StyleErrorMessage}>Máximo de 1000 milímetros</p>
              )}
              {errors.profundidade2?.type === "min" && (
                <p style={StyleErrorMessage}>Mínimo de 500 milímetros</p>
              )}
              {errors.profundidade2?.type === "required" && (
                <p style={StyleErrorMessage}>Campo obrigatório</p>
              )}
            </Box>

            {(ilhargaLeft || rightIlharga) &&
              (wallLeft || rightWall || (ilhargaLeft && rightIlharga)) &&
              controller0 &&
              controller1 &&
              controller2 &&
              controller3 &&
              controller4 ? (
              <>
                <Option>
                  <OptionWrapper>
                    <SecTitle>{ilhargaStyle[0].title}</SecTitle>
                    <BtnWrapper>
                      <OptionBtnCard
                        label={"Simples"}
                        icon={IlhargaSimplesTopo}
                        selected={single ? Marked : NotMarked}
                        onClick={() => selectSingle()}
                        iconStyle={{ width: "55px" }}
                      />
                      <OptionBtnCard
                        label={"Dupla"}
                        icon={IlhargaDuploTopo}
                        selected={double ? Marked : NotMarked}
                        onClick={() => selectDouble()}
                        iconStyle={{ width: "55px" }}
                      />
                    </BtnWrapper>
                    <DetailsInput
                      {...register("largura", {
                        required: true,
                        max: 800,
                        min: 40,
                      })}
                      largura={30}
                      type={ilhargaStyle[0].input}
                      placeholder={ilhargaStyle[0].placeholderMobile}
                      unity="milímetros"
                      value={ilhargaWidth}
                      onChange={(event: any) => {
                        setValue("largura", event.target.value);
                        setIlhargaWidth(event.target.value);
                      }}
                    />
                  </OptionWrapper>
                  {errors.largura?.type === "max" && (
                    <p style={StyleErrorMessage}>Máximo de 800 milímetros</p>
                  )}
                  {errors.largura?.type === "min" && (
                    <p style={StyleErrorMessage}>Mínimo de 40 milímetros</p>
                  )}
                  {errors.largura?.type === "required" && (
                    <p style={StyleErrorMessage}>Campo obrigatório</p>
                  )}

                  {double && ilhargaWidth ? (
                    <OptionWrapper>
                      <SecTitle>{ilhargaStyle[1].title}</SecTitle>
                      <BtnWrapper>
                        <OptionBtnCard
                          label={"Topo"}
                          icon={IlhargaDuploTopo}
                          selected={top ? Marked : NotMarked}
                          onClick={() => selectTop()}
                          iconStyle={{ width: "55px" }}
                        />
                        <OptionBtnCard
                          label={"Chanfrado"}
                          icon={IlhargaDuploChanfrado}
                          selected={chanfrado ? Marked : NotMarked}
                          onClick={() => selectChanfrado()}
                          iconStyle={{ width: "55px" }}
                        />
                      </BtnWrapper>
                    </OptionWrapper>
                  ) : (
                    ""
                  )}
                  {single && ilhargaWidth ? (
                    <OptionWrapper>
                      <SecTitle>{ilhargaStyle[1].title}</SecTitle>
                      <BtnWrapper>
                        <OptionBtnCard
                          label={"Topo"}
                          icon={IlhargaSimplesTopo}
                          selected={top ? Marked : NotMarked}
                          onClick={() => selectTop()}
                          iconStyle={{ width: "55px" }}
                        />
                        <OptionBtnCard
                          label={"Chanfrado"}
                          icon={IlhargaSimplesChanfrado}
                          selected={chanfrado ? Marked : NotMarked}
                          onClick={() => selectChanfrado()}
                          iconStyle={{ width: "55px" }}
                        />
                      </BtnWrapper>
                    </OptionWrapper>
                  ) : (
                    ""
                  )}
                </Option>
              </>
            ) : (
              ""
            )}

            <Footer>
              {
                // eslint-disable-next-line no-mixed-operators
                (wallLeft &&
                  rightWall &&
                  controller0 &&
                  controller1 &&
                  controller2 &&
                  controller3 &&
                  controller4) ||
                  (single && (top || chanfrado) && ilhargaWidth) ||
                  (double && (top || chanfrado) && ilhargaWidth) ? (
                  <>
                    {editingSummary ? (
                      <SbmtBtn>Voltar para o Resumo</SbmtBtn>
                    ) : (
                      <SbmtBtn>Avançar</SbmtBtn>
                    )}
                  </>
                ) : (
                  <DisabledBtn>
                    {editingSummary ? "Voltar para o Resumo" : "Avançar"}
                  </DisabledBtn>
                )
              }
            </Footer>
          </form>
        </Main>
      </MobileContainer>
    </>
  );
}

export default BenchL;
