import * as React from "react";
import EstimateCard from "../../components/EstimateCard";
import Plus from "../../assets/Plus.png";

import {
  AddAmbient,
  AddAmbientDiv,
  AddProduct,
  ButtonDiv,
  Columns,
  DirColumn,
  EsqColumn,
  Icon,
  Page,
  TotalDiv,
  NumbersDiv,
  Title2Div,
  DiaText,
  EntregaDiv,
  EntregaText,
  Preco2Total,
  PrecoTotal,
  Text2Total,
  TextTotal,
  TitleDiv,
  Mensagem,
  Button,
  Text,
  AmbientText,
  NameText,
  NameDiv,
  ColumnAmbient,
  RoomsWrapper,
  ButtonFazerPedidoDiv,
  PopUpEstimate,
  AddAmbientDivMobile,
} from "./style";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CountertopService, { JoinProps } from "../../services/CountertopService";
import EstimateService from "../../services/EstimateService";
import { TailSpin } from "react-loader-spinner";
import api from "../../services/api";
import { CgProfile } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiSearchLine } from "react-icons/ri";
import { Header } from "./style";
import {
  LeftSection,
  MobileLogo,
  LogoImg,
  Menu,
  SearchDiv,
  Action,
  SearchInput,
  RightBtns,
  Texth6,
  ProfileButton,
  NavbarA,
} from "../../components/Header/style";
import Logo from "../../assets/LogoSanstone.svg";
import LogoMobile from "../../assets/sansmobilelogo.svg";
import { IoMdClose } from "react-icons/io";
import { PopUp, ButtonToggle, PopUpText } from "../Home/style";
import toBRLPrice from "../../utils/toBRLPrice";

function Estimate() {
  const navigate = useNavigate();

  const [product, setProduct] = useState<any[]>([]);
  const half = product.length / 2;
  const last = product.length;

  const estimateFirst = product.slice(0, half);
  const estimateSecond = product.slice(half, last);

  const [productPriceSum, setProductPriceSum] = useState<number>(0);
  const [servicePriceSum, setServicePriceSum] = useState<number>(0);
  const [fullPrice, setFullPrice] = useState<number>(0);

  const estimateId = sessionStorage.getItem("estimateId");
  sessionStorage.removeItem("addProductToEstimate");
  sessionStorage.removeItem("addRoomToEstimate");

  let i = 0;

  useEffect(() => {
    if (estimateId) {
      EstimateService.fetchEstimate(estimateId).then((res) => {
        setProduct(res?.data.CartCountertops);
      });
    }
  }, []);
  console.log(product);
  useEffect(() => {
    let arrayOfProductsPrices = [];
    let arrayOfServicesPrices = [];
    let productPriceSum = 0;
    let servicePriceSum = 0;
    if (product.length > 0) {
      for (i = 0; i < product.length; i++) {
        arrayOfProductsPrices.push(product[i].cartCountertop.productPrice);
        arrayOfServicesPrices.push(product[i].cartCountertop.servicePrice);
      }
      for (i = 0; i < arrayOfProductsPrices.length; i++) {
        productPriceSum += arrayOfProductsPrices[i];
        servicePriceSum += arrayOfServicesPrices[i];
      }
      setProductPriceSum(productPriceSum);
      setServicePriceSum(servicePriceSum);
      setFullPrice(productPriceSum + servicePriceSum);
      console.log("servicePriceSum", servicePriceSum);
      console.log("productPriceSum", productPriceSum);
    }
  }, [product]);

  const [isLoading, setIsLoading] = useState(false);
  let estimateName = sessionStorage.getItem("estimateName");

  function toFloat(number: number): number {
    return parseFloat(number.toFixed(2));
  }

  function estimatePrice(call: string) {
    if (estimateId !== null && fullPrice !== undefined) {
      const prices = {
        price: toFloat(fullPrice),
        servicePriceSum: toFloat(servicePriceSum),
        productPriceSum: toFloat(productPriceSum),
      };
      EstimateService.updateEstimatePrice(estimateId, prices).then((res) => {
        if (res?.status === 200 && call === "button") {
          navigate("/finalize-compra");
        }
      });
    }
  }

  const [search, setSearch] = useState("");
  const [onPopUp, setOnPopUp] = useState(false);

  var rooms: any = product.map((item) => item.join.room);
  var filteredRooms = rooms.filter(
    (item: string, index: number) => rooms.indexOf(item) === index
  );
  estimatePrice("body");
  var count: any = [];

  function handlePopUp() {
    setOnPopUp(!onPopUp);
  }

  function logout() {
    localStorage.clear();
    navigate("/login");
  }
  console.log(filteredRooms?.length);
  return (
    <>
      <Header>
        <LeftSection>
          <MobileLogo
            src={LogoMobile}
            alt={"logo mobile"}
            onClick={() => navigate("/")}
          />
          <LogoImg src={Logo} alt="logo" onClick={() => navigate("/")} />
          <Menu onClick={handlePopUp}>
            <GiHamburgerMenu style={{ fontSize: 32, color: "#808080" }} />
          </Menu>
        </LeftSection>
        <RightBtns>
          <Action>
            <Texth6>Orçamentos</Texth6>
          </Action>
          <Action>
            <Texth6>Pedidos</Texth6>
          </Action>
          <Action>
            <Texth6>Agendamentos</Texth6>
          </Action>
          <Action>
            <Texth6>Recebimentos</Texth6>
          </Action>
          <ProfileButton onClick={handlePopUp}>
            <CgProfile style={{ fontSize: "36px", color: "#828282" }} />
          </ProfileButton>
        </RightBtns>
      </Header>
      {onPopUp && (
        <>
          <PopUp>
            <ButtonToggle onClick={handlePopUp}>
              <IoMdClose fontSize={24} style={{ marginTop: 16 }} />
            </ButtonToggle>
            <NavbarA>
              <PopUpText>Perfil</PopUpText>
            </NavbarA>
            <div style={{ width: "100%", border: " 1px solid #f2f2f2" }} />
            <NavbarA>
              <PopUpText>Ajuda</PopUpText>
            </NavbarA>
            <div style={{ width: "100%", border: " 1px solid #f2f2f2" }} />
            <NavbarA onClick={logout}>
              <PopUpText>Sair</PopUpText>
            </NavbarA>
          </PopUp>
        </>
      )}
      <Page style={{ paddingTop: "0" }}>
        {filteredRooms?.map((room: string, index: number) => (
          <>
            <NameDiv>
              <AmbientText style={{ paddingRight: "15px" }}>{room}</AmbientText>
              <NameText>{index == 0 ? estimateName : ""}</NameText>
            </NameDiv>
            {<div style={{ display: "none" }}>{(count = [])}</div>}
            {
              <div style={{ display: "none" }}>
                {product.map((item) => {
                  if (item.join.room === room) {
                    count.push(item.join.room);
                    return item.join.room;
                  }
                })}
              </div>
            }
            <ColumnAmbient>
              <EsqColumn
                count={
                  count.length <= 2
                    ? count.length * 100 + "px"
                    : (count.length / 1.6) * 100 + "px"
                }
              >
                <>
                  {product.map((estimateFirst, index) => {
                    if (room === estimateFirst.join.room) {
                      let title = estimateFirst.join.product;
                      if (estimateFirst.join.product === "Bancada de Pia") {
                        switch (estimateFirst.join.countertopType) {
                          case "Bancada Reta":
                            title = "Bancada de Pia Reta";
                            break;
                          case "Bancada em L":
                            title = "Bancada de Pia em L";
                            break;
                          case "Bancada em U":
                            title = "Bancada de Pia em U";
                            break;
                        }
                      }
                      return (
                        <EstimateCard
                          key={index}
                          cartCountertopId={
                            estimateFirst.cartCountertop.cartCountertopId
                          }
                          title={title}
                          material={estimateFirst.join.material}
                          quantidade={estimateFirst.join.amount}
                          preco={estimateFirst.cartCountertop.price}
                        />
                      );
                    } else {
                      return "";
                    }
                  })}
                </>
                <Link
                  to="/selecionar-produto"
                  onClick={() => {
                    sessionStorage.setItem("addProductToEstimate", "true");
                    sessionStorage.setItem("roomName", room);
                  }}
                >
                  <ButtonDiv>
                    <Icon>
                      <img src={Plus} />
                    </Icon>
                    <AddProduct>Adicionar Produto</AddProduct>
                  </ButtonDiv>
                </Link>
               { index == (filteredRooms.length -1) ? ( 
                    <AddAmbientDivMobile style={{ marginBottom: "30px" }}>
                      <Link
                        to="/selecionar-ambiente"
                        onClick={() => {
                          sessionStorage.setItem(
                            "addProductToEstimate",
                            "true"
                          );
                          sessionStorage.setItem("addRoomToEstimate", "true");
                        }}
                      >
                        <AddAmbient style={{ paddingBottom: "10px" }}>
                          Adicionar Ambiente
                        </AddAmbient>
                      </Link>
                    </AddAmbientDivMobile>
                ): <></>
                        }  </EsqColumn>
            </ColumnAmbient>
          </>
        ))}

        <AddAmbientDiv style={{ marginBottom: "30px" }}>
          <Link
            to="/selecionar-ambiente"
            onClick={() => {
              sessionStorage.setItem("addProductToEstimate", "true");
              sessionStorage.setItem("addRoomToEstimate", "true");
            }}
          >
            <AddAmbient>Adicionar Ambiente</AddAmbient>
          </Link>
        </AddAmbientDiv>

        <TotalDiv>
          <NumbersDiv>
            <TitleDiv>
              <TextTotal>Total</TextTotal>
              <PrecoTotal>
                {toBRLPrice(fullPrice)}
              </PrecoTotal>
            </TitleDiv>

            <Title2Div>
              <Text2Total>Produtos</Text2Total>
              <Preco2Total>
                {toBRLPrice(productPriceSum)}
              </Preco2Total>
            </Title2Div>

            <Title2Div>
              <Text2Total>Serviços</Text2Total>
              <Preco2Total>
                {toBRLPrice(servicePriceSum)}
              </Preco2Total>
            </Title2Div>
          </NumbersDiv>

          <Mensagem>Parcele ou receba desconto</Mensagem>

          <ButtonFazerPedidoDiv>
            <Button onClick={() => estimatePrice("button")}>
              <Text>Fazer Pedido</Text>
            </Button>
          </ButtonFazerPedidoDiv>
        </TotalDiv>
      </Page>
    </>
  );
}

export default Estimate;
