import styled from 'styled-components';

interface props{
    onFaucet?:boolean
}
export const Title = styled.h2`

font-size: 20px;
color: #828282;
margin-bottom:20px;
`
export const Box = styled.div<props>`
height: 50px;
min-width: 340px;
max-width: 90%;
background:white;
cursor:pointer;
display:flex;
border:none;
flex-direction:row;
align-items:center;
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
border-radius:8px;
justify-content:space-between;
&:hover{
    opacity:${props=>props.onFaucet? "1":"0.5"}
}
@media (max-width: 1200px ){
    width: 90%;
    height: fit-content;
    min-width: 300px;
}
@media (max-width: 912px){
    width:80vw;
    align-self: center;
}
@media (max-width: 600px){
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}
`
export const Name = styled.h3`

font-size: 16px;
color: #828282;
margin-right:20px;
padding: 1.73vw;
`

