import styled from 'styled-components';

export const MobileContainer = styled.div`
width: 100%;
display: flex;
span{
    display:none;
}
@media(max-width:912px){
    display:flex;
    flex:1;
    flex-direction:column;
    align-items:center;
    background:#2566C8;
    span{
        padding-top:5.5%;
        display:flex;
        
        color:white;
        font-size:20px;
        font-weight: 700;
    
    }
}
`

export const Container = styled.div`
padding: 100px 3.47222222222222vw 0px 3.47222222222222vw;
background:#F2F2F2;
height:89vh;
width: 100%;
@media(max-width:912px){
background:white;
margin-top:4vh;
border-radius:20px 20px 0 0;
height:auto;
width:93%;
}
`
export const Name = styled.p`
display:none;
@media(max-width:912px){
    display:flex;
    
    font-weight: 700;
    color:gray;
    text-align:center;
    font-size:20px;
}

`

export const BoxName = styled.div`
display: none;

@media(max-width:912px){
    display:flex;
    width: 95%;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    height: 3.5%;
   

   
}
`


export const TopBox = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:center;
@media(max-width:912px){
 flex-direction:column;   
}
`
export const MainText = styled.h1`
color: #828282;

font-size: 24px;
font-weight: 700;
display:flex;
flex:1.005;
@media(max-width:912px){
    display:none;
}
`

export const MainBox = styled.div`
display:flex;
margin-top:4.33vh;
flex-direction:column;
justify-content:center;
@media(max-width:912px){
    margin-top:3.8vh;
}
`
export const Buttons = styled.div`
display:flex;
flex-direction:row;
flex-flow:wrap;
justify-content:flex-start;
gap:1.38vw;
@media(max-width:912px){
    flex-direction:column;
    align-items:center;
}
`


export const GoBackBtn = styled.button`
border:none;

background:none;
display:flex;
align-items:center;
justify-content:center;
color:#828282;
font-size:16px;
font-weight: 300;
margin-top:50px;
&:hover{
    cursor:pointer;
    opacity:0.5;

}
@media(max-width:912px){
    display:none;
}
`
