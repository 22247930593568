import { useMercadopago } from "react-sdk-mercadopago/lib";
import { useState, useEffect } from 'react'
import MercadoPagoService from "../../services/MercadoPagoService";
import { Container, Input, MPInput } from "../FinishPurchase/style";
import api from "../../services/api";
import { Select } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import EstimateService from "../../services/EstimateService";
// import { findConfigFile } from "typescript";
// import { card_token } from "mercadopago";
interface creditFormProps {
    boletoIsSelected: boolean
}


function CreditForm({ boletoIsSelected }: creditFormProps) {

    let bandeiras = {
        "3" :"Dinners ClubAmerican Express" ,
        "4":"Visa" ,
        "5" :"Mastercard" , 
        "6" :"Discover"
     }
     let map = new Map(Object.entries(bandeiras));
     
    const navigate = useNavigate()


    const accessToken = localStorage.getItem('token');
    const price = sessionStorage.getItem('price')
    const estimateId = sessionStorage.getItem('estimateId')

    const mp = useMercadopago.v2("TEST-6ee52185-ceb6-4793-848c-769bfd1fcf3a", {
        locale: 'pt-BR'
    });

    console.log(price)

    //usando useffect para dar um get no price do estimate salvo no sessionStorage
    const [estimatePrice, setEstimatePrice] = useState<number>(0)
    useEffect(() => {
        const estimateId = sessionStorage.getItem('estimateId')
        if (estimateId !== null) {
            EstimateService.fetchEstimate(estimateId).then((res) => {
                setEstimatePrice(res?.data.estimate.price)
            })
            console.log(estimatePrice)

        }
    }, [estimatePrice])

    //dados que serão enviados para o back end
    const [data, setData] = useState({
        transaction_amount: estimatePrice,
        token: '',
        description: 'Sem',
        installments: 0,
        payment_method_id: 0,
        email: '',
        issuer_id: 0,
        identificationType: '',
        identificationNumber: '',
        external_reference: estimateId
    })

    useEffect(() => {
        if (mp) {

            const cardForm = mp.cardForm({
                amount: estimatePrice.toString(),
                iframe: true,
                form: {
                    id: "form-checkout",
                    cardNumber: {
                        id: "form-checkout__cardNumber",
                        placeholder: "Número do cartão",
                        style: {
                            fontFamily: "Gotham",
                            fontWeight: "400",
                            color: "#7d7c7c"
                          },
                        customFonts: [
                        {
                            src: "../../src/fonts/Gotham-Book.woff",
                        },
                        ],
                    },
                    expirationDate: {
                        id: "form-checkout__expirationDate",
                        placeholder: "MM/YY",
                        style: {
                            fontFamily: "Gotham",
                            fontWeight: "400",
                            color: "#7d7c7c"
                          },
                        customFonts: [
                        {
                            src: "../../src/fonts/Gotham-Book.woff",
                        },
                        ],
                    },
                    securityCode: {
                        id: "form-checkout__securityCode",
                        placeholder: "Código de segurança",
                        style: {
                            fontFamily: "Gotham",
                            fontWeight: "400",
                            color: "#7d7c7c"
                          },
                        customFonts: [
                        {
                            src: "../../src/fonts/Gotham-Book.woff",
                        },
                        ],
                    },
                    cardholderName: {
                        id: "form-checkout__cardholderName",
                        placeholder: "Titular do cartão",
                    },
                    issuer: {
                        id: "form-checkout__issuer",
                        placeholder: "Bandeira do cartão"
                    },
                    installments: {
                        id: "form-checkout__installments",
                        placeholder: "Parcelas",
                    },
                    identificationType: {
                        id: "form-checkout__identificationType",
                        placeholder: "Tipo de documento",
                    },
                    identificationNumber: {
                        id: "form-checkout__identificationNumber",
                        placeholder: "Número do documento",
                    },
                    cardholderEmail: {
                        id: "form-checkout__cardholderEmail",
                        placeholder: "E-mail",
                    },
                },
                callbacks: {
                    onFormMounted: (error: any) => {
                        if (error) return console.warn("Form Mounted handling error: ", error);
                        console.log("Form mounted");
                    },
                    onSubmit: (event: { preventDefault: () => void; }) => {
                        event.preventDefault();

                        const {
                            paymentMethodId: payment_method_id,
                            issuerId: issuer_id,
                            cardholderEmail: email,
                            amount,
                            token,
                            installments,
                            identificationNumber,
                            identificationType,
                        } = cardForm.getCardFormData();
                        data.token = token;

                        data.token = token
                        data.issuer_id = issuer_id
                        data.installments = installments
                        data.payment_method_id = payment_method_id
                        data.email = email
                        data.identificationNumber = identificationNumber
                        data.identificationType = identificationType
                        data.transaction_amount = amount

                        const postPayment = async (token: string) => {

                            if (accessToken) {

                                api.defaults.headers.common['Authorization'] = `Bearer ${token}`
                                MercadoPagoService.postPayment(data, accessToken).then((res) => {
                                    console.log(res)
                                    if (res?.status === 200 && estimateId) {
                                        EstimateService.updateEstimateStatus(estimateId, 'Pedido Realizado').then((res) => {
                                            console.log(res)
                                            navigate('/pedido-finalizado')
                                        })
                                    }
                                })

                             

                            }


                        }
                        if (accessToken) {
                            postPayment(accessToken)
                        }

                    },
                    onFetching: (resource: any) => {
                        console.log("Fetching resource: ", resource);
                    }
                },
            });

        }
        else {
            console.log('Erro ao conectar com a API')
        }

    }, [data, mp])

    if (boletoIsSelected) {
        setData({
            transaction_amount: estimatePrice,
            token: '',
            description: '',
            installments: 0,
            payment_method_id: 0,
            email: '',
            issuer_id: 0,
            identificationType: '',
            identificationNumber: '',
            external_reference: estimateId
        })
    }

    return (
        <>
            {/* <form id="form-checkout"> */}
            <Container>
                <MPInput
                    id="form-checkout__cardNumber"
                    placeholder='Número do Cartao' />
            </Container>
            <Container>
                <MPInput
                    id="form-checkout__expirationDate"
                    placeholder='Validade'
                />
            </Container>
            <Container>
                <MPInput
                    id="form-checkout__securityCode"
                    placeholder='CVV'
                />
            </Container>
            <Container>
                <Input
                    type='string'
                    placeholder='Nome Impresso no Cartão'
                    id="form-checkout__cardholderName" />
            </Container>
            <Container>
                <Select id="form-checkout__issuer" disabled={true} style={{appearance: "none", opacity:1}}></Select>
            </Container>
            <Container>
                <Select id="form-checkout__installments"></Select>
            </Container>
            <Container>
                <Select id="form-checkout__identificationType"></Select>
            </Container>
            <Container>
                <Input
                    type='string'
                    placeholder='Número do Documento'
                    id="form-checkout__identificationNumber"
                    maxLength={11}
                />
            </Container>
            <Container>
                <Input
                    type='string'
                    placeholder='E-mail'
                    id="form-checkout__cardholderEmail"
                />
                {/* <progress value="0" className="progress-bar">Carregando...</progress> */}
            </Container>
            {/* <button type="submit" id="form-checkout__submit" onClick={() => console.log(data.token)}>Pagar</button> */}
            {/* </form> */}


        </>
    )




};

export default CreditForm;