import styled from "styled-components";

export const Container = styled.div`
background:#F2F2F2;
height:100vh;
display:flex;
flex-direction:row;
align-items:center;
*border: solid red 2px;
@media(max-width:912px){
    flex-direction:column;
    width:100%;
}
`
export const LoginRightSection = styled.div`
display:flex;
flex:1;
flex-direction:row;
justify-content:center;
align-items:center;
*border: solid 2px orange;
`
