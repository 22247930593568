import styled from "styled-components";
//import Logo from '../../assets/LateralLogo.png'
import Logo from '../../assets/Lateral.png'


interface LogoProps {
    position: string;
}
export const LateralLogo = styled.div<LogoProps>`
display: flex;
justify-content: center;
align-items: center;
height:100%;
width:41.66%;
background:no-repeat;
background-size:cover;
background-position: center;
background-image:url(${Logo});

@media(max-width:912px){
    width:100vw;
    position:${props => props.position};
    height:35%;
}

`
export const LateralLogoImg = styled.img`
display: flex;
width: 310.2px;
height: 117.5px;
padding: 0;
margin: 0;
@media (max-width: 912px) {
    width: 210.2px;
    height: 79.62px;
}
`
