import styled from 'styled-components';

interface benchDetailsProps {
  onAdvance: boolean;
}

export const Page = styled.div<benchDetailsProps>`
display:flex;
flex-direction:column;
background:#F4F4F4;
padding-top:50px;
padding-left:3.472222222vw;
padding-right:3.472222222vw;
height: ${props => props.onAdvance ? "auto" : "94vh"};
padding-bottom:${props => props.onAdvance && "48px"};
@media (max-width: 912px) { display: none;}
`
export const InputContainer = styled.h1`
display: block;
margin-left: auto;
margin-right: auto;
margin-top:110px;

`

export const Title = styled.h1`
margin-bottom:3.3vh;

font-weight: 700;
font-size:1.5rem;
color:#828282;

text-align: center;
`
export const Container = styled.div`
  height: 3.125rem;
  width: 21.5vw;
  justify-content:space-between;
  align-items:center;
  display:flex;
  background:white;
  h6{
    font-size:0.625rem;
    
    margin-left:10px;
    }
  padding-right:20px;
  box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
  border-radius: 8px;
  color: #828282;
  @media(max-width:1840px){
    width:28.8vw;
  }
  @media(max-width:1661px){
    width:28.7vw;
  }
  @media(max-width:1534px){
    width:28.6vw;
  }
  @media(max-width:1425px){
    width:28.5vw;
  }
  @media(max-width:1163px){
    width:28.4vw;
  }
  @media(max-width:1099px){
    width:28vw;
  }
`
export const Input = styled.input`
padding-left:20px;
font-size:1rem;
display:flex;
flex:2;
border:none;
color: #828282;

&::placeholder{
  color:#BDBDBD
 }

&:focus{
    outline:none;
}
@media (max-width: 912px) {
  flex: none;
  width: 55%;
  height: 45px;
  background-color: #f2f2f2;
  padding-right: 20px;
  position: relative;
  top: 50%;
  border-radius: 5px;
  &::placeholder{
  color: #828282;
  text-align: center;
  
 }
 @supports (-webkit-touch-callout: none) {
  /* Atualmente, apenas dispositivos iOS possuem a feature -webkit-touch-callout,
  logo esse código testa se o dispositivo é iOS ou não. Caso a Apple mude algo,
  basta consultar
  https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariCSSRef/Articles/StandardCSSProperties.html
  e buscar por "Safari on iOS only"*/
  font-size: 16px;
  width: 95%;
  }
}
`
export const DisabledBtn = styled.div`
height: 40px;
width: 150px;
border:none;
color: #FFF;

font-size: 16px;
background:#D2D2D2;
border-radius:8px;
display:flex;
justify-content:center;
align-items:center;
`
export const SbmtBtn = styled.button`
height: 40px;
width: 150px;
border:none;
color: #FFF;

font-size: 16px;
background:#2566C8;
border-radius:8px;
&:hover{
  opacity:0.5;
  cursor:pointer;
}
`
export const Footer = styled.footer`
display:flex;
justify-content:space-between;
margin-top:4.44vh;
@media (max-width: 912px) {
  position: relative;
  width: 100vw;
  justify-content: flex-end;
  top: 76vh;
}
`
export const PageMobile = styled.div`
display: none;
@media (max-width: 912px) {
  display: flex;
  background-color:#fff;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh ;
}
`
export const TextInfo = styled.p `

color: #2566C8;
font-size: 1.1rem;
`
export const TextContainer = styled.div `
display: flex;
justify-content: center;
width: 100vw;
height: fit-content;
position: relative;
top: 6%;
`
export const SbmtButtomMobile = styled.img`
width: 35px;
padding-right: 25px;
`