import Marked from "../../assets/Marked.svg";
import NotMarked from "../../assets/NotMarked.svg";
import SingleFaucet from "../../assets/SingleFaucet.svg";
import MixerFaucet from "../../assets/MixerFaucet.svg";
import MonoCommandFaucet from "../../assets/MonoCommandFaucet.svg";
import BackFaucet from "../../assets/BackFaucet.svg";
import LeftFaucet from "../../assets/LeftFaucet.svg";
import RightFaucet from "../../assets/RightFaucet.svg";
import { useEffect, useState } from "react";
import { BottomSection, Counter } from "../CubaStyle/style";
import OptionBtnCard from "../../components/OptionBtnCard";
import { BottomWrapper, Page, TopWrapper, MobileContainer } from "./style";
import InstallationCard from "../../components/InstallationCard";
import { DisabledBtn, SbmtBtn } from "../BenchL/style";
import BackBtn from "../../components/BackBtn";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { setEngine } from "crypto";
import { Main } from "../CounterTopSelect/style";
import { CardsWrapper } from "../OvenStyle/style";
import { StepCounter } from "../../components/StepCounter";
console.log(MonoCommandFaucet);
function FaucetStyle() {
  //hooks tipo de torneira
  const [single, setSingle] = useState<boolean>(false);
  const [mixer, setMixer] = useState<boolean>(false);
  const [monoCommand, setMonoCommand] = useState<boolean>(false);

    const navigate = useNavigate();
    const editingSummary = sessionStorage.getItem("editing summary") === 'true' ? true: false;
    const editingProduct= sessionStorage.getItem("editing product") === 'true' ? true: false;
    //function tipo de torneira
    function selectType(index: number) {
        switch (index) {
            case 0:
                setSingle(!single);
                setMixer(false);
                setMonoCommand(false);
                break;
            case 1:
                setSingle(false);
                setMixer(!mixer);
                setMonoCommand(false);
                break;
            case 2:
                setSingle(false);
                setMixer(false);
                setMonoCommand(!monoCommand);
        }
    }

  //arrays tipo de torneira
  const faucetType = [
    { title: "Simples", icon: SingleFaucet },
    { title: "Misturador", icon: MixerFaucet },
    { title: "Monocomando", icon: MonoCommandFaucet },
  ];
  const typeArray = [single, mixer, monoCommand];

  //hooks posição de torneira
  const [back, setBack] = useState<boolean>(false);
  const [left, setLeft] = useState<boolean>(false);
  const [right, setRight] = useState<boolean>(false);

  //arrays posição da torneira
  const faucetPosition = [
    { title: "Fundo", icon: BackFaucet },
    { title: "Esquerda", icon: LeftFaucet },
    { title: "Direita", icon: RightFaucet },
  ];
  const positionArray = [back, left, right];
  //função posição de torneira
  function selectPosition(index: number) {
    switch (index) {
      case 0:
        setBack(!back);
        setLeft(false);
        setRight(false);
        setWallFaucet(false);
        break;
      case 1:
        setBack(false);
        setLeft(!left);
        setRight(false);
        setWallFaucet(false);
        break;
      case 2:
        setBack(false);
        setLeft(false);
        setRight(!right);
        setWallFaucet(false);
    }
  }
  //hooks torneira de parede
  const [wallFaucet, setWallFaucet] = useState<boolean>(false);

  //function torneira de parede
  const selectWall = () => {
    setWallFaucet(!wallFaucet);
    setBack(false);
    setLeft(false);
    setRight(false);
  };
  const wallAndBack = () => {
    setBack(!back);
    setWallFaucet(false);
  };
  console.log(back);

  // useEffect(() => {
  //     if (mixer) {
  //         setLeft(false);
  //         setRight(false);
  //         setBack(false);
  //         setWallFaucet(false);
  //     }
  //     if (single) {
  //         setLeft(false);
  //         setRight(false);
  //         setBack(false);
  //         setWallFaucet(false);
  //     }
  //     if (monoCommand) {
  //         setLeft(false);
  //         setRight(false);
  //         setBack(false);
  //         setWallFaucet(false);
  //     }
  // }, [single, mixer, monoCommand]);
  // console.log("back", back, "right", right, "left", left);

  function sendToSessionStorage() {
    sessionStorage.setItem(
      "faucetType",
      mixer
        ? "Misturador"
        : single
        ? "Simples"
        : monoCommand
        ? "Monocomando"
        : ""
    );
    sessionStorage.setItem(
      "faucetPosition",
      back ? "Fundo" : left ? "Esquerda" : right ? "Direita" : ""
    );
    sessionStorage.setItem("faucetWall", wallFaucet ? "true" : "false");
  }

  const faucetTypeStorage = sessionStorage.getItem("faucetType");
  const faucetPositionStorage = sessionStorage.getItem("faucetPosition");
  const faucetWallStorage = sessionStorage.getItem("faucetWall");

  useEffect(() => {
    console.log(faucetTypeStorage);
    if (faucetTypeStorage === "Simples") {
      setMixer(false);
      setSingle(true);
      setMonoCommand(false);
    } else if (faucetTypeStorage === "Misturador") {
      setMixer(true);
      setSingle(false);
      setMonoCommand(false);
    } else if (faucetTypeStorage === "Monocomando") {
      setMixer(false);
      setSingle(false);
      setMonoCommand(true);
    }
  }, []);

  useEffect(() => {
    console.log("posotion" + faucetPositionStorage);
    if (faucetPositionStorage === "Fundo") {
      setLeft(false);
      setRight(false);
      setBack(true);
    } else if (faucetPositionStorage === "Esquerda") {
      setLeft(true);
      setRight(false);
      setBack(false);
    } else if (faucetPositionStorage === "Direita") {
      setLeft(false);
      setRight(true);
      setBack(false);
    }
  }, []);

  useEffect(() => {
    console.log("wall" + faucetWallStorage);
    if (faucetWallStorage === "true") {
      setWallFaucet(true);
    } else if (faucetWallStorage === "false") {
      setWallFaucet(false);
    }
  }, []);

  return (
    <>
      <Page onAdvance={true}>
        <StepCounter step={4}/>
        <h2>Tipo de Torneira</h2>
        <TopWrapper>
          {faucetType.map((item, index) => (
            <OptionBtnCard
              onClick={() => selectType(index)}
              label={item.title}
              icon={item.icon}
              selected={typeArray[index] ? Marked : NotMarked}
              key={index}
            />
          ))}
        </TopWrapper>

        {single === true && (
          <>
            <h2>Posição da Torneira</h2>
            {mixer ? (
              <CardsWrapper>
                <OptionBtnCard
                  onClick={() => wallAndBack()}
                  label={faucetPosition[0].title}
                  icon={faucetPosition[0].icon}
                  selected={back ? Marked : NotMarked}
                />
              </CardsWrapper>
            ) : (
              <>
                <CardsWrapper>
                  {faucetPosition.map((item, index) => (
                    <OptionBtnCard
                      onClick={() => selectPosition(index)}
                      label={item.title}
                      icon={item.icon}
                      selected={positionArray[index] ? Marked : NotMarked}
                    />
                  ))}
                </CardsWrapper>

                <InstallationCard
                  name="Torneira de Parede"
                  src={wallFaucet ? Marked : NotMarked}
                  onClick={() => selectWall()}
                  full={true}
                />
              </>
            )}
          </>
        )}
        {monoCommand === true && (
          <>
            <h2>Posição da Torneira</h2>
            {mixer ? (
              <BottomWrapper>
                <OptionBtnCard
                  onClick={() => wallAndBack()}
                  label={faucetPosition[0].title}
                  icon={faucetPosition[0].icon}
                  selected={back ? Marked : NotMarked}
                />
              </BottomWrapper>
            ) : (
              <>
                <BottomWrapper>
                  {faucetPosition.map((item, index) => (
                    <OptionBtnCard
                      onClick={() => selectPosition(index)}
                      label={item.title}
                      icon={item.icon}
                      selected={positionArray[index] ? Marked : NotMarked}
                    />
                  ))}
                </BottomWrapper>
                <InstallationCard
                  name="Torneira de Parede"
                  src={wallFaucet ? Marked : NotMarked}
                  onClick={() => selectWall()}
                  full={true}
                />
              </>
            )}
          </>
        )}
        {mixer === true && (
          <>
            <h2>Posição da Torneira</h2>
            {mixer ? (
              <>
                <BottomWrapper>
                  <OptionBtnCard
                    onClick={() => wallAndBack()}
                    label={faucetPosition[0].title}
                    icon={faucetPosition[0].icon}
                    selected={back ? Marked : NotMarked}
                  />
                </BottomWrapper>
                <InstallationCard
                  name="Torneira de Parede"
                  src={wallFaucet ? Marked : NotMarked}
                  onClick={() => selectWall()}
                  full={true}
                />
              </>
            ) : (
              <BottomWrapper>
                {faucetPosition.map((item, index) => (
                  <OptionBtnCard
                    onClick={() => selectPosition(index)}
                    label={item.title}
                    icon={item.icon}
                    selected={positionArray[index] ? Marked : NotMarked}
                  />
                ))}
              </BottomWrapper>
            )}
          </>
        )}

                <BottomSection
                    style={{
                        display: "flex",
                        marginTop: "6.3vh",
                    }}
                >
                    <Link to={editingSummary || editingProduct ? "/resumo" : "/cuba"}>
                        <BackBtn />
                    </Link>
                    {(wallFaucet && (single || mixer || monoCommand)) ||
                            ((single || mixer || monoCommand) &&
                                (back || left || right)) ? (
                            <div>
                                {editingSummary ? (
                                    <SbmtBtn
                                        onClick={() => {
                                            sendToSessionStorage();
                                            navigate("/resumo");
                                        }}
                                    >Voltar para o Resumo</SbmtBtn>
                                ) : (
                                    <SbmtBtn
                                        onClick={() => {
                                            sendToSessionStorage();
                                            navigate("/tipo-de-fogao");
                                        }}>Avançar</SbmtBtn>
                                )
                                }


                            </div>
                        ) : (
                            <DisabledBtn>Avançar</DisabledBtn>
                        )}
                </BottomSection>
            </Page>

      {/* versão mobile */}

      <MobileContainer>
        <span>Orçamento</span>

        <Main style={{ position: "relative", minHeight: "100vh", gap: "20px" }}>
          <Link to={editingSummary || editingProduct ? "/resumo" : "/cuba"}>
            <BackBtn
              isMobile={true}
              style={{ position: "absolute", top: 30, left: 30, margin: 0 }}
            />
          </Link>
          <StepCounter step={4}/>
          <h2>Tipo de Torneira</h2>
          <TopWrapper>
            {faucetType.map((item, index) => (
              <OptionBtnCard
                onClick={() => selectType(index)}
                label={item.title}
                icon={item.icon}
                selected={typeArray[index] ? Marked : NotMarked}
                key={index}
              />
            ))}
          </TopWrapper>

          {single === true && (
            <>
              <h2>Posição da Torneira</h2>
              {mixer ? (
                <BottomWrapper>
                  <OptionBtnCard
                    onClick={() => wallAndBack()}
                    label={faucetPosition[0].title}
                    icon={faucetPosition[0].icon}
                    selected={back ? Marked : NotMarked}
                  />
                </BottomWrapper>
              ) : (
                <>
                  <BottomWrapper>
                    {faucetPosition.map((item, index) => (
                      <OptionBtnCard
                        onClick={() => selectPosition(index)}
                        label={item.title}
                        icon={item.icon}
                        selected={positionArray[index] ? Marked : NotMarked}
                      />
                    ))}
                  </BottomWrapper>
                  <InstallationCard
                    name="Torneira de Parede"
                    src={wallFaucet ? Marked : NotMarked}
                    onClick={() => selectWall()}
                    full={true}
                  />
                </>
              )}
            </>
          )}
          {monoCommand === true && (
            <>
              <h2>Posição da Torneira</h2>
              {mixer ? (
                <BottomWrapper>
                  <OptionBtnCard
                    onClick={() => wallAndBack()}
                    label={faucetPosition[0].title}
                    icon={faucetPosition[0].icon}
                    selected={back ? Marked : NotMarked}
                  />
                </BottomWrapper>
              ) : (
                <>
                  <BottomWrapper>
                    {faucetPosition.map((item, index) => (
                      <OptionBtnCard
                        onClick={() => selectPosition(index)}
                        label={item.title}
                        icon={item.icon}
                        selected={positionArray[index] ? Marked : NotMarked}
                      />
                    ))}
                  </BottomWrapper>
                  <InstallationCard
                    name="Torneira de Parede"
                    src={wallFaucet ? Marked : NotMarked}
                    onClick={() => selectWall()}
                    full={true}
                  />
                </>
              )}
            </>
          )}
          {mixer === true && (
            <>
              <h2>Posição da Torneira</h2>
              {mixer ? (
                <>
                  <BottomWrapper>
                    <OptionBtnCard
                      onClick={() => wallAndBack()}
                      label={faucetPosition[0].title}
                      icon={faucetPosition[0].icon}
                      selected={back ? Marked : NotMarked}
                    />
                  </BottomWrapper>
                  <InstallationCard
                    name="Torneira de Parede"
                    src={wallFaucet ? Marked : NotMarked}
                    onClick={() => selectWall()}
                    full={true}
                  />
                </>
              ) : (
                <BottomWrapper>
                  {faucetPosition.map((item, index) => (
                    <OptionBtnCard
                      onClick={() => selectPosition(index)}
                      label={item.title}
                      icon={item.icon}
                      selected={positionArray[index] ? Marked : NotMarked}
                    />
                  ))}
                </BottomWrapper>
              )}
            </>
          )}

                    <BottomSection
                        style={{
                            display: "flex",
                            marginTop: "6.3vh",
                        }}
                    >
                        <Link to={editingSummary || editingProduct ? "/resumo" : "/cuba"}>
                            <BackBtn />
                        </Link>
                        {(wallFaucet && (single || mixer || monoCommand)) ||
                            ((single || mixer || monoCommand) &&
                                (back || left || right)) ? (
                            <div>
                                {editingSummary ? (
                                    <SbmtBtn
                                        onClick={() => {
                                            sendToSessionStorage();
                                            navigate("/resumo");
                                        }}
                                    >Voltar para o Resumo</SbmtBtn>
                                ) : (
                                    <SbmtBtn
                                        onClick={() => {
                                            sendToSessionStorage();
                                            navigate("/tipo-de-fogao");
                                        }}>Avançar</SbmtBtn>
                                )
                                }
                            </div>
                        ) : (
                            <DisabledBtn>{editingSummary ? 'Voltar para o Resumo' : 'Avançar'}</DisabledBtn>
                        )}
                    </BottomSection>

                </Main>



            </MobileContainer>




        </>
    );
}

export default FaucetStyle;
