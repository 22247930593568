/* eslint-disable import/no-anonymous-default-export */
import api from '../api';

export interface EstimateProps {
    name: string | null,
    clientId: string | null,
    price?: number | null,
    address?: string | null,
    description?: string | null,
    status?: string | null,
}

export interface PricesUpdates {
    price: number,
    productPriceSum: number,
    servicePriceSum: number,
}


export default {
    async createEstimate(data: EstimateProps) {
        try {
            const response = await api.post(`/estimates`, data);
            return response;
        }
        catch (err) {
            console.log(err)
        }
    },
    async clientEstimateRelation(estimateId: string, clientId: string) {
        try {
            const response = await api.put(`/estimates/${estimateId}/client/${clientId}`)
            return response;
        }
        catch (e) {
            console.log(e)
        }
    },
    async clientAddressEstimateRelation(estimateId: string, clientAddressId: string) {
        try {
            const response = await api.put(`/estimate/${estimateId}/clientAdress/${clientAddressId}`)
            return response;
        }
        catch (e) {
            console.log(e)
        }
    },
    async getClientEstimate(token: string) {
        try {
            const response = await api.get('/private/estimates/clientEstimates', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return response
        }
        catch (e) {
            console.log(e)
        }
    }, async fetchEstimate(estimateId: string) {
        try {
            const response = api.get(`/estimates/indexCartCountertops/${estimateId}`);
            return response;
        }
        catch (e) {
            console.log(e)
        }
    },
    async updateEstimatePrice(estimateId: string, prices: PricesUpdates) {
        try {
            const response = api.put(`/estimates/${estimateId}`, prices)
            return response
        }
        catch (e) {
            console.log(e)
        }
    },
    async updateEstimateStatus(estimateId: string, status: string) {
        try {
            const response = api.put(`/estimates/${estimateId}`, {status})
            return response
        }
        catch (e) {
            console.log(e)
        }
    },
    async getEstimate(estimateId: string | null) {
        try {
            const response = api.get(`/estimates/${estimateId}`)
            return response
        }
        catch (e) {
            console.log(e)
        }
    },
}
