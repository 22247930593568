import React from "react";
import { CardImg, Content, RoomName } from "./style";

interface roomCardProps {
    onClick: () => void
    img: string | undefined
    name: string
}
export default function RoomCard(
    { onClick, img, name }: roomCardProps
) {

    return (

        <Content onClick={onClick}>
            <CardImg src={img} alt={name}
                style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }} />
            <RoomName>{name}</RoomName>
        </Content>

    )

}
