
import { Action, BlueBtn, Container, Form, H6, Input, InputDiv, TextBox } from './style';
import { Link, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

//icons
import { AiFillEye } from 'react-icons/ai'
import { AiFillEyeInvisible } from 'react-icons/ai'
import { AiOutlineMail } from 'react-icons/ai';
import { AiOutlineLock } from 'react-icons/ai';
import { AiOutlineArrowRight } from 'react-icons/ai'

import api from '../../services/api';
import { AuthContext } from '../../Contexts/auth';



interface LoginProps {
    position: string
}

export default function LoginCard({ position }: LoginProps) {
    useEffect(() => localStorage.clear(), [])



    const Auth = useContext(AuthContext)
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    //validação
    const { register, formState: { errors }, handleSubmit, setValue } = useForm();

    //função logar 
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const onSubmit = (data: any) => {
        console.log(data)
        api.post('/login', data).then(res => {
            const token: string = res.data.token;
            localStorage.setItem('token', token);
            Auth.setToken('Bearer ' + token);
            toast.success('Login realizado com sucesso!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                closeOnClick: true,
                draggable: true,
                onClick: () => navigate('/'),
                onClose: () => navigate('/')
            })
        },
            (error => toast.error('E-mail ou senha incorretos', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                closeOnClick: true,
                draggable: true,
            })
        ))
    }


    useEffect(() => { }, [

    ])
    return (
        <Container position={position}>
            <h2>Login</h2>
            <H6>Entre com sua conta.</H6>
            <Form onSubmit={handleSubmit(onSubmit)} style={{ position: 'relative' }}>
                <InputDiv>
                    < AiOutlineMail
                        style={{
                            fontSize: '1.5rem',
                            color: '#BDBDBD'
                        }}
                    />
                    <Input
                        {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                        placeholder='E-mail'
                        onChange={(event) => {
                            setValue('email', event.target.value)
                            setEmail(event?.target.value)}}
                        value={email}
                    />
                </InputDiv>
                {errors.email?.type === 'required' && <p style={{ color: 'red', position: 'absolute', top: 42, left: 15, fontSize: 12 }}>Campo obrigatório</p>}
                {errors.email?.type === 'pattern' && <p style={{ color: 'red', position: 'absolute', top: 42, left: 15, fontSize: 12 }}>E-mail inválido</p>}
                <InputDiv>
                    <AiOutlineLock
                        style={{
                            fontSize: '1.5rem',
                            color: '#BDBDBD'
                        }}
                    />
                    <Input placeholder='Senha'
                        {...register('password', { required: true })}
                        type={showPassword ? '' : 'password'}
                        onChange={(event) => {
                            setValue('password', event.target.value)
                            setPassword(event.target.value)}}
                        value={password}
                    />

                    {showPassword ?
                        <AiFillEyeInvisible
                            style={{ fontSize: '1.5rem', color: '#BDBDBD' }}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                        : <AiFillEye
                            style={{ fontSize: '1.5rem', color: '#BDBDBD' }}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    }

                </InputDiv>
                {errors.password?.type === 'required' && <p style={{ color: 'red', position: 'absolute', top: 102, left: 15, fontSize: 12 }}>Campo obrigatório</p>}
                <BlueBtn type='submit'
                >
                    <p>Entrar</p>
                    <AiOutlineArrowRight style={{ color: 'white', fontSize: '1.25rem', position: 'absolute', right: 25 }} />
                </BlueBtn>


            </Form>

            <TextBox>
                <a style={{color: "#D0D0D0"}} href="/redefinir-senha">Esqueci minha senha</a>
            </TextBox>
            <TextBox>
                <p>Ainda não possui conta?</p>
                <a href='/cadastre-se'>Criar conta</a>
            </TextBox>

        </Container >
    )
}
