import React, { useEffect, useState } from 'react';
import BackBtn from '../../components/BackBtn';
import {
  List,
  Product,
  SumUp,
  Title,
  Item,
  Type,
  Each,
  Container,
  Column,
  Total,
  Amount,
  BudgetBtn,
  ContainerInput,
  Input,
  Row,
  BudgetBtnDisable,
  Page,
  TitleItem,
  BottomDiv,
} from './style';
import { Link, useNavigate } from 'react-router-dom';
import CountertopService from '../../services/CountertopService';
import api from '../../services/api';
import EstimateService from '../../services/EstimateService';
import { AiFillEdit } from 'react-icons/ai';
import { DownloadPrices } from '../../components/DownloadPrices';
import toBRLPrice from '../../utils/toBRLPrice';

export default function Summary() {
  var identificationNumber = localStorage.getItem('identificationNumber');
  // var estimateId = sessionStorage.getItem('estimateId')
  var roomName = sessionStorage.getItem('roomName');
  var productName = sessionStorage.getItem('productName');
  var materialName = sessionStorage.getItem('materialName');
  var countertopType = sessionStorage.getItem('countertopType');
  var vatPosition = sessionStorage.getItem('vatPosition');
  var countertopDisposition = sessionStorage.getItem('countertopDisposition');
  // var cooktop = sessionStorage.getItem('cooktop');
  var amount = sessionStorage.getItem('amount');
  var dryBaseEstimateWidth = sessionStorage.getItem('dryBaseEstimateWidth');
  var dryBaseEstimateDepth = sessionStorage.getItem('dryBaseEstimateDepth');
  var wetBaseEstimateWidth = sessionStorage.getItem('wetBaseEstimateWidth');
  var wetBaseEstimateDepth = sessionStorage.getItem('wetBaseEstimateDepth');
  var flankPosition = sessionStorage.getItem('flankPosition');
  var flankWidth = sessionStorage.getItem('flankWidth');
  var flankFinishType = sessionStorage.getItem('flankFinishType');
  var flankJunctionType = sessionStorage.getItem('flankJunctionType');
  var stripEstimateHeight = sessionStorage.getItem('stripEstimateHeight');
  var frontEstimateHeight = sessionStorage.getItem('frontEstimateHeight');
  var vatType = sessionStorage.getItem('vatType');
  var vatAxle = sessionStorage.getItem('vatAxle');
  var dryBaseEstimateWidth2 = sessionStorage.getItem('dryBaseEstimateWidth1');
  var dryBaseEstimateDepth2 = sessionStorage.getItem('dryBaseEstimateDepth1');
  var dryBaseEstimateWidth1 = sessionStorage.getItem('dryBaseEstimateWidth');
  var dryBaseEstimateDepth1 = sessionStorage.getItem('dryBaseEstimateDepth');

  var vatEstimateAxleDistance = sessionStorage.getItem(
    'vatEstimateAxleDistance'
  );
  var vatEstimateEdgeDistance = sessionStorage.getItem(
    'vatEstimateEdgeDistance'
  );
  var faucetType = sessionStorage.getItem('faucetType');
  var faucetPosition = sessionStorage.getItem('faucetPosition');
  var faucetWall = sessionStorage.getItem('faucetWall') === 'true' ? true : false
  var stoveType = sessionStorage.getItem('stoveType');
  var shaft = sessionStorage.getItem('shaft');
  var shaftDistance = sessionStorage.getItem('shaftDistance');
  var shaftFrontDistance = sessionStorage.getItem('shaftFrontDistance');
  var installation = sessionStorage.getItem('installation');
  let typeOfInstallation = sessionStorage.getItem('typeOfInstallation');
  let addProductToEstimate = sessionStorage.getItem('addProductToEstimate');

  // const wallCenter = sessionStorage.getItem('wallCenter');
  // const wallLeft = sessionStorage.getItem('wallLeft');
  // const wallRight = sessionStorage.getItem('wallRight');
    
  let thereIsType = false;
  let thereWillBeInstallation = '';
  if (installation === 'true') {
    thereIsType = true;
    thereWillBeInstallation = 'Incluir instalação';
  } else {
    thereWillBeInstallation = 'Não incluir instalação';
    thereIsType = false;
    typeOfInstallation = null;
  }

  const navigate = useNavigate();
  const [stoveArea, setStoveArea] = useState<number>(1);
  const [materialPrice, setMaterialPrice] = useState(1);
  const [flankPrice, setFlankPrice] = useState<number>();
  const [installationPrice, setInstallationPrice] = useState(0);
  const [totalInstallationPrice, setTotalInstallationPrice] = useState(0);
  const [productPrice, setProductPrice] = useState<number>(10);
  const [pricePerAmount, setPricePerAmount] = useState(1);
  const estimateId = sessionStorage.getItem('estimateId');
  const [estimateName, setEstimateName] = useState<string>('');
  const [estimatePrices, setEstimatePrices] = useState<any>();
  const edit = sessionStorage.getItem("editing product") == 'true'? true: false;
  const countertopJoinId = sessionStorage.getItem('CountertopJoinId');

  useEffect(() => { setEstimateName(String(sessionStorage.getItem('estimateName'))) }, [])

  function toFloat(number: number | undefined): number {
    if (number) {
      return parseFloat(number.toFixed(2));
    } else return 0;
  }

  var dryArea: number;
  var wetArea: number;
  var stripArea: number;
  var frontArea: number;

  async function calculateCountertopPrice(
    material: string,
    cubaStyle: string | null,
    installationIncluded: boolean
  ) {
    switch (cubaStyle) {
      case 'Tramontina nº1':
        setStoveArea(305 * 470);
        break;
      case 'Tramontina nº2':
        setStoveArea(340 * 560);
        break;
      case 'Tramontina nº3':
        setStoveArea(720 * 400);
        break;
    }

    switch (material) {
      case 'Granito Preto São Gabriel':
        setMaterialPrice(770);
        break;
      case 'Granito Branco Itaúnas':
        setMaterialPrice(700);
        break;
      case 'Granito Preto Absoluto':
        setMaterialPrice(2550);
        break;
      case 'Quartzo Super White':
        setMaterialPrice(2100);
        break;
      case 'Quartzo White Star':
        setMaterialPrice(2600);
        break;
      case 'Quartzo Brown':
        setMaterialPrice(2350);
        break;
      case 'Quartzo Cinza':
        setMaterialPrice(2350);
        break;
      case 'Nanoglass':
        setMaterialPrice(2750);
        break;
    }

    switch (flankPosition + '-' + vatPosition) {
      case 'Ilharga na Esquerda-Esquerda':
        var flankArea =
          (Number(flankWidth) / 1000) * (Number(wetBaseEstimateDepth) / 1000);
        if (flankFinishType === 'Dupla') {
          setFlankPrice(flankArea * materialPrice * 2);
        } else {
          setFlankPrice(flankArea * materialPrice);
        }
        break;
      case 'Ilharga na Direita-Direita':
        flankArea =
          (Number(flankWidth) / 1000) * (Number(wetBaseEstimateDepth) / 1000);
        if (flankFinishType === 'Dupla') {
          setFlankPrice(flankArea * materialPrice * 2);
        } else {
          setFlankPrice(flankArea * materialPrice);
        }
        break;
      case 'Ilharga na Direita-Esquerda':
        flankArea =
          (Number(flankWidth) / 1000) * (Number(dryBaseEstimateDepth) / 1000);
        if (flankFinishType === 'Dupla') {
          setFlankPrice(flankArea * materialPrice * 2);
        } else {
          setFlankPrice(flankArea * materialPrice);
        }
        break;
      case 'Ilharga na Esquerda-Direita':
        flankArea =
          (Number(flankWidth) / 1000) * (Number(dryBaseEstimateDepth) / 1000);
        if (flankFinishType === 'Dupla') {
          setFlankPrice(flankArea * materialPrice * 2);
        } else {
          setFlankPrice(flankArea * materialPrice);
        }
        break;
      case 'Ilharga em ambos lados-Direita':
        flankArea =
          (Number(flankWidth) / 1000) * (Number(dryBaseEstimateDepth) / 1000) +
          (Number(flankWidth) / 1000) * (Number(dryBaseEstimateWidth) / 1000);
        if (flankFinishType === 'Dupla') {
          setFlankPrice(flankArea * materialPrice * 2);
        } else {
          setFlankPrice(flankArea * materialPrice);
        }
        break;
      case 'Ilharga em ambos lados-Esquerda':
        flankArea =
          (Number(flankWidth) / 1000) * (Number(dryBaseEstimateDepth) / 1000) +
          (Number(flankWidth) / 1000) * (Number(dryBaseEstimateWidth) / 1000);
        if (flankFinishType === 'Dupla') {
          setFlankPrice(flankArea * materialPrice * 2);
        } else {
          setFlankPrice(flankArea * materialPrice);
        }
        break;
    }

    if (countertopType === 'Bancada em L') {
      dryArea =
        (Number(dryBaseEstimateDepth) / 1000) *
        (Number(dryBaseEstimateWidth) / 1000);
      wetArea =
        (Number(wetBaseEstimateDepth) / 1000) *
          (Number(wetBaseEstimateWidth) / 1000) +
        (stoveArea >
        (Number(wetBaseEstimateDepth) / 1000) *
          (Number(wetBaseEstimateWidth) / 1000)
          ? 0
          : -stoveArea / 1000);
      stripArea =
        (Number(stripEstimateHeight) / 1000) *
          (Number(wetBaseEstimateDepth) / 1000) *
          2 +
        (Number(stripEstimateHeight) / 1000) *
          (Number(wetBaseEstimateWidth) / 1000);
      frontArea =
        (Number(frontEstimateHeight) / 1000) *
          (Number(dryBaseEstimateWidth) / 1000) +
        (Number(frontEstimateHeight) / 1000) *
          (Number(wetBaseEstimateWidth) / 1000);

      const sqrMeterArea = dryArea + wetArea + stripArea + frontArea;

      setProductPrice(
        sqrMeterArea * materialPrice +
          (flankPosition ? (flankPrice ? flankPrice : 0) : 0)
      );
    }

    if (countertopType === 'Bancada em U') {
      dryArea =
        (Number(dryBaseEstimateDepth1) / 1000) *
          (Number(dryBaseEstimateWidth1) / 1000) +
        (Number(dryBaseEstimateDepth2) / 1000) *
          (Number(dryBaseEstimateWidth2) / 1000);
      wetArea =
        (Number(wetBaseEstimateDepth) / 1000) *
          (Number(wetBaseEstimateWidth) / 1000) +
        (stoveArea >
        (Number(wetBaseEstimateDepth) / 1000) *
          (Number(wetBaseEstimateWidth) / 1000)
          ? 0
          : -stoveArea / 1000);
      stripArea =
        (Number(stripEstimateHeight) / 1000) *
          (Number(wetBaseEstimateDepth) / 1000) *
          2 +
        (Number(stripEstimateHeight) / 1000) *
          (Number(wetBaseEstimateWidth) / 1000);
      frontArea =
        (Number(frontEstimateHeight) / 1000) *
          (Number(dryBaseEstimateWidth1) / 1000) +
        (Number(frontEstimateHeight) / 1000) *
          (Number(dryBaseEstimateWidth2) / 1000) +
        (Number(frontEstimateHeight) / 1000) *
          (Number(wetBaseEstimateWidth) / 1000);
      const sqrMeterArea = dryArea + wetArea + stripArea + frontArea;
      setProductPrice(sqrMeterArea * materialPrice);
    }
    if (countertopType === 'Bancada Reta') {
      stripArea =
        (Number(stripEstimateHeight) / 1000) *
          (Number(wetBaseEstimateDepth) / 1000) *
          2 +
        (Number(stripEstimateHeight) / 1000) *
          (Number(wetBaseEstimateWidth) / 1000);
      frontArea =
        (Number(frontEstimateHeight) / 1000) *
        (Number(wetBaseEstimateWidth) / 1000);
      wetArea =
        (Number(wetBaseEstimateDepth) / 1000) *
          (Number(wetBaseEstimateWidth) / 1000) +
        (stoveArea >
        (Number(wetBaseEstimateDepth) / 1000) *
          (Number(wetBaseEstimateWidth) / 1000)
          ? 0
          : -stoveArea / 1000);
      const sqrMeterArea = stripArea + frontArea + wetArea;
      setProductPrice(sqrMeterArea * materialPrice);
    }

    if (installationIncluded) {
      let twicePrice = productPrice * 2;
      switch (typeOfInstallation) {
        case 'Apoiada em Armário':
          (30 * productPrice) / 100 < 350
            ? setInstallationPrice(350.0)
            : setInstallationPrice(toFloat(twicePrice / 3));
          break;
        case 'Apoiada em Tubos':
          (40 * productPrice) / 100 < 450
            ? setInstallationPrice(450.0)
            : setInstallationPrice(toFloat(twicePrice / 3));
          break;

        case 'Apoiada em Alvenaria':
          (35 * productPrice) / 100 < 400
            ? setInstallationPrice(400.0)
            : setInstallationPrice(toFloat(twicePrice / 3));
          break;
      }
    }
    setPricePerAmount(toFloat(Number(amount) * productPrice));
    setTotalInstallationPrice(Number(amount) * installationPrice);

    // if (thereWillBeInstallation === 'Incluir instalação') {
    //   sessionStorage.setItem('servicePrice', totalInstallationPricetoString());
    // } else {
    //   sessionStorage.setItem('servicePrice', '0');
    // }
  }
  const totalPrice = pricePerAmount + totalInstallationPrice;

  useEffect(() => {
    calculateCountertopPrice(
      materialName ? materialName : '',
      vatType,
      installation === 'true'
    );
  });

  const [approved, setApproved] = useState(false);

  if (addProductToEstimate) {
    EstimateService.getEstimate(estimateId).then((response) =>
      setEstimateName(response?.data.estimate.name)
    );
  }

  function sendToBackEnd() {
    if (
      identificationNumber &&
      roomName &&
      productName &&
      materialName &&
      amount &&
      pricePerAmount &&
      wetBaseEstimateWidth &&
      wetBaseEstimateDepth &&
      countertopType
    ) {
      console.log('oi')
      const countertop = {
        name: estimateName,
        price: totalPrice,
        clientId: identificationNumber,
        status: 'Em Edicao',
        description: null,
        cartcountertop_clientId: identificationNumber,
        estimate_id: estimateId,
        cartcountertop_price: totalPrice,
        cartcountertop_productPrice: pricePerAmount,
        cartcountertop_servicePrice: totalInstallationPrice,
        cartcountertop_approved: approved,
        join_amount: Number(amount),
        join_material: materialName,
        join_room: roomName,
        join_product: productName,
        join_countertopType: countertopType,
        join_installation: thereIsType,
        join_installationType: typeOfInstallation,
        join_countertopDisposition: countertopDisposition,
        dryBase_estimateWidth: Number(dryBaseEstimateWidth),
        dryBase_estimateDepth: Number(dryBaseEstimateDepth),
        dryBase1_estimateWidth:Number(dryBaseEstimateWidth2),
        dryBase1_estimateDepth:Number(dryBaseEstimateDepth2),
        faucet_type: faucetType,
        faucet_position: faucetPosition ? faucetPosition : null,
        faucet_wall: faucetWall,
        flank_position: flankPosition ? flankPosition : null,
        flank_finishType: flankFinishType ? flankFinishType : null,
        flank_estimateWidth: flankWidth ? Number(flankWidth) : 0,
        flank_junctionType: flankJunctionType ? flankJunctionType : null,
        front_estimateHeight: frontEstimateHeight
          ? Number(frontEstimateHeight)
          : 0,
        stove_type: stoveType,
        stove_shaft: shaft,
        stove_shaftDistance: shaftDistance ? Number(shaftDistance) : 0,
        stove_shaftFrontDistance: shaftFrontDistance
          ? Number(shaftFrontDistance)
          : 0,
        strip_estimateHeight: stripEstimateHeight
          ? Number(stripEstimateHeight)
          : 0,
        vat_type: vatType ? vatType : null,
        vat_axle: vatAxle ? vatAxle : null,
        vat_vatPosition: vatPosition ? vatPosition : null,
        vat_estimateAxleDistance: Number(vatEstimateAxleDistance),
        vat_estimateEdgeDistance: Number(vatEstimateEdgeDistance),
        vat_vatAxle: vatAxle,
        wetbase_estimateWidth: Number(wetBaseEstimateWidth),
        wetbase_estimateDepth: Number(wetBaseEstimateDepth),
      };
    
      setApproved(true);
      if (addProductToEstimate != undefined) {
        api
          .post('/cartCountertops/createCountertop', countertop)
          .then((res) => {
            //alert(JSON.stringify(res.data))
            sessionStorage.clear();
            sessionStorage.setItem('estimateId', String(estimateId));
            sessionStorage.setItem('estimateName', String(estimateName));
            navigate('/estimativa');
          });
      } else {
        CountertopService.createAll(countertop).then((res) => {
          if (res?.status === 201) {
            sessionStorage.setItem(
              'cartCountertopId',
              res.data.cartCountertop.cartCountertopId
            );
            sessionStorage.clear();
            sessionStorage.setItem('estimateId', res?.data.estimate.id);
            sessionStorage.setItem('estimateName', res?.data.estimate.name);
            navigate('/estimativa');
          }
        });
      }
    }
  }
  function sendUpdateToBackEnd() {
    if (
      identificationNumber &&
      roomName &&
      productName &&
      materialName &&
      amount &&
      pricePerAmount &&
      wetBaseEstimateWidth &&
      wetBaseEstimateDepth &&
      countertopType &&
      countertopJoinId
    ) {
      const countertop = {
        name: estimateName,
        price: totalPrice,
        clientId: identificationNumber,
        status: "Em Edicao",
        description: null,
        cartcountertop_clientId: identificationNumber,
        estimate_id: estimateId,
        cartcountertop_price: totalPrice,
        cartcountertop_productPrice: pricePerAmount,
        cartcountertop_servicePrice: totalInstallationPrice,
        cartcountertop_approved: approved,
        join_amount: Number(amount),
        join_material: materialName,
        join_room: roomName,
        join_product: productName,
        join_countertopType: countertopType,
        join_installation: thereIsType,
        join_installationType: typeOfInstallation,
        join_countertopDisposition: countertopDisposition,
        dryBase_estimateWidth: Number(dryBaseEstimateWidth),
        dryBase_estimateDepth: Number(dryBaseEstimateDepth),
        dryBase1_estimateWidth:Number(dryBaseEstimateWidth2),
        dryBase1_estimateDepth:Number(dryBaseEstimateDepth2),
        faucet_type: faucetType,
        faucet_position: faucetPosition ? faucetPosition : null,
        faucet_wall: faucetWall,
        flank_position: flankPosition ? flankPosition : null,
        flank_finishType: flankFinishType ? flankFinishType : null,
        flank_estimateWidth: flankWidth ? Number(flankWidth) : 0,
        flank_junctionType: flankJunctionType ? flankJunctionType : null,
        front_estimateHeight: frontEstimateHeight
          ? Number(frontEstimateHeight)
          : 0,
        stove_type: stoveType,
        stove_shaft: shaft,
        stove_shaftDistance: shaftDistance ? Number(shaftDistance) : 0,
        stove_shaftFrontDistance: shaftFrontDistance
          ? Number(shaftFrontDistance)
          : 0,
        strip_estimateHeight: stripEstimateHeight
          ? Number(stripEstimateHeight)
          : 0,
        vat_type: vatType ? vatType : null,
        vat_axle: vatAxle ? vatAxle : null,
        vat_vatPosition: vatPosition ? vatPosition : null,
        vat_estimateAxleDistance: Number(vatEstimateAxleDistance),
        vat_estimateEdgeDistance: Number(vatEstimateEdgeDistance),
        vat_vatAxle: vatAxle,
        wetbase_estimateWidth: Number(wetBaseEstimateWidth),
        wetbase_estimateDepth: Number(wetBaseEstimateDepth),
      };
      console.log(countertop)
      setApproved(true);
      CountertopService.updateCountertopData(countertop, countertopJoinId).then((res) => {
        sessionStorage.clear();
        sessionStorage.setItem('estimateId', String(estimateId));
        sessionStorage.setItem('estimateName', String(estimateName));
        navigate("/estimativa");
      });
    }
  }

  useEffect(() => {
    setEstimatePrices({
      productName: productName,
      countertopType: countertopType,
      materialName: materialName,
      materialPrice: materialPrice,
      wetBasePrice: toFloat(wetArea * materialPrice),
      dryBasePrice: toFloat(dryArea * materialPrice),
      frontPrice: toFloat(frontArea * materialPrice),
      stripPrice: toFloat(stripArea * materialPrice),
      flankPrice: toFloat(flankPrice),
      productPrice: toFloat(productPrice),
      amount: Number(amount),
      pricePerAmount: toFloat(pricePerAmount),
      installationPrice: toFloat(installationPrice),
      totalInstallationPrice: toFloat(totalInstallationPrice),
      totalPrice: toFloat(totalPrice),
      wetBaseEstimateWidth: Number(wetBaseEstimateWidth),
      wetBaseEstimateDepth: Number(wetBaseEstimateDepth),
      dryBaseEstimateWidth1: Number(dryBaseEstimateWidth1),
      dryBaseEstimateDepth1: Number(dryBaseEstimateDepth1),
      dryBaseEstimateWidth2: Number(dryBaseEstimateWidth2),
      dryBaseEstimateDepth2: Number(dryBaseEstimateDepth2),
      stripEstimateHeight: Number(stripEstimateHeight),
      frontEstimateHeight: Number(frontEstimateHeight),
      vatType: vatType,
      vatAxle: vatAxle,
      vatEstimateAxleDistance: Number(vatEstimateAxleDistance),
      vatEstimateEdgeDistance: Number(vatEstimateEdgeDistance),
      faucetType: faucetType,
      faucetPosition: faucetPosition,
      faucetWall: faucetWall,
      stoveType: stoveType,
      shaft: shaft,
      shaftDistance: Number(shaftDistance),
      shaftFrontDistance: Number(shaftFrontDistance),
      typeOfInstallation: installation === 'true' ? typeOfInstallation : 'Sem instalação',
      flankWidth: Number(flankWidth),
      flankJunctionType: flankJunctionType,
      flankFinishType: flankFinishType,
      countertopDisposition: countertopDisposition,
      vatPosition: vatPosition,
      
    });
  }, [totalPrice]);

  return (
    <Page style={{ height: '100%' }}>
      <Title >
        <SumUp>{edit ? "Editar produto" : "Resumo"}</SumUp>
        <div style={{ display:'flex', flexDirection:'row'}}>
        <Product>
        {sessionStorage.getItem('productName')} -{' '}
        {sessionStorage.getItem('materialName')}
        <AiFillEdit
              onClick={() => {
                sessionStorage.setItem('editing summary', 'true');
                navigate('/selecionar-material');
              }}
              style={{ paddingLeft: '10px', cursor: 'pointer' }}
            />
        </Product>
       </div>
        
      </Title>

      <List>
        <ul>
          <div>
            <TitleItem>
              Bancada
              <AiFillEdit
                onClick={() => {
                  sessionStorage.setItem('editing summary', 'true');
                  navigate('/selecionar-bancada');
                }}
                style={{ paddingLeft: '10px', cursor: 'pointer' }}
              />
            </TitleItem>
          </div>
          {amount && (
            <Each>
              <Item>
                Quantidade&#58; <Type>{amount}</Type>{' '}
              </Item>
            </Each>
          )}
          {countertopType && (
            <Each>
              <Item>
                Tipo de Bancada&#58; <Type>{countertopType}</Type>{' '}
              </Item>{' '}
            </Each>
          )}

          {vatPosition != 'null' && vatPosition && (
            <Each>
              <Item>
                Posição da cuba&#58;{' '}
                <Type>{`Cuba na ${vatPosition.toLocaleLowerCase()}`}</Type>{' '}
              </Item>
            </Each>
          )}

          {countertopDisposition && countertopDisposition!= 'null' &&  (
            <Each>
              <Item>
                Disposição das Bancadas&#58;{' '}
                <Type>{countertopDisposition}</Type>
              </Item>
            </Each>
          )}

          {/* caso seja bancada L*/}
          {wetBaseEstimateWidth && (
            <Each>
              <Item>
                Comprimento Bancada Molhada&#58;{' '}
                <Type>{`${wetBaseEstimateWidth} mm`}</Type>{' '}
              </Item>
            </Each>
          )}

          {wetBaseEstimateDepth && (
            <Each>
              <Item>
                Profundidade Bancada Molhada&#58;{' '}
                <Type>{`${wetBaseEstimateDepth} mm`}</Type>{' '}
              </Item>
            </Each>
          )}

          {dryBaseEstimateWidth && countertopType != "Bancada em U" && (
            <Each>
              <Item>
                Comprimento da Bancada Seca&#58;{' '}
                <Type>{`${dryBaseEstimateWidth} mm`}</Type>{' '}
              </Item>
            </Each>
          )}

          {dryBaseEstimateDepth && countertopType != "Bancada em U" && (
            <Each>
              <Item>
                Profundidade da Bancada Seca&#58;{' '}
                <Type>{`${dryBaseEstimateDepth} mm`}</Type>{' '}
              </Item>
            </Each>
          )}

          {flankPosition && (
            <Each>
              <Item>
                Posição da Ilharga&#58; <Type>{flankPosition}</Type>{' '}
              </Item>
            </Each>
          )}

          {flankWidth && (
            <Each>
              <Item>
                Largura da Ilharga&#58; <Type>{`${flankWidth} mm`}</Type>{' '}
              </Item>
            </Each>
          )}

          {flankFinishType && (
            <Each>
              <Item>
                Tipo de acabamento da Ilharga&#58;{' '}
                <Type>{flankFinishType}</Type>{' '}
              </Item>
            </Each>
          )}

          {flankJunctionType && (
            <Each>
              <Item>
                Tipo de Junção da Ilharga&#58; <Type>{flankJunctionType}</Type>{' '}
              </Item>
            </Each>
          )}

          {/* caso seja bancada U*/}       

          {countertopType == "Bancada em U"  && (
            <Each>
              <Item>
                Comprimento da Bancada Seca 1&#58;{' '}
                <Type>{`${dryBaseEstimateWidth1} mm`}</Type>{' '}
              </Item>
            </Each>
          )}

          {countertopType == "Bancada em U"  && (
            <Each>
              <Item>
                Profundidade da Bancada Seca 1&#58;{' '}
                <Type>{`${dryBaseEstimateDepth1} mm`}</Type>{' '}
              </Item>
            </Each>
          )}

          {countertopType == "Bancada em U"  && (
            <Each>
              <Item>
                Comprimento da Bancada Seca 2&#58;{' '}
                <Type>{`${dryBaseEstimateWidth2} mm`}</Type>{' '}
              </Item>
            </Each>
          )}

          {countertopType == "Bancada em U"  && (
            <Each>
              <Item>
                Profundidade da Bancada Seca 2&#58;{' '}
                <Type>{`${dryBaseEstimateDepth2} mm`}</Type>{' '}
              </Item>
            </Each>
          )}
          <div>
            <TitleItem>
              Cuba
              <AiFillEdit
                onClick={() => {
                  sessionStorage.setItem('editing summary', 'true');
                  navigate('/cuba');
                }}
                style={{ paddingLeft: '10px', cursor: 'pointer' }}
              />
            </TitleItem>
          </div>
          {stripEstimateHeight && (
            <Each>
              <Item>
                Altura da Saia&#58; <Type>{`${stripEstimateHeight} mm`}</Type>{' '}
              </Item>
            </Each>
          )}

          {frontEstimateHeight && (
            <Each>
              <Item>
                Altura Front&#58; <Type>{`${frontEstimateHeight} mm`}</Type>{' '}
              </Item>{' '}
            </Each>
          )}

          {vatType && (
            <Each>
              <Item>
                Tipo de Cuba&#58; <Type>{vatType}</Type>{' '}
              </Item>
            </Each>
          )}

          {vatAxle && (
            <Each>
              <Item>
                Eixo da Cuba&#58; <Type>{vatAxle}</Type>{' '}
              </Item>
            </Each>
          )}

          {vatEstimateAxleDistance && vatEstimateAxleDistance != '0' && (
            <Each>
              <Item>
                Distância do Eixo&#58;{' '}
                <Type>{`${vatEstimateAxleDistance} mm`}</Type>{' '}
              </Item>
            </Each>
          )}

          {vatEstimateEdgeDistance && (
            <Each>
              <Item>
                Distância Entre a Cuba e a Saia&#58;{' '}
                <Type>{`${vatEstimateEdgeDistance} mm`}</Type>{' '}
              </Item>
            </Each>
          )}
          <div>
            <TitleItem>
              Tipo de torneira
              <AiFillEdit
                onClick={() => {
                  sessionStorage.setItem('editing summary', 'true');
                  navigate('/tipo-de-torneira');
                }}
                style={{ paddingLeft: '10px', cursor: 'pointer' }}
              />
            </TitleItem>
          </div>
          {faucetType && (
            <Each>
              <Item>
                Tipo de Torneira&#58; <Type>{faucetType}</Type>{' '}
              </Item>
            </Each>
          )}

          {faucetPosition && (
            <Each>
              <Item>
                Posição da Torneira&#58; <Type>{faucetPosition}</Type>{' '}
              </Item>
            </Each>
          )}
          <div>
            <TitleItem>
              Tipo de Fogão
              <AiFillEdit
                onClick={() => {
                  sessionStorage.setItem('editing summary', 'true');
                  navigate('/tipo-de-fogao');
                }}
                style={{ paddingLeft: '10px', cursor: 'pointer' }}
              />
            </TitleItem>
          </div>
          {stoveType && (
            <Each>
              <Item>
                Tipo de Fogão&#58; <Type>{stoveType}</Type>{' '}
              </Item>
            </Each>
          )}

          {shaft && (
            <Each>
              <Item>
                Eixo do Fogão&#58; <Type>{shaft}</Type>{' '}
              </Item>
            </Each>
          )}

          {shaftDistance && shaftDistance != '0' && (
            <Each>
              <Item>
                Distância do Eixo&#58; <Type>{`${shaftDistance} mm`}</Type>{' '}
              </Item>
            </Each>
          )}

          {shaftFrontDistance && shaftFrontDistance != '0' && (
            <Each>
              <Item>
                Distância Entre a Corte e Saia&#58;{' '}
                <Type>{`${shaftFrontDistance} mm`}</Type>{' '}
              </Item>
            </Each>
          )}
          <div>
            <TitleItem>
              Instalação
              <AiFillEdit
                onClick={() => {
                  sessionStorage.setItem('editing summary', 'true');
                  navigate('/instalacao');
                }}
                style={{ paddingLeft: '10px', cursor: 'pointer' }}
              />
            </TitleItem>
          </div>
          {installation && (
            <Each>
              <Item>
                Instalação&#58; <Type>{thereWillBeInstallation}</Type>{' '}
              </Item>
            </Each>
          )}

          {typeOfInstallation && thereIsType && (
            <Each>
              <Item>
                Tipo de Instalação&#58; <Type>{typeOfInstallation}</Type>{' '}
              </Item>
            </Each>
          )}
        </ul>
      </List>

      <BottomDiv>
        {edit ? (
          <Link to="/estimativa" onClick={() => {
            sessionStorage.clear();
            sessionStorage.setItem('estimateId', String(estimateId));
            sessionStorage.setItem('estimateName', String(estimateName));
          }}>
            <BackBtn />
      </Link>
        ) : (
          <Link to="/instalacao"></Link>
        )}

        {edit ? (
          <Container>
            <Column>
              <Total>Total</Total>
              <Amount>{toBRLPrice(totalPrice)}</Amount>

              {true ? <DownloadPrices {...estimatePrices} /> : ""}
            </Column>
            <BudgetBtn onClick={sendUpdateToBackEnd}>
              Salvar alterações
            </BudgetBtn>
          </Container>
        ) : (
          <>
            {addProductToEstimate ? (
              <Container>
                <Column>
                  <Total>Total</Total>

                  <Amount>{toBRLPrice(totalPrice)}</Amount>
                </Column>
                <BudgetBtn onClick={sendToBackEnd}>
                  Adicionar ao Orçamento
                </BudgetBtn>
              </Container>
            ) : (
              <Container>
                <div>
                  <Column>
                    <Total>Nome da obra</Total>
                    <ContainerInput>
                      <Input
                        type="string"
                        placeholder="Identifique o nome da obra ou cliente"
                        value={estimateName === 'null' ? undefined : estimateName}
                        maxLength={50}
                        onChange={(event: any) =>
                          setEstimateName(event.target.value)
                        }
                      />
                    </ContainerInput>
                  </Column>
                  <Row>
                    <Column>
                      <Total>Total</Total>

                      <Amount>{toBRLPrice(totalPrice)}</Amount>

                      {true ? <DownloadPrices {...estimatePrices} /> : ""}
                    </Column>

                    {estimateName && estimateName !== 'null' ? (
                      <BudgetBtn onClick={sendToBackEnd}>
                        Adicionar ao Orçamento
                      </BudgetBtn>
                    ) : (
                      <BudgetBtnDisable>
                        Adicionar ao Orçamento
                      </BudgetBtnDisable>
                    )}
                  </Row>
                </div>
              </Container>
            )}
          </>
        )}
      </BottomDiv>
    </Page>
  );
}