import React, { useRef, useState } from "react";
import { Container, Form, H6, H2, Input, InputDiv, SignUpBtn, A, TextBox, GrayH6 } from "./style";
import { Link, useNavigate } from "react-router-dom";
import api from "../../services/api";
import { useForm } from "react-hook-form";
import { BsArrowRight } from "react-icons/bs";
import InputMask from "react-input-mask";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import ClientService from "../../services/ClientService";
import './style.css';
import { toast } from "react-toastify";

interface registerProps {
    position: string
}

const inputCleaner = (value: string) => {
    const cleanInput = value.replace(/[^0-9]/g, '');
    return cleanInput;
}

export default function RegisterCard({ position }: registerProps) {
    const { register, formState: { errors }, handleSubmit, setValue } = useForm();

    //o que mandar pro back
    // const [identificationType, setIdentificationType] = useState('');
    const [identificationNumber, setIdentificationNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisibility, setPasswordVisibility ] = useState(false);
    const [ConfirmPasswordVisibility, setConfirmPasswordVisibility ] = useState(false);
    
    let name = firstName.slice(0,1).toUpperCase()
    let rest = firstName.slice(1, firstName.length).toLowerCase()
    let userName = name + rest

    const navigate = useNavigate();
    const onSubmit = () => {
        const signUpData = {    
            identificationType: 'CPF',
            identificationNumber: identificationNumber,
            firstName: userName,
            lastName: lastName,
            email: email,
            phoneAreaCode: phoneNumber.slice(0, 2),
            phoneNumber: phoneNumber.slice(2),
            password: password,
            secretPass: prompt('Código de cadastro')
        }
        console.log(signUpData);
        api.post('/clients', signUpData).then(res => {
            toast.success('Conta criada com sucesso!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                closeOnClick: true,
                draggable: true,
                onClick: () => navigate('/login'),
                onClose: () => navigate('/login')
            })
        }).catch(err => {
            console.log(err);
            toast.error('Não foi possível criar uma conta. Verifique seus dados ou se a conta já existe!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                closeOnClick: true,
                draggable: true,
            })
        })        
    }

    const InputStyle = {
        
        border: 'none',
        backgroundColor: '#F2F2F2',
        marginLeft:'10px',
        outline: 'none'
    }

    const EyeStyle = {
        fontSize: '1.5rem',
        color: '#BDBDBD',
        marginLeft: 'auto',
        padding: '15px' 

    }
    // console.log(signUpData)
    return (
        <Container position={position}>
            <H2>Cadastre-se</H2>
            <H6>Crie uma conta com seu e-mail</H6>
            <Form onSubmit={handleSubmit(onSubmit)} style={{ position: 'relative' }}>
                <InputDiv>
                    <Input
                        placeholder='Nome'
                        {...register('nome', { required: true, pattern: /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/i })}
                        value={firstName}
                        onChange={(event) => {
                            setValue('nome', event.target.value)
                            setFirstName(event.target.value)}
                    }/>
                </InputDiv>
                {errors.nome?.type === 'required' && <p style={{ color: 'red', fontSize: 12 }}>Campo obrigatório</p>}
                <InputDiv>
                    <Input placeholder='Sobrenome'
                        {...register('sobrenome', { required: true, pattern: /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/i })}
                        value={lastName}
                        onChange={(event) => {
                            setValue('sobrenome', event.target.value)
                            setLastName(event.target.value)}} />
                </InputDiv>
                {errors.sobrenome?.type === 'required' && <p style={{ color: 'red', fontSize: 12 }}>Campo obrigatório</p>}
                <InputDiv>
                    <InputMask
                        className="mascara"
                        mask={'999.999.999-99'}
                        placeholder='CPF'
                        {...register('CPF', { required: true, minLength: 14, maxLength: 14, pattern: {
                            value: /[0-9]{3}[.][0-9]{3}[.][0-9]{3}[-][0-9]{2}$/,
                            message: 'Formato inválido'
                        }  })}
                        value={identificationNumber}
                        onChange={(event) => {
                            setValue('CPF', event.target.value)
                            setIdentificationNumber(inputCleaner(event.target.value))}}
                        style ={InputStyle} 
                        
                        
                        />
                </InputDiv>
                
                {errors.CPF?.type === 'required' && <p style={{ color: 'red', fontSize: 12 }}>Campo obrigatório</p>}
                {errors.CPF?.type === 'maxLength' && <p style={{ color: 'red', fontSize: 12 }}>CPF inválido
                </p>}
                {errors.CPF?.type === 'minLength' && <p style={{ color: 'red', fontSize: 12 }}>CPF inválido
                </p>}
                {errors.CPF?.type === 'pattern' && <p style={{ color: 'red', fontSize: 12 }}>CPF inválido
                </p>}
                <InputDiv>
                    <InputMask
                        className="mascara"
                        mask={'(99) 99999-9999'}
                        placeholder='Telefone'
                        {...register('telefone', { required: 'Campo obrigatório', maxLength: 15, minLength: 14, pattern: {
                                value: /[(][0-9]{2}[)][\s][0-9]{5}[-][0-9]{4}$/,
                                message: 'Formato inválido'
                            } 
                        })}
                        value={phoneNumber}
                        onChange={(event) => {
                            setValue('telefone', event.target.value)
                            setPhoneNumber(inputCleaner(event.target.value))}}
                        style = {InputStyle}
                    />
                </InputDiv>
                {errors.telefone?.type === 'required' && <p style={{ color: 'red', fontSize: 12 }}>Campo obrigatório</p>}
                {errors.telefone?.type === 'maxLength' && <p style={{ color: 'red', fontSize: 12 }}>Telefone inválido</p>}
                {errors.telefone?.type === 'minLength' && <p style={{ color: 'red', fontSize: 12 }}>Telefone inválido</p>}
                {errors.telefone?.type === 'pattern' && <p style={{ color: 'red', fontSize: 12 }}>Telefone  inválido</p>}
                <InputDiv>
                    <Input placeholder='E-mail'
                        {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                        value={email}
                        onChange={(event) => {
                            setValue('email', event.target.value)
                            setEmail(event.target.value)}}
                    />
                </InputDiv>
                {errors.email?.type === 'required' && <p style={{ color: 'red', fontSize: 12 }}>Campo obrigatório</p>}
                {errors.email?.type === 'pattern' && <p style={{ color: 'red', fontSize: 12 }}>E-mail inválido</p>}
                <InputDiv>
                    <Input placeholder='Senha'
                        {...register('senha', { required: true, minLength: 8 })}
                        value={password}
                        onChange={(event) => {
                            setValue('senha', event.target.value)
                            setPassword(event.target.value)}}
                        type = { passwordVisibility ? '': 'password'}
                        />
                        
                        {passwordVisibility ?
                        <AiFillEyeInvisible
                            onClick={() => setPasswordVisibility(!passwordVisibility)}
                            style={EyeStyle}
                        />
                        : <AiFillEye
                            onClick={() => setPasswordVisibility(!passwordVisibility)}
                            style={EyeStyle}
                        />
                    }
                    
                </InputDiv>
                {errors.senha?.type === 'minLength' && <p style={{ color: 'red', fontSize: 12 }}>Sua senha precisa ter pelo menos 8 dígitos</p>}
                <InputDiv>
                    <Input 
                        placeholder='Confirmar Senha'
                        {...register('confirmar_senha', { required: true, validate: value => value === password })} 
                        type = { ConfirmPasswordVisibility ? '': 'password'}
                        />
                        
                        {ConfirmPasswordVisibility ?
                        <AiFillEyeInvisible
                            onClick={() => setConfirmPasswordVisibility(!ConfirmPasswordVisibility)}
                            style={EyeStyle}
                            
                        />
                        : <AiFillEye
                            onClick={() => setConfirmPasswordVisibility(!ConfirmPasswordVisibility)}
                            style={EyeStyle}
                        />
                    }



                </InputDiv>
                {errors.confirmar_senha?.type === 'required' && <p style={{ color: 'red', fontSize: 12 }}>Campo obrigatório</p>}
                {errors.confirmar_senha?.type === 'validate' && <p style={{ color: 'red', fontSize: 12 }}>As senhas não são iguais</p>}
                <SignUpBtn type="submit">
                    <div>
                        <h4>Criar Conta</h4>
                    </div>
                    <BsArrowRight style={{ fontSize: 22, position: 'absolute', right: 15 }} />
                </SignUpBtn>
            </Form>

            <TextBox>
                <GrayH6>Já tem uma conta?</GrayH6> <A><Link to="/login"><h6>Entrar</h6></Link></A>
            </TextBox>
        </Container >
    )
}