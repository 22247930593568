import styled from "styled-components";

// export const Page = styled.div`
// display:flex;
// flex-direction:column;
// padding: 30px 3.47222222222222vw 0px 3.47222222222222vw;
// background:#F4F4F4;
// height:165vh;
// `
export const Page = styled.div`
display:flex;
flex-direction:column;
background:#f2f2f2;
padding-top:50px;
padding-left:3.472222222vw;
padding-right:3.472222222vw;
height: auto;
/* padding-bottom: 20px; */
@media (max-width: 912px) {
  padding-left:6vw;
  padding-right:6vw;
  padding-top:6vh;
  min-height: 100vh;
}
@media(max-width: 1000px){
     background-color: white;
}
`
export const Title = styled.div`
display:flex;
flex-direction:column;
align-items:center;

`
export const SumUp = styled.h2`

font-size: 24px;
font-weight: 700;
color:#2566C8;
`

export const Product = styled.h3`

font-size: 24px;
font-weight: 700;
color:#828282;
margin-top:5.598vh;
`

export const List = styled.div`
display: flex;
justify-content:center;
margin-top:7.309vh;
`
export const Each = styled.li`
margin-bottom:3.11vh;
`

export const Item = styled.h6`

align-self:start;
font-weight: 700;
font-size:15.5px;
color:#828282;
`
export const Type = styled.span`
font-weight: 400;
`
//AddBtn
export const Container = styled.div`
display: flex;
position: relative;
background-color:#ffffff;
flex-direction: row;
align-items: center;
padding:2rem 2rem;
gap:1.5rem;
//height: 12.44vh;
box-shadow: 0 1.24vh 2.196vw 0 rgba(44, 44, 44, 0.2);
border-radius:1.24vh;
@media (max-width: 1200px){
    width: fit-content;
    padding:3.5vw 3.5vw;
    box-shadow: 0 2vh 3vw 0 rgba(44, 44, 44, 0.2);
}
@media (max-width: 912px){
  margin-top: 5vw;
}

`
export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2.05vh;
  @media(max-width:350px){
    flex-direction: column;
    padding-top: 15px;
    gap: 10px;
    width: 90vw;
    left:5px;
  }
`

export const ContainerInput = styled.div`
  height: 3.125rem;
  width: 21.5vw;
  justify-content:space-between;
  align-items:center;
  display:flex;
  background:white;
  min-width: 335px;
  h6{
    font-size:0.625rem;
    
    margin-left:10px;
    }
  box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
  border-radius: 8px;
  color: #828282;
  @media(max-width:1840px){
    width:28.8vw;
  }
  @media(max-width:1661px){
    width:28.7vw;
  }
  @media(max-width:1534px){
    width:28.6vw;
  }
  @media(max-width:1425px){
    width:28.5vw;
  }
  @media(max-width:1163px){
    width:28.4vw;
  }
  @media(max-width:1099px){
    width:28vw;
  }
`
export const Input = styled.input`

padding-left:20px;
font-size:1rem;
display:flex;
flex:2;
border:none;
color: #828282;

&::placeholder{
  color:#BDBDBD
 }

&:focus{
    outline:none;
}
@supports (-webkit-touch-callout: none) {
  /* Atualmente, apenas dispositivos iOS possuem a feature -webkit-touch-callout,
  logo esse código testa se o dispositivo é iOS ou não. Caso a Apple mude algo,
  basta consultar
  https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariCSSRef/Articles/StandardCSSProperties.html
  e buscar por "Safari on iOS only"*/
  font-size: 16px;
  width: 95%;
  }
`
export const Total = styled.h6`
//position: absolute;
color: #BDBDBD;

font-weight: 700;
font-size: 2.17vh;
top: 15px;
margin-bottom: 2.05vh;
`

export const Amount = styled.h2`
color: #828282;

font-size: 3.88vh;
`

export const BudgetBtn = styled.button`
display: flex;
align-items: center;
position: relative;
justify-content: center;
align-self: center;
width: 10.91vw;
margin-left: 2.05vh;
/* height: 6.22vh; */
padding: 0.5rem;
border: none;
border-radius:0.77vh;
background-color: #2566C8;

color: #FFF;
font-size: 1.25rem;
font-weight: 700;
line-height: 24px;
&:hover{
    cursor:pointer;
    opacity:0.8;
};
@media (max-width: 1200px){
    width: fit-content;
}
@media (min-width: 2410px){
    width: fit-content;
    padding: 15px;
}

`
export const BudgetBtnDisable = styled.button`
display: flex;
align-items: center;
justify-content: center;
align-self: center;
position: relative;
width: 10.91vw;
margin-left: 2.05vh;
/* height: 6.22vh; */
padding: 0.5rem;
border: none;
border-radius:0.77vh;
background-color: #D2D2D2;

color: #FFF;
font-size: 1.25rem;
font-weight: 700;
line-height: 24px;
@media (max-width: 1200px){
    width: fit-content;
}
@media (min-width: 2410px){
    width: fit-content;
    padding: 15px;
}

`
export const TitleItem = styled.h2`

display: flex;
align-self:center;
font-weight: 700;
font-size:20px;
color:#828282;
padding-bottom: 12px;
`
export const BottomDiv = styled.div`
display: flex;
width:100%;
height: 100%;
align-self:center;
align-items: flex-end;
margin-bottom: 6.3vh;
justify-content: space-between;
@media(max-width:1200px){
  justify-content: center;
}
`