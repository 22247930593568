import styled from "styled-components";

export const Body = styled.div`
@media(max-width:912px){
    background-color: white;
}
`
export const MainContent = styled.div`
display:flex;
flex-direction:column;
padding: 30px 90px 30px 90px;   
height: 100%;
@media (max-width:912px){
    padding: 30px 10px 30px 10px;  
    background-color: black;
    background-color: white;
}
@media (max-width:748px){
    /* padding: 30px 15px 30px 15px;   */
    background-color: black;
    background-color: white;
}
`
export const Div = styled.div`
display: flex;
flex-direction: row;
align-items: center;

height: fit-content;
*border: solid 2px green;
`
export const Texth1 = styled.h1`

font-size: 24px;
font-weight: 700;
color: #828282;
@media (max-width:912px){
    display:none;
}
`
export const CardContainer = styled.div`
/* display:flex;
flex-direction:row;
flex-flow:wrap;
justify-content:space-between; */
  display: flex;
  gap: 0.25rem;
  padding: 0.25rem;
  align-items: center;
  grid-area: content;
  justify-content: center;
@media (max-width:912px){
    display:none;
}
`
export const Content1 = styled.div`
  padding: 0.25rem;
  width: 100%;
  height: 100%;
`;


export const Line = styled.div`
background-color:#f2f2f2;
height:1px;
width:100%;
margin-top:30px;
@media (max-width:912px){
    display:none;
}
`
export const BotSection = styled.div`
display:flex;
flex-direction:row;
flex:1;
@media (max-width:912px){
    display:none;
}
`

export const Section = styled.section`
display:flex;
flex-direction:column;
width:33.333333333%;
gap: 70px;
width: 100%;
padding-bottom: 10vw;
@media (max-width:912px){
    height: calc(17.3% + 15px);
    gap:10px;
    align-items: center;
}
@media(max-width:375px){
 padding-left: 7px;   
}
`
export const Btn = styled.button`
border:none;
background: linear-gradient(271.82deg, #2566C8 0%, #4485E8 100%);
box-shadow: 0 8px 30px 0 rgba(0,44,113,0.25);
height: 40px;
width: 20rem;
border-radius: 8px;
color:white;
display:flex;
align-items:center;
margin-top:1rem;
@media (max-width:1400px){
    width:16rem; 
}
@media (max-width:912px){
    display:none;
}
&:hover{
    cursor:pointer;
    opacity: 0.5;
}
`
export const SectionTitle = styled.h4`
color: #828282;

font-size: 16px;
font-weight: 300;
line-height: 17px;
text-align: center;
`
export const InSecTxt = styled.h4`
color: #BDBDBD;

font-size: 16px;
font-weight: 300;
line-height: 16px;
text-align: center;
margin-top: 8.5rem;
@media(max-width:1090px){
    font-size: 14px;
}
@media(max-width: 912px) {
    display:flex;
    margin-top: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
`
export const BtnTxt = styled.h4`
color: #FFF;

font-size: 16px;
font-weight: 700;
text-align: center;
width: 100%;
`
export const SectionLeft = styled.div`
border-right: 1px solid #F2F2F2;
display: flex;
flex-direction: column;
align-items: center;
&:hover{
    cursor:grab
}
@media(max-width: 912px) {
    height: 180px;
    width:100%;
    border:none;
}
`
export const SectionRight = styled.div`
border-left: 1px solid #F2F2F2;
display: flex;
flex-direction: column;
align-items: center;

@media(max-width: 912px) {
    height: 180px;
    border:none;
}
`
export const SectionMid = styled.div`
display: flex;
flex-direction: column;
align-items: center;
&:hover{
    cursor:pointer
}
@media(max-width: 912px) {
    height: 180px;
    border:none;
    width: 100%;
}
`
export const MobileBtn = styled.button`
display:none;
@media (max-width:912px){
    align-self:center;
    border:none;
    background: linear-gradient(271.82deg, #2566C8 0%, #4485E8 100%);
    box-shadow: 0 8px 30px 0 rgba(0,44,113,0.25);
    height: 40px;
    width: 20rem;
    border-radius: 8px;
    color:white;
    display:flex;
    align-items:center;
    margin-top:1rem;
@media (min-width:200px) and (max-width:912px) {
    padding: 12px;
    max-width: 300px;
    
}
}
`
export const MobileBotSection = styled.div`
display:none;
@media (max-width:912px){
    display:flex;
    flex:1;
    flex-direction: column;
    gap:1rem;
    background-color: white;
}
@media (max-width:375px){
   margin-left: -5%;
}
`
export const PopUpText = styled.text`
color: #828282;

font-size: 16px;
font-weight: 300;
line-height: 17px;
@media (max-width: 912px) {
    font-size: 18px;
}
`;

export const PopUp = styled.div`
position: absolute;
z-index: 1;
right: 0;
width: 8.6%;
padding: 12px;
border: none;
background-color: white;
margin-top: 0.2rem;
height: 18%;
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
display: flex;
justify-content: center;
flex-direction: column;
gap: 16px;
border-radius: 5px;
/* border: 0.5px solid lightgray; */
@media (min-width: 913px) and (max-width: 981px) {
    margin-right: 21%;
    /* border: 0.5px solid lightgray; */
}

@media (max-width: 912px) {
    height: 100vh;
    width: 240px;
    top: 0;
    left: 0;
    text-align: center;
    gap: 64px;
    margin-top: -1rem;
    justify-content: start;
    padding-top: 86px;

}

@media (min-width:200px) and (max-width:912px) {
    height: 140vh;
    width: 200px;
}

@media (min-width:389px) and (max-width:414px) {
    height: 120vh;
}
`;

export const ButtonToggle = styled.button`
color: #828282;

font-size: 24.4px;
font-weight: 500;
line-height: 17px;
display: none;
background-color: transparent;
border: none;
@media (max-width: 912px) {
    display: flex;
    margin-left: 14px;   
    /* margin-top: -28.75rem; */
}

/* @media (min-width:393px) and (max-width: 415px) {
    margin-top: -800px;
} */
/* @media (min-width: 200px) and (max-width:414px) {
    margin-top: -446px;
} */


`;