import { useEffect, useRef, useState } from "react";
import OptionBtnCard from "../../components/OptionBtnCard";
import Cuba from "../../assets/cuba.svg";
import Cuba1 from "../../assets/cuba1.svg";
import Cuba2 from "../../assets/cuba2.svg";
import Marked from "../../assets/Marked.svg";
import NotMarked from "../../assets/NotMarked.svg";
import Center from "../../assets/Center.svg";
import Left from "../../assets/Left.svg";
import Right from "../../assets/Right.svg";
import {
  TopInputWrapper,
  CubaTypeWrapper,
  CubaAxisWrapper,
  DistanceInputWrapper,
  BottomSection,
  InputTitle2,
  InputTitle1,
  Div,
  Page,
} from "./style";
import { DisabledBtn, SbmtBtn, TitleInput } from "../BenchL/style";
import BackBtn from "../../components/BackBtn";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container, Input } from "../../components/DetailsInput/style";
import { StyleErrorMessage } from "../../components/StyleErrorMessage";
import { Main, MobileContainer, Top } from "../CounterTopSelect/style";
import { StepCounter } from "../../components/StepCounter";
import { LegendaText1 } from "../BenchSizing/style";

function CubaStyle() {
  //validação
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ mode: "onBlur" });

  const onSubmit = () => {
    sendToSessionStorage();
    editingSummary ? navigate("/resumo") : navigate("/tipo-de-torneira");
  };
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [controller0, setController0] = useState<number>(); //altura saia/tira
  const [controller1, setController1] = useState<number>(); //Altura Frontspício
  const [controller2, setController2] = useState<number>(); //Distância do eixo
  const [controller3, setController3] = useState<number>(); //Distância entre Cuba e Saia
  const [select0, setSelect0] = useState<boolean>(false);
  const [select1, setSelect1] = useState<boolean>(false);
  const [select2, setSelect2] = useState<boolean>(false);
  const [center, setCenter] = useState<boolean>(false);
  const [left, setLeft] = useState<boolean>(false);
  const [right, setRight] = useState<boolean>(false);
  const [advance, setAdvance] = useState<boolean>(true);

  const [show, setShow] = useState<boolean>(false);

  const countertopType = sessionStorage.getItem("countertopType");
  const editingSummary = sessionStorage.getItem("editing summary") === 'true' ? true: false;
  const editingProduct = sessionStorage.getItem("editing product") === 'true' ? true: false;

  const paginaAnterior =
    editingSummary || editingProduct ? "resumo" :
      countertopType === "Bancada Reta"
        ? "selecionar-bancada"
        : countertopType === "Bancada em L"
          ? "bancada-L"
          : "bancada-U";

  useEffect(() => {
    showButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller2 && controller3]);

  useEffect(() => {
    if (
      controller0 &&
      controller1 &&
      (select0 || select1 || select2) &&
      (center || left || right)
    ) {
      setAdvance(false);
    } else {
      setAdvance(true);
    }
  }, [
    controller0,
    controller1,
    select0,
    select1,
    select2,
    center,
    left,
    right,
  ]);

  function showButton() {
    setShow(!show);
  }
  const cubaNames = [
    { name: "Tramontina nº1", index: 0, icon: Cuba },
    { name: "Tramontina nº2", index: 1, icon: Cuba1 },
    { name: "Tramontina nº3", index: 2, icon: Cuba2 },
  ];
  const cubaAxis = [
    { name: "Centralizada", icon: Center },
    { name: "Partindo da Esquerda", icon: Left },
    { name: "Partindo da Direita", icon: Right },
  ];
  console.log(select0, select1, select2);

  function selectCuba(index: number) {
    switch (index) {
      case 0:
        setSelect0(!select0);
        setSelect1(false);
        setSelect2(false);
        break;
      case 1:
        setSelect0(false);
        setSelect1(!select1);
        setSelect2(false);
        break;
      case 2:
        setSelect0(false);
        setSelect1(false);
        setSelect2(!select2);
    }
  }
  function selectAxis(index: number) {
    switch (index) {
      case 0:
        setCenter(!center);
        setLeft(false);
        setRight(false);
        break;
      case 1:
        setCenter(false);
        setLeft(!left);
        setRight(false);
        break;
      case 2:
        setCenter(false);
        setLeft(false);
        setRight(!right);
    }
  }

  const selectArray = [select0, select1, select2];
  const selectArray2 = [center, left, right];

  // sessionStorage.removeItem("stripEstimateHeight");
  // sessionStorage.removeItem("frontEstimateHeight");
  // sessionStorage.removeItem("vatEstimateAxleDistance");
  // sessionStorage.removeItem("vatEstimateEdgeDistance");
  // sessionStorage.removeItem("vatType");
  // sessionStorage.removeItem("vatAxle");

  function sendToSessionStorage() {
    sessionStorage.setItem(
      "stripEstimateHeight",
      controller0 ? controller0.toString() : ""
    ); //altura saia/tira
    sessionStorage.setItem(
      "frontEstimateHeight",
      controller1 ? controller1.toString() : ""
    ); //Altura Frontspício
    sessionStorage.setItem(
      "vatEstimateAxleDistance",
      controller2 ? controller2.toString() : ""
    ); //Distância do eixo
    sessionStorage.setItem(
      "vatEstimateEdgeDistance",
      controller3 ? controller3.toString() : ""
    ); //Distância entre Cuba e Saia
    sessionStorage.setItem(
      "vatType",
      select0
        ? "Tramontina nº1"
        : select1
        ? "Tramontina nº2"
        : select2
        ? "Tramontina nº3"
        : ""
    );
    sessionStorage.setItem(
      "vatAxle",
      center
        ? "Centralizada"
        : left
        ? "Partindo da Esquerda"
        : right
        ? "Partindo da Direita"
        : ""
    );
  }

  const stripEstimateHeightStorage = sessionStorage.getItem("stripEstimateHeight");
  const frontEstimateHeightStorage = sessionStorage.getItem("frontEstimateHeight");
  const vatEstimateAxleDistanceStorage = sessionStorage.getItem("vatEstimateAxleDistance");
  const vatEstimateEdgeDistanceStorage = sessionStorage.getItem("vatEstimateEdgeDistance");
  const vatTypeStorage = sessionStorage.getItem("vatType");
  const vatAxle = sessionStorage.getItem("vatAxle");

  useEffect(() => {
    if(stripEstimateHeightStorage) {
      {setController0(Number(stripEstimateHeightStorage))
      setValue("altura1", stripEstimateHeightStorage)}
    }

    if(frontEstimateHeightStorage) {
      {setController1(Number(frontEstimateHeightStorage))
      setValue("altura2", frontEstimateHeightStorage)}
    }

    if(vatEstimateAxleDistanceStorage) {
      {setController2(Number(vatEstimateAxleDistanceStorage))
      setValue("medida_1", vatEstimateAxleDistanceStorage)}
    }

    if(vatEstimateEdgeDistanceStorage) {
      {setController3(Number(vatEstimateEdgeDistanceStorage))
      setValue("medida_2", vatEstimateEdgeDistanceStorage)}
    }

    if(vatTypeStorage === "Tramontina nº1") {
      setSelect0(true)
      setSelect1(false)
      setSelect2(false)
    }

    if(vatTypeStorage === "Tramontina nº2") {
      setSelect0(false)
      setSelect1(true)
      setSelect2(false)
    }


    if(vatTypeStorage === "Tramontina nº3") {
      setSelect0(false)
      setSelect1(false)
      setSelect2(true)
    }

    if(vatAxle === "Centralizada") {
      setCenter(true)
      setRight(false)
      setLeft(false)
    }

    if(vatAxle === "Partindo da Esquerda") {
      setCenter(false)
      setRight(false)
      setLeft(true)
    }

    if(vatAxle === "Partindo da Direita") {
      setCenter(false)
      setRight(true)
      setLeft(false)
    }

  },[])


  return (
    <Page>
      <MobileContainer>
        <span>Orçamento</span>
        <Main style={controller0 && controller1 ? {height: ''} : {height: '100vh'}}>
          <div
            style={windowSize[0] <= 1000 ? { padding: " 0vw 0vw 3vw 5vw" } : {}}
          >
            <div
              style={{
                paddingTop: "25px",
                paddingBottom: "20px",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <BackBtn
                onClick={() => navigate(`/${paginaAnterior}`)}
                isMobile={true}
                style={{ position: "absolute", left: 0 }}
              />
              <Top>
                <StepCounter step={3} />
              </Top>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Organização dos inputs no desktop */}
              <TopInputWrapper>
                <InputTitle1>
                  <h2 style={{ color: "#828282" }}>Altura Saia/Tira</h2>
                </InputTitle1>

                {/* Divisão feita para arrumar as mensagens de erro */}
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Div style={{ flexDirection: "column" }}>
                    <InputTitle2>
                      <h2 style={{ color: "#828282" }}>Altura Saia/Tira</h2>
                    </InputTitle2>

                    <Container largura={28.7}>
                      <Input
                        type="number"
                        {...register("altura1", {
                          required: true,
                          max: 200,
                          min: 40,
                        })}
                        placeholder={"Altura"}
                        value={controller0}
                        onChange={(event: any) =>
                          {setController0(event.target.value)
                            setValue("altura1", event.target.value)}
                        }
                      />
                      <h6>milímetros</h6>
                    </Container>
                  </Div>
                  {errors.altura1?.type === "max" && (
                    <p style={StyleErrorMessage}>Máximo de 200 milímetros</p>
                  )}
                  {errors.altura1?.type === "min" && (
                    <p style={StyleErrorMessage}>Mínimo de 40 milímetros</p>
                  )}
                  {errors.altura1?.type === "required" && (
                    <p style={StyleErrorMessage}>Campo obrigatório</p>
                  )}
                </div>

                <InputTitle1>
                  <h2 style={{ color: "#828282" }}>Altura Frontspício</h2>
                </InputTitle1>
                {/* Divisão feita para arrumar as mensagens de erro */}
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Div style={{ flexDirection: "column" }}>
                    <InputTitle2>
                      <h2 style={{ color: "#828282" }}>Altura Frontspício</h2>
                    </InputTitle2>
                    <Container largura={28.7}>
                      <Input
                        type="number"
                        {...register("altura2", {
                          required: true,
                          max: 800,
                          min: 40,
                        })}
                        placeholder={"Altura"}
                        value={controller1}
                        onChange={(event: any) =>
                          {setController1(event.target.value)
                          setValue("altura2", event.target.value)}
                        }
                      />
                      <h6>milímetros</h6>
                    </Container>
                  </Div>
                  {errors.altura2?.type === "max" && (
                    <p style={StyleErrorMessage}>Máximo de 800 milímetros</p>
                  )}
                  {errors.altura2?.type === "min" && (
                    <p style={StyleErrorMessage}>Mínimo de 40 milímetros</p>
                  )}
                  {errors.altura2?.type === "required" && (
                    <p style={StyleErrorMessage}>Campo obrigatório</p>
                  )}
                </div>
              </TopInputWrapper>
                  
              {controller0 && controller1 && (
                <>
                <h2 style={{ color: "#828282", paddingBottom: "15px" }}>
                  Tipo de Cuba
                </h2>
                <CubaTypeWrapper>
                    {cubaNames.map((cuba, index) => (
                      <OptionBtnCard
                        onClick={() => selectCuba(index)}
                        label={cuba.name}
                        icon={cuba.icon}
                        selected={selectArray[index] ? Marked : NotMarked} />
                    ))}
                  </CubaTypeWrapper>
                  </>
              )}
              {controller0 && controller1 && (select0 || select1 || select2) && (
                  <><h2 style={{ color: "#828282", paddingBottom: "15px" }}>
                  Eixo de Cuba
                </h2><CubaAxisWrapper>
                    {cubaAxis.map((axis, index) => (
                      <OptionBtnCard
                        onClick={() => selectAxis(index)}
                        label={axis.name}
                        icon={axis.icon}
                        selected={selectArray2[index] ? Marked : NotMarked} />
                    ))}
                  </CubaAxisWrapper></>
              )}
            
              {controller0 &&
              controller1 &&
              (select0 || select1 || select2) &&
              (center || left || right) ? (
                <>
                  <DistanceInputWrapper>
                    {center ? (
                      ""
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Div style={{ flexDirection: "column" }}>
                          <TitleInput>Distância do eixo</TitleInput>
                          <Container largura={28.7}>
                            <Input
                              type="number"
                              {...register("medida_1", {
                                required: true,
                                max: 2300,
                                min: 300,
                              })}
                              placeholder={
                                windowSize[0] <= 1000
                                  ? "Distância do eixo"
                                  : "Medida"
                              }
                              onChange={(event: any) =>
                                {setController2(event.target.value)
                                setValue("medida_1", event.target.value)}
                              }
                            />
                            <h6>milímetros</h6>
                          </Container>
                        </Div>
                        {errors.medida_1?.type === "max" && (
                          <p style={StyleErrorMessage}>
                            Máximo de 2300 milímetros
                          </p>
                        )}
                        {errors.medida_1?.type === "min" && (
                          <p style={StyleErrorMessage}>
                            Mínimo de 300 milímetros
                          </p>
                        )}
                        {errors.medida_1?.type === "required" && (
                          <p style={StyleErrorMessage}>Campo obrigatório</p>
                        )}
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Div style={{ flexDirection: "column" }}>
                        <TitleInput>Distância entre Cuba e Saia</TitleInput>
                        <Container largura={28.7}>
                          <Input
                            type="number"
                            {...register("medida_2", {
                              required: true,
                              max: 400,
                              min: 50,
                            })}
                            placeholder={
                              windowSize[0] <= 1000
                                ? "Distância entre Cuba e Saia"
                                : "Medida"
                            }
                            value={controller3}
                            onChange={(event: any) =>
                              {setController3(event.target.value)
                              setValue("medida_2", event.target.value)}}
                          />
                          <h6>milímetros</h6>
                        </Container>
                      </Div>
                      {errors.medida_2?.type === "max" && (
                        <p style={StyleErrorMessage}>
                          Máximo de 400 milímetros
                        </p>
                      )}
                      {errors.medida_2?.type === "min" && (
                        <p style={StyleErrorMessage}>Mínimo de 50 milímetros</p>
                      )}
                      {errors.medida_2?.type === "required" && (
                        <p style={StyleErrorMessage}>Campo obrigatório</p>
                      )}
                    </div>
                  </DistanceInputWrapper>
                </>
              ) : (
                ""
              )}
              <BottomSection>
                <Link to={`/${paginaAnterior}`}>
                  <BackBtn />
                </Link>

                {(center && controller3 && controller0 && controller1 && (select0 || select1 || select2)) 
                || ((right || left) && controller0 && controller1 && controller2 && controller3 && (select0 || select1 || select2)) ? (
                  <>
                    {editingSummary ? (
                      <SbmtBtn
                        onClick={() => {
                          if (center) {
                            setController2(undefined);
                          }
                        }}
                      >
                        Voltar para o Resumo
                      </SbmtBtn>
                    ) : (
                      <SbmtBtn
                        onClick={() => {
                          if (center) {
                            setController2(undefined);
                          }
                        }}
                      >
                        Avançar
                      </SbmtBtn>
                    )}
                  </>
                ) : (
                  <DisabledBtn>
                    {editingSummary ? "Voltar para o Resumo" : "Avançar"}
                  </DisabledBtn>
                )}
              </BottomSection>
            </form>
          </div>
        </Main>
      </MobileContainer>
    </Page>
  );
}

export default CubaStyle;
