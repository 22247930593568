/* eslint-disable import/no-anonymous-default-export */
import { useState } from "react";
import api from "../api";

export interface JoinProps {
  amount: number;
  // name: string;
  price: number;
  room: string;
  product: string;
  material: string;
  description?: string;
}

export interface createAllProps {
    name: string,
    clientId: string,
    price: number,
    status: string,
    description: string | null | undefined,
    cartcountertop_clientId: string,
    // cartcountertop_estimateId: string | null,
    cartcountertop_price: number,
    cartcountertop_productPrice: number,
    cartcountertop_servicePrice: number,
    cartcountertop_approved: boolean,
    join_amount: number,
    join_material: string
    join_room: string
    join_product: string
    join_countertopType: string
    join_installation: boolean,
    join_installationType?: string | null
    join_countertopDisposition?: string | null
    dryBase_estimateWidth?: number | null
    dryBase_estimateDepth?: number | null,
    dryBase1_estimateWidth?: number | null
    dryBase1_estimateDepth?: number | null,
    faucet_type?: string | null
    faucet_position?: string | null
    faucet_wall?: boolean | null,
    flank_position?: string | null,
    flank_finishType?: string | null,
    flank_estimateWidth?: number | null,
    flank_junctionType?: string | null,
    front_estimateHeight: number,
    stove_type?: string | null
    stove_shaft?: string | null,
    stove_shaftDistance?: number | null,
    stove_shaftFrontDistance?: number | null,
    strip_estimateHeight: number | null,
    vat_type?: string | null,
    vat_axle?: string | null,
    vat_estimateAxleDistance?: number | null,
    vat_estimateEdgeDistance?: number | null,
    vat_vatAxle?: string | null,
    vat_vatPosition?: string | null,
    wetbase_estimateWidth: number,
    wetbase_estimateDepth: number,
}

var countertopJoinId: string;


export default {
  //CountertopJoin
  async createCountertopJoin(dataCountertopJoin: JoinProps) {
    try {
      const response = await api.post(`/CountertopJoins`, dataCountertopJoin);
      return response;
    } catch (err) {
      console.log(err);
    }
  },

  async clientJoinRelation(countertopJoinId: string, clientId: string) {
    try {
      const response = await api.put(
        `/CountertopJoins/${countertopJoinId}/Client/${clientId}`
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },

  //CountertopWetBase
  async postWetBase(dataWetBase: {
    estimateWidth: number;
    estimateDepth: number;
  }) {
    try {
      const response = await api.post("/countertopWetBases", dataWetBase);
      return response;
    } catch (err) {
      console.log(err);
    }
  },

  async putWetBase(
    dataWetBase: { estimateWidth: number; estimateDepth: number },
    id: number
  ) {
    try {
      const response = await api.put(`/countertopWetBases/${id}`, dataWetBase);
      return response;
    } catch (err) {
      console.log(err);
    }
  },

  async getWetBase() {
    try {
      const response = await api.get("/countertopWetBases");
      return response;
    } catch (err) {
      console.log(err);
    }
  },

  //CountertopDryBase
  async postDryBase(dataDryBase: {
    estimateWidth: number;
    estimateDepth: number;
  }) {
    try {
      const response = await api.post("/countertopDryBases", dataDryBase);
      return response;
    } catch (err) {
      console.log(err);
    }
  },

  async getDryBase() {
    try {
      const response = await api.get("/countertopDryBases");
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async createAll(countertop: createAllProps) {
    try {
      const response = api.post("/cartCountertops/createAll", countertop);
      return response;
    } catch (e) {
      console.log(e);
    }
  },
  async fetchCartCountertop(cartCountertopId: string) {
    try {
      const response = api.get(
        `/cartCountertops/showRelations/${cartCountertopId}`
      );
      return response;
    } catch (e) {
      console.log(e, "Sem Id");
    }
  },
  async fillCartCountertop(countertopId: string) {
    const cartId = await api.get("/cartCountertops");
    cartId.data.cartCountertops.filter(function (cart: any) {
      if (cart.cartCountertopId == countertopId) {
        sessionStorage.setItem("price", cart.price)
        sessionStorage.setItem("cartCountertopId", cart.cartCountertopId)
        sessionStorage.setItem("productPrice",cart.productPrice)
        sessionStorage.setItem("servicePrice", cart.servicePrice)
      }
    });
    try {
      const joins = await api.get("/CountertopJoins");
      joins.data.countertop.filter(function (join: any) {
        if (join.CartCountertopCartCountertopId == countertopId) {
          api.get(`/CountertopJoins/${join.id}`);
          countertopJoinId = join.id;
            sessionStorage.setItem("CountertopJoinId",join.id);
            sessionStorage.setItem('countertopDisposition',join.countertopDisposition);
            sessionStorage.setItem("amount",  join.amount);
            sessionStorage.setItem("roomName",  join.room);
            sessionStorage.setItem("materialName",join.material);
            sessionStorage.setItem("installation",join.installation);
            sessionStorage.setItem("typeOfInstallation", join.installationType);
            sessionStorage.setItem("countertopType",join.countertopType);
            sessionStorage.setItem("productName",join.product);
        
        }
      });
      const torneira = await api.get("/countertopFaucets");
      torneira.data.countertop.filter(function (torneira: any) {
        if (torneira.CountertopJoinId == countertopJoinId) {
          sessionStorage.setItem("faucetType", torneira.type);
          sessionStorage.setItem("faucetWall", torneira.wall == true ? 'true' : 'false')
          sessionStorage.setItem("faucetPosition", torneira.position);
        }
      });
      const cuba = await api.get("/countertopVats");
      cuba.data.countertopVats.filter(function (cuba: any) {
        if (cuba.CountertopJoinId == countertopJoinId) {
          sessionStorage.setItem("vatType", cuba.type);
          sessionStorage.setItem("vatAxle", cuba.vatAxle);
          sessionStorage.setItem("vatEstimateAxleDistance",cuba.estimateAxleDistance);
          sessionStorage.setItem("vatEstimateEdgeDistance",cuba.estimateEdgeDistance);
          sessionStorage.setItem("vatPosition", cuba.vatPosition)
        }
      });
      const saia = await api.get("/countertopStrips");
      saia.data.countertopStrips.filter(function (saia: any) {
        if (saia.CountertopJoinId == countertopJoinId) {
          sessionStorage.setItem("stripEstimateHeight", saia.estimateHeight);
        }
      });
      const fogao = await api.get("/countertopStoves");
      fogao.data.countertopStoves.filter(function (fogao: any) {
        if (fogao.CountertopJoinId == countertopJoinId) {
          sessionStorage.setItem("stoveType", fogao.type);
          sessionStorage.setItem("shaft", fogao.shaft);
          sessionStorage.setItem("shaftDistance", fogao.shaftDistance);
          sessionStorage.setItem("shaftFrontDistance",fogao.shaftFrontDistance);
        }
      });
      const bancadaSeca =  await api.get(`/countertopDryBases/fillCart/${countertopJoinId}`)
      console.log(bancadaSeca)
      const countertopType = sessionStorage.getItem("countertopType"); 
      if(countertopType != "Bancada Reta" && bancadaSeca){
        countertopType == "Bancada em U" ?
        <>
           { sessionStorage.setItem(
             "dryBaseEstimateWidth",
              bancadaSeca.data[0].estimateWidth
           )}
           {sessionStorage.setItem(
             "dryBaseEstimateDepth",
             bancadaSeca.data[0].estimateDepth
           )}
           {sessionStorage.setItem(
             "dryBaseEstimateWidth1",
             bancadaSeca.data[1].estimateWidth
           )}
           {sessionStorage.setItem(
             "dryBaseEstimateDepth1",
             bancadaSeca.data[1].estimateDepth
           )}
        </>  :
       <>  
       {sessionStorage.setItem(
         "dryBaseEstimateWidth",
         bancadaSeca.data[0].estimateWidth
       )}
        {sessionStorage.setItem(
         "dryBaseEstimateDepth",
         bancadaSeca.data[0].estimateDepth
       )}
       </>
      }
      const bancadaMolhada = await api.get("/countertopWetBases");
      bancadaMolhada.data.countertopWetBases.filter(function (
        bancadaMolhada: any
      ) {
        if (bancadaMolhada.CountertopJoinId == countertopJoinId) {
          sessionStorage.setItem(
            "wetBaseEstimateWidth",
            bancadaMolhada.estimateWidth
          );
          sessionStorage.setItem(
            "wetBaseEstimateDepth",
            bancadaMolhada.estimateDepth
          );
          sessionStorage.setItem(
            "frontDepth",
            bancadaMolhada.frontDepth
          );
          sessionStorage.setItem(
            "backDepth",
            bancadaMolhada.backDepth
          );
        }
      });
      const fronts = await api.get("/countertopFronts");
      fronts.data.countertop.filter(function (front: any) {
        if (front.CountertopJoinId == countertopJoinId) {
          sessionStorage.setItem("frontEstimateHeight", front.estimateHeight);
        }
      });
      const ilharga = await api.get("/CountertopFlank");
      ilharga.data.countertopflank.filter(function (ilha: any) {
        if (ilha.CountertopJoinId == countertopJoinId) {
          sessionStorage.setItem("flankPosition", ilha.position);
          sessionStorage.setItem("flankWidth", ilha.estimateWidth);
          sessionStorage.setItem("flankFinishType", ilha.finishType);
          sessionStorage.setItem("flankJunctionType", ilha.junctionType);
        }
      });
      return console.log("Orçamento preenchido com sucesso!")
    } catch (e) {
      console.log(e);
    }
  },
  async updateCountertopData(countertopData : createAllProps, countertopJoinId : string){
    try{
        api.put(`/countertopJoins/${countertopJoinId}`,
        {"material": countertopData.join_material,"amount":countertopData.join_amount, "countertopType":countertopData.join_countertopType,
         "installation":countertopData.join_installation,"installationType":countertopData.join_installationType,"countertopDisposition": countertopData.join_countertopDisposition, "ClientIdentificationNumber": countertopData.clientId}).then((res) => {
      });
      
      const getTorneira = await api.get(`/countertopFaucets`)
      getTorneira.data.countertop.filter(function (torneira: any) {
        if (torneira.CountertopJoinId == countertopJoinId) {
          api.put(`/countertopFaucets/${torneira.id}`,
          {"type": countertopData.faucet_type,"position":countertopData.faucet_position, "wall":countertopData.faucet_wall,}).then((res) => {
          
        });
        }
      });
      const getFogao = await api.get(`/countertopStoves`)
      getFogao.data.countertopStoves.filter(function (fogao: any) {
        if (fogao.CountertopJoinId == countertopJoinId) {
          api.put(`/countertopStoves/${fogao.id}`,
          {"type": countertopData.stove_type,"shaft":countertopData.stove_shaft, "shaftDistance":countertopData.stove_shaftDistance,
           "shaftFrontDistance": countertopData.stove_shaftFrontDistance}).then((res) => {
           
        });
        }
      });
      const getCuba = await api.get(`/countertopVats`)
      getCuba.data.countertopVats.filter(function (cuba: any) {
        if (cuba.CountertopJoinId == countertopJoinId) {
          api.put(`/countertopVats/${cuba.id}`,
          {"type": countertopData.vat_type,"vatPosition":countertopData.vat_vatPosition,"estimateAxleDistance":countertopData.vat_estimateAxleDistance,
          "estimateEdgeDistance":countertopData.vat_estimateEdgeDistance,"vatAxle":countertopData.vat_vatAxle}).then((res) => {
           
        });
        }
      });
      const getSaia = await api.get(`/countertopStrips`)
      getSaia.data.countertopStrips.filter(function (saia: any) {
        if (saia.CountertopJoinId == countertopJoinId) {
          api.put(`/countertopStrips/${saia.id}`,
          {"estimateHeight":countertopData.strip_estimateHeight}).then((res) => {
           
        });
        }
      });
      const getFront = await api.get(`/countertopFronts`)
      getFront.data.countertop.filter(function (front: any) {
        if (front.CountertopJoinId == countertopJoinId) {
          api.put(`/countertopFronts/${front.id}`,
          {"estimateHeight":countertopData.front_estimateHeight}).then((res) => {
          
        });
        }
      });
      const cartId = (await api.get(`/countertopJoins/${countertopJoinId}`)).data.countertop.CartCountertopCartCountertopId
      const getCountertop = await api.get(`/cartCountertops`)
      getCountertop.data.cartCountertops.filter(function (cart: any) {
        if (cart.cartCountertopId == cartId) {
          api.put(`/cartCountertops/${cart.cartCountertopId}`,
          {"productPrice":countertopData.cartcountertop_productPrice,"servicePrice":countertopData.cartcountertop_servicePrice,
          "price":countertopData.cartcountertop_price, "approved":countertopData.cartcountertop_approved}).then((res) => {
        });
        console.log(getCountertop)
        }
       
      });
      const getBancadaMolhada = await api.get(`/countertopWetBases`)
      getBancadaMolhada.data.countertopWetBases.filter(function (wetBases : any){
        if (wetBases.CountertopJoinId == countertopJoinId) {
          api.put(`/countertopWetBases/${wetBases.id}`,
          {"estimateWidth":countertopData.wetbase_estimateWidth,"estimateDepth":countertopData.wetbase_estimateDepth}).then((res) => {
        
        });
        }
      })
      const bancadaSeca = await api.get(`/countertopDryBases/fillCart/${countertopJoinId}`);
      const countertopDryBaseData1 = {
        minWidth: 300,
        maxWidth: 2300,
        frontDepth: 50,
        backDepth: 70,
        estimateWidth: countertopData.dryBase1_estimateWidth!,
        estimateDepth: countertopData.dryBase1_estimateDepth!,
      };
      const countertopDryBaseData = {
        minWidth: 300,
        maxWidth: 2300,
        frontDepth: 50,
        backDepth: 70,
        estimateWidth: countertopData.dryBase_estimateWidth!,
        estimateDepth: countertopData.dryBase_estimateDepth!,
      };
     
      const counterType = sessionStorage.getItem("countertopType")!;
      if( counterType == "Bancada em U" && bancadaSeca.data.length == 2){
        api.put(`/countertopDryBases/${bancadaSeca.data[0].id}`,
        {"estimateWidth":countertopData.dryBase_estimateWidth,"estimateDepth":countertopData.dryBase_estimateDepth}).then((res) =>{
          return res
        })
        api.put(`/countertopDryBases/${bancadaSeca.data[1].id}`,
        {"estimateWidth":countertopData.dryBase1_estimateWidth,"estimateDepth":countertopData.dryBase1_estimateDepth})
           
      } else
      if(counterType == "Bancada em U" && bancadaSeca.data.length == 1){
        api.put(`/countertopDryBases/${bancadaSeca.data[0].id}`,
        {"estimateWidth":countertopData.dryBase_estimateWidth,"estimateDepth":countertopData.dryBase_estimateDepth}).then((res) =>{
          return console.log(res)
        })
        api.post('/countertopDryBases', countertopDryBaseData1).then((res)=>{
          console.log(res)
          if(res){
            api.put(`/CountertopJoins/${countertopJoinId}/CountertopDryBases/${res.data.CountertopDryBase.id}`).then((res)=>{
              console.log(res)
            })
          }
        })
       
        }
      else
      if(counterType == "Bancada em U" && bancadaSeca.data.length == 0){
        api.post('/countertopDryBases', countertopDryBaseData).then((res)=>{
          console.log(res)
          if(res){
            api.put(`/CountertopJoins/${countertopJoinId}/CountertopDryBases/${res.data.CountertopDryBase.id}`).then((res)=>{
              console.log(res)
            })
          }
        })
        api.post('/countertopDryBases', countertopDryBaseData1).then((res)=>{
          console.log(res)
          if(res){
            api.put(`/CountertopJoins/${countertopJoinId}/CountertopDryBases/${res.data.CountertopDryBase.id}`).then((res)=>{
              console.log(res)
            })
          }
        })
       
        }
        else
      if(counterType == "Bancada em L" && bancadaSeca.data.length == 2){
        api.put(`/countertopDryBases/${bancadaSeca.data[0].id}`,
        {"estimateWidth":countertopData.dryBase_estimateWidth,"estimateDepth":countertopData.dryBase_estimateDepth}).then((res) =>{
          return console.log(res)
        })
        api.delete(`/countertopDryBases/${bancadaSeca.data[1].id}`,).then((res) =>{
          return console.log(res)
        })
      }
      else
      if(counterType == "Bancada em L" && bancadaSeca.data.length == 1){
        api.put(`/countertopDryBases/${bancadaSeca.data[0].id}`,
        {"estimateWidth":countertopData.dryBase_estimateWidth,"estimateDepth":countertopData.dryBase_estimateDepth}).then((res) =>{
          return console.log(res)
        })
      }
      else
      if(counterType == "Bancada em L" && bancadaSeca.data.length == 0){
        api.post('/countertopDryBases', countertopDryBaseData).then((res)=>{
          console.log(res)
          if(res){
            api.put(`/CountertopJoins/${countertopJoinId}/CountertopDryBases/${res.data.CountertopDryBase.id}`).then((res)=>{
              console.log(res)
            })
          }
        })
    }
     return console.log("Orçamento atualizado com sucesso!");
  }
    catch(e){
      console.log(e)
    }
  },
  async duplicateCartCounterTop(){
  const totalInstallationPrice = sessionStorage.getItem("servicePrice");
  const estimateName = sessionStorage.getItem("estimateName");
  const estimateId = sessionStorage.getItem("estimateId")
  var identificationNumber = localStorage.getItem('identificationNumber');
  var roomName = sessionStorage.getItem('roomName');
  var productName = sessionStorage.getItem('productName');
  var materialName = sessionStorage.getItem('materialName');
  var countertopType = sessionStorage.getItem('countertopType');
  var vatPosition = sessionStorage.getItem('vatPosition');
  var countertopDisposition = sessionStorage.getItem('countertopDisposition');
  var amount = sessionStorage.getItem('amount');
  var dryBaseEstimateWidth = sessionStorage.getItem('dryBaseEstimateWidth');
  var dryBaseEstimateDepth = sessionStorage.getItem('dryBaseEstimateDepth');
  var wetBaseEstimateWidth = sessionStorage.getItem('wetBaseEstimateWidth');
  var wetBaseEstimateDepth = sessionStorage.getItem('wetBaseEstimateDepth');
  var flankPosition = sessionStorage.getItem('flankPosition');
  var flankWidth = sessionStorage.getItem('flankWidth');
  var flankFinishType = sessionStorage.getItem('flankFinishType');
  var flankJunctionType = sessionStorage.getItem('flankJunctionType');
  var stripEstimateHeight = sessionStorage.getItem('stripEstimateHeight');
  var frontEstimateHeight = sessionStorage.getItem('frontEstimateHeight');
  var vatType = sessionStorage.getItem('vatType');
  var vatAxle = sessionStorage.getItem('vatAxle');
  var dryBaseEstimateWidth2 = sessionStorage.getItem('dryBaseEstimateWidth1');
  var dryBaseEstimateDepth2 = sessionStorage.getItem('dryBaseEstimateDepth1');
  var dryBaseEstimateWidth1 = sessionStorage.getItem('dryBaseEstimateWidth');
  var dryBaseEstimateDepth1 = sessionStorage.getItem('dryBaseEstimateDepth');
  var vatEstimateAxleDistance = sessionStorage.getItem(
    'vatEstimateAxleDistance'
  );
  var vatEstimateEdgeDistance = sessionStorage.getItem(
    'vatEstimateEdgeDistance'
  );
  var faucetType = sessionStorage.getItem('faucetType');
  var faucetPosition = sessionStorage.getItem('faucetPosition');
  var faucetWall = sessionStorage.getItem('faucetWall') === 'true' ? true : false;
  var stoveType = sessionStorage.getItem('stoveType');
  var shaft = sessionStorage.getItem('shaft');
  var shaftDistance = sessionStorage.getItem('shaftDistance');
  var shaftFrontDistance = sessionStorage.getItem('shaftFrontDistance');
  var installation = sessionStorage.getItem('installation');
  let typeOfInstallation = sessionStorage.getItem('typeOfInstallation');
  let addProductToEstimate = 'true';
  var totalPrice = sessionStorage.getItem("price");
  var productPrice = sessionStorage.getItem("productPrice");
  let thereIsType = false;
  let thereWillBeInstallation = '';
  if (installation === 'true') {
    thereIsType = true;
    thereWillBeInstallation = 'Incluir instalação';
  } else {
    thereWillBeInstallation = 'Não incluir instalação';
    thereIsType = false;
    typeOfInstallation = null;
  }
  var approved : boolean = true;

    if (
      identificationNumber &&
      roomName &&
      productName &&
      materialName &&
      amount &&
      wetBaseEstimateWidth &&
      wetBaseEstimateDepth &&
      countertopType
    ) {
      const countertop : any = {
        name: estimateName,
        price: totalPrice,
        clientId: identificationNumber,
        status: 'Em Edicao',
        description: null,
        cartcountertop_clientId: identificationNumber,
        estimate_id: estimateId,
        cartcountertop_price: totalPrice,
        cartcountertop_productPrice: productPrice,
        cartcountertop_servicePrice: totalInstallationPrice,
        cartcountertop_approved: approved,
        join_amount: Number(amount),
        join_material: materialName,
        join_room: roomName,
        join_product: productName,
        join_countertopType: countertopType,
        join_installation: thereIsType,
        join_installationType: typeOfInstallation,
        join_countertopDisposition: countertopDisposition,
        dryBase_estimateWidth:
          countertopType === 'Bancada em U'
            ? Number(dryBaseEstimateWidth1)
            : Number(dryBaseEstimateWidth),
        dryBase_estimateDepth:
          countertopType === 'Bancada em U'
            ? Number(dryBaseEstimateDepth1)
            : Number(dryBaseEstimateDepth),
        dryBase_estimateWidth1:
          countertopType === 'Bancada em U' ? Number(dryBaseEstimateWidth2) : 0,
        dryBase_estimateDepth1:
          countertopType === 'Bancada em U' ? Number(dryBaseEstimateDepth2) : 0,
        faucet_type: faucetType,
        faucetWall: faucetWall,
        faucet_position: faucetPosition ? faucetPosition : null,
        flank_position: flankPosition ? flankPosition : null,
        flank_finishType: flankFinishType ? flankFinishType : null,
        flank_estimateWidth: flankWidth ? Number(flankWidth) : null,
        flank_junctionType: flankJunctionType ? flankJunctionType : null,
        front_estimateHeight: frontEstimateHeight
          ? Number(frontEstimateHeight)
          : 0,
        stove_type: stoveType,
        stove_shaft: shaft,
        stove_shaftDistance: shaftDistance ? Number(shaftDistance) : 0,
        stove_shaftFrontDistance: shaftFrontDistance
          ? Number(shaftFrontDistance)
          : 0,
        strip_estimateHeight: stripEstimateHeight
          ? Number(stripEstimateHeight)
          : 0,
        vat_type: vatType ? vatType : null,
        vat_axle: vatAxle ? vatAxle : null,
        vat_vatPosition: vatPosition ? vatPosition : null,
        vat_estimateAxleDistance: Number(vatEstimateAxleDistance),
        vat_estimateEdgeDistance: Number(vatEstimateEdgeDistance),
        vat_vatAxle: vatAxle,
        wetbase_estimateWidth: Number(wetBaseEstimateWidth),
        wetbase_estimateDepth: Number(wetBaseEstimateDepth),
      };
      if (addProductToEstimate != undefined) {
        api
          .post('/cartCountertops/createCountertop', countertop)
          .then((res) => {
            sessionStorage.clear();
            sessionStorage.setItem('estimateId', String(estimateId));
            sessionStorage.setItem('estimateName', estimateName!);
            window.location.reload();
            console.log(res)
          });
      }
  }
}, async deleteCartCounterTop(cartCountertopId: string, countertopJoinId: string){

  api.delete(`/cartCountertops/${cartCountertopId}`).then((res) => {
    console.log(res);
  });
  try {
    const torneira = await api.get("/countertopFaucets");
    torneira.data.countertop.filter(function (torneira: any) {
      if (torneira.CountertopJoinId == countertopJoinId) {
        api.delete(`/countertopFaucets/${torneira.id}`).then((res) => {
          console.log(res);
        });
      }
    });
    const cuba = await api.get("/countertopVats");
    cuba.data.countertopVats.filter(function (cuba: any) {
      if (cuba.CountertopJoinId == countertopJoinId) {
        api.delete(`/countertopVats/${cuba.id}`).then((res) => {
          console.log(res);
        });
      }
    });
    const saia = await api.get("/countertopStrips");
    saia.data.countertopStrips.filter(function (saia: any) {
      if (saia.CountertopJoinId == countertopJoinId) {
        api.delete(`/countertopStrips/${saia.id}`).then((res) => {
          console.log(res);
        });
      }
    });
    const fogao = await api.get("/countertopStoves");
    fogao.data.countertopStoves.filter(function (fogao: any) {
      if (fogao.CountertopJoinId == countertopJoinId) {
        api.delete(`/countertopStoves/${fogao.id}`).then((res)=>{
          console.log(res)
        });
      }
    });
    const bancadaSeca =  await api.get(`/countertopDryBases/fillCart/${countertopJoinId}`)
    const countertopType = sessionStorage.getItem("countertopType"); 
    if(countertopType != "Bancada Reta"){
      if(countertopType == "Bancada em U"){
        api.delete(`/countertopDryBases/${bancadaSeca.data[0].id}`)
        api.delete(`/countertopDryBases/${bancadaSeca.data[1].id}`)}
        else{
          api.delete(`/countertopDryBases/${bancadaSeca.data[0].id}`)
        }
      }
     
    
    const bancadaMolhada = await api.get("/countertopWetBases");
    bancadaMolhada.data.countertopWetBases.filter(function (
      bancadaMolhada: any
    ) {
      if (bancadaMolhada.CountertopJoinId == countertopJoinId) {
        api.delete(`/countertopWetBases/${bancadaMolhada.id}`).then((res) => {
          console.log(res);
        });
      }
    });
    const fronts = await api.get("/countertopFronts");
    fronts.data.countertop.filter(function (front: any) {
      if (front.CountertopJoinId == countertopJoinId) {
        api.delete(`/countertopFronts/${front.id}`).then((res) => {
          console.log(res);
        });
      }
    });
    const ilharga = await api.get("/CountertopFlank");
    ilharga.data.countertopflank.filter(function (ilha: any) {
      if (ilha.CountertopJoinId == countertopJoinId) {
        api.delete(`/CountertopFlank/${ilha.id}`).then((res) => {
          console.log(res);
        });
      }
    });
    return console.log("Produto deletado com sucesso!")
  } catch (e) {
    console.log(e);
  }
}
}