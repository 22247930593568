import { Img, RoomBtn, MaterialBtn, MobileBtn } from './style';
import { BsPlusLg } from 'react-icons/bs'


export interface Props {
    name?: string;
    url?: any;
    thereIsImage: boolean;
    onClick?: any
    color?: any
}

function CardButtonOff({ name, url, thereIsImage, onClick, color }: Props) {


    return (
        <>
            {
                thereIsImage ?
                    (
                        <MobileBtn url={url} onClick={onClick} thereIsImage={thereIsImage}>
                            <p style={{ color: color }}>{name}</p>
                            <MaterialBtn onClick={onClick}>
                                <h3>{name}</h3>
                                <Img src={url} />
                            </MaterialBtn>
                        </MobileBtn>
                    ) : (
                        <RoomBtn onClick={onClick}>
                            <h3>{name}</h3>
                            <div>
                                <BsPlusLg />
                            </div>
                        </RoomBtn>

                    )
            }
        </>
    );
}

export default CardButtonOff;
