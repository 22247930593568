import React from "react";
import LoginLeftSection from '../../components/LoginLeftSection';
import { Container, LoginRightSection } from './style';
import RegisterCard from "../../components/RegisterCard";

export default function Register() {
    return (

        <Container>
            <LoginLeftSection position="absolute"/>
            <LoginRightSection>
                <RegisterCard position='absolute'/>
            </LoginRightSection>


        </Container>

    );
}