import styled from "styled-components"

interface containerProps{
    afterPosition:boolean
}

export const Page = styled.div<containerProps>`
    padding: 3.5%;
    background:#f2f2f2;
    display:flex;
    flex-direction:column;
    
    height: ${props => props.afterPosition ? 'auto' : '100vh'};
    @media(min-width:1200), (max-width:1600px){
    padding-left:5%;
    padding-right:5%;
}
@media(max-width: 912px){
    background-color: white;
    min-height: 100vh;
    width: 90vw;
    top: 50px;
    position: relative;
    border-radius: 20px 20px 0px 0px;
}
`
export const Top = styled.div`
display: flex;
width: 98%;
height: fit-content;
justify-content: center;
align-content: center;
padding: 0;
margin-bottom: 2%;
`



export const Counter = styled.img`
width:256px;
display:flex;
margin: 0;
@media (max-width: 912px){
    margin: 0;
    margin-top: 12%;
}
`


export const PathText = styled.div`
color: #BDBDBD;

font-size: 2.79vh;
font-weight: 700;
flex-direction: row;
margin-bottom:3.59vh;
@media (max-width: 600px){
    margin-top: 5%;
}
`
export const A = styled.a`
 font-size: 1.2rem;
color:#BFB8B9;
&:hover{
    opacity:0.5;
    cursor:pointer;
};
`
export const Div = styled.div`

@media (max-width:1000px) {
  display:flex;
  flex-direction: column;
  background-color: #f2f2f2;
  width:90vw;
  height:30px;
  align-content: center;
  padding-top: 5px;
  border-radius: 5px;
}
`

export const MainText = styled.div`
color: #828282;

font-size: 1.75vw;
@media (max-width: 600px){
    margin-top: 5%;
    font-size: 16px;
}
`

export const Title = styled.text`
  
  font-weight: 700;
  font-size: 1.5rem;
  color: #828282;
 
`;

// export const Title = styled.h1`
//   margin-top: 3.3vh;
//   margin-bottom: 3.3vh;


//   @media (max-width: 912px) {
//     display: none;
//   }
// `;

export const Cards1 = styled.div`
display:flex;
flex-direction:row;
align-items:center;
overflow-x: auto;
height: fit-content;
padding-bottom: 20px;
padding-top: 15px;
width: 95vw;
gap: 25px;
scroll-snap-type: x mandatory;
scroll-padding: 50px;
@media (max-width: 912px) {
    height: 270px;
    padding: 10px 0 20px 0;
    
}
`
export const Cards2 = styled.div`
display:flex;
flex-direction:row;
align-items:center;
margin-bottom: 4.665vh;
gap:25px;
justify-content:center;
@media(max-width: 1000px ){
    flex-direction: column;
    padding-top: 10px;
    gap: 10px;
    p{
        margin-left: -40px;
    }
}
@media(min-width: 1600px ){
  justify-content:start;
}
`
export const CardWrapper = styled.div`
    flex-direction: "column";
    display: "flex";

@media(min-width: 1600px ){
    width: 29vw;
}
`
export const Btns = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
margin-top:8.08vh;
@media (max-width: 600px){
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}
`
export const InputMobile = styled.input`
padding-left:20px;
display:flex;
flex:2;
border:none;
color: #828282;

&::placeholder{
  color:#BDBDBD;
 }

&:focus{
    outline:none;
}
background-color:transparent;
text-align: center;
font-size: 12px;
padding-right: 10px;
@supports (-webkit-touch-callout: none) {
  /* Atualmente, apenas dispositivos iOS possuem a feature -webkit-touch-callout,
  logo esse código testa se o dispositivo é iOS ou não. Caso a Apple mude algo,
  basta consultar
  https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariCSSRef/Articles/StandardCSSProperties.html
  e buscar por "Safari on iOS only"*/
  font-size: 16px;
  width: 95%;
  }
`
export const MobileContainer = styled.div`
span{
    display: none;
}
@media(max-width:912px){
    display:flex;
    flex:1;
    flex-direction:column;
    align-items:center;
    background:#2566C8;
    span{
        padding-top:5.5%;
        display:flex;
        
        color:white;
        font-size:20px;
        font-weight: 700;
    
    }
}
`
export const Main = styled.main`
@media (max-width: 912px){
display:flex;
width:100vw;
background-color: #ffffff;
flex-direction: column;
border-top-left-radius:20px;
border-top-right-radius: 20px;
margin-top:4vh;
color: #828282;
.Title{
  align-self: center;
  
  font-size:1rem;
  font-weight: 700;
  color:#bdbdbd;
  margin-bottom: 2rem;
}
}



`
export const DivCuba = styled.div`
    width: 90vw;

@media (max-width: 912px) {
    display: flex;
    width: 90vw;
    align-items: center;
    flex-direction: column;
}

`