import styled from "styled-components";

export const OptionBtn = styled.button`
width:29.86vw;
height:9.375rem;
border:none;
background:white;
justify-content:space-between;
border-radius:8px;
align-items:center;
display: -webkit-flex;
display: flex;
padding: 0px 3.75rem 0px 3.75rem;
position:relative;
*border: 2px solid red;
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
gap: 1.73vw;
scroll-snap-align: center;
@media (max-width: 1200px){
    width: 60%;
    align-self: center;
    justify-content: space-between;
    
}
@media (max-width: 912px) {
   :first-child{
    margin-left: 10vw;
}
    :last-child{
        margin-right: 5vw;
    }
}
@media (max-width: 700px) {
    min-width: 330px;
}
@media (max-width: 425px) {
   :first-child{
    margin-left: 7vw;
}
    :last-child{
        margin-right: 5vw;
    }
}
`
export const SecTitle = styled.label`

font-size:20px;
font-weight: 700;
color:#828282;
`
export const Letter = styled.label`
display: contents;

font-size:16px;
font-weight: 700;
color:#828282;
@media (max-width: 755px) {
   height: fit-content;
   font-size :13px ;
}
`
export const Option = styled.div`
display:-webkit-flex;
flex-direction:column;
gap:30px;
align-self:flex-start;
`
export const SelectIcon = styled.img`
position: absolute;
width:22px;
top:11px;
right:11px;
`
