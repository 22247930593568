import * as React from 'react';
import { Title } from '../BenchL/style';
import { Info, Page } from './style';
import { Header, PopUpEstimate } from '../Estimate/style';
import { ButtonToggle, PopUpText } from '../Home/style';
import {
  LeftSection,
  MobileLogo,
  LogoImg,
  Menu,
  RightBtns,
  Action,
  Texth6,
  ProfileButton,
  NavbarA,
} from '../../components/Header/style';
import Check from '../../assets/IconCheck.png';
import { Button, Text } from '../FinishPurchase/style';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/LogoSanstone.svg';
import LogoMobile from '../../assets/sansmobilelogo.svg';
import { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { CgProfile } from 'react-icons/cg';
import { IoMdClose } from 'react-icons/io';

export default function CompletedOrder() {
  const navigate = useNavigate();
  const [onPopUp, setOnPopUp] = useState(false);

  function handlePopUp() {
    setOnPopUp(!onPopUp);
  }
  function logout() {
    localStorage.clear();
    navigate('/login');
  }

  return (
    <>
      <Header>
        <LeftSection>
          <MobileLogo
            src={LogoMobile}
            alt={'logo mobile'}
            onClick={() => navigate('/')}
          />
          <LogoImg src={Logo} alt="logo" onClick={() => navigate('/')} />
          <Menu onClick={handlePopUp}>
            <GiHamburgerMenu style={{ fontSize: 32, color: '#808080' }} />
          </Menu>
        </LeftSection>
        <RightBtns>
          <Action>
            <Texth6>Orçamentos</Texth6>
          </Action>
          <Action>
            <Texth6>Pedidos</Texth6>
          </Action>
          <Action>
            <Texth6>Agendamentos</Texth6>
          </Action>
          <Action>
            <Texth6>Recebimentos</Texth6>
          </Action>
          <ProfileButton onClick={handlePopUp}>
            <CgProfile style={{ fontSize: '36px', color: '#828282' }} />
          </ProfileButton>
        </RightBtns>
      </Header>
      {onPopUp && (
        <>
          <PopUpEstimate>
            <ButtonToggle onClick={handlePopUp}>
              <IoMdClose fontSize={24} style={{ marginTop: 16 }} />
            </ButtonToggle>
            <NavbarA>
              <PopUpText>Perfil</PopUpText>
            </NavbarA>
            <NavbarA>
              <PopUpText>Modo Escuro</PopUpText>
            </NavbarA>
            <NavbarA>
              <PopUpText>Ajuda</PopUpText>
            </NavbarA>
            <NavbarA onClick={logout}>
              <PopUpText>Sair</PopUpText>
            </NavbarA>
          </PopUpEstimate>
        </>
      )}

      <Page>
        <Title
          style={{
            margin: 0,
            display: 'flex',
            textAlign: 'center',
            paddingTop: 110,
          }}
        >
          Pedido Realizado!
        </Title>
        <img style={{ width: '60px', padding: '40px' }} src={Check} />
        <Info>Aguarde a confirmação do pagamento.</Info>

        <Info style={{ width: '450px', textAlign: 'center' }}>
          Seu pedido seguirá para fabricação após a confirmação do pagamento.
        </Info>
        <Link to={'/'}>
          <Button style={{ marginTop: '10px' }}>
            <Text>Ir para Página Inicial</Text>
          </Button>
        </Link>
        <Info style={{ paddingTop: '30px', fontSize: 14, color: '#BDBDBD' }}>
          Quero agendar medição local.
        </Info>
      </Page>
    </>
  );
}
