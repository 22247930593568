import * as React from 'react';

import {PurchaseDiv,Title,Info,ProdutoMaterial, Div,} from './style';

interface Props {
    title: string;
    info: string;


  }


function PurchasePageCard({title, info} : Props) {

    return (
      
      <> 
        <PurchaseDiv>
          <Div>
            <ProdutoMaterial>
                <Title>{title}</Title>
                <Info>{info}</Info>
            </ProdutoMaterial>
          </Div>
        </PurchaseDiv>
      </>
    );
  }

export default PurchasePageCard;