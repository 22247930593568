import React, { useEffect, useState } from 'react';
import {
  Column,
  Container,
  ErrorContainer,
  Form,
  Input,
  InputDiv,
  Row,
  Select,
  SelectDiv,
  SignUpBtn,
  SmallColumn,
} from './style';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import './style.css';
import ClientAddressService from '../../services/ClientAddressService';
import EstimateService from '../../services/EstimateService';
import { toast } from 'react-toastify';

interface registerProps {
  position: string;
  addressId: string;
  newAddress: boolean; // um paramêtro para diferenciar os formulários de cadastro
  margin?: string;
}

export default function AddressForm({
  position,
  addressId,
  newAddress,
  margin,
}: registerProps) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ mode: 'onBlur' });

  //o que mandar pro back
  const [zipCode, setZipCode] = useState('');
  const [streetName, setStreetName] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [neighborhoodName, setNeighborhoodName] = useState('');
  const [addicionalInfo, setAddicionalInfo] = useState('');
  const [addressName, setAddressName] = useState<string>();
  const [ufName, setUfName] = useState('');
  const [cityName, setCityName] = useState('');

  const id = localStorage.getItem('identificationNumber');
  const token = localStorage.getItem('token');
  const value = localStorage.getItem('addressName');
  const addressNameStore = addressName ? addressName : value;
  const estimateId = sessionStorage.getItem('estimateId');
  const addressData = {
    zipCode: zipCode,
    streetName: streetName,
    streetNumber: streetNumber,
    additionalInfo: addicionalInfo,
    ClientIdentificationNumber: id,
    addressName: addressNameStore,
    neighborhoodName: neighborhoodName,
    ufName: ufName,
    cityName: cityName,
  };

  const navigate = useNavigate();
  const warningStyle = {
    fontSize: 12,
    color: 'red',
  };
  console.log(addressId);
  const onSubmit = () => {
    if (token) {
      newAddress
        ? ClientAddressService.createAddress(token, addressData)
          .then((response) => {
            console.log(response);

            EstimateService.clientAddressEstimateRelation(estimateId!, addressId!)
              .then((response) => console.log(addressId));
            toast.success('Endereço cadastrado com sucesso!', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              closeOnClick: true,
              draggable: true,
              onClick: () => navigate('/finalize-compra')

            })
              navigate('/finalize-compra');
            })
            .catch((error) => {
              if (error.response && error.response.status === 400) {
                toast.warning('Esse endereço já foi cadastrado!', {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                  closeOnClick: true,
                  draggable: true,
                  onClick: () => navigate('/finalize-compra')
                  
              })
                navigate('/finalize-compra');
              } else {
                toast.error('Ocorreu um erro ao criar o endereço!', {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                  closeOnClick: true,
                  draggable: true,
                  onClick: () => navigate('/finalize-compra')
                  
              })
                navigate('/finalize-compra');
                console.error(error);
              }
            })
        : ClientAddressService.createAddress(token, addressData)
          .then((response) => {
            toast.success('Endereço adicionado com sucesso!', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              closeOnClick: true,
              draggable: true,
              onClick: () => navigate('/finalize-compra')

            })
              navigate('/finalize-compra');
            })
            .catch((error) => {
              if (error.response && error.response.status === 400) {
                toast.warning('Esse endereço já foi cadastrado!', {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                  closeOnClick: true,
                  draggable: true,
                  onClick: () => navigate('/finalize-compra')
                  
              })
                navigate('/finalize-compra');
              } else {
                toast.error('Ocorreu um erro ao criar o endereço!', {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                  closeOnClick: true,
                  draggable: true,
                  onClick: () => navigate('/finalize-compra')
                  
              })
                navigate('/finalize-compra');
                console.error(error);
              }
            });
    }
  };

  const handleAddressName = (value: string) => {
    if (value != '') {
      setAddressName(value);
    }
    if (value === '') {
      const localStorageValue = localStorage.getItem('addressName');
      setAddressName(localStorageValue!);
    }
  };

  const verificaCep = (e: string) => {
    const cep = e.replace(/\D/g, '');
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setStreetName(data.logradouro);
        setAddicionalInfo(data.complemento);
        setNeighborhoodName(data.bairro);
        setUfName(data.uf);
        setCityName(data.localidade);

        setValue('endereco', data.logradouro);
        setValue('complemento', data.complemento);
        setValue('bairro', data.bairro);
        setValue('uf', data.uf);
        setValue('cidade', data.localidade);
      });
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Container position={position} margin={margin}>
      <Form onSubmit={handleSubmit(onSubmit)} style={{ position: 'relative' }}>
        {/* {(addressId != '1') ? (
          <> */}
        <InputDiv>
          <Input
            placeholder="Nome do endereço"
            {...register('nome', { required: true, maxLength: 60 })}
            onChange={(event) => {
              handleAddressName(event.target.value);
              setValue('nome', event.target.value);
            }}
          />
        </InputDiv>
        <ErrorContainer>
          {errors.nome?.type === 'required' && (
            <p style={warningStyle}>Campo obrigatório</p>
          )}
          {errors.nome?.type === 'maxLength' && (
            <p style={warningStyle}>Máximo de 60 caracteres</p>
          )}
        </ErrorContainer>
        <Row>
          <Column>
            <InputDiv>
              <InputMask
                className="mascaraCEP"
                mask={'99999-999'}
                style={{
                  border: 'none',
                  background: '#f2f2f2',
                  
                  fontSize: '1rm',
                  color: '#222222',
                }}
                placeholder="CEP"
                {...register('CEP', {
                  required: true,
                  pattern: {
                    value: /^[0-9]{5}[-][0-9]+$/,
                    message: 'Formato inválido',
                  },
                  minLength: 9,
                  maxLength: 9,
                })}
                value={zipCode}
                onChange={(event) => {
                  setZipCode(event.target.value);
                  setValue('CEP', event.target.value);
                  // if(addressId == '-1') handleAddressName('')
                  // else{}
                }}
                onBlur={() => verificaCep(zipCode)}
              />
            </InputDiv>
            <ErrorContainer>
              {errors.CEP?.type === 'required' && (
                <p style={warningStyle}>Campo obrigatório</p>
              )}
              {errors.CEP?.type === 'minLength' && (
                <p style={warningStyle}>Campo inválido</p>
              )}
              {errors.CEP?.type === 'maxLength' && (
                <p style={warningStyle}>Campo inválido</p>
              )}
              {errors.CEP?.type === 'pattern' && (
                <p style={warningStyle}>Campo inválido</p>
              )}
            </ErrorContainer>
          </Column>
          <SmallColumn>
            <SelectDiv>
              <Input
                placeholder={windowSize[0] <= 425 ? 'Nº' : 'Número'}
                {...register('numero', {
                  required: true,
                  pattern: {
                    value: /^[0-9]+$/i,
                    message: 'Formato inválido',
                  },
                })}
                value={streetNumber}
                type='number'
                onChange={(event) => {
                  setValue('numero', event.target.value);
                  setStreetNumber(event.target.value);
                }}
              />
            </SelectDiv>
            <ErrorContainer>
              {errors.numero?.type === 'required' && (
                <p style={warningStyle}>Campo obrigatório</p>
              )}
              {errors.numero?.type === 'pattern' && (
                <p style={warningStyle}>Campo inválido</p>
              )}
            </ErrorContainer>
          </SmallColumn>



        </Row>

        <InputDiv>
          <Input
            placeholder="Endereço"
            {...register('endereco', {
              required: true,
              pattern: {
                value: /([a-zà-úA-ZÀ-Ú ])/,
                message: 'Formato inválido',
              },
            })}
            value={streetName}
            onChange={(event) => {
              setValue('endereco', event.target.value);
              setStreetName(event.target.value);
            }}
          />
        </InputDiv>
        <ErrorContainer>
          {errors.endereco?.type === 'required' && (
            <p style={warningStyle}>Campo obrigatório</p>
          )}
          {errors.endereco?.type === 'pattern' && (
            <p style={warningStyle}>Campo inválido</p>
          )}
        </ErrorContainer>

        <InputDiv>
          <Input
            placeholder="Bairro"
            {...register('bairro', {
              required: true,
              pattern: {
                value: /([a-zà-úA-ZÀ-Ú ])/,
                message: 'Formato inválido',
              },
            })}
            value={neighborhoodName}
            onChange={(event) => {
              setValue('bairro', event.target.value);
              setNeighborhoodName(event.target.value);
            }}
          />
        </InputDiv>
        <ErrorContainer>
          {errors.bairro?.type === 'required' && (
            <p style={warningStyle}>Campo obrigatório</p>
          )}
          {errors.bairro?.type === 'pattern' && (
            <p style={warningStyle}>Campo inválido</p>
          )}
        </ErrorContainer>

        <Row>
          <Column>
            <InputDiv>
              <Input
                placeholder="Cidade"
                {...register('cidade', {
                  required: true,
                  pattern: {
                    value: /([a-zà-úA-ZÀ-Ú ])/,
                    message: 'Formato inválido',
                  },
                })}
                value={cityName}
                onChange={(event) => {
                  setValue('cidade', event.target.value);
                  setCityName(event.target.value);
                }}
              />
            </InputDiv>
            <ErrorContainer>
              {errors.cidade?.type === 'required' && (
                <p style={warningStyle}>Campo obrigatório</p>
              )}
              {errors.cidade?.type === 'pattern' && (
                <p style={warningStyle}>Campo inválido</p>
              )}
            </ErrorContainer>
          </Column>
          <SmallColumn>
            <SelectDiv>
              <Select
                {...register('uf', {
                  required: true,
                })}
                value={ufName}
                style={{ opacity: !ufName ? 0.7 : undefined}}
                onChange={(event: any) => {
                  setValue('uf', event.target.value);
                  setUfName(event.target.value);
                }}
              >
                <option value="" disabled selected>UF</option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </Select>
            </SelectDiv>
            <ErrorContainer>
              {errors.uf?.type === 'required' && (
                <p style={warningStyle}>Campo obrigatório</p>
              )}
              {errors.uf?.type === 'pattern' && (
                <p style={warningStyle}>Campo inválido</p>
              )}
            </ErrorContainer>
          </SmallColumn>
        </Row>

        <InputDiv>
          <Input
            placeholder="Complemento"
            {...register('complemento', {
              maxLength: 35,
              pattern: {
                value: /([a-zà-úA-ZÀ-Ú ])/,
                message: 'Formato inválido',
              },
            })}
            value={addicionalInfo}
            onChange={(event) => {
              setValue('complemento', event.target.value);
              setAddicionalInfo(event.target.value);
            }}
          />
        </InputDiv>
        <ErrorContainer>
          {errors.complemento?.type === 'maxLength' && (
            <p style={warningStyle}>Máximo de 35 caracteres!</p>
          )}
          {errors.complemento?.type === 'pattern' && (
            <p style={warningStyle}>Campo inválido</p>
          )}
        </ErrorContainer>
        <SignUpBtn type="submit">
          <div>
            {newAddress ? (
              <h4>Cadastrar endereço</h4>
            ) : (
              <h4>Adicionar endereço</h4>
            )}
          </div>
        </SignUpBtn>
      </Form>
    </Container>
  );
}
