import styled from "styled-components";

export const EstimateDiv = styled.div`
  height: 3.938rem;
  width: 26.25rem;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 20px 0 rgba(142, 142, 142, 0.2);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  margin-top: 1.25rem;
  @media (max-width: 912px) {
    /* box-shadow: 0 15px 20px 0 rgba(142,142,142,0.2); */
    box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(0, 0, 0, 0.16) 0px 3px 6px;
    h1 {
      text-align: "right";
    }
  }
  @media (max-width: 525px) {
    width: 80vw;
    flex-wrap: nowrap;
  }
`;
export const ProdutoMaterial = styled.div`
  line-height: 15px;
  width: 50%;
  margin-left: 1.25rem;
  align-self: center;
  @media (max-width: 525px) {
    width: 70vw;
  }
  @media (max-width: 395px) {
    width: 55vw;
  }
`;
export const QntTotal = styled.div`
  line-height: 15px;
  width: 25%;
  margin-right: 2.5rem;
  align-self: center;
  @media (max-width: 425px) {
    width: 15vw;
  }
   @media (max-width: 395px) {
    width: 15vw;
    margin-right: 30px;
  }
`;
export const Title = styled.h1`
  color: #828282;
  
  font-size: 14px;
  font-weight: 700;
   @media (max-width: 395px) {
    font-size: 12px;
  }
  @media (max-width: 325px) {
    font-size: 11px;
  }
`;
export const Material = styled.h1`
  color: #828282;
  
  font-size: 14px;
  font-weight: 300;
  margin-top: 5px;
  @media (max-width: 395px) {
    font-size: 12px;
  }
  @media (max-width: 325px) {
    font-size: 11px;
  }
`;
export const Quantidade = styled.h1`
  color: #bdbdbd;
  
  font-size: 14px;
  font-weight: 700;
  text-align: right;
  @media (max-width: 325px) {
    font-size: 12px;
  }
`;
export const Preco = styled.h1`
  color: #bdbdbd;
  font-size: 14px;
  font-weight: 300;
  text-align: right;
  margin-top: 5px;
   @media (max-width: 395px) {
    font-size: 11.8px;
  }
  @media (max-width: 325px) {
    font-size: 10.5px;
  }
`;
export const Dropdown = styled.ul`
  background-color: #ffffff;
  z-index: 999;
  position: absolute;
  height: fit-content;
  border-radius: 5px;
  min-width: 20vw;
  margin-top: 1vw;
  transition: all 0.3s ease-in;
  box-shadow: 0 8px 20px 0 rgba(142, 142, 142, 0.2);
  margin-bottom:25vw;
  @media(min-width: 913px) and (max-width:1384px) {
    margin-bottom: 45vw;
    width: 18.6rem;

  }
  @media (max-width: 912px) {
    margin-bottom: 85vw;
    width: 18.6rem;
  }
`;
export const ModalBackground = styled.div`
  background-color: #5c505067;
  z-index: 900;
  width: 100vw;
  height: 140vh;
  top: 0px;
  bottom: 0px;
  left: 0;
  transition: all 0.3s ease-in;
  position:absolute;
  align-items: center;
  justify-content: center;
  @media(max-width:1396px){
  height:150vh;
  
}
`;
export const Item = styled.li`
  a {
    padding-left: 8%;
    
    color: #828282;
    font-weight: 300;
    font-size: 19px;
    :hover {
      color: #2566c8;
      cursor: pointer;
    }
    @media(max-width: 425px) {
      font-size: 17px;
    }
  }
  text {
    padding-left: 8%;
    
    color: #828282;
    font-size: 19px;
    :hover {
      color: #c82525;
      cursor: pointer;
    }
     @media(max-width: 425px) {
      font-size: 17px;
    }
  }
  p {
    
    color: #828282;
    font-size: 16px;
  }
`;
export const LIstName = styled.ul`
  background-color: #2566c8;
  height: fit-content;
  border-radius: 5px 5px 0px 0px;
  min-width: 20vw;
  padding: 10px;
  flex-direction: row;
  display:flex;
  text-align: center;
  transition: all 0.3s ease-in;
  box-shadow: 0 8px 20px 0 rgba(142, 142, 142, 0.2);
  @media (max-width: 600px) {
    font-size: 14px;
  }
  a {
    
    color: #828282;
    font-size: 14px;
    :hover {
      color: #2566c8;
      cursor: pointer;
    }
  }
  text {
    
    color: #828282;
    font-size: 14px;
    :hover {
      color: #c82525;
      cursor: pointer;
    }
  }
  p {
    font-size: 21px;
    @media(max-width: 1568px) {
      font-size: 18px;
    }
    @media(max-width: 600px) {
      font-size: 15px;
    }
  }
`;
