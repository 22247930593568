import styled from "styled-components";
import { Props } from ".";

export const RoomBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    width: 22vw;
    border: none;
    background-color: #D2D2D2;
    box-shadow: 0 8px 20px 0 rgba(142, 142, 142, 0.2);
    border-radius: 8px;
    h3 {
        color: #88888883;
        
        font-size: 16px;
    }
    div {
        display: none;
    }
    @media (max-width: 912px) {
        height: 4.464vh;
        width: 61vw;
        justify-content: space-between;
        padding-right: 3.62vw;
        padding-left: 2.23vw;
        box-shadow: 0 0 10px 0 rgba(149, 149, 149, 0.3);
        h3 {
            font-size: 16px;
        }
        div {
            display: flex;
            color: #2566c8;
        }
    }
    @media (max-width: 700px) {
        width: 85.5vw;
        h3 {
            font-size: 12px;
        }
    }
`;
export const Img = styled.img`
    width: 5vw;
    height: 4.777777778vh;
    object-fit: cover;
    border-radius: 8px;
`;
export const MaterialBtn = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 7vh;
    width: 22vw;
    border: none;
    background-color: white;
    box-shadow: 0 8px 20px 0 rgba(142, 142, 142, 0.2);
    border-radius: 8px;
    padding-left: 1.38vw;
    padding-right: 10px;
    h3 {
        color: #828282;
        
        font-size: 16px;
    }
    &:hover {
        opacity: 0.5;
    }

    @media (max-width: 912px) {
        /* height:10px;
  width:41vw;
  h3{
    font-size:12px;
} */
        display: none;
    }
`;
export const MobileBtn = styled.div<Props>`
    p {
        display: none;
    }
    @media (max-width: 912px) {
        &:active {
            opacity: 0.5;
        }
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background: white;
        height: 6.7vh;
        width: 41vw;
        background-image: url(${(props) => props.url});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
        p {
            display: flex;
            margin-top: 10px;
            margin-left: 10px;
            font-size: 12px;
            font-weight: 700;
            
        }
    }
`;
