import { useEffect, useState } from 'react';

const ZapierChatbot: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      <zapier-interfaces-chatbot-embed
        is-popup="true"
        chatbot-id='cllfdkm2j337490rkzfspmww7q'
        height={`${windowHeight >= 750 ? '700px' : '90%'}`}
        width={`${windowWidth >= 500 ? '450px' : '90%'}`}
        style={{
          position: 'fixed',
          zIndex: '9999'
        }}
      ></zapier-interfaces-chatbot-embed>
    </div>
  );
};

export default ZapierChatbot;
