import styled from "styled-components";

export const Container = styled.div`
background:#FFF;
display:flex;
flex-direction:row;
align-items:center;
height:100vh;
@media(max-width:912px){
    flex-direction:column;
    width:100vw;
    height:100vh;
}
`
export const LoginRightSection = styled.div`
display:flex;
flex:1;
flex-direction:row;
justify-content:center;
align-items:center;

`