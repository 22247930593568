import styled from "styled-components";
interface displayChoice {
    display?: string
}
export const Header = styled.header<displayChoice>`
height:80px;
background-color: #FFF;
display:flex;
flex-direction: row;
box-shadow: 0 2px 10px 0 rgba(147,147,147,0.25);
align-items:center;
flex-flow:wrap;
justify-content:space-between;
padding: 0px 25px 0px 25px;
@media (max-width:1200px){
    justify-content: center;
    gap:10px;
    height:100px;
    padding-top:20px;   
}
@media (max-width:912px){
    display: ${prop => prop.display ?? 'flex'};
    position:relative;
    height:auto;
    box-shadow: none;
    padding: 64px 20px 0px 20px;
}
`
export const LeftSection = styled.div`
display:flex;
flex-direction:row;
align-items:center;
@media (max-width:912px){
    width:100%;
    flex-direction:column;
    gap:50px;
    position:relative;
}
`
export const LogoImg = styled.img`
margin-right:25px;
@media (max-width:912px){
    display:none;
}
`
export const MobileLogo = styled.img`
display:none;
@media(max-width:912px){
    display:flex;
}
`
export const SearchDiv = styled.div`
height:40px;
width:400px;
border-radius: 5px;
background-color:#f2f2f2;
/* box-shadow: 0 2px 10px 0 rgba(147,147,147,0.25); */
display:flex;
flex-direction: row;
align-items:center;

@media (max-width:912px){
    width: 100%;
    box-shadow:none;
    background-color: #f2f2f2;
    margin-bottom: 2vh;
}

`
export const Menu = styled.button`
display:none;
@media (max-width:912px){
    position:absolute;
    top:0;
    left: 0;
    display:flex;
    background-color: white;
    border:none;
    &:active{
        opacity: 0.5;
    }
}
`
export const Action = styled.a`
&:hover{
    cursor:pointer;
    opacity:0.5;
}
`
export const NavbarA = styled.a`
   font-size: 10px;
   &:hover{
    cursor:pointer;
    opacity:0.5;
}

`

export const SearchInput = styled.input`

height:40px;
width:18vw;
font-size: 14px;
font-weight: 500;
width:90%;
margin-right: 5vw;
border:none;
background-color: #f2f2f2;
&:focus{
    outline:none;
}
margin-left:15px;
@media (max-width:912px){
    background-color: #f2f2f2;
}
@supports (-webkit-touch-callout: none) {
  /* Atualmente, apenas dispositivos iOS possuem a feature -webkit-touch-callout,
  logo esse código testa se o dispositivo é iOS ou não. Caso a Apple mude algo,
  basta consultar
  https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariCSSRef/Articles/StandardCSSProperties.html
  e buscar por "Safari on iOS only"*/
  font-size: 16px;
  width: 95%;
  }
`
export const RightBtns = styled.div`
display:flex;
flex-direction:row;
gap:15px;
align-items:center;
@media (max-width:912px){
    display:none;
}
`
export const Texth6 = styled.h6`
color: #828282;

font-size: 14px;
font-weight: 700;
line-height: 15px;
text-align: center;
`
export const ProfileButton = styled.button`
border:none;
background:none;
margin-left:10px;
&:hover{
    opacity:0.5;
}
`