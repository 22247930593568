import * as React from "react";
import MenuIcon from "../../assets/dotMenu.svg";

import {
  EstimateDiv,
  Title,
  Material,
  Quantidade,
  Preco,
  QntTotal,
  ProdutoMaterial,
  Dropdown,
  Item,
  LIstName,
  ModalBackground,
} from "./style";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CountertopService from "../../services/CountertopService";
import api from "../../services/api";
import EstimateService from "../../services/EstimateService";
import X from "../../assets/Close.svg"
import Loader from "../Loader";
import toBRLPrice from "../../utils/toBRLPrice";
interface Props {
  title: string;
  material: string;
  quantidade: number;
  preco: number;
  cartCountertopId: string;
}
export interface cartProps {
  cartCountertopId: string;
  productPrice: number;
  servicePrice: number;
  price: number;
  ClientIdentificationNumber: string;
  EstimateId: string;
}

function EstimateCard({
  title,
  material,
  quantidade,
  preco,
  cartCountertopId,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const estimateId = sessionStorage.getItem("estimateId");
  const estimateName = sessionStorage.getItem("estimateName");
  const handleOpen = () => {
    setLoading(true)
    setOpen(!open);
    // sessionStorage.clear()
    setTimeout(() => {
      setLoading(false)
      CountertopService.fillCartCountertop(cartCountertopId).then((res) => {
        console.log(res); 
      });
    }, 2000);
  
  };
  const handleMenuOne = () => {
    setOpen(false);
    sessionStorage.setItem("editing summary", "true");
    sessionStorage.setItem("editing product", "true");
    navigate("/resumo");
  };
  const handleMenuTwo = () => {
    CountertopService.duplicateCartCounterTop().then((res: any) => {
      console.log(res);
    });
    setOpen(false);
  };
  const deleteProduct = () => {
    const joinId = sessionStorage.getItem("CountertopJoinId");
    CountertopService.deleteCartCounterTop(cartCountertopId, joinId!).then((res: any) => {
      console.log(res);
      window.location.reload();
      if (estimateId) {
        EstimateService.fetchEstimate(estimateId).then((res) => {
          if (res?.data.CartCountertops == 0) {
            api.delete(`/estimates/${estimateId!}`).then((res) => {
              console.log(res);
            });
            navigate("/");
          }
        });
        sessionStorage.clear()
        sessionStorage.setItem('estimateId', String(estimateId))
        sessionStorage.setItem('estimateName', String(estimateName))
      }
    });

    setOpen(false);
  };
  return (
    <div style={{ flexDirection: "row", display: "flex" }}>
      <EstimateDiv>
        <ProdutoMaterial>
          <Title>{title}</Title>
          <Material>{material}</Material>
        </ProdutoMaterial>
        <QntTotal>
          <Quantidade>Qnt. {quantidade}</Quantidade>
          <Preco>
            {toBRLPrice(preco)}
          </Preco>
        </QntTotal>
        <div style={{ width: "10%", cursor: "pointer" }} onClick={handleOpen}>
          <img src={MenuIcon} height={"21px"} style={{ paddingTop: "18px" }} />
        </div>
      </EstimateDiv>
          <ModalBackground style={{ display: open ? "flex" : "none" }}>
          <Dropdown style={{ transform: open ? "scale(1)" : "scale(0)" }}>
  
            <LIstName>
            <p style={{color:'#FFFFFF', textAlign:'center',lineHeight: '24px', marginLeft:'20%'}}>Altere seu pedido</p>
              <a
                style={{ fontSize: '18px', color: 'white', marginLeft: '15%' }}
                onClick={() => {
                  setOpen(false)
                  sessionStorage.clear()
                  sessionStorage.setItem('estimateId', String(estimateId))
                  sessionStorage.setItem('estimateName', String(estimateName))
                }}
              > X </a>
            </LIstName>
              
            {loading ? (
              <Loader/>
            ):
            <div style={{ padding: '10px', width: '100%' }}>
            <Item>
              <a onClick={handleMenuOne}>Editar produto</a>
            </Item>
            <Item>
              <a onClick={handleMenuTwo}>Duplicar produto</a>
            </Item>
            <Item>
              <text onClick={deleteProduct}>Excluir produto</text>
            </Item>
          </div>
            }
          
          </Dropdown>
        </ModalBackground>
          
    </div>

  );
}

export default EstimateCard;