import { ReactNode } from 'react';

import { Container, Counter } from './styles';

interface StepCounterProps {
  step: number;
}

export function StepCounter({ step }: StepCounterProps) {
  return (
    <Container>
      <Counter src={require(`../../assets/StepCounter${step}.svg`)}/>
    </Container>
  );
}