import styled from 'styled-components';

export const Container = styled.section`
height:100vh;
background:#f2f2f2;
display:flex;
flex-direction: column;
padding-left:3.472222222vw;
padding-right:3.472222222vw;
@media (max-width:913px){
    display: none;
}
`
export const BottomContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin-top:6.666vh;
padding-right:1vw;

@media (max-width: 600px){
    justify-content: center;
}
`
