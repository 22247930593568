import styled from "styled-components";


export const AddressInfoContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
width: 100%;
/* height: 90px; */
box-sizing: border-box;


`
export const TextInfo = styled.p`

`
export const TextWrapper = styled.div`
display: flex;
flex-direction: row;
 gap:15px;
@media(max-width: 912px){
    flex-direction: column;
    gap:0px;
    padding-bottom: 5px;
}

`
export const TextAddressName = styled.h1`
font-size: 14px;
font-weight: 500;
line-height:24px;
`