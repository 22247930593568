import styled from 'styled-components';

export const PurchaseDiv = styled.div`
height: 3.938rem;
width: 420px;
border-radius: 8px;
background-color: #FFFFFF;
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);

display: flex;
flex-direction: row;
flex-wrap: wrap;
margin-top:1.25rem;
@media(max-width:550px){
    width: 75vw;

}
@media(max-width: 912px) {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(0, 0, 0, 0.16) 0px 3px 6px;
}
`
export const Div = styled.div`
width:344px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;

`
export const ProdutoMaterial = styled.div`
padding-left:20px;

`
export const QntTotal = styled.div`

`
export const Title = styled.h1`
color: #828282;

font-size: 14px;
font-weight: 500;
line-height:24px;

`
export const Info = styled.h1`
color: #828282;

font-size: 14px;
font-weight: 300;
margin-top:5px;
line-height:24px;

`
export const Quantidade = styled.h1`
color: #BDBDBD;

font-size: 14px;
font-weight: 700;
text-align: right;
`
export const Preco = styled.h1`
color: #BDBDBD;

font-size: 14px;
font-weight: 300;
text-align: right;
margin-top:5px;
`