import * as React from "react";
import { useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import CardButton from "../../components/CardButton";
import { Input, InputBox } from "../RoomSelect/style";
import {
    BoxName,
    Buttons,
    Container,
    GoBackBtn,
    MainBox,
    MainText,
    MobileContainer,
    Name,
    TopBox,
} from "./style";
import BackBtn from "../../components/BackBtn";
import ProductService from "../../services/ProductService";
import CardButtonOff from "../../components/CardButtonOff";

interface productProps {
    name: string;
    available: boolean;
}
function ProductSelect() {
    const [search, setSearch] = useState("");
    const [product, setProduct] = useState<productProps[]>([]);
    const productArray = [{ name: 'Bancada de Pia', available: 1 },
    { name: 'Bancada de Tanque', available: 0 },
    { name: 'Balcão', available: 0 },
    { name: 'Ilha', available: 0 },
    { name: 'Tampo', available: 0 },
    { name: 'Aduela de Porta', available: 0 },
    { name: 'Aduela de Janela', available: 0 },
    { name: 'Ilharga', available: 0 },
    { name: 'Rodapé', available: 0 },
    { name: 'Soleira', available: 0 },
    { name: 'Peitoril', available: 0 },
    { name: 'Filete', available: 0 },
    { name: 'Piso', available: 0 },
    { name: 'Tabeira', available: 0 },
    { name: 'Revestimento de Parede', available: 0 },
    { name: 'Sóculo', available: 0 },
    { name: 'Fronstispício de Bancada', available: 0 },
    { name: 'Tira de Bancada', available: 0 },
    { name: 'Saia de Bancada', available: 0 },
    { name: 'Peça Avulsa', available: 0 },]

    const lowerCaseSearch = search.toLowerCase();
    const filteredProduct = productArray.filter((product) =>
        product.name.toLocaleLowerCase().includes(lowerCaseSearch)
    );
    const navigate = useNavigate();


    const selectProduct = async (productName: string) => {
        sessionStorage.setItem("productName", productName);
        navigate("/selecionar-material");

    };

    const addProductToEstimate = sessionStorage.getItem('addProductToEstimate');
    const addRoomToEstimate = sessionStorage.getItem('addRoomToEstimate');
    const paginaAnterior =
        addRoomToEstimate != undefined
            ? "selecionar-ambiente"
            : addProductToEstimate != undefined
                ? "estimativa"
                : "selecionar-ambiente";



    sessionStorage.removeItem("productName")

    // React.useEffect(() => {
    //     ProductService.getProduct().then((res) => {
    //         setProduct(res?.data.products);
    //         console.log(res);
    //     });
    // }, []);

    return (
        <MobileContainer>
            <span>Orçamento</span>
            <Container style={{ position: "relative", height: '115vh' }}>
                <Link
                    to={`/${paginaAnterior}`}
                    style={{
                        position: "absolute",
                        top: 20,
                        height: "3.5%",
                        zIndex: 100
                    }}
                >
                    <BackBtn isMobile={true} />
                </Link>
                <BoxName>
                    <Name style={{ alignSelf: "center" }}>
                        {" "}
                        {sessionStorage.getItem("roomName")}
                    </Name>
                </BoxName>
                <TopBox>
                    <MainText>Selecione o produto</MainText>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                        }}
                    >
                        <InputBox>
                            <RiSearchLine
                                style={{
                                    color: "#828282",
                                    marginLeft: "5px",
                                }}
                            />
                            <Input
                                /* type="search" */
                                placeholder="Buscar"
                                value={search}
                                onChange={(event) =>
                                    setSearch(event.target.value)
                                }
                            />
                        </InputBox>
                    </div>
                </TopBox>
                <MainBox>
                    <Buttons>
                        {/* For alimentado com o array 'product'  */}
                        {filteredProduct.map((item, index) =>
                            item.available ? (
                                <CardButton
                                    name={item.name}
                                    key={index}
                                    thereIsImage={false}
                                    onClick={() => {
                                        selectProduct(item.name)
                                        console.log(item.name)
                                    }}
                                />
                            ) : (
                                <CardButtonOff
                                    name={item.name}
                                    key={index}
                                    thereIsImage={false}
                                />
                            )
                        )}
                    </Buttons>
                </MainBox>
                <div style={{ paddingTop: '48px', width: 'fit-content' }}>
                    <Link to={`/${paginaAnterior}`}>
                        <BackBtn />
                    </Link>

                </div>

            </Container>
        </MobileContainer>
    );
}

export default ProductSelect;
