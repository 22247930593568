import styled from "styled-components";
interface registerProps {
  position: string
}
export const Container = styled.div<registerProps>`
display:flex;
flex-direction:column;
background-color:#FFF;
height:auto;
width:400px;
padding: 26px 27px 26px 40px;
border-radius:22.5px;
box-shadow: 0 2px 20px 0 rgba(149,149,149,0.3);
position:${props => props.position};
*border: solid 2px red;
h4{
    font-weight: 500; 
    font-size:16px;
}
  @media(max-width:912px){
    width:100vw;
    position:${props => props.position};
    top:32%;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    height:auto;
    padding-bottom: 10px;
  }
`

export const H2 = styled.h2`
height: 30px;
width: 20rem;

font-weight: 700;
color:#2566C8;
font-size:24px;
@media (max-width: 912px) {
    position: relative;
    left: 10%;
    padding-top: 8%;
    height: fit-content;
    width: fit-content;
}
   

`

export const H6 = styled.h6`

    font-weight: 500;
    color: #BDBDBD;
    font-size:14px;
margin-bottom:20px;
margin-top:10px;
@media (max-width: 912px) {
    position: relative;
    left: 10%;
    width: fit-content;
    height: fit-content;
}

`
export const Form = styled.form`
display:flex;
flex-direction:column;
p{
    
}
@media (max-width: 912px) {
    align-items: center;
}

`

export const InputDiv = styled.div`
display:flex;
flex-direction:row;
margin-bottom:20px;
height:40px;
background:#F2F2F2;
border-radius:5px;
align-items:center;
img{
    height:40px;
    width:20rem;
}
@media (max-width: 912px) {
    width: 80vw;
    
}
`
export const Input = styled.input`

border:none;
background-color:#F2F2F2;
width: 100%;
&:focus{
    outline:none;
}
margin-left:10px;
@supports (-webkit-touch-callout: none) {
  /* Atualmente, apenas dispositivos iOS possuem a feature -webkit-touch-callout,
  logo esse código testa se o dispositivo é iOS ou não. Caso a Apple mude algo,
  basta consultar
  https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariCSSRef/Articles/StandardCSSProperties.html
  e buscar por "Safari on iOS only"*/
  font-size: 16px;
  width: 95%;
}
`


export const SignUpBtn = styled.button`
background-image: linear-gradient(271.82deg, #2566C8 0%, #4485E8 100%);
height:40px;
width:100%;
border:none;
border-radius:8px;
box-shadow: 0 8px 30px 0 rgba(0,44,113,0.25);
color:white;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
position:relative;
&:hover{
    cursor:pointer;
    opacity:0.5;
}
@media (max-width: 912px) {
    width: 80vw;
}

`

export const TextBox = styled.div`

display:flex;
flex-direction:row;
gap:1%;
padding-top:13px;
justify-content:center;
align-items: flex-end;
@media (max-width: 912px) {
    padding: 0;
}

`

export const GrayH6 = styled.h6`

font-size: 14px;
font-weight: 300;
color:#BDBDBD;



`

export const A = styled.a`
text-align: center;
&:hover{
    opacity:0.5;
    cursor:pointer;
}
h6{
    font-size: 14px;
    color:#2566C8;

}
margin-top: 32px;

`
// export const Select = styled.select`
// flex-direction:row;
// margin-bottom:20px;
// height:40px;
// background:#F2F2F2;
// border-radius:5px;
// border:none;
// `