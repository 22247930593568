import styled from "styled-components";

export const Content =  styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
max-width: 30%;

scroll-snap-align: center;
:first-child{
    margin-left: 10px;
}
:last-child{
    margin-right: 15px;
}
@media (max-width:912px){
    max-width: 400px;
}
`

export const Box = styled.div`
position:relative;
background-color:#D2D2D2;
display:flex;
flex-direction:column;
align-items:center;
justify-content: center;
width:29.648vw;
height:23.32vh;
box-shadow: 0.9vh 0.5vh 1vw 0 rgba(54,54,54,0.2);
border-radius:1.24vh;
overflow:hidden;
padding-top: 10px;
&:hover{
    cursor:pointer;
    opacity:0.8;
};
@media (max-width: 912px){
    width:80vw;
    box-shadow: 0vh 1.24vh 2.196vw 0 rgba(54,54,54,0.2);

}
`
export const ArrangementName = styled.h6`
color: #88888883;

font-size: 1.17vw;
font-weight: 700;
margin-bottom:2.88vh;
@media (max-width: 600px){
    font-size: 12px;
}
`
export const MarkedIcon = styled.img`
position:absolute;
top:0.625rem;
right:0.625rem;
height:24px;
width:24px;
border:none;

`
