import styled from "styled-components";


interface benchDetailsProps {
    onAdvance?: boolean;
}
export const Page = styled.section<benchDetailsProps>`
display: flex;
flex-direction: column;
height: auto;
padding: 6vw 3.47vw 0 3.47vw;
h2{

font-weight: 700;
font-size:1.25rem;
color:#828282;
margin-bottom:2.2vh;
}
@media(min-width:1200), (max-width:1600px){
    padding-left:5%;
    padding-right:5%;
}
@media(max-width: 912px){
    background-color: #ffffff;
    min-height: 100vh;
    width: 90vw;
    top: 50px;
    position: relative;
    border-radius: 20px 20px 0px 0px;
    h2{
        margin-left: 5vw;
    }
}
`
export const MobileContainer = styled.div`
span{
    display: none;
}
@media(max-width:912px){
    display:flex;
    flex:1;
    flex-direction:column;
    align-items:center;
    background:#2566C8;
    width:100vw;
    span{
        padding-top:35px;
        display:flex;
        
        color:white;
        font-size:20px;
        font-weight: 700;
    
    }
}
`
export const Title = styled.h2`

font-size: 20px;
color: #828282;
margin-bottom:20px;
`
export const Box = styled.div`
height: 50px;
background:white;
cursor:pointer;
display:inline-flex;
border:none;
flex-direction:row;
align-items:center;
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
border-radius:8px;
justify-content:flex-start;
img{
    margin-left:1.063rem
}
@media(max-width:1200px){
    width: 90%;
    height: fit-content;
    padding: 10px;
    align-self:center;
}
`
export const Name = styled.text`
display: contents;

font-size: 16px;
color: #828282;
b{
    font-weight: 700;
    color:black;
    display: contents
}


`
export const BottomDiv = styled.div`
@media(max-width:1200px){
    width:430px;
    align-self:center;
}
`
export const BottomWrapper = styled.div`
display:flex;
flex-direction:row;
margin-bottom:5.5vh;
width: 100%;

gap:1.73vw;
@media (max-width:912px){
    flex-direction:column; 
    overflow-x: scroll;
    align-self:center;
    padding-bottom: 25px;
    margin-bottom: 4vh;
    align-self:center;
}
`
export const CardsWrapper = styled.div`
display:flex;
flex-direction:row;
margin-bottom:5.5vh;
width: 92vw;
scroll-snap-type: x mandatory;
scroll-padding: 50px;
gap:1.73vw;
@media (max-width:1200px){
    flex-direction:row; 
    overflow-x: scroll;
    align-self:center;
    padding-bottom: 25px;
    margin-bottom: 4vh;
    align-self:center;
}
`
export const Top = styled.div`
display: flex;
width: 98%;
height: fit-content;
justify-content: center;
align-content: center;
padding: 0;
margin-bottom: 2%;
@media(max-width:912px){
    margin-top: 3vh;
}
`
export const Div = styled.div`

@media (max-width:1000px) {
  display:flex;
  flex-direction: column;
  background-color: #f2f2f2;
  width:90vw;
  height:30px;
  align-content: center;
  padding-top: 5px;
  border-radius: 5px;
}
`