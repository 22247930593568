import {
  Page,
  SbmtBtn,
  Title,
  DisabledBtn,
  Footer,
  AddressContainer,
} from "./style";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BackBtn from "../../components/BackBtn";
import PageHeader from "../../components/Header";
import AddressCard from "../../components/AddressCard";
import Marked from "../../assets/Marked.png";
import NotMarked from "../../assets/NotMarked.png";
import { AddressDiv, Div, Info, InfoDiv } from "../FinishPurchase/style";
import AddressForm from "../../components/AddressForm";
import ClientAddressService from "../../services/ClientAddressService";
import { AddressInfoCard } from "../../components/AddressInfoCard";
import { PageMobile, TextContainer, TextInfo } from "../Identify/style";
import './style.css'
import EstimateService from "../../services/EstimateService";

interface AddressInfo {
  id: string;
  zipCode: string;
  streetName: string;
  streetNumber: string;
  additionalInfo: string;
  ClientIdentificationNumber: string;
  addressName: string;
}

function Address(): JSX.Element {
  const [advance, setAdvance] = useState<boolean>(false);
  const [selectA, setSelectA] = useState(false);
  const [selectA1, setSelectA1] = useState(false);
  const [address, setAddress] = useState<AddressInfo[]>([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("identificationNumber");
  const estimateId = sessionStorage.getItem("estimateId");

  function selectAC() {
    setSelectA(!selectA);
    setSelectA1(false);
    sessionStorage.setItem("addressName", endereco[0].addressName);
  }
  function selectAC1() {
    setSelectA(false);
    setSelectA1(!selectA1);
    setSelectedAddress('');
  }
  // função altera a opção de endereço escolhida pelo usuário
  function changeAddress(addressId: string, radioButtonIndex: string) {
    var radios = document.getElementById(
      radioButtonIndex
    ) as HTMLInputElement | null;
    radios!.checked = true;
    setSelectedAddress(addressId);
    console.log(`selectedAddress:  ${selectedAddress}`)
  }
  // Aqui o onSubmit cria a relação entre o estimate e o endereço escolhido pelo cliente
  function onSubmit(){
    if (token && id) {
        EstimateService.clientAddressEstimateRelation(estimateId!,selectedAddress).then((response) =>
            console.log(response)
        );
    }
  }

  useEffect(() => {
    if (token && id) {
      ClientAddressService.getClientAddress(token).then((response) =>
        setAddress(response?.data.address)
      );

    }
  }, []);

  var endereco: AddressInfo[] = [];
  endereco = address?.filter(function (address) {
    return address?.ClientIdentificationNumber == id;
  });
  console.log(endereco, selectedAddress)
  return (
    <>
      <PageHeader display="none" />
      <Page onAdvance={advance}>
        <div>
          <Title>Endereço da obra</Title>

          <AddressContainer>
            <AddressCard
              title="Endereço Cadastrado"
              selected={selectA ? Marked : NotMarked}
              onClick={() => selectAC()}
            />
            <AddressCard
              title="Escolher novo endereço"
              selected={selectA1 ? Marked : NotMarked}
              onClick={() => selectAC1()}
            />
          </AddressContainer>

          {selectA ? (
            <div style={{ maxHeight: "350px", overflowY: "auto" }}>
              {endereco.map((address, index) => (
                <AddressContainer style={{ marginLeft: '-25px', paddingBottom: '20px' }}>
                  <AddressDiv>
                    <InfoDiv>
                      <Info>
                        <Div
                          onClick={() =>
                            changeAddress(address.id, String(index))
                          }
                        >
                          <AddressInfoCard
                            key={index}
                            addressName={address.addressName}
                            streetName={address.streetName}
                            streetNumber={address.streetNumber}
                            zipCode={address.zipCode}
                            additionalInfo={address.additionalInfo}
                          />
                          <input
                            type={"radio"}
                            name="SelectAddress"
                            id={String(index)}
                          />
                        </Div>
                      </Info>
                    </InfoDiv>
                  </AddressDiv>
                </AddressContainer>
              ))}
            </div>
          ) : selectA1 ? (
            <AddressContainer style={{ marginLeft: '-25px' }}>
              <AddressForm
                newAddress={true}
                position="relative"
                addressId={String(address.length + 1)} // última posição do array
              ></AddressForm>
            </AddressContainer>
          ) : (
            ""
          )}
        </div>

        <Footer style={{ paddingBottom: "20px" }}>
          <Link to="/finalize-compra">
            <BackBtn />
          </Link>
          {/* Se a opção de escolher os endereços estiver selecionada, ele coloca no SessionStorage e avança */}
          {selectA ? (
            selectedAddress != "" ? (
              <Link to="/finalize-compra">
               <SbmtBtn onClick={()=> onSubmit()}>
                  Avançar
                </SbmtBtn>
              </Link>
            ) : (
              <DisabledBtn>Avançar</DisabledBtn>
            )
          ) : (
            ""
          )}
        </Footer>
      </Page>

      {/* Versão Mobile */}

      <PageMobile>
        <TextContainer>
          <TextInfo>Endereço da Obra</TextInfo>
        </TextContainer>
        <div style={{ display: 'flex', gap: '10px', width: '100vw', justifyContent: 'center', marginBottom: '40px' }}>
          <AddressCard
            title="Endereço Cadastrado"
            selected={selectA ? Marked : NotMarked}
            onClick={() => selectAC()}
          />
          <AddressCard
            title="Escolher novo endereço"
            selected={selectA1 ? Marked : NotMarked}
            onClick={() => selectAC1()}
          />
        </div>
        {selectA ? (
          <div style={{ maxHeight: "400px", overflowY: "auto", position: 'relative', top: '11%',}} className="scroll">
            {endereco.map((address, index) => (
              <AddressContainer style={{ padding: '10px', paddingBottom: '20px' }}>
                <AddressDiv
                  style={{
                    width: '80vw',
                    boxShadow: " -0.1vh 0.54vh 4.196vw 0.2vh rgba(54,54,54,0.2)"

                  }}>
                  <InfoDiv style={{ border: '' }}>
                    <Info style={{ border: '', minHeight: '62px' }}>
                      <Div
                        style={{ width: '92%', paddingRight: '10px' }}
                        onClick={() =>
                          changeAddress(address.id, String(index))
                        }

                      >
                        <AddressInfoCard
                          key={index}
                          addressName={address.addressName}
                          streetName={address.streetName}
                          streetNumber={address.streetNumber}
                          zipCode={address.zipCode}
                          additionalInfo={address.additionalInfo}
                        />


                      </Div>
                    </Info>
                  </InfoDiv>
                  <div 
                    style={{ 
                      position: 'absolute',
                      left: '90%',
                      display: 'flex',
                      paddingTop: '30px' }}>
                    <input
                      type={"radio"}
                      name="SelectAddressMobile"
                      id={String(index)}
                      checked = {selectedAddress == String(index+1) ? true : false}
                    />
                  </div>

                </AddressDiv>
              </AddressContainer>
            ))}
          </div>
        ) : selectA1 ? (
          <AddressContainer>
            <AddressForm
              newAddress={true}
              position="relative"
              addressId={String(endereco.length + 1)}
              margin="0px"
            ></AddressForm>
          </AddressContainer>
        ) : (
          ""
        )}

        <Footer style={{top: '20%', position: 'relative'}}>
          <Link to="/finalize-compra">
            <BackBtn />
          </Link>
          {/* Se a opção de escolher os endereços estiver selecionada, ele coloca no SessionStorage e avança */}
          {selectA ? (
            selectedAddress != "" ? (
              <Link to="/finalize-compra">
                <SbmtBtn onClick={()=> onSubmit()}
                >
                  Avançar
                </SbmtBtn>
              </Link>
            ) : (
              <DisabledBtn>Avançar</DisabledBtn>
            )
          ) : (
            ""
          )}
        </Footer>


      </PageMobile>

    </>
  );
}

export default Address;
