/* eslint-disable import/no-anonymous-default-export */
import api from "../api";

interface paymentProps {
    transaction_amount: number,
    token: string,
    description?: string | null,
    installments: number,
    payment_method_id: number,
    issuer_id: number,
    email: string,
    identificationType: string,
    identificationNumber: string,
    external_reference: string | null

}
interface boletoProps {
    transaction_amount: number | string,
    description?: string | null,
    first_name: string,
    last_name: string,
    email: string,
    identificationType: string,
    identificationNumber: string | number,
    external_reference:string | null
}

export default {
    async postPayment(data: paymentProps, token: string) {
        try {
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`
            const response = await api.post('/private/MPPayment/creditCard', data)
            console.log(response.data)
            return response
        }
        catch (e) {
            console.log(e)
        }
    },
    async postBoletoPayment(data: boletoProps, token: string) {
        try {
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`
            const response = await api.post('/private/MPPayment/boleto', data)
            return response
        }
        catch (e) {
            console.log(e)
        }
    }

}
