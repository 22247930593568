import * as React from 'react';
import { useEffect, useState } from 'react';
import {Title, TitleDiv, Box, PieceContainer, PieceTitle, ContainerBS, XvLWrapper, XvpLWrapper, XvRWrapper, Page, XvpLWrapperDiv, XvpRWrapper,  RightDiv, LeftDiv, WrappersDiv, BoxDiv, BottomDiv, XvBWrapper, XvpBWrapper,TopDiv, XvTWrapper,  XvpTWrapper, SAcabamentoDiv, SAcabamentoWrapper, BottomContainer,  LegendaText1, LegendaText2, ButtonDiv, DisabledButton, SbmtButton,  } from './style';

import DetailsInput from '../../components/DetailsInput';
import BackBtn from '../../components/BackBtn';
import Marked from '../../assets/Marked.svg';
import NotMarked from '../../assets/NotMarked.svg';
import {useForm} from 'react-hook-form';

import {Link, useNavigate} from 'react-router-dom';
import { TitleInput } from '../BenchL/style';
import { Container, Div, Input } from '../../components/DetailsInput/style';

function BenchSizing(): JSX.Element{
    //validação
    const navigate = useNavigate()
    const { register, formState: { errors }, handleSubmit} = useForm();
    const onSubmit = (data:any) => {console.log(data); navigate('/instalacao')}; // apenas para a verificação da validação aguardando onSubmit oficial

    
    const [advance, setAdvance] = useState<boolean>(false);

    const [xvLeft,setXvLeft] = useState<boolean>(false);
    const [xvpLeft,setXvpLeft] = useState<boolean>(false);
    const [xvRight,setXvRight] = useState<boolean>(false);
    const [xvpRight,setXvpRight] = useState<boolean>(false);
    const [xvTop,setXvTop] = useState<boolean>(false);
    const [xvBottom,setXvBottom] = useState<boolean>(false);
    const [xvpTop,setXvpTop] = useState<boolean>(false);
    const [xvpBottom,setXvpBottom] = useState<boolean>(false);
    const [sAcabamento,setsAcabamento] = useState<boolean>(false);


    const [c0, setc0] = useState<number>();
    const [c1, setc1] = useState<number>();
    const [c2, setc2] = useState<number>();

    const [show, setShow] = useState(false);
    console.log('c0 é:', c0, 'c1 é:', c1, 'c2 é:', c2, 'c3 é:', 'logo show é', show);

    useEffect(
        () => {
            showButton()
        }, [
        (c0 && c1 && c2 && xvLeft && xvpLeft && xvRight && xvpRight && xvTop && xvBottom && xvpTop && xvpBottom && sAcabamento) 
        ]
    )

    function showButton() {
        if (
            c0 !== (undefined && 0 && NaN) &&
            c1 !== (undefined && 0 && NaN) &&
            c2 !== (undefined && 0 && NaN) &&
            (xvLeft && xvpLeft && xvRight && xvpRight && xvTop && xvBottom && xvpTop && xvpBottom && sAcabamento)
        ) {
            setShow(!show)
        }
        else {
            setShow(false);
        }
    }
 
    function selectXvLeft(){
        setXvLeft(!xvLeft)
    };
    function selectXvpLeft(){
        setXvpLeft(!xvpLeft)
    };
    function selectXvRight(){
        setXvRight(!xvRight)
    };
    function selectXvpRight(){
        setXvpRight(!xvpRight)
    };
    function selectXvTop(){
        setXvTop(!xvTop)
    };
    function selectXvpTop(){
        setXvpTop(!xvpTop)
    };
    function selectXvBottom(){
        setXvBottom(!xvBottom)
    };
    function selectXvpBottom(){
        setXvpBottom(!xvpBottom)
    };
    function selectSAcabamento(){
        setXvLeft(false)
        setXvpLeft(false)
        setXvRight(false)
        setXvpRight(false)
        setXvTop(false)
        setXvpTop(false)
        setXvBottom(false)
        setXvpBottom(false)
        setsAcabamento(!sAcabamento)

    };
    function Advance() {
        setAdvance(!advance);
    }

    return(

        <>
        <Page>
            <form onSubmit={handleSubmit(onSubmit)}>
                <main>
                    <TitleDiv>
                        <Title>Peitoril </Title>
                        <Title>{'>'}</Title>
                        <Title>Granito Preto São Gabriel </Title>
                    </TitleDiv>

                    <div>
                        <BoxDiv>
                        <Box>
                            <Div>
                                 <TitleInput>Quantidade</TitleInput>
                                 <Container largura={30}>
                                    <Input
                                        type='number'
                                        {...register('quantidade', { required: true, max: 2300, min: 1})}
                                        placeholder={'Quantidade'}
                                        value={c0}
                                        onChange={(event: any) => setc0(event.target.value)}
                                    />
                                <h6>unidade(s)</h6>
                                </Container>
                                {errors.quantidade?.type === 'max' && <p style={{ color: 'red', fontSize: 12, marginTop: 8}}>Máximo de 2300 unid</p>}
                                {errors.quantidade?.type === 'min' && <p style={{ color: 'red', fontSize: 12, marginTop: 8 }}>Mínimo de 1 unid</p>}
                                {errors.quantidade?.type === 'required' && <p style={{ color: 'red', fontSize: 12, marginTop: 8 }}>Campo obrigatório</p>}
                                </Div>
                            </Box>

                            <Box>
                                <Div>
                                    <TitleInput>Comprimento</TitleInput>
                                    <Container largura={30}>
                                        <Input
                                            type='number'
                                            {...register('comprimento', { required: true, max: 2300, min: 300})}
                                            placeholder={'Comprimento'}
                                            value={c1}
                                            onChange={(event: any) => setc1(event.target.value)}
                                        />
                                        <h6>milímetros</h6>
                                    </Container>
                                    {errors.comprimento?.type === 'max' && <p style={{ color: 'red', fontSize: 12, marginTop: 8}}>Máximo de 2300 milímetros</p>}
                                    {errors.comprimento?.type === 'min' && <p style={{ color: 'red', fontSize: 12, marginTop: 8 }}>Mínimo de 300 milímetros</p>}
                                    {errors.comprimento?.type === 'required' && <p style={{ color: 'red', fontSize: 12, marginTop: 8 }}>Campo obrigatório</p>}
                                </Div>
                            </Box>
                            
                            <Box>
                                <Div>
                                    <TitleInput>Largura</TitleInput>
                                    <Container largura={30}>
                                        <Input
                                            type='number'
                                            {...register('largura', { required: true, max: 2300, min: 300})}
                                            placeholder={'Largura'}
                                            value={c2}
                                            onChange={(event: any) => setc2(event.target.value)}
                                        />
                                        <h6>milímetros</h6>
                                    </Container>
                                    {errors.largura?.type === 'max' && <p style={{ color: 'red', fontSize: 12, marginTop: 8}}>Máximo de 2300 milímetros</p>}
                                    {errors.largura?.type === 'min' && <p style={{ color: 'red', fontSize: 12, marginTop: 8}}>Mínimo de 300 milímetros</p>}
                                    {errors.largura?.type === 'required' && <p style={{ color: 'red', fontSize: 12, marginTop: 8}}>Campo obrigatório</p>}
                                </Div>
                            </Box>
                            
                        </BoxDiv>
                    </div>

                    <div>
                        <TitleDiv>
                            <Title>Informe as posições dos Acabamentos</Title>
                        </TitleDiv>

                        <TopDiv>
                                <XvTWrapper onClick={() => setXvTop(!xvTop)}>
                                    <img src={xvTop ? Marked : NotMarked}/>
                                    <label>xv</label>
                                </XvTWrapper>
                                <XvpTWrapper onClick={() => setXvpTop(!xvpTop)}>
                                    <img src={xvpTop ? Marked : NotMarked}/>
                                    <label>xvp</label>
                                </XvpTWrapper>
                        </TopDiv>

                        <WrappersDiv>
                            <LeftDiv>
                                <div>
                                    <XvLWrapper onClick={() => setXvLeft(!xvLeft)}>
                                        <img src={xvLeft ? Marked : NotMarked}/>
                                        <label>xv</label>
                                    </XvLWrapper>
                                </div>                                
                                
                                <XvpLWrapperDiv>
                                    <XvpLWrapper onClick={() => setXvpLeft(!xvpLeft)}>
                                        <img src={xvpLeft ? Marked : NotMarked}/>
                                        <label>xvp</label>
                                    </XvpLWrapper>
                                </XvpLWrapperDiv>
                            </LeftDiv>   

                            <ContainerBS>
                                <PieceContainer>
                                    <PieceTitle>LADO POLIDO DA PEÇA</PieceTitle>
                                </PieceContainer>
                            </ContainerBS>

                            <RightDiv>
                                <div>
                                    <XvRWrapper onClick={() => setXvRight(!xvRight)}>
                                        <img src={xvRight ? Marked : NotMarked}/>
                                        <label>xv</label>
                                    </XvRWrapper>
                                </div>
                                
                                <XvpRWrapper onClick={() => setXvpRight(!xvpRight)}>
                                    <img src={xvpRight ? Marked : NotMarked}/>
                                    <label>xvp</label>
                                </XvpRWrapper>
                                
                            </RightDiv>
                        </WrappersDiv>

                        <BottomContainer>
                            <BottomDiv>
                                
                                <XvBWrapper onClick={() => setXvBottom(!xvBottom)}>
                                    <img src={xvBottom ? Marked : NotMarked}/>
                                    <label>xv</label>
                                </XvBWrapper>
                                <XvpBWrapper onClick={() => setXvpBottom(!xvpBottom)}>
                                    <img src={xvpBottom ? Marked : NotMarked}/>
                                    <label>xvp</label>
                                </XvpBWrapper>
                                
                            </BottomDiv>

                            <SAcabamentoDiv>
                                <SAcabamentoWrapper onClick={() => selectSAcabamento()}>
                                    <img src={sAcabamento ? Marked : NotMarked}/>
                                    <label>Sem Acabamento</label>
                                </SAcabamentoWrapper>
                            </SAcabamentoDiv>

                            <LegendaText1>Legenda:</LegendaText1>  
                            <LegendaText1>XV - Acabamento Virado</LegendaText1>  
                            <LegendaText2>XVP - Acabamento Virado com Pingadeira</LegendaText2>  
                            
                        </BottomContainer>

                        <ButtonDiv>

                        <Link to='/selecionar-material'><BackBtn/></Link>
                        {((c0 && c1 && c2 && (xvLeft || xvpLeft || xvRight || xvpRight || xvTop || xvBottom || xvpTop || xvpBottom)) || (c0 && c1 && c2 && sAcabamento)) ? 

                        /* COMENTADO PARA TESTE DE VALIDAÇÃO
                        <Link to='/instalacao'><SbmtButton>Avançar</SbmtButton></Link>
                        */ 
                        <SbmtButton>Avançar</SbmtButton> :  <DisabledButton>Avançar</DisabledButton>}
                        </ButtonDiv>                    
                    </div>
                </main>
            </form>
        </Page>    
        </>    
    );
}

export default BenchSizing;
