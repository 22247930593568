import PageHeader from "../../components/Header";
import RoomCard from "../../components/RoomCard";
import {
  BotSection,
  Btn,
  BtnTxt,
  CardContainer,
  InSecTxt,
  Line,
  MainContent,
  MobileBotSection,
  MobileBtn,
  Section,
  SectionLeft,
  SectionMid,
  SectionRight,
  SectionTitle,
  Texth1,
  PopUp,
  PopUpText,
  ButtonToggle,
  Div,
  Body,
  Content1
} from "./style";
import Cozinha from "../../assets/Cozinha.svg";
import Banheiro from "../../assets/Banheiro.png";
import Sala from "../../assets/Sala.svg";
import Varanda from "../../assets/Exterior.svg";
import { useNavigate } from "react-router-dom";
import { BsPlusLg } from "react-icons/bs";
import { useEffect, useState } from "react";
import OrderCard, { CardProps } from "../../components/OrderCard";
import ScrollContainer from "react-indiana-drag-scroll";
import "./style.css";
import EstimateService from "../../services/EstimateService";
// import { CommonActions } from "@react-navigation/native"
import ClientService from "../../services/ClientService";
import {
  Action,
  Header,
  LeftSection,
  LogoImg,
  Menu,
  MobileLogo,
  NavbarA,
  ProfileButton,
  RightBtns,
  SearchDiv,
  SearchInput,
  Texth6,
} from "../../components/Header/style";
import Logo from "../../assets/LogoSanstone.svg";
import LogoMobile from "../../assets/sansmobilelogo.svg";
import { RiSearchLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";
import {IoMdClose} from 'react-icons/io';
import ZapierChatbot from "../../components/ZapierChatBot";
import toBRLPrice from "../../utils/toBRLPrice";

interface EstimateData {
  id: string;
  name: string;
  address: string;
  createdAt: string;
  price: string;
  status: string;
}
// Array para converter os meses em Abreviações
var meses = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
//  Essa função converte o formato da data YYYY/MM/DD para DD-MM, ajustando no card
function Date(ent: any) {
  var str = "";
  var index = parseInt(str.concat(ent[5], ent[6]));
  var formatação = ent[8] + ent[9] + " " + meses[index - 1];
  return formatação;
}

function generateColor(index: number) {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
export default function Home() {
  const cardArray = [
    { img: Cozinha, name: "Cozinha" },
    { img: Banheiro, name: "Banheiro" },
    { img: Sala, name: "Sala" },
    { img: Varanda, name: "Varanda" },
  ];
  const [estimate, setEstimate] = useState<EstimateData[]>([]);
  const [visit, setVisit] = useState<CardProps[]>([]);
  const [onPopUp, setOnPopUp] = useState(false);
  sessionStorage.clear();

  function handlePopUp() {
    setOnPopUp(!onPopUp);
  }

  function logout() {
    localStorage.clear();
    navigate("/login");
  }

  //escolhe o ambiente, cria estimate armazena id e armazena ambiente
  const chooseRoom = (roomName: string) => {
    sessionStorage.setItem("roomName", roomName);
    navigate("/selecionar-produto");
  };

  const navigate = useNavigate();
  const [clientDetails, setClientDetails] = useState({
    firstName: "",
    lastName: "",
    identificationNumber: "",
    identificationType: "",
  });
  const [token, setToken] = useState("");
  useEffect(() => {
    const token = localStorage.getItem("token");
    // if (!token) {
    //     console.log('Você não tem autorização')
    // }
    if (token) {
      setToken(token);
      ClientService.getDetails(token).then((res) => {
        console.log(res);
        localStorage.setItem("firstName", res?.data.client.firstName);
        localStorage.setItem("lastName", res?.data.client.lastName);
        localStorage.setItem(
          "identificationNumber",
          res?.data.client.identificationNumber
        );
        localStorage.setItem(
          "identificationType",
          res?.data.client.identificationType
        );
        localStorage.setItem("phoneAreaCode", res?.data.client.phoneNumber);
        localStorage.setItem("phoneNumber", res?.data.client.phoneNumber);
        localStorage.setItem("email", res?.data.client.email);
        setClientDetails(res?.data.client);
      });
    }
  }, [token]);

  //cria um estimate e salva seu id
  function navigateToSelectRoom() {
    navigate("/selecionar-ambiente");
  }

  // puxa todos os estimates do back
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      EstimateService.getClientEstimate(token).then((response) =>
        setEstimate(response?.data.Estimates)
      );
    }
  }, []);
  const estimateNameToLowerCase = (
    estimateName: string,
    searchValue: string
  ) => {
    estimateName = estimateName
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    searchValue = searchValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    if (estimateName.toLowerCase().includes(searchValue.toLowerCase())) {
      return true;
    }
  };
  const estimateDateToLowerCase = (
    estimateDate: string,
    searchValue: string
  ) => {
    estimateDate = estimateDate
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    searchValue = searchValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    if (estimateDate.toLowerCase().includes(searchValue.toLowerCase())) {
      return true;
    }
  };

  // todos os estimates que não forem de status "Pedido Realizado" estarão aqui
  const [search, setSearch] = useState("");
  var estimates: any[] = [];
  var orders: any[] = [];
  if (search) {
    estimates = estimate?.filter(function (estimate) {
      if (estimate?.status == "Em Edicao")
        return (
          estimate?.id == search ||
          estimateNameToLowerCase(estimate?.name, search) ||
          estimate?.price == search ||
          estimateDateToLowerCase(Date(estimate?.createdAt), search)
        );
    });
    sessionStorage.removeItem("editing summary"); // remove o modo de edição e o botão de voltar p/ resumo
    orders = estimate?.filter(function (estimate) {
      if (estimate?.status == "Pedido Realizado")
        return (
          estimate?.id == search ||
          estimateNameToLowerCase(estimate?.name, search) ||
          estimate?.price == search ||
          estimateDateToLowerCase(Date(estimate?.createdAt), search)
        );
    });
  } else {
    estimates = estimate?.filter(function (estimate) {
      return estimate?.status == "Em Edicao";
    });
    // se pedido já tiver sido realizado vai para array de pedidos
    orders = estimate?.filter(function (estimate) {
      return estimate?.status != "Em Edicao";
    });
  }
  // Essa função foi exportada para o componente OrderCard
  // const editEstimate = (id: string, name: string) => {
  //   console.log(id);
  //   sessionStorage.setItem("estimateId", id);
  //   sessionStorage.setItem("estimateName", name);
  //   navigate("/estimativa");
  // };
  return (
    <Body>
      <ZapierChatbot/>
      <Header>
        <LeftSection>
          <MobileLogo
            src={LogoMobile}
            alt={"logo mobile"}
            onClick={() => navigate("/")}
          />
          <LogoImg src={Logo} alt="logo" onClick={() => navigate("/")} />
          <Menu onClick={handlePopUp}>
            <GiHamburgerMenu style={{ fontSize: 32, color: "#808080" }} />
          </Menu>
          <SearchDiv>
            <Action>
              <RiSearchLine
                style={{
                  color: "#808080",
                  fontSize: "24px",
                  marginLeft: "15px",
                }}
              />
            </Action>
            <SearchInput
              /* type="search" */
              placeholder="Buscar"
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
            />
          </SearchDiv>
        </LeftSection>
        <RightBtns>
          <Action>
            <Texth6>Orçamentos</Texth6>
          </Action>
          <Action>
            <Texth6>Pedidos</Texth6>
          </Action>
          <Action>
            <Texth6>Agendamentos</Texth6>
          </Action>
          <Action>
            <Texth6>Recebimentos</Texth6>
          </Action>
          <ProfileButton onClick={handlePopUp}>
            <CgProfile style={{ fontSize: "36px", color: "#828282" }} />
          </ProfileButton>
        </RightBtns>
      </Header>
      {onPopUp && (
        <>
          <PopUp>
            <ButtonToggle onClick={handlePopUp}>
              <IoMdClose fontSize={24} style={{ marginTop: 16 }} />
            </ButtonToggle>
            <NavbarA>
              <PopUpText>Perfil</PopUpText>
            </NavbarA>
            <div style={{width:'100%', border:' 1px solid #f2f2f2'}}/>
            <NavbarA>
              <PopUpText>Ajuda</PopUpText>
            </NavbarA>
            <div style={{width:'100%', border:' 1px solid #f2f2f2'}}/>
            <NavbarA onClick={logout}>
              <PopUpText>Sair</PopUpText>
            </NavbarA>
          </PopUp>
        </>
      )}
      <MainContent>
        <Texth1>
          Qual ambiente deseja fazer obra, {localStorage.getItem("firstName")}?
        </Texth1>
        <CardContainer>
          {cardArray.map((btn, index) => (
            <Content1>
              <RoomCard
                key={index}
                img={btn.img}
                name={btn.name}
                onClick={() => chooseRoom(btn.name)}
              />
            </Content1>
          ))}
        </CardContainer>
        <Line></Line>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "40px",
          }}
        ></div>

        <BotSection>
          <Section>
            <SectionTitle>Orçamentos</SectionTitle>
            <SectionLeft>
              <ScrollContainer
                className="scroll-container"
                vertical={true}
                horizontal={false}
                activationDistance={4}
                hideScrollbars={false}
                style={{ minWidth: "350px" }}
              >
                {estimates?.length > 0 ? (
                  estimates?.map((estimateCard, index) => (
                    <OrderCard
                      clickEvent={[estimateCard.id, estimateCard.name]}
                      key={index}
                      id={estimateCard.id}
                      userName={estimateCard.name}
                      location={estimateCard.address}
                      date={Date(estimateCard.createdAt).slice(0, 6)}
                      price={toBRLPrice(Number(estimateCard.price))}
                      bgColor={"#00a0e1"}
                    />
                  ))
                ) : (
                  <InSecTxt>Seus orçamentos aparecerão aqui</InSecTxt>
                )}
              </ScrollContainer>

              <Btn
                style={{ position: "relative" }}
                onClick={() => navigateToSelectRoom()}
              >
                <BsPlusLg
                  style={{
                    position: "absolute",
                    left: "20px",
                    fontSize: "1rem",
                  }}
                />
                <BtnTxt style={{ paddingLeft: "10px" }}>Novo Orçamento</BtnTxt>
              </Btn>
            </SectionLeft>
          </Section>

          <Section>
            <SectionTitle>Pedidos</SectionTitle>
            <SectionMid>
              <ScrollContainer
                className="scroll-container"
                vertical={true}
                horizontal={false}
                activationDistance={4}
                hideScrollbars={false}
              >
                {orders?.length > 0 ? (
                  orders?.map((estimateCard, index) => (
                    <OrderCard
                      clickEvent={["", ""]}
                      key={index}
                      id={estimateCard.id}
                      userName={estimateCard.name}
                      location={estimateCard.address}
                      date={Date(estimateCard.createdAt).slice(0, 6)}
                      price={toBRLPrice(Number(estimateCard.price))}
                      bgColor={
                        estimateCard.status == "Em fabricação"
                          ? "#fa9917"
                          : estimateCard.status == "Preparando para a entrega"
                          ? "#33e1ff"
                          : estimateCard.status == "Pedido entregue"
                          ? "#2ac940"
                          : "#f3ca3e"
                      }
                    />
                  ))
                ) : (
                  <InSecTxt>Seus pedidos aparecerão aqui</InSecTxt>
                )}
              </ScrollContainer>
            </SectionMid>
          </Section>

          <Section>
            <SectionTitle>Visitas</SectionTitle>
            <SectionRight>
              <ScrollContainer
                className="scroll-container"
                vertical={true}
                horizontal={false}
                activationDistance={4}
                hideScrollbars={false}
              >
                {visit.length > 0 ? (
                  visit.map((visitCard, index) => (
                    <OrderCard
                      clickEvent={["", ""]}
                      key={index}
                      id={visitCard.id}
                      userName={visitCard.userName}
                      location={visitCard.location}
                      date={visitCard.date}
                      price={visitCard.price}
                      bgColor={generateColor(index)}
                    />
                  ))
                ) : (
                  <InSecTxt>Seus agendamentos aparecerão aqui</InSecTxt>
                )}
              </ScrollContainer>

              <Btn style={{ position: "relative" }}>
                <BsPlusLg
                  style={{
                    position: "absolute",
                    left: "20px",
                    fontSize: "1rem",
                  }}
                />
                <BtnTxt>Agendar Visita</BtnTxt>
              </Btn>
            </SectionRight>
          </Section>
        </BotSection>

        <MobileBotSection>
          <Section>
            <SectionTitle>Orçamentos</SectionTitle>
            <SectionLeft>
              <ScrollContainer
                className="scroll-container-mobile"
                vertical={false}
                horizontal={true}
                activationDistance={1}
                style={{
                  width: "100vw",
                  paddingLeft: "0px",
                  scrollSnapType: "x mandatory",
                  scrollPadding: "50px",
                }}
              >
                {estimates?.length > 0 ? (
                  estimates?.map((estimateCard, index) => (
                    <OrderCard
                      clickEvent={[estimateCard.id, estimateCard.name]}
                      key={index}
                      id={estimateCard.id}
                      userName={estimateCard.name}
                      location={estimateCard.address}
                      date={Date(estimateCard.createdAt).slice(0, 6)}
                      price={toBRLPrice(Number(estimateCard.price))}
                      bgColor={"#00a0e1"}
                    />
                  ))
                ) : (
                  <InSecTxt>Seus orçamentos aparecerão aqui</InSecTxt>
                )}
              </ScrollContainer>

              <Btn
                style={{ position: "relative" }}
                onClick={() => navigate("/selecionar-ambiente")}
              >
                <BsPlusLg
                  style={{
                    position: "absolute",
                    left: "20px",
                    fontSize: "1rem",
                  }}
                />
                <BtnTxt style={{ paddingLeft: "10px" }}>Agendar Visita</BtnTxt>
              </Btn>
            </SectionLeft>
          </Section>

          <Section>
            <SectionTitle>Pedidos</SectionTitle>
            <SectionMid>
              <ScrollContainer
                className="scroll-container-mobile"
                vertical={false}
                horizontal={true}
                activationDistance={1}
                style={{
                  width: "100vw",
                  paddingLeft: "0px",
                  scrollSnapType: "x mandatory",
                  scrollPadding: "50px",
                }}
              >
                {orders?.length > 0 ? (
                  orders?.map((estimateCard, index) => (
                    <OrderCard
                      clickEvent={["", ""]}
                      key={index}
                      id={estimateCard.id}
                      userName={estimateCard.name}
                      location={estimateCard.address}
                      date={Date(estimateCard.createdAt).slice(0, 6)}
                      price={toBRLPrice(Number(estimateCard.price))}
                      bgColor={
                        estimateCard.status == "Em fabricação"
                          ? "#fa9917"
                          : estimateCard.status == "Preparando para a entrega"
                          ? "#33e1ff"
                          : estimateCard.status == "Pedido entregue"
                          ? "#2ac940"
                          : "#f3ca3e"
                      }
                    />
                  ))
                ) : (
                  <InSecTxt>Seus pedidos aparecerão aqui</InSecTxt>
                )}
              </ScrollContainer>
            </SectionMid>
          </Section>

          <Section>
            <SectionTitle>Visitas</SectionTitle>
            <SectionRight>
              <ScrollContainer
                className="scroll-container-mobile"
                vertical={false}
                horizontal={true}
                activationDistance={1}
                style={{ width: "100vw", paddingLeft: "0px" }}
              >
                <InSecTxt>Seus agendamentos aparecerão aqui</InSecTxt>
              </ScrollContainer>
            </SectionRight>
          </Section>
        </MobileBotSection>

        <MobileBtn
          style={{ position: "relative" }}
          onClick={navigateToSelectRoom}
        >
          <BsPlusLg
            style={{ position: "absolute", left: "20px", fontSize: "1rem" }}
          />
          <BtnTxt>Novo orçamento</BtnTxt>
        </MobileBtn>
      </MainContent>
    </Body>
  );
}
