
import LoginCard from '../../components/LoginCard';
import LoginLeftSection from '../../components/LoginLeftSection';
import { Container, LoginRightSection } from './style';
import './style.tsx'

export default function Login() {
  
  return (
    <Container>

      <LoginLeftSection position='relative' />
      <LoginRightSection>
        <LoginCard position='absolute' />
      </LoginRightSection>
    </Container>

  );
}


