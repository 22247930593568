import React from "react";
import styled from "styled-components";
import { mobileVersion } from "./";

export const GoBackBtn = styled.button`
border:none;

background:none;
display:flex;
align-items:center;
justify-content:center;
color:#828282;
font-size:16px;
font-weight: 300;
*margin-top:50px;
&:hover{
    cursor:pointer;
    opacity:0.5;

}
@media(max-width:1200px){
   margin-right: 50vw;
};
@media(max-width:912px){
    display:none
};

`

export const MobileBtn = styled.button`
display:none;
border:none;
border-radius:45px;
height:40px;
width:40px;
justify-content:center;
align-items:center;
&:active{
opacity:0.5
}
@media(max-width:912px){
    display:flex;
    margin-left:4%
}
@media(min-width:700px){
    margin-left:16%
}

`
