
import Icon1 from '../../assets/Imagem.png';
import Icon2 from '../../assets/Imagem1.png';
import Icon3 from '../../assets/Imagem2.png';
import Icon4 from '../../assets/Imagem3.png';
import Icon5 from '../../assets/Imagem4.png';
import Icon6 from '../../assets/Imagem5.png';
import Icon7 from '../../assets/Imagem6.png';
import Icon8 from '../../assets/Imagem7.png';
import Icon9 from '../../assets/Imagem8.png';
import Icon10 from '../../assets/Imagem9.png';
import Icon11 from '../../assets/Imagem10.png';
import Icon12 from '../../assets/Imagem11.png';
import Icon13 from '../../assets/Imagem12.png';
import Icon14 from '../../assets/Imagem13.png';
import Icon15 from '../../assets/Imagem14.png';
import Icon16 from '../../assets/Imagem15.png';
import Icon17 from '../../assets/Imagem16.png';
import Icon18 from '../../assets/Imagem17.png';
import Icon19 from '../../assets/Imagem18.png';
import Icon20 from '../../assets/Imagem19.png';


export const IconArray = [
    Icon1, Icon2, Icon3, Icon4, Icon5,
    Icon6, Icon7, Icon8, Icon9, Icon10,
    Icon11, Icon12, Icon13, Icon14, Icon15,
    Icon16, Icon17, Icon18, Icon19, Icon20
]
const black = 'black';
const white = 'white';

export const colors = [
//     white, black,
//     black, black,
//     white, black,
//     black, black,
//     black,black,
//     white, black,
//     black,black,
//     black, black,
//     black, white,
//     white, white
// 
]