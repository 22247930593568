import {
  Page,
  Title,
  Footer,
  AddressContainer,
} from "./style";
import { Link } from "react-router-dom";
import BackBtn from "../../components/BackBtn";
import PageHeader from "../../components/Header";
import AddressForm from "../../components/AddressForm";
import { PageMobile, TextContainer, TextInfo } from "../Identify/style";
import { useEffect, useState } from "react";
import ClientAddressService from "../../services/ClientAddressService";
import EstimateService from "../../services/EstimateService";
import React from "react";

interface AddressInfo {
  id: string,
  substring: string;
  zipCode: string;
  streetName: string;
  streetNumber: string;
  additionalInfo: string;
  ClientIdentificationNumber: string;
  addressName: string;
}

function AddressRegistration(): JSX.Element {
  const [address, setAddress] = useState<AddressInfo[]>([]);
  const estimateId = sessionStorage.getItem("estimateId");
  const [estimate, setEstimate] = useState<any>({});

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      ClientAddressService.getClientAddress(token).then((response) =>
        setAddress(response?.data.address)
      );
    }
  }, []);

  // React.useEffect(() => {
  //   if (estimateId) {
  //     EstimateService.fetchEstimate(estimateId).then((res) => {
  //       setEstimate(res?.data);
  //     });
  //   }
  // }, [estimateId]);

  // var endereco: string | any[] = [];
  // // Aqui é gerado um array com os endereços do cliente, onde o id esperado é/foi o selecionado pelo usuário
  // endereco = address?.filter(function (address) {
  //   const id = estimate.estimate?.ClientAddressId
  //   const clientId = localStorage.getItem("identificationNumber");
  //   return (address.id == id) && (address.ClientIdentificationNumber == clientId);
  // });


  return (
    <>
      <PageHeader display="none" />
      <Page onAdvance={false}>
        <div>
          <Title>Endereço da obra</Title>

          <AddressContainer>
          <AddressForm position="relative" addressId = {String(address.length+1)} newAddress={true}/>
          </AddressContainer>

        </div>

        <Footer>
          <Link to="/finalize-compra">
            <BackBtn />
          </Link>
        </Footer>
      </Page>

      {/* Versão mobile */}
      <PageMobile style={{width:'100vw', height: '100vh'}}>
        <TextContainer>
          <TextInfo>Endereço da Obra</TextInfo>
        </TextContainer>
        <div style={{ display: 'flex', width: '100vw'}}>
            <AddressForm position="relative" addressId = {String(address.length+1)} newAddress={true}/>
        </div>
      </PageMobile>
    </>
  );
}

export default AddressRegistration;
