import styled from 'styled-components';


interface benchDetailsProps {
    onAdvance?: boolean;
}
export const Page = styled.section<benchDetailsProps>`
display: flex;
flex-direction: column;
height: auto;
padding: 6vw 3.47vw 0 3.47vw;
h2{

font-weight: 700;
font-size:1.25rem;
color:#828282;
margin-bottom:2.2vh;
}
@media(min-width:1200), (max-width:1600px){
    padding-left:5%;
    padding-right:5%;
}
@media(max-width: 912px){
    background-color: #ffffff;
    display: none;
     h2{
        margin-left: 5vw;
    }
}
`

export const TopWrapper = styled.div`
display:flex;
width: 92vw;
flex-direction:row;
margin-bottom:5.5vh;
gap:1.73vw;
*border: solid 2px green;
scroll-snap-type: x mandatory;
scroll-padding: 50px;
@media (max-width:1200px){
    flex-direction:row; 
    overflow-x: scroll;
    align-self:center;
    padding-bottom: 25px;
    margin-bottom: 4vh; 
    align-self:center;
    padding-bottom: 25px;
    margin-bottom: 4vh;
}

`
export const BottomWrapper = styled.div`
display:flex;
flex-direction:row;
margin-bottom:5.5vh;
width: 100vw;
scroll-snap-type: x mandatory;
scroll-padding: 50px;
gap:1.73vw;
@media (max-width:1200px){
    flex-direction:row; 
    overflow-x: scroll;
    align-self:center;
    padding-bottom: 25px;
    margin-bottom: 4vh;
    align-self:center;
}
`
export const BottomCardsWrapper = styled.div`
display:flex;
flex-direction:row;
margin-bottom:5.5vh;
width: 100%;

gap:1.73vw;
@media (max-width:1200px){
    flex-direction:row; 
    overflow-x: scroll;
    align-self:center;
    padding-bottom: 25px;
    margin-bottom: 4vh;
    flex-direction:row; 
    overflow-x: scroll;
    align-self:center;
    padding-bottom: 25px;
    margin-bottom: 4vh;
}
`
export const MobileContainer = styled.div`
display: none;
@media(max-width:912px){
    span{
        display: none;
    }
    display:flex;
    flex-direction:column;
    align-items:center;
    background:#2566C8;
    span{
        padding-top:5.5%;
        display:flex;
        
        color:white;
        font-size:20px;
        font-weight: 700;
    
    }
    h2{
        margin-left: 6vw;
    }
}
`