import internal from "stream";
import styled from "styled-components";
import { Props } from ".";
export const RoomBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    width: 22vw;
    border: none;
    background-color: white;
    box-shadow: 0 8px 20px 0 rgba(142, 142, 142, 0.2);
    border-radius: 8px;
    h3 {
        color: #828282;
        
        font-size: 16px;
    }
    &:hover {
        opacity: 0.5;
    }
    div {
        display: none;
    }
    @media (max-width: 912px) {
        height: 4.464vh;
        width: 66.5vw;
        justify-content: space-between;
        padding-right: 3.62vw;
        padding-left: 2.23vw;
        box-shadow: 0 0 10px 0 rgba(149, 149, 149, 0.3);
        h3 {
            font-size: 16px;
        }
        div {
            display: flex;
            color: #2566c8;
        }
    }
    @media (max-width: 700px) {
        width: 91vw;
        h3 {
            font-size: 12px;
        }
    }
`;

interface ImgProps {
    opacity: number;
}

export const Img = styled.img<ImgProps>`
    width: 5vw;
    height: 4.777777778vh;
    object-fit: cover;
    border-radius: 8px;
    opacity: ${(props) => props.opacity};
`;

interface MaterialProps {
    activeColor: string;
    titleColor: string;
    hoverOpacity: number;
}

export const MaterialBtn = styled.button<MaterialProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 7vh;
    width: 22vw;
    border: none;
    background-color: ${(props) => props.activeColor};
    box-shadow: 0 8px 20px 0 rgba(142, 142, 142, 0.2);
    border-radius: 8px;
    padding-left: 1.38vw;
    padding-right: 10px;
    h3 {
        color: ${(props) => props.titleColor};
        
        font-size: 16px;
        display: contents;
    }
    &:hover {
        opacity: ${(props) => props.hoverOpacity};
    }

    @media (max-width: 912px) {
        /* height:10px;
  width:41vw;
  h3{
    font-size:12px;
} */
        display: none;
    }
`;
export const MobileBtn = styled.div<Props>`
    p {
        display: none;
    }
    @media (max-width: 912px) {
        &:active {
            opacity: 0.5;
        }
        display: flex;
        background: white;
        height: 6.7vh;
        width: 41vw;
        background-image: url(${(props) => props.url});
        opacity: ${(props) => props.opacity};
        color: ${(props) => props.titleColor};
        background-color: ${(props) => props.activeColor};
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
        p {
            display: flex;
            margin-top: 10px;
            margin-left: 10px;
            font-size: 12px;
            font-weight: 700;
            
        }
    
    }
`;

//passar essas props para essa div tambem para ela ficar daquele jeito
export const MobileName = styled.div<MaterialProps>`
  display: none;
  @media (max-width: 912px) {
    display: flex;
    background-color: ${(props) => props.activeColor};
    color: ${(props) => props.titleColor};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.20 );
    border-radius:5px;
    padding: 4px;
    width: 124px;
    &:hover {
    opacity: ${(props) => props.hoverOpacity};

    }

  } 
  @media (max-width: 560px) {
    width: 100px;
  }
  @media (max-width: 380px) {
    width: 72px;
  }
  p {
    font-size: 10px;
    margin-right: 8px;
    @media (max-width: 380px) {
        font-size: 8px;
    }
  }

`;
