import * as React from 'react';
import { LateralLogo, LateralLogoImg } from './style';
import './style.tsx'
// import { Image } from './style';
import Logo from '../../assets/Logo.png'

interface LogoProps{
    position:string
}

export default function LoginLeftSection({ position }: LogoProps) {
    return (
        <LateralLogo position={position}>
            <LateralLogoImg src={Logo}></LateralLogoImg>
        </LateralLogo>
    )
}

