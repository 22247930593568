import styled from "styled-components";
interface resetProps {
    position: string
}
export const Container = styled.div<resetProps>`
display:flex;
align-items: center;
flex-direction:column;
height: 340px;
width: 420px;
position:${props=>props.position};
border-radius: 10px;
background-color: #FFF;
box-shadow: 0 2px 20px 0 rgba(149,149,149,0.3);
  @media(max-width:912px){
    width:100vw;
    margin-left:0;
    position:${props => props.position};
    padding-top: 60px;
    top:30%;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    height:70vh;
  }
`
export const TextDiv = styled.div`
display:flex;
flex-direction:column;
align-items:start;
width:20rem;
margin-top:55px;
`

export const H2 = styled.h2`

font-weight: 700;
font-size:24px;
color: #2566C8;
@media (max-width: 912px) {
  position: relative;
  right: 3%;
}

`
export const H3 = styled.h3`

font-weight: 500;
font-size:14px;
color:#D0D0D0;
padding-top:10px;
@media (max-width: 912px) {
  position: relative;
  right: 3%;
}


`
export const Form = styled.form`
height:40px;
width:20rem;
border-radius:5px;
display:flex;
flex-direction: column;
margin-top:60px;
gap:10px;
`
export const BtnsDiv = styled.div`
display:flex;
flex-direction:row;
align-items:center;
width:20rem;
margin-top:20px;
justify-content: space-between;
margin-left: -20px;
`
export const CancelBtn = styled.button`
border:none;
background:none;

font-size: 14px;
margin-left:25px;
color: #2566C8;
width: 84px;
&:hover{
cursor:pointer;
opacity:0.5;
}
`
export const ResetBtn = styled.button`
height: 40px;
width: 190px;
border-radius: 8px;
background: linear-gradient(271.82deg, #2566C8 0%, #4485E8 100%);
box-shadow: 0 8px 30px 0 rgba(0,44,113,0.25);

font-weight: 500;
font-size:17px;
color:#FFF;
border:none;
&:hover{
    opacity:0.5;
    cursor:pointer;
}
`
