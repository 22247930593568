import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  DisabledBtn,
  Footer,
  Icon,
  Main,
  Page,
  Parede0wrapper,
  ProductBox,
  SbmtBtn,
  Title,
  TitleInput,
  BoxU,
  WallWrapper0,
  WallWrapper1,
  CooktopWrapper0,
  CooktopWrapper1,
} from "../BenchU/style";
import BenchIcon from "../../assets/DispUR1.svg";
import Marked from "../../assets/Marked.svg";
import NotMarked from "../../assets/NotMarked.svg";
import { Container, Div, Input } from "../../components/DetailsInput/style";
import BackBtn from "../../components/BackBtn";
import { MobileContainer } from "./style";
import { StyleErrorMessage } from "../../components/StyleErrorMessage";
import { StepCounter } from "../../components/StepCounter";

export default function BenchU() {
  //validação
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ mode: "onBlur" });
  const editingSummary = sessionStorage.getItem("editing summary") == 'true' ? true: false;;

  const onSubmit = () => {
    sendToSessionStorage();
    editingSummary ? navigate("/resumo") : navigate("/cuba");
  };

  //checks
  const [cooktop0, setCooktop0] = useState<boolean>(false);
  const [cooktop1, setCooktop1] = useState<boolean>(false);
  const [wall0, setWall0] = useState<boolean>(false);
  const [wall1, setWall1] = useState<boolean>(false);
  const [wall2, setWall2] = useState<boolean>(false);

  // const [advance, setAdvance] = useState<boolean>(false);

  //inputs
  const [controller0, setController0] = useState<any>();
  const [controller1, setController1] = useState<any>();
  const [controller2, setController2] = useState<any>();
  const [controller3, setController3] = useState<any>();
  const [controller4, setController4] = useState<any>();
  const [controller5, setController5] = useState<any>();
  const [controller6, setController6] = useState<any>();

  console.log("controller0 = " + controller0);
  console.log("controller1 =" + controller1);
  console.log("controller2 = " + controller2);
  console.log("controller3 = " + controller3);
  console.log("controller4 =" + controller4);
  console.log("controller5 = " + controller5);
  console.log("controller6 = " + controller6);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  function sendToSessionStorage() {
    sessionStorage.setItem("cooktop-2", cooktop0 ? "Sim" : "Não");
    sessionStorage.setItem("cooktop-1", cooktop1 ? "Sim" : "Não");
    sessionStorage.setItem("wallLeft", wall0 ? "Sim" : "Não");
    sessionStorage.setItem("wallCenter", wall2 ? "Sim" : "Não");
    sessionStorage.setItem("wallRight", wall1 ? "Sim" : "Não");
    sessionStorage.setItem("amount", controller0 ? controller0.toString() : "");
    sessionStorage.setItem(
      "wetBaseEstimateWidth",
      controller1 ? controller1.toString() : ""
    );
    sessionStorage.setItem(
      "dryBaseEstimateWidth",
      controller2 ? controller2.toString() : ""
    );
    sessionStorage.setItem(
      "dryBaseEstimateWidth1",
      controller3 ? controller3.toString() : ""
    );
    sessionStorage.setItem(
      "wetBaseEstimateDepth",
      controller4 ? controller4.toString() : ""
    );
    sessionStorage.setItem(
      "dryBaseEstimateDepth",
      controller5 ? controller5.toString() : ""
    );
    sessionStorage.setItem(
      "dryBaseEstimateDepth1",
      controller6 ? controller6.toString() : ""
    );
  }

  const amount = sessionStorage.getItem("amount");
  const wetBaseEstimateWidth_0 = sessionStorage.getItem(
    "wetBaseEstimateWidth"
  );
  const wetBaseEstimateDepth_0 = sessionStorage.getItem(
    "wetBaseEstimateDepth"
  );
  const dryBaseEstimateDepth_1 = sessionStorage.getItem(
    "dryBaseEstimateDepth"
  );
  const dryBaseEstimateDepth_2 = sessionStorage.getItem(
    "dryBaseEstimateDepth1"
  );
  const dryBaseEstimateWidth_1 = sessionStorage.getItem(
    "dryBaseEstimateWidth"
  );
  const dryBaseEstimateWidth_2 = sessionStorage.getItem(
    "dryBaseEstimateWidth1"
  );
  const cooktop_0 = sessionStorage.getItem("cooktop-2");
  const cooktop_1 = sessionStorage.getItem("cooktop-1");
  const wall_Left = sessionStorage.getItem("wallLeft");
  const wall_Right = sessionStorage.getItem("wallRight");
  const wall_Center = sessionStorage.getItem("wallCenter");

  console.log(cooktop_0);

  useEffect(() => {
    if (amount) {
      {
        setController0(Number(amount));
        setValue("quantidade", amount);
      }
    }
    if (wetBaseEstimateWidth_0) {
      {
        setController1(Number(wetBaseEstimateWidth_0));
        setValue("largura1", wetBaseEstimateWidth_0);
      }
    }
    if (wetBaseEstimateDepth_0) {
      {
        setController4(Number(wetBaseEstimateDepth_0));
        setValue("profundidade1", wetBaseEstimateDepth_0);
      }
    }
    if (dryBaseEstimateDepth_1) {
      {
        setController5(Number(dryBaseEstimateDepth_1));
        setValue("profundidade2", dryBaseEstimateDepth_1);
      }
    }
    if (dryBaseEstimateDepth_2) {
      {
        setController6(Number(dryBaseEstimateDepth_2));
        setValue("profundidade3", dryBaseEstimateDepth_2);
      }
    }
    if (dryBaseEstimateWidth_2) {
      {
        setController3(Number(dryBaseEstimateWidth_2));
        setValue("largura3", dryBaseEstimateWidth_2);
      }
    }
    if (dryBaseEstimateWidth_1) {
      {
        setController2(Number(dryBaseEstimateWidth_1));
        setValue("largura2", dryBaseEstimateWidth_1);
      }
    }
    if (cooktop_0 == "Sim") {
      setCooktop0(Boolean(cooktop_0));
    }
    if (cooktop_1 == "Sim") {
      setCooktop1(Boolean(cooktop_1));
    }
    if (wall_Left == "Sim") {
      setWall0(Boolean(wall_Left));
    }
    if (wall_Right == "Sim") {
      setWall1(Boolean(wall_Right));
    }
    if (wall_Center == "Sim") {
      setWall2(Boolean(wall_Center));
    }
  }, []);

  // const inputValue = [
  //     controller0,
  //     controller1,
  //     controller2,
  //     controller3,
  //     controller4,
  //     controller5,
  //     controller6
  // ];
  // const setController = [
  //     setController0,
  //     setController1,
  //     setController2,
  //     setController3,
  //     setController4,
  //     setController5,
  //     setController6
  // ];

  return (
    <>
      <Page>
        <StepCounter step={2} />
        <Title>Detalhes Bancada</Title>
        <ProductBox>
          <img
            src={BenchIcon}
            alt="Imagem da bancada"
            style={{ width: "420px" }}
          />
          <CooktopWrapper0 onClick={() => setCooktop0(!cooktop0)}>
            <Icon src={cooktop0 ? Marked : NotMarked} />
            <label>Cooktop</label>
          </CooktopWrapper0>
          <CooktopWrapper1 onClick={() => setCooktop1(!cooktop1)}>
            <Icon src={cooktop1 ? Marked : NotMarked} />
            <label>Cooktop</label>
          </CooktopWrapper1>
          <WallWrapper0 onClick={() => setWall0(!wall0)}>
            <Icon src={wall0 ? Marked : NotMarked} />
            <label>Parede</label>
          </WallWrapper0>
          <WallWrapper1 onClick={() => setWall1(!wall1)}>
            <Icon src={wall1 ? Marked : NotMarked} />
            <label>Parede</label>
          </WallWrapper1>
          <Parede0wrapper
            onClick={() => setWall2(!wall2)}
            style={{ bottom: 80, left: "49%" }}
          >
            <Icon src={wall2 ? Marked : NotMarked} />
            <label>Parede</label>
          </Parede0wrapper>
        </ProductBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <BoxU>
            <Box>
              <Div>
                <TitleInput>Quantidade</TitleInput>
                <Container largura={30}>
                  <Input
                    type="number"
                    {...register("quantidade", {
                      required: true,
                      max: 2300,
                      min: 1,
                    })}
                    placeholder={"Quantidade"}
                    value={controller0}
                    onChange={(event: any) => {
                      setController0(event.target.value);
                      setValue("quantidade", event.target.value);
                    }}
                  />
                  <h6>unidade(s)</h6>
                </Container>
                {errors.quantidade?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 2300 unid</p>
                )}
                {errors.quantidade?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 1 unid</p>
                )}
                {errors.quantidade?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Div>
            </Box>
            <Box>
              <Div>
                <TitleInput>Largura Bancada Molhada</TitleInput>
                <Container largura={30}>
                  <Input
                    type="number"
                    {...register("largura1", {
                      required: true,
                      max: 2300,
                      min: 650,
                    })}
                    placeholder={"Largura"}
                    value={controller1}
                    onChange={(event: any) => {
                      setController1(event.target.value);
                      setValue("largura1", event.target.value);
                    }}
                  />
                  <h6>milímetros</h6>
                </Container>
                {errors.largura1?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 2300 milímetros</p>
                )}
                {errors.largura1?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 650 milímetros</p>
                )}
                {errors.largura1?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Div>
              <Div>
                <TitleInput>Largura Bancada Seca 1</TitleInput>
                <Container largura={30}>
                  <Input
                    type="number"
                    {...register("largura2", {
                      required: true,
                      max: 2300,
                      min: 300,
                    })}
                    placeholder={"Largura"}
                    value={controller2}
                    onChange={(event: any) => {
                      setController2(event.target.value);
                      setValue("largura2", event.target.value);
                    }}
                  />
                  <h6>milímetros</h6>
                </Container>
                {errors.largura2?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 2300 milímetros</p>
                )}
                {errors.largura2?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 300 milímetros</p>
                )}
                {errors.largura2?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Div>
              <Div>
                <TitleInput>Largura Bancada Seca 2</TitleInput>
                <Container largura={30}>
                  <Input
                    type="number"
                    {...register("largura3", {
                      required: true,
                      max: 2300,
                      min: 300,
                    })}
                    placeholder={"Largura"}
                    value={controller3}
                    onChange={(event: any) => {
                      setController3(event.target.value);
                      setValue("largura3", event.target.value);
                    }}
                  />
                  <h6>milímetros</h6>
                </Container>
                {errors.largura3?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 2300 milímetros</p>
                )}
                {errors.largura3?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 300 milímetros</p>
                )}
                {errors.largura3?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Div>
            </Box>

            <Box>
              <Div>
                <TitleInput>Profundidade Bancada Molhada</TitleInput>
                <Container largura={30}>
                  <Input
                    type="number"
                    {...register("profundidade1", {
                      required: true,
                      max: 1000,
                      min: 500,
                    })}
                    placeholder={"Profundidade"}
                    value={controller4}
                    onChange={(event: any) => {
                      setController4(event.target.value);
                      setValue("profundidade1", event.target.value);
                    }}
                  />
                  <h6>milímetros</h6>
                </Container>
                {errors.profundidade1?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 1000 milímetros</p>
                )}
                {errors.profundidade1?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 500 milímetros</p>
                )}
                {errors.profundidade1?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Div>

              <Div>
                <TitleInput>Profundidade Bancada Seca 1</TitleInput>
                <Container largura={30}>
                  <Input
                    type="number"
                    {...register("profundidade2", {
                      required: true,
                      max: 1000,
                      min: 500,
                    })}
                    placeholder={"Profundidade"}
                    value={controller5}
                    onChange={(event: any) => {
                      setController5(event.target.value);
                      setValue("profundidade2", event.target.value);
                    }}
                  />
                  <h6>milímetros</h6>
                </Container>
                {errors.profundidade2?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 1000 milímetros</p>
                )}
                {errors.profundidade2?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 500 milímetros</p>
                )}
                {errors.profundidade2?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Div>

              <Div>
                <TitleInput>Profundidade Bancada Seca 2</TitleInput>
                <Container largura={30}>
                  <Input
                    type="number"
                    {...register("profundidade3", {
                      required: true,
                      max: 1000,
                      min: 500,
                    })}
                    placeholder={"Profundidade"}
                    value={controller6}
                    onChange={(event: any) => {
                      setController6(event.target.value);
                      setValue("profundidade3", event.target.value);
                    }}
                  />
                  <h6>milímetros</h6>
                </Container>
                {errors.profundidade3?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 1000 milímetros</p>
                )}
                {errors.profundidade3?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 500 milímetros</p>
                )}
                {errors.profundidade3?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Div>
            </Box>
          </BoxU>

          <Footer>
            <Link to="/selecionar-bancada">
              <BackBtn />
            </Link>
            {
              // eslint-disable-next-line no-mixed-operators
              controller0 &&
              controller1 &&
              controller2 &&
              controller3 &&
              controller4 &&
              controller5 &&
              controller6 ? (
                <div>
                  {editingSummary ? (
                    <SbmtBtn>Voltar para o Resumo</SbmtBtn>
                  ) : (
                    <SbmtBtn>Avançar</SbmtBtn>
                  )}
                </div>
              ) : (
                <DisabledBtn style={{ textAlign: "center" }}>
                  {editingSummary ? "Voltar para o Resumo" : "Avançar"}
                </DisabledBtn>
              )
            }
          </Footer>
        </form>
      </Page>

      {/* Versão Mobile */}

      <MobileContainer>
        <span>Orçamento</span>
        <Main style={{ position: "relative", height: "100%" }}>
          <h1 className="Title">Bancada em U</h1>
          <BackBtn
            onClick={() => navigate("/selecionar-bancada")}
            isMobile={true}
            style={{ position: "absolute", top: 15, left: 0 }}
          />

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <StepCounter step={2} />
          </div>

          <Title>Detalhes Bancada</Title>
          <ProductBox>
            <img
              src={BenchIcon}
              alt="Imagem da bancada"
              style={{ maxWidth: "446.31px" }}
            />
            <CooktopWrapper0 onClick={() => setCooktop0(!cooktop0)}>
              <Icon src={cooktop0 ? Marked : NotMarked} />
              <label>Cooktop</label>
            </CooktopWrapper0>
            <CooktopWrapper1 onClick={() => setCooktop1(!cooktop1)}>
              <Icon src={cooktop1 ? Marked : NotMarked} />
              <label>Cooktop</label>
            </CooktopWrapper1>
            <WallWrapper0 onClick={() => setWall0(!wall0)}>
              <Icon src={wall0 ? Marked : NotMarked} />
              <label>Parede</label>
            </WallWrapper0>
            <WallWrapper1 onClick={() => setWall1(!wall1)}>
              <Icon src={wall1 ? Marked : NotMarked} />
              <label>Parede</label>
            </WallWrapper1>
            <Parede0wrapper
              onClick={() => setWall2(!wall2)}
              style={{ bottom: 80, left: "49%" }}
            >
              <Icon src={wall2 ? Marked : NotMarked} />
              <label>Parede</label>
            </Parede0wrapper>
          </ProductBox>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ backgroundColor: "#fff", paddingLeft: "5px" }}
          >
            <BoxU style={{ gap: "0" }}>
              <Box>
                <Div>
                  <TitleInput>Quantidade</TitleInput>
                  <Container largura={30}>
                    <Input
                      type="number"
                      {...register("quantidade", {
                        required: true,
                        max: 2300,
                        min: 1,
                      })}
                      placeholder={"Quantidade"}
                      value={controller0}
                      onChange={(event: any) => {
                        setController0(event.target.value);
                        setValue("quantidade", event.target.value);
                      }}
                    />
                    <h6>unidade(s)</h6>
                  </Container>
                </Div>
                {errors.quantidade?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 2300 unid</p>
                )}
                {errors.quantidade?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 1 unid</p>
                )}
                {errors.quantidade?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Box>
              <Box>
                <Div>
                  <TitleInput>Largura Bancada Molhada</TitleInput>
                  <Container largura={30}>
                    <Input
                      type="number"
                      {...register("largura1", {
                        required: true,
                        max: 2300,
                        min: 650,
                      })}
                      placeholder={
                        windowSize[0] <= 1000
                          ? "Largura Bancada Molhada"
                          : "Largura"
                      }
                      value={controller1}
                      onChange={(event: any) => {
                        setController1(event.target.value);
                        setValue("largura1", event.target.value);
                      }}
                    />
                    <h6>milímetros</h6>
                  </Container>
                </Div>
                {errors.largura1?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 2300 milímetros</p>
                )}
                {errors.largura1?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 650 milímetros</p>
                )}
                {errors.largura1?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
                <Div>
                  <TitleInput>Largura Bancada Seca 1</TitleInput>
                  <Container largura={30}>
                    <Input
                      type="number"
                      {...register("largura2", {
                        required: true,
                        max: 2300,
                        min: 300,
                      })}
                      placeholder={
                        windowSize[0] <= 1000
                          ? "Largura Bancada Seca 1"
                          : "Largura"
                      }
                      value={controller2}
                      onChange={(event: any) => {
                        setController2(event.target.value);
                        setValue("largura2", event.target.value);
                      }}
                    />
                    <h6>milímetros</h6>
                  </Container>
                </Div>
                {errors.largura2?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 2300 milímetros</p>
                )}
                {errors.largura2?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 300 milímetros</p>
                )}
                {errors.largura2?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
                <Div>
                  <TitleInput>Largura Bancada Seca 2</TitleInput>
                  <Container largura={30}>
                    <Input
                      type="number"
                      {...register("largura3", {
                        required: true,
                        max: 2300,
                        min: 300,
                      })}
                      placeholder={
                        windowSize[0] <= 1000
                          ? "Largura Bancada Seca 2"
                          : "Largura"
                      }
                      value={controller3}
                      onChange={(event: any) => {
                        setController3(event.target.value);
                        setValue("largura3", event.target.value);
                      }}
                    />
                    <h6>milímetros</h6>
                  </Container>
                </Div>
                {errors.largura3?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 2300 milímetros</p>
                )}
                {errors.largura3?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 300 milímetros</p>
                )}
                {errors.largura3?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Box>

              <Box>
                <Div>
                  <TitleInput>Profundidade Bancada Molhada</TitleInput>
                  <Container largura={30}>
                    <Input
                      type="number"
                      {...register("profundidade1", {
                        required: true,
                        max: 1000,
                        min: 500,
                      })}
                      placeholder={
                        windowSize[0] <= 1000
                          ? "Profundidade Bancada Molhada"
                          : "Profundidade"
                      }
                      value={controller4}
                      onChange={(event: any) => {
                        setController4(event.target.value);
                        setValue("profundidade1", event.target.value);
                      }}
                    />
                    <h6>milímetros</h6>
                  </Container>
                </Div>
                {errors.profundidade1?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 1000 milímetros</p>
                )}
                {errors.profundidade1?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 500 milímetros</p>
                )}
                {errors.profundidade1?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
                <Div>
                  <TitleInput>Profundidade Bancada Seca 1</TitleInput>
                  <Container largura={30}>
                    <Input
                      type="number"
                      {...register("profundidade2", {
                        required: true,
                        max: 1000,
                        min: 500,
                      })}
                      placeholder={
                        windowSize[0] <= 1000
                          ? "Profundidade Bancada Seca 1"
                          : "Profundidade"
                      }
                      value={controller5}
                      onChange={(event: any) => {
                        setController5(event.target.value);
                        setValue("profundidade2", event.target.value);
                      }}
                    />
                    <h6>milímetros</h6>
                  </Container>
                </Div>
                {errors.profundidade2?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 1000 milímetros</p>
                )}
                {errors.profundidade2?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 500 milímetros</p>
                )}
                {errors.profundidade2?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
                <Div>
                  <TitleInput>Profundidade Bancada Seca 2</TitleInput>
                  <Container largura={30}>
                    <Input
                      type="number"
                      {...register("profundidade3", {
                        required: true,
                        max: 1000,
                        min: 500,
                      })}
                      placeholder={
                        windowSize[0] <= 1000
                          ? "Profundidade Bancada Seca 2"
                          : "Profundidade"
                      }
                      value={controller6}
                      onChange={(event: any) => {
                        setController6(event.target.value);
                        setValue("profundidade3", event.target.value);
                      }}
                    />
                    <h6>milímetros</h6>
                  </Container>
                </Div>
                {errors.profundidade3?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 1000 milímetros</p>
                )}
                {errors.profundidade3?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 500 milímetros</p>
                )}
                {errors.profundidade3?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </Box>
            </BoxU>

            <Footer>
              <Link to="/selecionar-bancada">
                <BackBtn />
              </Link>
              {
                // eslint-disable-next-line no-mixed-operators
                controller0 &&
                controller1 &&
                controller2 &&
                controller3 &&
                controller4 &&
                controller5 &&
                controller6 ? (
                  <div>
                    {editingSummary ? (
                      <SbmtBtn>Voltar para o Resumo</SbmtBtn>
                    ) : (
                      <SbmtBtn>Avançar</SbmtBtn>
                    )}
                  </div>
                ) : (
                  <DisabledBtn>
                    {editingSummary ? "Voltar para o Resumo" : "Avançar"}
                  </DisabledBtn>
                )
              }
            </Footer>
          </form>
        </Main>
      </MobileContainer>
    </>
  );
}