import { TailSpin } from 'react-loader-spinner';
import { Container } from './styles';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';


export default function Loader() {
  return (
    <Container>
        <TailSpin
        height="48"
        width="48"
        color="#2566c8"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        />
    </Container>
  );
}

