import React, { useEffect, useState } from "react";
import { Content, Box, ArrangementName, MarkedIcon } from "./style";


interface CtrTopCardProps {
  onClick: any,
  title: string,
  src: string,
  selected: string,
  style?: any
}

export default function CounterTopCard({ onClick, title, src, selected, style }: CtrTopCardProps) {
  const [CountertopType, setCountertopType] = useState(sessionStorage.getItem('countertopType'));
  const [screenSize, setScreenSize] = useState(window.screen.width);

  useEffect(() => {
    const handleCountertopType = () => {
      setCountertopType(CountertopType);
      setScreenSize(window.screen.width);
    };
    handleCountertopType();
  }, []);

  return (
    <Content>
      <Box onClick={onClick} style={(CountertopType == 'Bancada em U') && title.includes('Disposição') &&
        screenSize > 912 ? { width: '21vw' } : {}}>
        <ArrangementName>{title}</ArrangementName>
        <img style={style} src={src} />
        <MarkedIcon src={selected} />
      </Box>
    </Content>
  );
}