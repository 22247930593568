import styled from "styled-components";

export const Content =  styled.div`
display: inline-block;
justify-content: space-between;
@media (max-width: 912px) {
    *border: solid 2px red;
    padding: 0;
    margin: 0;
    width: 43vw;
    gap: 10px;
    position: relative;
    top: 15vh;
}
`

export const Box = styled.div`
position:relative;
background-color:#FFF;
display:flex;
width:18vw;
height:6.7092651757188495vh;
box-shadow: 0 1.24vh 2.196vw 0 rgba(54,54,54,0.2);
border-radius:1.24vh;
margin-right:1.83vw;
overflow:hidden;
@media (max-width: 912px) {
    *border: solid 2px green;
    width: 43vw;
    height: 8vh;
    box-shadow: -0.1vh 1.24vh 4.196vw 0.2vh rgba(54,54,54,0.2);


}


`
export const ArrangementName = styled.h6`
color: #828282;

font-size: 0.8vw;
font-weight: 700;
position: absolute;
top: 50%;
left: 50%;
margin-right: -50%;
transform: translate(-50%, -50%);
@media (max-width: 912px) {
    display: flex;
    width: 35vw;
    *border: solid 2px yellow;
    padding-left: 15px;
    font-size: 0.78rem;
    top: 60%;
    left: 45%;
    
}
`
export const MarkedIcon = styled.img`
position:absolute;
top:0.625rem;
right:0.625rem;
height:24px;
width:24px;
border:none;
position: absolute;           
top: 50%;                         
transform: translate(0, -50%);
@media (max-width: 912px) {
    top: 30%;
}

`
