import React from "react";
import { Content, Box, ArrangementName, MarkedIcon } from "./style";


interface AddressCardProps {
onClick:any,
title:string,
selected: string,
}

export default function AddressCard ({onClick,title, selected}:AddressCardProps) {
    return (
    <Content>
        <Box onClick={onClick}>
            <ArrangementName>{title}</ArrangementName>
            <MarkedIcon src={selected}/>
        </Box>
    </Content>
    );
}
