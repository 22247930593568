import * as React from "react";
import PurchasePageCard from "../../components/PurchasePageCard";
import One from "../../assets/One.png";
import Two from "../../assets/Two.png";
import Three from "../../assets/Three.png";
import Alterar from "../../assets/AlterarIcone.png";
import Cartao from "../../assets/Cartao.png";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import './style.css'
import Logo from "../../assets/LogoSanstone.svg";
import LogoMobile from "../../assets/sansmobilelogo.svg";




import {
  AddProduct,
  ButtonDiv,
  DirColumn,
  EsqColumn,
  Icon,
  IconCard,
  Page,
  TotalDiv,
  NumbersDiv,
  Title2Div,
  DiaText,
  EntregaDiv,
  EntregaText,
  Preco2Total,
  PrecoTotal,
  Text2Total,
  TextTotal,
  TitleDiv,
  Mensagem,
  Button,
  Text,
  NameDiv,
  ColumnAmbient,
  IdentifyText,
  AddressDiv,
  Div,
  InfoDiv,
  Info,
  NameDivPurchase,
  Input,
  Container,
  DirColumnBottom,
  AddProduct3,
  InputContainer,
  ButtonAdress,
  ButtonAdressDiv,
} from "./style";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import PageHeader from "../../components/Header";
import { useEffect, useState } from "react";
import CreditForm from "../CreditForm";
import MercadoPagoService from "../../services/MercadoPagoService";
import EstimateService from "../../services/EstimateService";
import api from "../../services/api";
import ClientAddressService from "../../services/ClientAddressService";
import { AddressInfoCard } from "../../components/AddressInfoCard";
import { Select } from "../CreditForm/style";
import { workerData } from "worker_threads";
import { CgProfile } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { LeftSection, MobileLogo, LogoImg, Menu, RightBtns, Action, Texth6, ProfileButton, NavbarA } from "../../components/Header/style";
import { Header, PopUpEstimate } from "../Estimate/style";
import { ButtonToggle, PopUpText } from "../Home/style";
import { toast } from 'react-toastify';
import toBRLPrice from "../../utils/toBRLPrice";
import { BiCreditCard } from "react-icons/bi";
import { TbReportMoney } from "react-icons/tb";


interface SubmitButtonProps {
  loading: boolean;
}

interface AddressInfo {
  id: string,
  substring: string;
  zipCode: string;
  streetName: string;
  streetNumber: string;
  additionalInfo: string;
  ClientIdentificationNumber: string;
  addressName: string;
  shippingFee: number;
}

const inputCleaner = (value: string) => {
  const cleanInput = value.replace(/[^0-9]/g, '');
  return cleanInput;
}
const InputStyle = {
  paddingLeft: '10px',
  border: 'none',
  marginLeft: '10px',
  fontSize: '1rem',
  color: '#BDBDBD',
  outline: 'none'
}

function Purchase() {
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const identificationNumber = localStorage.getItem("identificationNumber");
  const email = localStorage.getItem("email");
  const productName = sessionStorage.getItem("productName");
  const accessToken = localStorage.getItem("token");
  const estimateId = sessionStorage.getItem("estimateId");
  const { register, formState: { errors }, handleSubmit } = useForm();
  const navigate = useNavigate();

  // máscara dos input e texto
  let PartOneCPF = identificationNumber?.slice(0, 3)
  let PartTwoCPF = identificationNumber?.slice(3, 6)
  let PartThreeCPF = identificationNumber?.slice(6, 9)
  let PartFourCPF = identificationNumber?.slice(9, 11)
  
  const Purchase = [
    {
      title: "Nome/Razão Social",
      info: firstName && lastName ? `${firstName} ${lastName}` : "",
    },
    {
      title: "CPF/CNPJ",
      info: identificationNumber ?
      PartOneCPF + '.' +
      PartTwoCPF + '.' +
      PartThreeCPF + '-' +
      PartFourCPF : ""
    },
    { title: "E-mail", info: email ? email : "" },
  ];
  
  const [boleto, setBoleto] = useState<boolean>(false);
  const [credito, setCredito] = useState<boolean>(false);
  const [nome, setNome] = useState<string>(firstName ? firstName : '');
  const [sobrenome, setSobrenome] = useState<string>(lastName ? lastName : '');
  const [mail, setMail] = useState<string>(email ? email : '');
  const [docT, setDocT] = useState<string>('CPF');
  const [numero, setNumero] = useState<string>(
    identificationNumber ? identificationNumber : ''
  );
  const [estimate, setEstimate] = useState<any>({});
  const [address, setAddress] = useState<AddressInfo[]>([]);
  const [addressStatus, setAddressStatus] = useState<boolean>(false);
  const [shippingFee, setShippingFee] = useState<number>(0);
  const [productPriceSum, setProductPriceSum] = useState<number>(0);
  const [servicePriceSum, setServicePriceSum] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [fullPrice, setFullPrice] = useState<number>(0);
  const [onOverflow, setOnOverflow] = useState(true)
  
  
  var addressData = false
  var endereco: string | any[] = [];
  // Aqui é gerado um array com os endereços do cliente, onde o id esperado é/foi o selecionado pelo usuário
  endereco = address?.filter(function (address) {
    const id = estimate.estimate?.ClientAddressId
    const clientId = localStorage.getItem("identificationNumber");
    return (address.id == id) && (address.ClientIdentificationNumber == clientId);
  });

  function selectBoleto() {
    setBoleto(true);
    setCredito(false);
  }

  function selectCredito() {
    setBoleto(false);
    setCredito(true);
  }

  function verificaDados() {
    if (endereco?.length > 0) {
      addressData = true
    }
    // verifica se a forma de pagamento está selecionada e o endereço preenchido
    const paymentData = (credito || boleto) as boolean;

    (paymentData && addressData) === true ? (
      boleto && accessToken && payBoleto()
    ) : (
      <></>
    );

    if ((paymentData || addressData) === false) {
      toast.info('Insira a forma de pagamento e o endereço, por favor!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
    })
      return;
    } else if (addressData === false) {
      toast.info("Insira o endereço de entrega, por favor!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
    })
      return;
    } else if (paymentData === false) {
      toast.info("Insira a forma de pagamento, por favor!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
    })
      return;
    }
  }
  
  function handleOverflow() {
    setOnOverflow(!onOverflow)
  }
  
  async function payBoleto() {
    const boletoData = {
      transaction_amount: Number(estimate.estimate.price),
      description: "Sem descrição",
      first_name: nome,
      last_name: sobrenome,
      email: mail,
      identificationType: docT,
      identificationNumber: inputCleaner(numero),
      external_reference: estimateId,
    };

    if (boletoData.external_reference && accessToken && estimateId) {
      MercadoPagoService.postBoletoPayment(boletoData, accessToken).then(
        (res) => {
          if (res?.status === 200)
            EstimateService.updateEstimateStatus(
              estimateId,
              "Pedido Realizado"
            ).then((res) => {
              if (res?.status === 200) navigate("/pedido-finalizado");
            });
        }
      );
    }
  }
  function toFloat(number: number): number {
    return parseFloat(number.toFixed(2))
  }

  function estimatePrice() {
    if (estimateId !== null && fullPrice !== undefined) {
        const prices = {
            price: toFloat(fullPrice),
            servicePriceSum: toFloat(servicePriceSum),
            productPriceSum: toFloat(productPriceSum),
        }
        EstimateService.updateEstimatePrice(estimateId, prices).then((res) => {
           console.log(res)
        })
    }
}

  useEffect(() => {
    if (estimateId) {
      EstimateService.fetchEstimate(estimateId).then((res) => {
        setEstimate(res?.data);
      });
    }
  }, [estimateId]);
  

  useEffect(() => {
    if (estimate?.CartCountertops?.length > 0) {
      setProductPriceSum(estimate ? estimate.estimate?.productPriceSum : 0)
      setServicePriceSum(estimate ? estimate.estimate?.servicePriceSum : 0)
      setPrice(estimate ? estimate.estimate?.price : 0)
      setShippingFee(endereco[0] ? endereco[0]?.shippingFee : 0);
      setFullPrice(price + shippingFee)
      estimatePrice()
    }
  }, [estimate, endereco]);


  useEffect(() => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("identificationNumber");
    if (token && id) {
      ClientAddressService.getClientAddress(token).then((response) => 
        setAddress(response?.data.address)
      );
    }
  }, []);

  useEffect(()=>{
    address.forEach(function(value) {
      value.ClientIdentificationNumber == identificationNumber ? setAddressStatus(true) : <></>; 
  })
  },[address])

    const [onPopUp, setOnPopUp] = useState(false);    

  function handlePopUp() {
    setOnPopUp(!onPopUp)
  }

  function logout() {
    localStorage.clear()
    navigate('/login')
  }
  
  return (
    <div style={{ width: '100%' }}>
      <Header>
        <LeftSection>
          <MobileLogo
            src={LogoMobile}
            alt={"logo mobile"}
            onClick={() => navigate("/")}
          />
          <LogoImg src={Logo} alt="logo" onClick={() => navigate("/")} />
          <Menu onClick={handlePopUp}>
            <GiHamburgerMenu style={{ fontSize: 32, color: "#808080" }} />
          </Menu>
        </LeftSection>
        <RightBtns>
          <Action>
            <Texth6>Orçamentos</Texth6>
          </Action>
          <Action>
            <Texth6>Pedidos</Texth6>
          </Action>
          <Action>
            <Texth6>Agendamentos</Texth6>
          </Action>
          <Action>
            <Texth6>Recebimentos</Texth6>
          </Action>
          <ProfileButton onClick={handlePopUp}>
            <CgProfile style={{ fontSize: "36px", color: "#828282" }} />
          </ProfileButton>
        </RightBtns>
      </Header>
      {onPopUp && (
        <>
          <PopUpEstimate>
            <ButtonToggle onClick={handlePopUp}>
              <IoMdClose fontSize={24} style={{marginTop:16}}/>
            </ButtonToggle>
            <NavbarA>
              <PopUpText>Perfil</PopUpText>
            </NavbarA>
            <NavbarA>
              <PopUpText>Modo Escuro</PopUpText>
            </NavbarA>
            <NavbarA>
              <PopUpText>Ajuda</PopUpText>
            </NavbarA>
            <NavbarA onClick={logout}                                                                                                                    >
              <PopUpText>Sair</PopUpText>
            </NavbarA>
          </PopUpEstimate>
        </>
      )}
      <form id="form-checkout">
        <Page>
          <ColumnAmbient>
            <EsqColumn>
              <NameDiv>
                <Icon>
                  <img src={One} />
                </Icon>
                <IdentifyText>Identificação</IdentifyText>
              </NameDiv>

              {Purchase.map((Purchase, index) => (
                <PurchasePageCard
                  title={Purchase.title}
                  info={Purchase.info}
                  key={index}
                />
              ))}
            </EsqColumn>

            <DirColumn>
              <NameDiv>
                <Icon>
                  <img src={Two} />
                </Icon>
                <IdentifyText>Endereço de Entrega</IdentifyText>
              </NameDiv>

              {endereco?.length > 0 ? (
                <>
                  <AddressDiv
                    style={{
                      overflow: onOverflow ? 'hidden' : 'auto',
                      maxHeight: onOverflow ? 'fit-content' : '400px',
                    }}
                  >
                  <AddressInfoCard
                    addressName={endereco[0].addressName}
                    streetName={endereco[0].streetName}
                    streetNumber={endereco[0].streetNumber}
                    zipCode={endereco[0].zipCode}
                    additionalInfo={endereco[0].additionalInfo}
                  />
                    {endereco[0].streetName.length > 80 && onOverflow && (
                      <>
                        <ButtonAdressDiv>
                          <ButtonAdress
                            style={{
                              textDecoration: onOverflow ? 'underline' : '',
                            }}
                            onClick={handleOverflow}
                          >
                            ver mais
                          </ButtonAdress>
                        </ButtonAdressDiv>
                      </>
                    )}
                    {!onOverflow && (
                      <ButtonAdress
                        style={{
                          marginLeft: onOverflow ? '' : '380px',
                          marginTop: onOverflow ? '' : '-34px',
                          fontSize: onOverflow ? '' : '16px',
                        }}
                        onClick={handleOverflow}
                      >
                        X
                      </ButtonAdress>
                    )}
                  </AddressDiv>

                  <Link to="/endereco-obra">
                    <ButtonDiv>
                      <IconCard>
                        <img src={Alterar} />
                      </IconCard>
                      <AddProduct>Escolher Outro Endereço</AddProduct>
                    </ButtonDiv>
                  </Link>
                </>
              ) : addressStatus ? (
                <Link to="/endereco-obra">
                  <ButtonDiv>
                    <IconCard>
                      <img src={Alterar} />
                    </IconCard>
                    <AddProduct>Escolher Novo Endereço</AddProduct>
                  </ButtonDiv>
                </Link>
              ) : (
                <Link to="/cadastrar-endereco">
                  <ButtonDiv onClick={() => (addressData = true)}>
                    <IconCard>
                      <img src={Alterar} />
                    </IconCard>
                    <AddProduct>Adicionar endereço de entrega </AddProduct>
                  </ButtonDiv>
                </Link>
              )}
            </DirColumn>
          </ColumnAmbient>

          <ColumnAmbient>
            <EsqColumn>
              <NameDiv>
                <Icon>
                  <img src={Three} />
                </Icon>
                <IdentifyText>Pagamento</IdentifyText>
              </NameDiv>

              <ButtonDiv onClick={() => selectBoleto()}>
                <IconCard>
                  <TbReportMoney size="100%" color={boleto? '#2566C8' : "#BDBDBD"}/>
                </IconCard>
                <AddProduct3 selected={boleto}>Boleto Bancário</AddProduct3>
              </ButtonDiv>

              <ButtonDiv onClick={() => selectCredito()}>
                <IconCard>
                  <BiCreditCard size="100%" color={credito? '#2566C8' : "#BDBDBD"}/>
                </IconCard>
                <AddProduct3 selected={credito}>Cartão de Crédito</AddProduct3>
              </ButtonDiv>
            </EsqColumn>

            <DirColumnBottom>
              {boleto ? (
                <>
                  <Container>
                    <Input
                      type="string"
                      placeholder="Nome"
                      value={nome}
                      onChange={(event: React.FormEvent<HTMLInputElement>) =>
                        setNome(event.currentTarget.value)
                      }
                    />
                  </Container>
                  <Container>
                    <Input
                      type="string"
                      placeholder="Sobrenome"
                      value={sobrenome}
                      onChange={(event: React.FormEvent<HTMLInputElement>) =>
                        setSobrenome(event.currentTarget.value)
                      }
                    />
                  </Container>
                  <Container>
                    <Input
                      type="string"
                      placeholder="E-mail"
                      value={mail}
                      onChange={(event: React.FormEvent<HTMLInputElement>) =>
                        setMail(event.currentTarget.value)
                      }
                    />
                  </Container>
                  <Container>
                    <Select
                      placeholder="Tipo de Documento"
                      value={docT}
                      onChange={(event: any) => {
                        setDocT(event.target.value);
                      }}
                    >
                      <option value={'CPF'}>CPF</option>
                      <option value={'CNPJ'}>CNPJ</option>
                    </Select>
                    </Container>
                  <Container>
                    <InputMask
                      mask={
                        docT === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99'
                      }
                      className={'mascaraPurchase'}
                      {...register('doc', {
                        required: true,
                        minLength: 14,
                      })}
                      value={numero}
                      type="string"
                      placeholder="Número do Documento"
                      onChange={(event: React.FormEvent<HTMLInputElement>) =>
                        setNumero(event.currentTarget.value)
                      }
                    />

                    {errors.doc?.type === 'required' && (
                      <p style={{ color: 'red', fontSize: 12 }}>
                        Campo obrigatório
                      </p>
                    )}

                    {errors.doc?.type === 'minLength' && (
                      <p style={{ color: 'red', fontSize: 12 }}>
                        Campo inválido
                      </p>
                    )}
                    {errors.CPF?.type === 'pattern' && (
                      <p style={{ color: 'red', fontSize: 12 }}>CPF inválido</p>
                    )}
                  </Container>
                  <input
                    type="hidden"
                    name="transaction_amount"
                    id="transactionAmount"
                    value={
                      estimate.estimate.price
                        ? estimate.estimate.price
                        : '10.00'
                    }
                  />
                  <input
                    type="hidden"
                    name="description"
                    id="description"
                    value={productName ? productName : ''}
                  />
                </>
              ) : (
                ''
              )}
              {credito ? <CreditForm boletoIsSelected={boleto} /> : ''}
            </DirColumnBottom>
          </ColumnAmbient>

          <TotalDiv>
            <NumbersDiv>
              <TitleDiv>
                <TextTotal>Total</TextTotal>
                <PrecoTotal>
                  {toBRLPrice(fullPrice)}
                </PrecoTotal>
              </TitleDiv>

              <Title2Div>
                <Text2Total>Produtos</Text2Total>
                <Preco2Total>
                  {toBRLPrice(productPriceSum)}{' '}
                </Preco2Total>
              </Title2Div>

              <Title2Div>
                <Text2Total>Serviços</Text2Total>
                <Preco2Total>
                  {toBRLPrice(servicePriceSum)}
                </Preco2Total>
              </Title2Div>

              <Title2Div>
                <Text2Total>Frete</Text2Total>
                <Preco2Total>
                  {toBRLPrice(shippingFee)}
                </Preco2Total>
              </Title2Div>

              <EntregaDiv>
                <EntregaText>Prazo de Entrega</EntregaText>
                <DiaText>
                  7 {'a'} 12 {'dias úteis'}
                </DiaText>
              </EntregaDiv>
            </NumbersDiv>


            <Button
              id="form-checkout__submit"
              type={boleto ? 'button' : 'submit'}
              onClick={() => verificaDados()}
            > 
            
              {<Text>Finalizar Compra</Text>}
            </Button>
            <Mensagem>Parcele ou receba desconto</Mensagem>
          </TotalDiv>
        </Page>
      </form>
    </div>
  );
}

export default Purchase;