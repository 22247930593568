/* eslint-disable import/no-anonymous-default-export */
import api from '../api';

export interface ClientAddressProps {
    zipCode?: string,
    streetName?: string,
    streetNumber?: string,
    additionalInfo?: string | null,
    ClientIdentificationNumber?: string | null,
    addressName?: string | null,
    neighborhoodName?: string,
    ufName?: string,
    cityName?: string,
}

export default {
    
    async createAddress(token: string, data: ClientAddressProps) {
        try {
            const response = api.post(`/private/clientAdresses`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return response
        }
        catch (e) {
            console.log(e)
        }
    },
    async getClientAddress(token: string) {
        try {
            const response = await api.get(`/private/clientAdresses`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return response
        }
        catch (e) {
            console.log(e)
        }
    },
    async updateClientAddress(token: string, id: string, data: ClientAddressProps) {
        try {
            const response = api.put(`/private/clientAdresses/${id}`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return response
        }
        catch (e) {
            console.log(e)
        }
    }
}
