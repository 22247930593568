import styled from "styled-components";

export const Content = styled.button`
display:flex;
flex-direction:row;
align-items:center;
width: 90%;
min-width: 300px;
height:110px;
box-shadow: 0 8px 30px 0 rgba(54,54,54,0.2);
border-radius:8px;
margin-top:25px;
overflow:hidden;
background-color: #FFF;
border:none;
&:hover{
    opacity:0.5;
    cursor: pointer;
}
@media(max-width:1090px){
    min-width: 240px;
}
`
export const RoomName = styled.h6`
color: #828282;

font-size: 24px;
font-weight: 700;
margin-left:20px;
@media(max-width:1090px){
    font-size: 18px;
}
`
export const CardImg = styled.img`
height:130px;
width:110px;
object-fit:cover;
`

export const Anchor = styled.a`
background-color: red;
&:hover{
    opacity:0.5;
}
`
