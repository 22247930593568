import { Img, RoomBtn, MaterialBtn, MobileBtn, MobileName } from "./style";
import { BsPlusLg } from "react-icons/bs";

export interface Props {
    name?: string;
    url?: any;
    thereIsImage: boolean;
    onClick?: any;
    color?: any;
    available?: boolean;
    activeColor?: string;
    titleColor?: string,
    opacity?: number;

}

function CardButton({
    name,
    url,
    thereIsImage,
    onClick,
    color,
    available,
}: Props) {
    return (
        <>
            {thereIsImage ? (
                <MobileBtn
                    url={url}
                    onClick={onClick}
                    thereIsImage={thereIsImage}
                    activeColor={available ? "white" : "#D2D2D2"}
                    titleColor={available ? "#828282" : "#88888883"}
                    opacity={available ? 1 : 0.38}
                     
                >   
                    <MobileName
                        activeColor={available ? "white" : "#D2D2D2"}
                        titleColor={available ? "#828282" : "#88888883"}
                        hoverOpacity={available ? 1 : 0.5}
                        >
                            
                        <p style={{ color: color }}>{name}</p>
                    </MobileName>
                    <MaterialBtn
                        onClick={onClick}
                        activeColor={available ? "white" : "#D2D2D2"}
                        titleColor={available ? "#828282" : "#88888883"}
                        hoverOpacity={available ? 0.5 : 1}

                    >
                        <h3>{name}</h3>
                        <Img src={url} opacity={available ? 1 : 0.5} />
                    </MaterialBtn>
                </MobileBtn>
            ) : (
                <RoomBtn onClick={onClick}>
                    <h3>{name}</h3>
                    <div>
                        <BsPlusLg />
                    </div>
                </RoomBtn>
            )}
        </>
    );
}

export default CardButton;
