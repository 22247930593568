import * as React from 'react';
import NotMarked from '../../assets/NotMarked.png';
import Marked from '../../assets/Marked.png'
import { Box, Name } from './style';

export interface InstallationCardProps {

    name?: string;
    active?: boolean;
    index?: number;
    onClick?: () => void;
    src: string;
    full?:boolean;


}

function InstallationCard({ name, onClick, src, full }: InstallationCardProps) {
   
    return (
        <div>
            {
                window.screen.width > 912 ?
                <Box onClick={onClick} onFaucet={full} style={ name == 'Torneira de Parede' ? {width:'29.8vw'} : {width:'28.7vw'}}>
                    <img src={src} style={{ marginLeft: '20px' }} />
                    <Name>{name}</Name>
                </Box> 
            :
                <Box onClick={onClick} onFaucet={full} style={ name == 'Torneira de Parede' ? {width:'90vw', marginLeft:'5%'} : {width:'28.7vw'}}>
                    <img src={src} style={{ marginLeft: '20px' }} />
                    <Name>{name}</Name>
                </Box>
            }
          
        </div>
    );
}

export default InstallationCard;