import { AddressInfoContainer, TextAddressName, TextInfo, TextWrapper } from "./style";

interface AddressInfo {
    zipCode: string,
    streetName: string,
    streetNumber: string,
    additionalInfo?: string,
    addressName: string
}
export function AddressInfoCard(
    {
        zipCode,
        streetName,
        streetNumber,
        additionalInfo,
        addressName
    }: AddressInfo) {
    return (
        <AddressInfoContainer>
            <TextAddressName>{addressName}</TextAddressName>
            <TextWrapper>
                <TextInfo>{streetName}</TextInfo>
               <TextInfo> Nº {streetNumber} {zipCode}</TextInfo>
                {additionalInfo? <TextInfo>{additionalInfo}</TextInfo>: ''}
            </TextWrapper>
        </AddressInfoContainer>
    )
}