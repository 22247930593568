import styled from "styled-components";
interface LoginProps {
  position: string
}

export const Container = styled.div<LoginProps>`
  display: flex;
  flex-direction: column;
  background: white;
  height: 500px;
  width: 400px;
  padding: 40px 40px 40px 40px;
  border-radius: 10px;
  box-shadow: 0 2px 20px 0 rgba(149, 149, 149, 0.3);
  h6 {
    
    font-weight: 500;
    color: #D0D0D0;
    font-size: 14px;
  }
  h2 {
    
    font-weight: 700;
    color: #2566c8;
    font-size: 24px;
    @media (max-width: 912px) {
      height: fit-content;
      width: fit-content;
      position: relative;
      top: 7%;
      left: 8%;
    }
    
  }
  p {
    font-weight: 500;
    font-size: 16px;
  }

  @media(max-width:912px){
    width:100vw;
    position:${props => props.position};
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    padding: 0;
    margin: 0;
    height:73vh;
  }
  @media(min-width:1280px ){ // problema de que > que 1280 fica com isso tbm
    height: 450px;
    width: 350px;
  }
  @media (min-width: 1300px) {
    height: 500px;
  width: 400px;
    
  }
 
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  @media (max-width: 912px) {
    align-items: center;
  }
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  height: 40px;
  background: #f2f2f2;
  border-radius: 8px;
  align-items: center;
  padding-left:15px;
  padding-right:15px;
  @media (max-width: 912px) {
    width: 80vw;
  }
 
`;

export const Input = styled.input`

margin-left:15px;
border: none;
background-color: #f2f2f2;
width:100%;
font-size:14px;
  &:focus {
    outline: none;
  }  
  &::placeholder{
  font-size:14px;
  letter-spacing:normal;
  
}
${props => props.type === 'password' && `
letter-spacing:.25rem;
&::placeholder{
  font-size:14px;
  letter-spacing:normal;

}
  `}

@supports (-webkit-touch-callout: none) {
  /* Atualmente, apenas dispositivos iOS possuem a feature -webkit-touch-callout,
  logo esse código testa se o dispositivo é iOS ou não. Caso a Apple mude algo,
  basta consultar
  https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariCSSRef/Articles/StandardCSSProperties.html
  e buscar por "Safari on iOS only"*/
  font-size: 16px;
  width: 95%;
}
`;

export const Action = styled.a`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  &:hover {
    cursor: pointer;
  }
  h6 {
    &:hover {
      opacity: 0.5;
    }
  }
  
`;



export const H6 = styled.h6`
  margin-bottom: 106px;
  margin-top: 5%;
  @media(max-width:912px){
    margin-top: 8%;
    width: fit-content;
    height: fit-content;
    position: relative;
    top: 4%;
    left: 8%;
  }
`;

export const TextBox = styled.div`
  
  display: flex;
  flex-direction: row;
  gap: 1%;
  padding-top: 20px;
  justify-content: center;
  align-items: flex-end;
  font-size:14px;
  p{
    font-weight: 700;
    color:#D0D0D0;
  }
  a{
    color: #2566c8;
    font-weight: 700;
    &:hover{
      cursor:pointer; 
      opacity:0.5
  }
  }
`;
export const BlueBtn = styled.button`

display:flex;
height:40px;
justify-content:center;
align-items:center;
  background-image: linear-gradient(271.82deg, #2566c8 0%, #4485e8 100%);
  flex-direction:row;
  border: none;
  border-radius: 8px;
  position:relative;
  &:hover {
    opacity: 0.5;
    cursor:pointer;
  }

  p {
    color: white;
  }
  @media (max-width: 414px) {
    width: 87vw;
  }
  @media (max-width: 912px) {
    width: 83.7vw;
  }
  @media (min-width: 913px) {
    width: 100%;
  }
`

  ;
