import styled from "styled-components";
interface registerProps {
  position: string;
  margin?: string;
}

interface selectStyleProps {
  opacity?: number;
}
export const Container = styled.div<registerProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #fff;
  height: auto;
  width: 400px;
  padding: 26px;
  border-radius: 22.5px;
  box-sizing: border-box;
  margin-top: 20px;
  box-shadow: 0 2px 20px 0 rgba(149, 149, 149, 0.3);
  position: ${(props) => props.position};
  h4 {
    
    font-weight: 500;
    font-size: 16px;
  }
  
  @media (max-width: 912px) {
    width: 94vw;
    margin-left:${(props) => props.margin ? props.margin : '3vw'} ;
;
    position: ${(props) => props.position};
    top: 30%;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    padding: 0px;
    background-color: #fff;
    box-shadow: none;

  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  p {
    margin-bottom: 20px;
  }
  @media (max-width: 912px) {
    *border: solid 2px red;
     padding: 0px 15px;
  }
`;

export const Row = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;

  @media (max-width: 350px) {
    flex-direction: column;
  }
`
export const Column = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
`
export const SmallColumn = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 200px;
  @media (max-width: 350px) {
    width: 100%;
  }
`

export const InputDiv = styled.div`
  display: flex;
  flex-direction: row;
  /* margin-bottom: 20px; */
  padding: 0px 10px 0px 10px;
  height: 40px;
  background: #f2f2f2;
  border-radius: 5px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  img {
    height: 40px;
    width: 20rem;
  }
`;

export const Input = styled.input`
  border: none;
  background-color: #f2f2f2;
  color: #222222;
  
  font-size: 1rm;
  width: 100%;
  &:focus {
    outline: none;
  }
  @supports (-webkit-touch-callout: none) {
  /* Atualmente, apenas dispositivos iOS possuem a feature -webkit-touch-callout,
  logo esse código testa se o dispositivo é iOS ou não. Caso a Apple mude algo,
  basta consultar
  https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariCSSRef/Articles/StandardCSSProperties.html
  e buscar por "Safari on iOS only"*/
  font-size: 16px;
  width: 95%;
  }
`;

export const SelectDiv = styled.div`
  display: flex;
  flex-direction: row;
  /* margin-bottom: 20px; */
  padding: 0px 10px 0px 10px;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  background: #f2f2f2;
  border-radius: 5px;
  justify-content: left;
  img {
    height: 40px;
    width: 20rem;
  }
  `;

export const Select = styled.select<selectStyleProps>`
  display: flex;
  border: none;
  background-color: #f2f2f2;
  color: #222222;
  opacity: ${(props) => props.opacity ? props.opacity : '1'};
  
  font-size: 1rm;
  width: 100%;
  appearance: none;
  &:focus {
    outline: none;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  height: 1.1rem;
  width: 100%;
  padding: 5px 0px 5px 0px;
`

export const SignUpBtn = styled.button`
  background-image: linear-gradient(271.82deg, #2566c8 0%, #4485e8 100%);
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 8px;
  box-shadow: 0 8px 30px 0 rgba(0, 44, 113, 0.25);
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;
