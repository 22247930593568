import {
  Page,
  SbmtBtn,
  Title,
  DisabledBtn,
  Footer,
  Container,
  Input,
  InputContainer,
  PageMobile,
  TextInfo,
  TextContainer,
  SbmtButtomMobile,
} from './style';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import BackBtn from '../../components/BackBtn';
import PageHeader from '../../components/Header';
import SbmtBtnOn from '../../assets/AvancarButtonOn.png'
import SbmtBtnOff from '../../assets/AvancarButtonOff.png'



function Identify(): JSX.Element {
  const [advance, setAdvance] = useState<boolean>(false);
  const [identifique, setIdentifique] = useState<any>();

  const setAdressName = () => {
    localStorage.setItem('addressName', identifique);
  };

  return (
    <>
      <PageHeader display='none' />
      <Page onAdvance={advance}>
        <InputContainer>
          <Title>Identifique seu endereço</Title>
          <Container>
            <Input
              type="string"
              placeholder="Nome do endereço"
              value={identifique}
              onChange={(event: any) => setIdentifique(event.target.value)}
            />
          </Container>
        </InputContainer>

        <Footer>
          <Link to="/finalize-compra">
            <BackBtn />
          </Link>
          {identifique ? (
            <div onClick={() => setAdressName()}>
              <Link to="/cadastrar-endereco">
                <SbmtBtn> Avançar</SbmtBtn>
              </Link>
            </div>
          ) : (
            <DisabledBtn>Avançar</DisabledBtn>
          )}
        </Footer>
      </Page>

      {/* Versão Mobile */}

      <PageMobile>
        <TextContainer>
          <TextInfo>Identifique seu endereço</TextInfo>
        </TextContainer>
        <Input
          type="string"
          placeholder="Nome do endereço"
          value={identifique}
          onChange={(event: any) => setIdentifique(event.target.value)}
        />
        <Footer>
        {identifique ? (
            <div onClick={() => setAdressName()}>
              <Link to="/cadastrar-endereco">
                <SbmtButtomMobile src={SbmtBtnOn}/>
              </Link>
            </div>
          ) : (
            <SbmtButtomMobile src={SbmtBtnOff}/>
            )}
        </Footer>


      </PageMobile>
    </>
  );
}

export default Identify;
