import * as React  from 'react';
import { Container, Div, Input, Title } from './style';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    isEmpty?: boolean;
    type?: string | undefined;
    unity?: string;
    largura : number;
}

function DetailsInput({largura, isEmpty, type, unity, ...rest }: InputProps) {

    return (

        <Div>
            <Title>
                {type}
            </Title>
            <Container largura={largura}>
                <Input
                    type='number'
                    {...rest}
                />
                <h6>{unity}</h6>
            </Container>
        </Div>
    );
}

export default DetailsInput;
