import * as React from "react";
import {
  BtnsDiv,
  CancelBtn,
  Container,
  Form,
  H2,
  H3,
  ResetBtn,
  TextDiv,
} from "./style";
import { Input, InputDiv } from "../LoginCard/style";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify";

interface resetProps {
  position: string
}

export default function ResetPasswordCard({ position }: resetProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [input, setInput] = useState('');
  const [token, setToken] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(true);

  const navigate = useNavigate();

  const inputCleaner = (value: string) => {
    const cleanInput = value.replace(/[^A-Za-z0-9Çç!@#$%¨&*()"'{}~^]/g, "");
    return cleanInput;
  };

  const EyeStyle = {
    fontSize: "1.5rem",
    color: "#BDBDBD",
    marginLeft: "auto",
    padding: "15px",
  };
  const [modal, setModal] = useState({
    form1: true,
    form2: false,
    form3: false,
  });
  const resetData = {
    email: email!,
    password: password!,
  };

  const { form1, form2, form3 } = modal;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = () => {
    //método post levando email pra rota de esqueci senha
    if(token != ''){
    setModal({ form1: false, form2: true, form3: false })
    }
  };

  const sendPasswordToken = (Email: string) => {
  
   api.post("/clients/forgotPassword", resetData).then((res) => {
       setToken(res.data.token)
    },
    (error => toast.error('Usuário não identificado', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      closeOnClick: true,
      draggable: true,
  })
))
}

  const validateToken = (userToken: string) => {
    if (userToken == token!) {
      setModal({ form1: false, form2: false, form3: true });
    } else {
      toast.error('Token de verificação inválido', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
    })
    }
  };
  const createNewPassword = (senha: string) => {
    api.post("/clients/changePassword", resetData).then((res) => {
      toast.success('Senha redefinida com sucesso!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        onClick: () => navigate('/login')
        
    })
        navigate('/login');
    });
  };
  return (
    <>
      {form1 && (
        <Container position={position}>
          <TextDiv>
            <H2>Redefina sua senha</H2>
            <H3>Digite seu e-mail para redefinir a senha:</H3>
          </TextDiv>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputDiv style={{ width:'90.5%'
              }}>
              <AiOutlineMail
                style={{
                  fontSize: "1.5rem",
                  color: "#BDBDBD",
                }}
              />
              <Input
                {...register("email", {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
                placeholder="E-mail"
                onChange={(event) => setEmail(event?.target.value)}
                value={email}
                onFocus={(e) => e.target.value = ''}
                style={{ height: "40px" }}
              />
            </InputDiv>
            {errors.email?.type === "required" && (
              <p style={{ color: "red", marginLeft: 15, fontSize: 12 }}>
                Campo obrigatório
              </p>
            )}
            {errors.email?.type === "pattern" && (
              <p style={{ color: "red", marginLeft: 15, fontSize: 12 }}>
                E-mail inválido
              </p>
            )}

            <BtnsDiv>
              <Link to="/login">
                <CancelBtn>Cancelar</CancelBtn>
              </Link>
              <ResetBtn type="submit" onClick={() => sendPasswordToken(email!)}>
                Redefinir senha
              </ResetBtn>
            </BtnsDiv>
          </Form>
        </Container>
      )}
      {form2 && (
        <Container position={position}>
          <TextDiv>
            <H2>Redefina sua senha</H2>
            <H3>Digite abaixo, o token recebido por e-mail:</H3>
          </TextDiv>
          <InputDiv style={{ marginTop: 30, width:'80%' }}>
            <Input
              {...register("token", { required: true, pattern: /[0-9]{6}$/i })}
              placeholder="Token"
              onChange={(e) => setInput(inputCleaner(e.target.value))}
              onFocus={(e) => e.target.value = ''}
              value={input}
              style={{ height: "40px", width: "100%" }}
              id={"TokenInput"}
            />
          </InputDiv>
          <BtnsDiv>
            <CancelBtn
              onClick={() =>
                setModal({ form1: true, form2: false, form3: false })
              }
            >
              Cancelar
            </CancelBtn>
            <ResetBtn type="submit" onClick={() => validateToken(input)}>
              {" "}
             Validar Token
            </ResetBtn>
          </BtnsDiv>
        </Container>
      )}
      {form3 && (
        <Container position={position}>
          <TextDiv>
            <H2>Redefina sua senha</H2>
            <H3>Digite abaixo, uma nova senha de acesso:</H3>
          </TextDiv>
          <InputDiv style={{ marginTop: 30, width:'80%' }}>
            <Input
              placeholder="Senha"
              {...register("senha", { required: true, minLength: 8})}
              value={password}
              onChange={(e) => setPassword(inputCleaner(e.target.value))}
              type={passwordVisibility ? "" : "password"}
            />

            {passwordVisibility ? (
              <AiFillEyeInvisible
                onClick={() => setPasswordVisibility(!passwordVisibility)}
                style={EyeStyle}
              />
            ) : (
              <AiFillEye
                onClick={() => setPasswordVisibility(!passwordVisibility)}
                style={EyeStyle}
              />
            )}
          </InputDiv>
          {errors.senha?.type === "minLength" && (
            <p style={{ color: "red", fontSize: 12 }}>
              Sua senha precisa ter pelo menos 8 dígitos
            </p>
          )}
          <BtnsDiv>
            <CancelBtn
              onClick={() =>
                setModal({ form1: true, form2: false, form3: false })
              }
            >
              Cancelar
            </CancelBtn>
            <ResetBtn type="submit" onClick={() => createNewPassword(password)}>
              Alterar
            </ResetBtn>
          </BtnsDiv>
        </Container>
      )}
    </>
  );
}
