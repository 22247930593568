import React, { useEffect, useState } from "react";
import CounterTopCard from "../../components/CounterTopCard";
import BackBtn from "../../components/BackBtn";
import {
  Page,
  Top,
  Counter,
  PathText,
  A,
  MainText,
  Cards1,
  Btns,
  Cards2,
  Title,
  Div,
  CardWrapper,
} from "./style";
import Straight from "../../assets/IconStraightCounterTop.svg";
import offStraight from "../../assets/IconStraightCounterTopOff.svg";
import L from "../../assets/IconLshapedCntrTop.svg";
import offL from "../../assets/IconLshapedCntrTopOff.svg";
import U from "../../assets/IconUshapedCntrTop.svg";
import offU from "../../assets/IconUshapedCntrTopOff.svg";
import UC from "../../assets/IconUCCounterTop.svg";
import UL from "../../assets/IconULCounterTop.svg";
import UR from "../../assets/IconUshapedCntrTop.svg";
import UR1 from "../../assets/DispUR1.svg";
import UR2 from "../../assets/DispUR2.svg";
import UR3 from "../../assets/DispUR3.svg";
import UR4 from "../../assets/DispUR4.svg";
import UL1 from "../../assets/DispUL1.svg";
import UL2 from "../../assets/DispUL2.svg";
import UL3 from "../../assets/DispUL3.svg";
import UL4 from "../../assets/DispUL4.svg";
import UC1 from "../../assets/DispUC1.svg";
import UC2 from "../../assets/DispUC2.svg";
import UC3 from "../../assets/DispUC3.svg";
import UC4 from "../../assets/DispUC4.svg";
import Marked from "../../assets/Marked.svg";
import NotMarked from "../../assets/NotMarked.svg";
import Right from "../../assets/IconLRightBasin.svg";
import Left from "../../assets/IconLLeftBasin.svg";
import Disp1 from "../../assets/IconLRightType1.svg";
import Disp2 from "../../assets/IconLRightType2.svg";
import Disp3 from "../../assets/IconLLeftType1.svg";
import Disp4 from "../../assets/IconLLeftType2.svg";
import { DisabledBtn, SbmtBtn, TitleInput } from "../BenchL/style";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Container, Input } from "../../components/DetailsInput/style";
import { StyleErrorMessage } from "../../components/StyleErrorMessage";
import { features } from "process";
import { StepCounter } from "../../components/StepCounter";
import { MobileContainer } from "../OvenStyle/style";
import CounterTopCardOff from "../../components/CounterTopCardOff";
import { toast } from "react-toastify";

export default function CounterTopSelection() {
  //validação
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ mode: "onBlur" });

  const onSubmit = () => {
    sendToSessionStorage();
    editingSummary && bancadaReta
      ? navigate("/resumo")
      : navigate(`${whereToGo}`);
  };

  //controlar os tipo de bancada
  const [select, setSelect] = useState(false);
  const [select1, setSelect1] = useState(false);
  const [select2, setSelect2] = useState(false);

  const valorLocal = localStorage.getItem("chave");
  console.log(valorLocal);

  //desmarcar (clear) nas bancadas nao selecionadas
  function unmarkCountertopStraight() {
    setc0(undefined);
    setc1(undefined);
    setc2(undefined);
  }

  function unmarkCountertopU() {
    setSelectU1(false);
    setSelectU2(false);
    setSelectU3(false);

    unmarkDispositionU();
  }

  function unmarkDispositionU() {
    setSelectDU1(false);
    setSelectDU2(false);
    setSelectDU3(false);
    setSelectDU4(false);
  }

  function unmarkCountertopL() {
    setRight(false);
    setLeft(false);
    setSelectD1(false);
    setSelectD2(false);
    setSelectD3(false);
    setSelectD4(false);
  }

  //function para os tipos de bancada
  function selectStraight() {
    setSelect(!select);
    setSelect1(false);
    setSelect2(false);
    console.log("===== click");

    unmarkCountertopL();
    unmarkCountertopU();

    sessionStorage.removeItem("dryBaseEstimateWidth");
    sessionStorage.removeItem("dryBaseEstimateDepth");
    sessionStorage.removeItem("countertopDisposition");
    sessionStorage.removeItem("vatPosition");
    sessionStorage.removeItem("dryBaseEstimateWidth1");
    sessionStorage.removeItem("dryBaseEstimateDepth1");

    sessionStorage.removeItem("flankPosition");
    sessionStorage.removeItem("flankWidth");
    sessionStorage.removeItem("flankFinishType");
    sessionStorage.removeItem("flankJunctionType");
  }

  function selectL() {
    setSelect(false);
    setSelect1(!select1);
    setSelect2(false);
    let x = sessionStorage.setItem("countertopType", "Bancada em L");
    console.log("=>>>" + x);

    unmarkCountertopStraight();
    unmarkCountertopL();
    unmarkCountertopU();

    sessionStorage.removeItem("wetBaseEstimateWidth");
    sessionStorage.removeItem("wetBaseEstimateDepth");
    sessionStorage.removeItem("dryBaseEstimateWidth");
    sessionStorage.removeItem("dryBaseEstimateDepth");
    sessionStorage.removeItem("dryBaseEstimateWidth1");
    sessionStorage.removeItem("dryBaseEstimateDepth1");
  }

  function selectU() {
    setSelect(false);
    setSelect1(false);
    setSelect2(!select2);
    let x = sessionStorage.setItem("countertopType", "Bancada em U");
    console.log("------" + x);

    unmarkCountertopStraight();
    unmarkCountertopL();
    unmarkCountertopU();

    sessionStorage.removeItem("wetBaseEstimateWidth");
    sessionStorage.removeItem("wetBaseEstimateDepth");
    sessionStorage.removeItem("dryBaseEstimateWidth");
    sessionStorage.removeItem("dryBaseEstimateDepth");
    sessionStorage.removeItem("flankPosition");
    sessionStorage.removeItem("flankWidth");
    sessionStorage.removeItem("flankFinishType");
    sessionStorage.removeItem("flankJunctionType");
  }

  const vatDisposition = [
    { title: "Direita", img: Right },
    { title: "Esquerda", img: Left },
  ];

  const [right, setRight] = useState(false);
  const [left, setLeft] = useState(false);
  const directionArray = [right, left];

  const navigate = useNavigate();

  function selectDirection(index: number) {
    switch (index) {
      case 0:
        setRight(!right);
        setLeft(false);
        setSelectD1(false);
        setSelectD2(false);
        setSelectD3(false);
        setSelectD4(false);
        break;

      case 1:
        setRight(false);
        setLeft(!left);
        setSelectD1(false);
        setSelectD2(false);
        setSelectD3(false);
        setSelectD4(false);
        break;
    }
  }

  //divisão entre bancada seca e molhada quando escolhida Cuba na direita
  const [selectD1, setSelectD1] = useState(false);
  const [selectD2, setSelectD2] = useState(false);

  function selectDisp1() {
    setSelectD1(!selectD1);
    setSelectD2(false);
  }

  function selectDisp2() {
    setSelectD1(false);
    setSelectD2(!selectD2);
  }

  //divisão entre bancada seca e molhada quando escolhida Cuba na esquerda
  const [selectD3, setSelectD3] = useState(false);
  const [selectD4, setSelectD4] = useState(false);

  function selectDisp3() {
    setSelectD3(!selectD3);
    setSelectD4(false);
  }

  function selectDisp4() {
    setSelectD3(false);
    setSelectD4(!selectD4);
  }

  const amount = sessionStorage.getItem("amount");
  const materialName = sessionStorage.getItem("materialName");
  const wetBaseEstimateWidth = sessionStorage.getItem("wetBaseEstimateWidth");
  const wetBaseEstimateDepth = sessionStorage.getItem("wetBaseEstimateDepth");
  const counterTopType = sessionStorage.getItem("countertopType");
  const vatPosition = sessionStorage.getItem("vatPosition");
  const counterTopDisposition = sessionStorage.getItem("countertopDisposition");

  useEffect(() => {
    if (amount) {
      setc0(Number(amount));
      setValue("quantidade", amount);
    }
    if (wetBaseEstimateWidth) {
      setc1(Number(wetBaseEstimateWidth));
      setValue("largura", wetBaseEstimateWidth);
    }
    if (wetBaseEstimateDepth) {
      setc2(Number(wetBaseEstimateDepth));
      setValue("profundidade", wetBaseEstimateDepth);
    }
  }, []);

  // const [counterTopTypeState, setCounterTopTypeState] = useState('')ve e pod eapaga

  useEffect(() => {
    // setCounterTopTypeState(counterTopType!)
    if (counterTopType === "Bancada Reta") {
      setSelect(true);
      setSelect1(false);
      setSelect2(false);
    } else if (counterTopType === "Bancada em U") {
      setSelect2(true);
      setSelect(false);
      setSelect1(false);
    } else if (counterTopType === "Bancada em L") {
      setSelect1(true);
      setSelect(false);
      setSelect2(false);
    }
  }, [select]);

  console.log(counterTopType);
  const editingSummary = sessionStorage.getItem("editing summary") === "true" ? true : false;
  const editingProduct = sessionStorage.getItem("editing product") === "true" ? true : false;

  const [advance, setAdvance] = useState<boolean>(false);

  //state dos inputs
  const [c0, setc0] = useState<number>();
  const [c1, setc1] = useState<number>();
  const [c2, setc2] = useState<number>();
  console.log(c1,c2)
  console.log("=>" + c0);

  //posição bancada u
  const [selectU1, setSelectU1] = useState(false);
  const [selectU2, setSelectU2] = useState(false);
  const [selectU3, setSelectU3] = useState(false);

  function selectCU1() {
    setSelectU1(!selectU1);
    setSelectU2(false);
    setSelectU3(false);

    unmarkDispositionU();
  }

  function selectCU2() {
    setSelectU1(false);
    setSelectU2(!selectU2);
    setSelectU3(false);

    unmarkDispositionU();
  }

  function selectCU3() {
    setSelectU1(false);
    setSelectU2(false);
    setSelectU3(!selectU3);

    unmarkDispositionU();
  }

  //disposicoes da bancada u direita
  const [selectDU1, setSelectDU1] = useState(false);
  const [selectDU2, setSelectDU2] = useState(false);
  const [selectDU3, setSelectDU3] = useState(false);
  const [selectDU4, setSelectDU4] = useState(false);

  //   //disposicoes da bancada u esquerda
  // const [selectDUE1, setSelectDUE1] = useState(false);
  // const [selectDUE2, setSelectDUE2] = useState(false);
  // const [selectDUE3, setSelectDUE3] = useState(false);
  // const [selectDUE4, setSelectDUE4] = useState(false);

  function selectDispU1() {
    setSelectDU1(!selectDU1);
    setSelectDU2(false);
    setSelectDU3(false);
    setSelectDU4(false);
  }

  function selectDispU2() {
    setSelectDU1(false);
    setSelectDU2(!selectDU2);
    setSelectDU3(false);
    setSelectDU4(false);
  }

  function selectDispU3() {
    setSelectDU1(false);
    setSelectDU2(false);
    setSelectDU3(!selectDU3);
    setSelectDU4(false);
  }

  function selectDispU4() {
    setSelectDU1(false);
    setSelectDU2(false);
    setSelectDU3(false);
    setSelectDU4(!selectDU4);
  }

  const bancadaReta = select && c0 && c1 && c2 ? true : false;
  const bancadaL = selectD1 || selectD2 || selectD3 || selectD4 ? true : false;
  const bancadaU =
    selectDU1 || selectDU2 || selectDU3 || selectDU4 ? true : false;

  function sendToSessionStorage() {
    sessionStorage.setItem("countertopType", type);
    if (bancadaReta) {
      sessionStorage.setItem("amount", c0 ? c0.toString() : "");
      sessionStorage.setItem("wetBaseEstimateWidth", c1 ? c1.toString() : "");
      sessionStorage.setItem("wetBaseEstimateDepth", c2 ? c2.toString() : "");
    } else if (bancadaL) {
      sessionStorage.setItem(
        "vatPosition",
        right && (selectD1 || selectD2) ? "Direita" : "Esquerda"
      );
      sessionStorage.setItem(
        "countertopDisposition",
        selectD1 || selectD3 ? "Disposição 1" : "Disposição 2"
      );
    } else if (bancadaU) {
      sessionStorage.setItem(
        "vatPosition",
        selectU1 ? "Centro" : selectU2 ? "Esquerda" : "Direita"
      );
      if (selectDU1) {
        sessionStorage.setItem("countertopDisposition", "Disposição 1");
      } else if (selectDU2) {
        sessionStorage.setItem("countertopDisposition", "Disposição 2");
      } else if (selectDU3) {
        sessionStorage.setItem("countertopDisposition", "Disposição 3");
      } else if (selectDU4) {
        sessionStorage.setItem("countertopDisposition", "Disposição 4");
      }
    }
  }

  const [type, setType] = useState("");

  useEffect(() => {
    if (select) {
      setType("Bancada Reta");
    } else if (select1) {
      setType("Bancada em L");
    } else if (select2) {
      setType("Bancada em U");
    }
  }, [select, select1, select2]);

  //clear nas opções de disposição caso ele mude de opção posição na bancada em U
  useEffect(() => {
    if (selectU1 || selectU2 || selectU3) {
      setSelectDU1(false);
      setSelectDU2(false);
      setSelectDU3(false);
      setSelectDU4(false);
    }
  }, [selectU1, selectU2, selectU3]);
  //clear de opções de bancada em L, caso se selecione U ou Reta
  useEffect(() => {
    if (select || select2) {
      unmarkCountertopL();
    }
  }, [select, select2]);

  //qual página ir depende da opção selecionada (Reta, L ou U)
  let whereToGo = "";
  if (select) {
    whereToGo = "/cuba";
  } else if (select1) {
    whereToGo = "/bancada-L";
  } else if (select2) {
    whereToGo = "/bancada-U";
  }

  useEffect(() => {
    if (vatPosition === "Esquerda") {
      setRight(false);
      setLeft(true);
      setSelectU1(false);
      setSelectU2(true);
      setSelectU3(false);
    } else if (vatPosition === "Direita") {
      setRight(true);
      setLeft(false);

      setSelectU1(false);
      setSelectU2(false);
      setSelectU3(true);
    }
  }, []);


  useEffect(() => {
    if(counterTopType === "Bancada em L") {
      if (counterTopDisposition === "Disposição 2") {
        setSelectD3(false)
        setSelectD4(true)
    }

    if (counterTopDisposition === "Disposição 1") {
      setSelectD3(true)
      setSelectD4(false)
  }
  }
  },[])


  useEffect(() => {
    if(counterTopType === "Bancada em U") {
      if (counterTopDisposition === "Disposição 2") {
        setSelectDU3(false)
        setSelectDU4(false)
        setSelectDU1(false)
        setSelectDU2(true)
    }
  
    if (counterTopDisposition === "Disposição 1") {
      setSelectDU3(false)
      setSelectDU4(false)
      setSelectDU1(true)
      setSelectDU2(false)
    }
  
    if(counterTopDisposition === "Disposição 3") {
      setSelectDU3(true)
      setSelectDU4(false)
      setSelectDU1(false)
      setSelectDU2(false)
    }
      
    if(counterTopDisposition === "Disposição 4") {
      setSelectDU3(false)
      setSelectDU4(true)
      setSelectDU1(false)
      setSelectDU2(false)
    }
  }  
  },[selectU1, selectU2, selectU3])

  const alerta = (mensagem: string) => {
    toast.warning(mensagem, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      closeOnClick: true,
      draggable: true
    })
  }

  const aviso1 = 'Não é possível mudar o tipo de Bancada em um produto que já está no orçamento'

  return (
    <MobileContainer>
      <span>Orçamento</span>

      <Page afterPosition={select || select1 || select2 ? true : false}>
        <BackBtn
          onClick={() => navigate("/selecionar-material")}
          isMobile={true}
          style={{ position: "absolute", top: "3vh", left: 0 }}
        />
        <StepCounter step={1} />

        <PathText>
          <Title>
            <A>Bancada de Pia &gt;</A> <A> {materialName}</A>
          </Title>
        </PathText>
        <div style={{ height: "fit-content" }}>
          <MainText>
            <Title>Selecione o Tipo de Bancada</Title>
          </MainText>

          <Cards1>
            {!editingProduct || (editingProduct && counterTopType === 'Bancada Reta') ? <CounterTopCard
              title="Bancada Reta"
              src={Straight}
              // selected={counterTopType === 'Bancada Reta' ? Marked : NotMarked || counterTopType === 'Bancada em U' ? NotMarked : Marked || counterTopType === 'Bancada em L' ? NotMarked : Marked}
              selected={select ? Marked : NotMarked}
              onClick={() => {
                selectStraight();
                sessionStorage.setItem("countertopType", "Bancada Reta");
                setAdvance(select || select1 || select2 ? true : false);
              }}
            /> : <CounterTopCardOff
              title="Bancada Reta"
              src={offStraight}
              selected={NotMarked}
              onClick={() => alerta(aviso1)}
            />}

            {!editingProduct || (editingProduct && counterTopType === 'Bancada em L') ? <CounterTopCard
              title="Bancada em L"
              src={L}
              selected={select1 ? Marked : NotMarked}
              onClick={() => {
                selectL();
                sessionStorage.setItem("countertopType", "Bancada em L");
                setAdvance(select || select1 || select2 ? true : false);
              }}
            /> : <CounterTopCardOff
              title="Bancada em L"
              src={offL}
              selected={NotMarked}
              onClick={() => alerta(aviso1)}
            />}

            {!editingProduct || (editingProduct && counterTopType === 'Bancada em U') ? <CounterTopCard
              title="Bancada em U"
              src={U}
              selected={select2 ? Marked : NotMarked}
              onClick={() => {
                selectU();
                sessionStorage.setItem("countertopType", "Bancada em U");
                setAdvance(select || select1 || select2 ? true : false);
              }}
            /> : <CounterTopCardOff
              title="Bancada em U"
              src={offU}
              selected={NotMarked}
              onClick={() => alerta(aviso1)}
            />}
          </Cards1>
        </div>
        {select1 ? (
          <div>
            <MainText>
              <Title> Posição da Cuba </Title>
            </MainText>

            <Cards1>
              {vatDisposition.map((item, index) => (
                <CounterTopCard
                  title={item.title}
                  src={item.img}
                  selected={directionArray[index] ? Marked : NotMarked}
                  onClick={() => selectDirection(index)}
                />
              ))}
            </Cards1>
          </div>
        ) : (
          ""
        )}

        {right ? (
          <div>
            <MainText>
              <Title>Disposição da Bancada</Title>
            </MainText>

            <Cards1>
              <CounterTopCard
                title="Disposição 1"
                src={Disp1}
                selected={selectD1 ? Marked : NotMarked}
                onClick={() => selectDisp1()}
              />

              <CounterTopCard
                title="Disposição 2"
                src={Disp2}
                selected={selectD2 ? Marked : NotMarked}
                onClick={() => selectDisp2()}
              />
            </Cards1>
          </div>
        ) : (
          ""
        )}

        {left ? (
          <div>
            <MainText>
              <Title>Disposição da Bancada</Title>
            </MainText>

            <Cards1>
              <CounterTopCard
                title="Disposição 1"
                src={Disp3}
                selected={selectD3 ? Marked : NotMarked}
                onClick={() => selectDisp3()}
              />

              <CounterTopCard
                title="Disposição 2"
                src={Disp4}
                selected={selectD4 ? Marked : NotMarked}
                onClick={() => {
                  selectDisp4();
                  sessionStorage.setItem(
                    "countertopDisposition",
                    "Disposiçao 2"
                  );
                }}
              />
            </Cards1>
          </div>
        ) : (
          ""
        )}

        {select2 ? (
          <div>
            <MainText>
              <Title> Posição da Cuba </Title>
            </MainText>

            <Cards1>
              <CounterTopCard
                title="Centro"
                src={UC}
                selected={selectU1 ? Marked : NotMarked}
                onClick={() => selectCU1()}
              />

              <CounterTopCard
                title="Esquerda"
                src={UL}
                selected={selectU2 ? Marked : NotMarked}
                onClick={() => selectCU2()}
              />

              <CounterTopCard
                title="Direita"
                src={UR}
                selected={selectU3 ? Marked : NotMarked}
                onClick={() => selectCU3()}
              />
            </Cards1>
            {selectU1 ? (
              <div>
                <MainText>
                  <Title>Disposição da Bancada</Title>
                </MainText>

                <Cards1>
                  <CounterTopCard
                    title="Disposição 1"
                    src={UC1}
                    selected={selectDU1 ? Marked : NotMarked}
                    onClick={() => selectDispU1()}
                  />

                  <CounterTopCard
                    title="Disposição 2"
                    src={UC2}
                    selected={selectDU2 ? Marked : NotMarked}
                    onClick={() => selectDispU2()}
                  />

                  <CounterTopCard
                    title="Disposição 3"
                    src={UC3}
                    selected={selectDU3 ? Marked : NotMarked}
                    onClick={() => selectDispU3()}
                  />

                  <CounterTopCard
                    title="Disposição 4"
                    src={UC4}
                    selected={selectDU4 ? Marked : NotMarked}
                    onClick={() => selectDispU4()}
                  />
                </Cards1>
              </div>
            ) : (
              ""
            )}
            {selectU2 ? (
              <div>
                <MainText>
                  <Title>Disposição da Bancada</Title>
                </MainText>

                <Cards1>
                  <CounterTopCard
                    title="Disposição 1"
                    src={UL1}
                    selected={selectDU1 ? Marked : NotMarked}
                    onClick={() => selectDispU1()}
                  />

                  <CounterTopCard
                    title="Disposição 2"
                    src={UL2}
                    selected={selectDU2 ? Marked : NotMarked}
                    onClick={() => selectDispU2()}
                  />

                  <CounterTopCard
                    title="Disposição 3"
                    src={UL3}
                    selected={selectDU3 ? Marked : NotMarked}
                    onClick={() => selectDispU3()}
                  />

                  <CounterTopCard
                    title="Disposição 4"
                    src={UL4}
                    selected={selectDU4 ? Marked : NotMarked}
                    onClick={() => selectDispU4()}
                  />
                </Cards1>
              </div>
            ) : (
              ""
            )}

            {selectU3 ? (
              <div>
                <MainText>
                  <Title>Disposição da Bancada</Title>
                </MainText>

                <Cards1>
                  <CounterTopCard
                    title="Disposição 1"
                    src={UR1}
                    selected={selectDU1 ? Marked : NotMarked}
                    onClick={() => selectDispU1()}
                  />

                  <CounterTopCard
                    title="Disposição 2"
                    src={UR2}
                    selected={selectDU2 ? Marked : NotMarked}
                    onClick={() => selectDispU2()}
                  />

                  <CounterTopCard
                    title="Disposição 3"
                    src={UR3}
                    selected={selectDU3 ? Marked : NotMarked}
                    onClick={() => selectDispU3()}
                  />

                  <CounterTopCard
                    title="Disposição 4"
                    src={UR4}
                    selected={selectDU4 ? Marked : NotMarked}
                    onClick={() => selectDispU4()}
                  />
                </Cards1>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          {select ? (
            <Cards2>
              <CardWrapper>
                <Div>
                  <TitleInput>Quantidade</TitleInput>
                  <Container largura={28.7}>
                    <Input
                      type="number"
                      {...register("quantidade", {
                        required: true,
                        max: 2300,
                        min: 1,
                      })}
                      placeholder={amount?.length ? amount : "Quantidade"}
                      value={c0}
                      onChange={(event: any) => {
                        setValue("quantidade", event.target.value);
                        setc0(event.target.value);
                      }}
                    />
                    <h6>unidade(s)</h6>
                  </Container>
                </Div>
                {errors.quantidade?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 2300 unid</p>
                )}
                {errors.quantidade?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 1 unid</p>
                )}
                {amount
                  ? ""
                  : errors.quantidade?.type === "required" && (
                      <p style={StyleErrorMessage}>Campo obrigatório</p>
                    )}
              </CardWrapper>

              <CardWrapper>
                <Div>
                  <TitleInput>Largura Total</TitleInput>
                  <Container largura={28.7}>
                    <Input
                      type="number"
                      {...register("largura", {
                        required: true,
                        max: 6000,
                        min: 650,
                      })}
                      placeholder={"Largura"}
                      value={c1}
                      onChange={(event: any) => {
                        setValue("largura", event.target.value);
                        setc1(event.target.value);
                      }}
                    />
                    <h6>milímetros</h6>
                  </Container>
                </Div>
                {errors.largura?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo 6000 milímetros</p>
                )}
                {errors.largura?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 650 milímetros</p>
                )}

                {wetBaseEstimateWidth
                  ? ""
                  : errors.largura?.type === "required" && (
                      <p style={StyleErrorMessage}>Campo obrigatório</p>
                    )}
              </CardWrapper>
              <CardWrapper>
                <Div>
                  <TitleInput>Profundidade</TitleInput>
                  <Container largura={28.7}>
                    <Input
                      type="number"
                      {...register("profundidade", {
                        required: true,
                        max: 1000,
                        min: 500,
                      })}
                      placeholder={
                        wetBaseEstimateDepth?.length
                          ? wetBaseEstimateDepth
                          : "Profundidade"
                      }
                      value={c2}
                      onChange={(event: any) => {
                        setValue("largura", event.target.value);
                        setc2(event.target.value);
                      }}
                    />
                    <h6>milímetros</h6>
                  </Container>
                </Div>
                {errors.profundidade?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 1000 milímetros</p>
                )}
                {errors.profundidade?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 500 milímetros</p>
                )}
                {wetBaseEstimateDepth
                  ? ""
                  : errors.profundidade?.type === "required" && (
                      <p style={StyleErrorMessage}>Campo obrigatório</p>
                    )}
              </CardWrapper>
            </Cards2>
          ) : (
            ""
          )}

          <Btns style={{ paddingBottom: "15px" }}>
            <Link to={editingSummary || editingProduct ? "/resumo" : "/selecionar-material"}>
              <BackBtn />
            </Link>

            {bancadaReta || bancadaL || bancadaU ? (
              <div>
                {editingSummary && bancadaReta ? (
                  <SbmtBtn>Voltar para o Resumo</SbmtBtn>
                ) : (
                  <SbmtBtn>Avançar</SbmtBtn>
                )}
              </div>
            ) : (
              <DisabledBtn style={{ textAlign: "center" }}>
                {editingSummary && select ? "Voltar para o Resumo" : "Avançar"}
              </DisabledBtn>
            )}
          </Btns>
        </form>
      </Page>
    </MobileContainer>
  );
}
