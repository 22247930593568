import styled from 'styled-components'
const style = {
    color: {
        gray: '#363636',
        black: '#535353'
    }
}

interface DotsProps{
    bgColor: string
}
export const StatusBar = styled.p<DotsProps>`
width: 90%;
height:10px;
border: none;
background-color: ${prop => prop.bgColor != "#DFDFDF" ? prop.bgColor : "#DFDFDF"};

`
export const TextInfo = styled.div`
color:${style.color.gray};
opacity: 0.64;
font-size:12px;
`


export const Container = styled.div<DotsProps>`
width:343px;
background-color: white;

color:${style.color.black};
border-radius:8px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
padding: 16px;
margin-bottom: 10px;
cursor: pointer;
scroll-snap-align: center;
&:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); /* adicionando uma sombra mais forte quando o mouse está sobre o card */
  }
*p{}
>*{&:first-child{
    display: flex;
    flex-direction: row;
    width:100%;
    gap:12px;
    align-items: center;
    margin-bottom: 1rem;
    p{
    color:${style.color.black};
    opacity: 0.64;
    font-size:12px
    }
}}
div div{
    height:15px;
    width:15px;
    background-color: ${props => props.bgColor};
    border-radius:7.5px;
}
>*{
    &:nth-child(2){
        /* background-color: gray; */
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        >*{&:nth-child(2){
            font-size:20px;
            color:${style.color.black};
        }}
    }
    
        &:nth-child(3){
            display:flex;
            flex-direction: row;
            justify-content: space-between;
            font-size:12px;
            color:${style.color.gray};
            opacity: 0.64;
        }
    
}
@media (max-width:912px){
    min-width: 267px;
    *&:first-child{
        margin-left: 30vw;
    }
    :last-child{
    margin-right: 30px;
}  
    /* para alinhar o card no centro (quando houver apenas 1) */
    /* *&:only-child{
        margin-left: 30vw; 
    } */
}
@media (max-width:1350px){
    width:250px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);
}
@media (max-width:1060px){
    width:235px;
}
@media (max-width:575px){
    *&:first-child{
        margin-left: 20vw;
    }
}
@media (max-width:426px){
    min-width:250px;
    *&:first-child{
        margin-left: 15vw;
    }
}
@media (max-width:395px){
    width:230px;
    *&:first-child{
        margin-left: 13vw;
    }
}
@media (max-width: 321px){
    *&:first-child{
        margin-left: 5vw;
    }
}
`

