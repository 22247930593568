import Cooktop from "../../assets/Cooktop.svg";
import BuiltIn from "../../assets/BuiltIn.svg";
import None from "../../assets/None.svg";
import { useEffect, useState } from "react";
import OptionBtnCard from "../../components/OptionBtnCard";
import Marked from "../../assets/Marked.svg";
import NotMarked from "../../assets/NotMarked.svg";
import { BottomSection, Counter } from "../CubaStyle/style";
import { TopWrapper } from "../FaucetStyle/style";
import Center from "../../assets/Center.svg";
import Left from "../../assets/Left.svg";
import Right from "../../assets/Right.svg";
import {
  CardsWrapper,
  BottomDiv,
  BottomWrapper,
  Box,
  MobileContainer,
  Name,
  Page,
  Top,
  Div,
} from "./style";
import { DisabledBtn, SbmtBtn, TitleInput } from "../BenchL/style";
import BackBtn from "../../components/BackBtn";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container, Input } from "../../components/DetailsInput/style";
import { StyleErrorMessage } from "../../components/StyleErrorMessage";
import { StepCounter } from "../../components/StepCounter";

function OvenStyle() {
  //validação
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ mode: "onBlur" });

  // sessionStorage.removeItem("stoveType");
  // sessionStorage.removeItem("shaft");
  // sessionStorage.removeItem("shaftDistance");
  // sessionStorage.removeItem("shaftFrontDistance");
  const editingSummary = sessionStorage.getItem("editing summary") === 'true' ? true: false
  const editingProduct = sessionStorage.getItem("editing product") === 'true' ? true: false

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function sendToSessionStorage() {
    sessionStorage.setItem(
      "stoveType",
      cooktop ? "Cooktop" : builtIn ? "Embutido" : "Sem fogão"
    );
    if (!cooktop) {
      sessionStorage.setItem("shaftFrontDistance", "");
      sessionStorage.setItem("shaftDistance", "");
      sessionStorage.setItem("shaft", "");
    }
    if (cooktop) {
      sessionStorage.setItem(
        "shaft",
        center
          ? "Centralizado"
          : left
          ? "Partindo da Esquerda"
          : "Partindo da Direita"
      );
      sessionStorage.setItem(
        "shaftFrontDistance",
        distance1 ? distance1.toString() : ""
      );

      if (center) {
        sessionStorage.setItem("shaftDistance", "");
      } else {
        sessionStorage.setItem(
          "shaftDistance",
          distance0 ? distance0.toString() : ""
        );
      }
    }
  }

  // controla a altura da página
  const [advance, setadvance] = useState<boolean>(false);

  //controlar os estados de cooktop, embutido e sem fogão
  const [cooktop, setCooktop] = useState<boolean>(false);
  const [builtIn, setBuiltIn] = useState<boolean>(false);
  const [none, setNone] = useState<boolean>(false);

  const stoveType = sessionStorage.getItem("stoveType");
  const shaft = sessionStorage.getItem("shaft");
  const shaftDistance = sessionStorage.getItem("shaftDistance");
  const shaftFrontDistance = sessionStorage.getItem("shaftFrontDistance");
  const installationRules = sessionStorage.getItem("installationRules");  

  //function pra selecionar cooktop, embutido ou sem fogão
  function selectOven(index: number) {
    switch (index) {
      case 0:
        setCooktop(!cooktop);
        setBuiltIn(false);
        setNone(false);
        break;
      case 1:
        setCooktop(false);
        setBuiltIn(!builtIn);
        setNone(false);
        break;
      case 2:
        setCooktop(false);
        setBuiltIn(false);
        setNone(!none);
        break;
    }
  }
  //variáveis que controlam as opções
  const optionArray = [cooktop, builtIn, none];
  //controlar os estados de centro, esquerda e direita
  const [center, setCenter] = useState<boolean>(false);
  const [left, setLeft] = useState<boolean>(false);
  const [right, setRight] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  //function pra selecionar cooktop, embutido ou sem fogão

  function selectAxis(index: number) {
    switch (index) {
      case 0:
        setCenter(!center);
        setLeft(false);
        setRight(false);
        break;
      case 1:
        setCenter(false);
        setLeft(!left);
        setRight(false);
        break;
      case 2:
        setCenter(false);
        setLeft(false);
        setRight(!right);
        break;
    }
  }

  //variáveis que controlam as opções
  const axisArray = [center, left, right];
  //inputs
  const [distance0, setDistance0] = useState<number>();
  const [distance1, setDistance1] = useState<number>();
  console.log(distance0, distance1);
  //botao de confirmação
  
  useEffect(() => {
    if ((distance0 || distance1) == (undefined || NaN || 0 || null || "")) {
      setConfirm(false);
    }
    if ((center || left || right) === false) {
      setDistance0(undefined);
      setDistance1(undefined);
    }
    if ((cooktop || builtIn || none) === false) {
      setConfirm(false);
    }
    if (cooktop === false) {
      setDistance0(undefined);
      setDistance1(undefined);
      setadvance(true);
    }
    if (cooktop === true) {
      // setadvance(false);
    }
    if ((distance0 || distance1) !== (undefined || NaN || 0 || null || "")) {
      setadvance(false);
    }
  }, [cooktop]);
  console.log(advance);

  console.log(distance0,distance1)  

  const option = [
    { title: "Cooktop", icon: Cooktop },
    { title: "Embutido", icon: BuiltIn },
    { title: "Sem fogão", icon: None },
  ];
  const axis = [
    { title: "Centralizado", icon: Center },
    { title: "Partindo da Esquerda", icon: Left },
    { title: "Partindo da Direita", icon: Right },
  ];

  useEffect(() => {

    if (stoveType === "Cooktop") {
      setCooktop(true);
      setBuiltIn(false);
      setNone(false);
    } 
    
    if (stoveType === "Embutido") {
      setCooktop(false);
      setBuiltIn(true);
      setNone(false);
    } 
    
    if (stoveType === "Sem fogão") {
      setCooktop(false);
      setBuiltIn(false);
      setNone(true);
    }

    if (shaft === "Centralizado") {
      setCenter(true);
      setRight(false);
      setLeft(false);
    } 
    
    if (shaft === "Partindo da Esquerda") {
      setCenter(false);
      setRight(false);
      setLeft(true);
    } 
    
    if (shaft === "Partindo da Direita") {
      setCenter(false);
      setRight(true);
      setLeft(false);
    }

    if (shaftDistance) {
      {setDistance0(Number(shaftDistance))
      setValue("medida1", shaftDistance)};
    }

    if (shaftFrontDistance) {
      {setDistance1(Number(shaftFrontDistance));
      setValue("medida2", shaftFrontDistance)}
    }

    if (installationRules) {
      setConfirm(true);
    }
  },[]);
  return (
    <MobileContainer>
      <span>Orçamento</span>
      <Page onAdvance={advance}>
        <div style={{ flexDirection: "column", display: "flex" }}>
          <BackBtn
            onClick={() => navigate(editingSummary || editingProduct ? "/resumo" : "/tipo-de-torneira")}
            isMobile={true}
            style={{ position: "absolute", top: "3vh", left: 0 }}
          />
          <StepCounter step={5} />
        </div>
        <h2>Tipo de Fogão</h2>
        <CardsWrapper>
          {option.map((option, index) => (
            <OptionBtnCard
              label={option.title}
              icon={option.icon}
              selected={optionArray[index] ? Marked : NotMarked}
              onClick={() => selectOven(index)}
            />
          ))}
        </CardsWrapper>
        {cooktop && (
          <>
            <h2>Eixo do Fogão na Bancada Seca</h2>
            <CardsWrapper>
              {axis.map((axis, index) => (
                <OptionBtnCard
                  label={axis.title}
                  icon={axis.icon}
                  selected={axisArray[index] ? Marked : NotMarked}
                  onClick={() => selectAxis(index)}
                  key={index}
                />
              ))}
            </CardsWrapper>
          </>
        )}
        {cooktop && (center || left || right) ? (
          <>
            <BottomWrapper>
              {center ? (
                ""
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    flexDirection: "column",
                    paddingBottom: "5px",
                  }}
                >
                  <Div>
                    <TitleInput>Distância do eixo</TitleInput>
                    <Container largura={28.7}>
                      <Input
                        type="number"
                        {...register("medida1", {
                          required: true,
                          max: 2300,
                          min: 300,
                        })}
                        placeholder={
                          windowSize[0] > 1000 ? "Medida" : "Distância do eixo"
                        }
                        value={distance0}
                        onChange={(event: any) =>
                          {setDistance0(event.target.value)
                          setValue("medida1", event.target.value)}
                          
                        }
                      />
                      <h6>milímetros</h6>
                    </Container>
                  </Div>
                  {errors.medida1?.type === "max" && (
                    <p style={StyleErrorMessage}>Máximo de 2300 milímetros</p>
                  )}
                  {errors.medida1?.type === "min" && (
                    <p style={StyleErrorMessage}>Mínimo de 300 milímetros</p>
                  )}
                  {errors.medida1?.type === "required" && (
                    <p style={StyleErrorMessage}>Campo obrigatório</p>
                  )}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  flexDirection: "column",
                  paddingBottom: "5px",
                }}
              >
                <Div>
                  <TitleInput>Distância entre o corte e a saia</TitleInput>
                  <Container largura={28.7}>
                    <Input
                      type="number"
                      {...register("medida2", {
                        required: true,
                        max: 2300,
                        min: 300,
                      })}
                      placeholder={
                        windowSize[0] > 1000
                          ? "Medida"
                          : "  Distância entre o corte e a saia"
                      }
                      value={distance1}
                      onChange={(event: any) =>
                        {setDistance1(event.target.value)
                        setValue("medida2", event.target.value)}
                      }
                    />
                    <h6>milímetros</h6>
                  </Container>
                </Div>
                {errors.medida2?.type === "max" && (
                  <p style={StyleErrorMessage}>Máximo de 2300 milímetros</p>
                )}
                {errors.medida2?.type === "min" && (
                  <p style={StyleErrorMessage}>Mínimo de 300 milímetros</p>
                )}
                {errors.medida2?.type === "required" && (
                  <p style={StyleErrorMessage}>Campo obrigatório</p>
                )}
              </div>
            </BottomWrapper>
          </>
        ) : (
          ""
        )}

        {(cooktop && (right || left ) && distance0 && distance1) ||
        (cooktop && center && distance1) || builtIn 
         ? (
          <>
            <Box
              onClick={() => {
                setConfirm(!confirm);
                sessionStorage.setItem("installationRules", "true");
              }}
            >
              <img
                style={{ paddingRight: "10px" }}
                src={confirm ? Marked : NotMarked}
              />
              <Name>
                Estou ciente que as medidas do modelo de fogão cabem nas
                dimensões especificadas na bancada e se enquadram nas{" "}
                <b>Regras de Aplicação</b>.
              </Name>
            </Box>
          </>
        ) : (
          ""
        )}              
        <BottomSection
          style={{
            marginTop: "6.3vh",
          }}
        >
          <Link to={editingSummary || editingProduct ? "/resumo" : "/tipo-de-torneira"}>
            <BackBtn />
          </Link>
          {(cooktop &&
            (right || left) &&
            (distance0! >= 300 || distance1! >= 300) &&
            confirm) ||
          (cooktop && center && (distance1! >= 300) && confirm) ||
          (builtIn && confirm) ||
          none ? (
            <div>
              {editingSummary ? (
                <SbmtBtn
                  onClick={() => {
                    sendToSessionStorage();
                    navigate("/resumo");
                  }}
                >
                  Voltar para o Resumo
                </SbmtBtn>
              ) : (
                <SbmtBtn
                  onClick={() => {
                    sendToSessionStorage();
                    navigate("/instalacao");
                  }}
                >
                  Avançar
                </SbmtBtn>
              )}
            </div>
          ) : (
            <DisabledBtn>
              {editingSummary ? "Voltar para o Resumo" : "Avançar"}
            </DisabledBtn>
          )}
        </BottomSection>
      </Page>
    </MobileContainer>
  );
}

export default OvenStyle;
