import styled from 'styled-components';

export const Title = styled.h2`
color: #828282;

font-size: 1.25rem;
margin-bottom:1.25rem;
@media (max-width:913px){
  padding-top: 5%;
}
`

export const CardBox = styled.div`
display:flex;
flex-direction:row;
overflow-x: scroll;
gap:0.85rem;
padding-bottom: 25px;
::-webkit-scrollbar{display:none;}
@media (max-width: 477px){
  flex-direction: column;
}
`
export const Section = styled.section`
display:flex;
flex-direction:column;
gap:3.125rem;
@media (max-width: 1200px){
  padding-top: 1.73vw;

}
`
export const Description = styled.h2`
color: #828282;
  
  font-size: 16px;
  font-weight: 300;
  align-self:center;
`
