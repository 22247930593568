import styled from 'styled-components';

export const Container = styled.div`
padding: 100px 3.47222222222222vw 0px 3.47222222222222vw;
background:#F2F2F2;
height:89vh;
width: 100%;
@media(max-width:912px){
min-height: 100vh;
background:white;
margin-top:4vh;
border-radius:20px 20px 0 0;
width:93%;
}
`
export const TopBox = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:center;
@media(max-width:912px){
 flex-direction:column;   
}

`
export const MainText = styled.h1`
color: #828282;

font-size: 24px;
font-weight: 700;
display:flex;
flex:1.005;
@media(max-width:912px){
    display:none;
}
`

export const MainBox = styled.div`
display:flex;
margin-top:4.33vh;
flex-direction:column;
justify-content:center;
@media(max-width:912px){
    margin-top:3.8vh;
}
`
export const Buttons = styled.div`
display:flex;
flex-direction:row;
flex-flow:wrap;
justify-content:flex-start;
gap:1.38vw;
@media(max-width:912px){
    flex-direction:column;
    align-items:center;
}
`


export const CloseBtn = styled.button`
border:none;

background:none;
display:flex;
align-items:center;
justify-content:center;
color:#828282;
font-size:16px;
font-weight: 300;
&:hover{
    cursor:pointer;
    opacity:0.5;
}
@media(max-width:912px){
    display:none;
}
`
export const InputBox = styled.div`
display:flex;
flex-direction:row;
align-items:center;
margin-left:0.5%;
margin-right:1.8%;
width:auto;
border-bottom:solid 1px #828282;
@media(max-width:912px){
 border:none;
 border-radius: 5px;
 background:#F2F2F2;
 height:4.464vh;
 width:61vw;   
}
@media(max-width:910px){
    width:85.5vw;   
}
`
export const Input = styled.input`
background:none;
border:none;
height:30px;
width:100%;

font-size:16px;
font-weight: 300;
margin-left:15px;
&:focus{
    outline:none;
}
@media(max-width:700px){
    font-size:12px;   
}
@supports (-webkit-touch-callout: none) {
  /* Atualmente, apenas dispositivos iOS possuem a feature -webkit-touch-callout,
  logo esse código testa se o dispositivo é iOS ou não. Caso a Apple mude algo,
  basta consultar
  https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariCSSRef/Articles/StandardCSSProperties.html
  e buscar por "Safari on iOS only"*/
  font-size: 16px;
  width: 95%;
  }
`
export const Line = styled.div`
background-color:#828282;
height:1px;
width:33.83vw;
`
