import styled from 'styled-components';

interface InputProps {
  largura: number;
}

export const Container = styled.div<InputProps>`
  height: 3.125rem;
  justify-content:space-between;
  align-items:center;
  display:flex;
  background:white; 
  h6{
    font-size:0.625rem;
    
    margin-left:10px;
    padding:20px;
    }
  /* padding-right:20px; */
  box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
  border-radius: 8px;
  color: #828282;
  @media(max-width:2000px){
    width:29.86vw;
  }
  @media(max-width:1840px){
    width:${(props) => props.largura}vw;
  }
  @media(max-width:1661px){
    width:${(props) => props.largura}vw;
  }
  @media(max-width:1534px){
    width:${(props) => props.largura}vw;
  }
  @media(max-width:1425px){
    width:${(props) => props.largura}vw;
  }
  @media(max-width:1163px){
    width:${(props) => props.largura}vw;
  }
  @media(max-width:1099px){
    width:${(props) => props.largura}vw;
  }
  @media (max-width:1000px){
    background-color:transparent;
    padding-right:0px;
    width:100%;
    font-size:12px;
    height:30px;
    box-shadow: none;
    color:#BDBDBD;
    /* padding-left:41px; */
    h6{
      padding-right: 5vw;
    }
    
}
`

export const Input = styled.input`

padding-left:20px;
font-size:1rem;
display:flex;
flex:2;
border:none;
color: #828282;
width: 80%;
&::placeholder{
  color:#BDBDBD;
 }

&:focus{
    outline:none;
}
@media (max-width:1000px){
  background-color:transparent;
  text-align: center;
  font-size: 12px;
  padding-right: 10px;
}
@supports (-webkit-touch-callout: none) {
  /* Atualmente, apenas dispositivos iOS possuem a feature -webkit-touch-callout,
  logo esse código testa se o dispositivo é iOS ou não. Caso a Apple mude algo,
  basta consultar
  https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariCSSRef/Articles/StandardCSSProperties.html
  e buscar por "Safari on iOS only"*/
  font-size: 16px;
  width: 95%;
  }
`
export const Title = styled.h2`

font-weight: 700;
font-size:1rem;
color:#828282;
margin-bottom:0.5rem;
@media (max-width:1000px){
  display:none;
}
`
export const Div = styled.div`

@media (max-width:1000px) {
  display:flex;
  flex-direction: column;
  background-color: #f2f2f2;
  width:90vw;
  height:30px;
  align-content: center;
  padding-top: 5px;
  border-radius: 5px;
  /* margin-bottom: 5vw; */
}
`