import { Container, StatusBar, TextInfo } from "./style";
import { useNavigate } from "react-router-dom";

export interface CardProps {
    id: string,
    userName: string,
    location: string,
    date: string,
    price: string,
    bgColor: string,
    clickEvent: string[],
}


function OrderCard(
    { id, userName, location, date, price, bgColor, clickEvent }: CardProps
) {
    const navigate = useNavigate();
    const editEstimate = (id: string, name: string) => {
        if((id && name) != ''){
            console.log(id);
            sessionStorage.setItem("estimateId", id);
            sessionStorage.setItem("estimateName", name);
            navigate("/estimativa");
        }
      };
    return (
        <>
            <Container bgColor={bgColor} onClick={() => editEstimate(clickEvent[0],clickEvent[1])}>
                <div>
                    <div></div> <p>{id}</p>
                </div>
                <div>
                    <p>{userName}</p> <p>{date}</p>
                </div>
                <div>
                    <p>
                        {
                            bgColor == "#00a0e1" ?
                                location :
                                bgColor == "#f3ca3e" ?
                                    "Aguardando Pagamento" :
                                    bgColor == "#fa9917" ?
                                        "Em fabricação" :
                                        bgColor == "#33e1ff" ?
                                            "Preparando para a entrega" :
                                            bgColor == "#2ac940" ?
                                                "Pedido entregue" :
                                                ""
                        }
                    </p>
                    <p>{price}</p>
                </div>
                {bgColor != '#00a0e1' ? (
                    <>
                        <div style={{ width: "100%", display: 'flex', paddingTop: '15px' }}>
                            <StatusBar style={{
                                borderRadius: '20px 0px 0px 20px',
                                borderRight: '2px #9C9C9C solid'
                            }}
                                bgColor={bgColor}
                            />
                            <StatusBar style={{
                                borderRight: '2px #9C9C9C solid'
                            }}
                                bgColor={bgColor != "#f3ca3e" ? bgColor : "#DFDFDF"}
                            />
                            <StatusBar style={{
                                borderRight: '2px #9C9C9C solid'
                            }}
                                bgColor={
                                    bgColor == "#33e1ff" ?
                                        bgColor :
                                        bgColor == "#2ac940" ?
                                            bgColor : "#DFDFDF"}
                            />
                            <StatusBar style={{
                                borderRadius: '0px 20px 20px 0px'
                            }}
                                bgColor={bgColor == "#2ac940" ? bgColor : "#DFDFDF"}
                            />
                        </div>
                        <TextInfo style={{ width: "100%", display: 'flex', justifyContent: 'space-between' }}>
                            <p>Pagamento</p>
                            <p style={{ paddingRight: '10px' }}>Fabricação</p>
                            <p>Entregue</p>
                        </TextInfo>

                    </>


                ) : ''}

            </Container>
        </>
    );
}

export default OrderCard;