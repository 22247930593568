import styled from 'styled-components';


export const Select = styled.select`
border: none;
width: 100%;
margin: 0px 20px 0px 20px;
/* padding-left:20px;
padding-right:20px; */
font-size:1rem;
font-weight: 400;
display:flex;
border:none;
color: #7d7c7c;

/* @media(max-width: 450px) {
  max-width: 385px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px;
}

@media(max-width: 414px) {
  max-width: 295px;
  margin-bottom: 10px;
} */
&::placeholder{
  color:#7d7c7c
 }
&:focus{
    outline:none;
}
  justify-content:space-between;
  align-items:center;
  display:flex;
  background-color: none;
  border-radius: 8px; 
  color: #828282; 
`