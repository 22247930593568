import * as React from "react";
import { BottomContainer, Container } from "./style";
import InstallationSection from "../../components/InstallationSection";
import { BottomDiv } from "../OvenStyle/style";
import BackBtn from "../../components/BackBtn";
import { DisabledBtn, Main, MobileContainer, SbmtBtn } from "../BenchL/style";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { StepCounter } from "../../components/StepCounter";

function Installation() {
    const [advance, setAdvance] = useState(false);
    let i: number;
    let reactArray: boolean[];

    // sessionStorage.removeItem("installation");
    // sessionStorage.removeItem("typeOfInstallation");

    const editingSummary = sessionStorage.getItem("editing summary") === 'true' ? true: false
    const editingProduct = sessionStorage.getItem("editing product") === 'true' ? true: false

    function getActiveButtons(reactArray: boolean[]) {
       ' reactArray = reactArray;'
        // if ((reactArray[0] || reactArray[1]) &&
        //     (reactArray[2] || reactArray[3] || reactArray[4])) {
        //     setAdvance(!advance);
        // }
    }

    return (
        <>
            <Container>
                <div style={{marginTop:'50px'}}>
                    <StepCounter step={6}/>
                </div>
                <InstallationSection activeButtons={getActiveButtons} />
            </Container>
            
              {/* responsividade mobile */}
            <MobileContainer>
                <span>Orçamento</span>
                <Main style={{ position: 'relative', height:'100vh' }}>
                <Link to={editingSummary || editingProduct ? "/resumo" : "/tipo-de-fogao"}><BackBtn isMobile={true} style={{ position: 'absolute', top: 30, left: 30, margin:0 }}/></Link>
                <div style={{marginTop:'50px'}}>
                    <StepCounter step={6}/>
                </div>
                <InstallationSection activeButtons={getActiveButtons} />
             </Main> 
             </MobileContainer> 
        </>
    );
}

export default Installation;
