import styled from 'styled-components';

interface AddressProps {
  onAdvance: boolean;
}

export const Page = styled.div<AddressProps>`
display:flex;
flex-direction:column;
background:#F4F4F4;
padding-top:50px;
padding-left:3.472222222vw;
padding-right:3.472222222vw;
height: ${props => props.onAdvance ? "auto" : "94vh"};
padding-bottom:${props => props.onAdvance && "48px"};
@media (max-width: 912px) {
  display: none;
}

`
export const Title = styled.h1`
margin-top:110px;
margin-bottom:3.194888178913738vh;

font-weight: 700;
font-size:1.5rem;
color:#828282;
text-align: center;
`
export const AddressContainer = styled.h1`
display:flex;
justify-content:center;
align-items:center;
*border: solid 2px red;
@media (max-width: 912px) {
  width: 100vw;
  height: fit-content;
  
}
`
export const Footer = styled.footer`
display:flex;
justify-content:space-between;
margin-top:4.44vh;
`