import { Letter, OptionBtn, SelectIcon } from "./style";


export interface OptionProps {
    onClick: any,
    label: string,
    icon: string,
    selected: string,
    iconStyle?: React.CSSProperties | undefined,
    btnStyle?: React.CSSProperties | undefined,
    index?:number | undefined,


}


function OptionBtnCard({ onClick, label, icon, selected, iconStyle, btnStyle, index }: OptionProps) {
    return (
        <>
            <OptionBtn type='button' onClick={onClick} style={btnStyle}>
                <Letter>
                    {label}
                </Letter>
                <img src={icon} style={iconStyle}/>
                <SelectIcon src={selected} />
            </OptionBtn>
        </>
    );
}

export default OptionBtnCard;
