import styled from 'styled-components';

export const Container = styled.div`
padding: 100px 3.47222222222222vw 0px 3.47222222222222vw;
background: #f2f2f2;
height:89vh;
width: 100%;
@media(max-width:912px){
background:white;
margin-top:4vh;
border-radius:20px 20px 0 0;
height:auto;
width:93%;
}
`
export const TopBox = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:center;
@media(max-width:912px){
 flex-direction:column;   
}
`
export const MainText = styled.h1`
color: #828282;

font-size: 24px;
font-weight: 700;
display:flex;
flex:1.005;
@media(max-width:912px){
    display:none;
}
`

export const MainBox = styled.div`
display:flex;
background-color: #f2f2f2;
margin-top:4.33vh;
flex-direction:column;
justify-content:center;
@media(max-width:912px){
    margin-top:3.8vh;
    margin-left:4.3%;
    width:85.5vw;
}
`
export const Buttons = styled.div`
display:flex;
flex-direction:row;
flex-flow:wrap;
justify-content:flex-start;
gap:1.38vw;
@media(max-width:912px){
    flex-direction:row;
    flex-flow:wrap;
    align-items:center;
    gap:3.411vw;
}

`

export const RoomBtn = styled.button`
display:flex;
justify-content:center;
align-items:center;
height:65px;
width:20rem;
border:none;
background-color:white;
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
border-radius:8px;
margin-bottom:1%;
h3{
  color: #828282;
  
  font-size: 16px;
}
`

export const GoBackBtn = styled.button`
border:none;

background:none;
display:flex;
align-items:center;
justify-content:center;
color:#828282;
font-size:16px;
font-weight: 300;
margin-top:50px;
&:hover{
    cursor:pointer;
    opacity:0.5;
}
@media(max-width:912px){
    display:none;
}
`
