import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 98%;
  height: fit-content;
  justify-content: center;
  align-content: center;
  padding: 0;
  margin-bottom: 2%;
  @media (max-width: 912px) {
    margin-bottom: 10vw;
  }
`;

export const Counter = styled.img`
  width: 256px;
  display: flex;
  margin: 0;
  @media (max-width: 912px) {
    margin: 0;
    margin-top: 12%;
  }
`;