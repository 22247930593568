import React from "react";
import { Action, Header, LeftSection, LogoImg, Menu, MobileLogo, ProfileButton, RightBtns, SearchDiv, SearchInput, Texth6 } from "./style";
import Logo from '../../assets/LogoSanstone.svg';
import LogoMobile from '../../assets/sansmobilelogo.svg'
import { RiSearchLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { GiHamburgerMenu } from 'react-icons/gi'
import { useNavigate } from "react-router-dom";
interface displayChoice {
    display?: string
}

export default function PageHeader({display}: displayChoice) {
    const navigate=useNavigate()
    return (
        <Header display={display}>
            <LeftSection>
                <MobileLogo src={LogoMobile} alt={'logo mobile'} onClick={()=>navigate('/')} />
                <LogoImg src={Logo} alt="logo" onClick={()=>navigate('/')}/>
                <Menu>
                    <GiHamburgerMenu style ={{fontSize:32, color:'#808080'}}/>
                </Menu>
            </LeftSection>
            <RightBtns>
                <Action><Texth6>Orçamentos</Texth6></Action>
                <Action><Texth6>Pedidos</Texth6></Action>
                <Action><Texth6>Agendamentos</Texth6></Action>
                <Action><Texth6>Recebimentos</Texth6></Action>
                <ProfileButton>
                    <CgProfile style={{ fontSize: "36px", color: "#828282" }} />
                </ProfileButton>
            </RightBtns>

        </Header>
    )
}
