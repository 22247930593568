import styled from "styled-components";

export const Page  = styled.div`
background:#F4F4F4;
padding:3.125rem 3.188rem 0rem 3.188rem;
height:100vh;
width:100vw;
`
export const TitleDiv = styled.div`
margin: 30px 0px 36px 0px;
display:flex;
gap: 0.438rem;
`
export const Title = styled.h1`

font-weight: 700;
font-size:1.125rem;
color:#BDBDBD;
`
export const BoxDiv = styled.div`
align-items: center;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
gap: 1.563rem;
`
export const Box = styled.div`
display:flex;
flex-direction:column;
gap:30px;
`
export const ContainerBS = styled.div`
display: inline-block;
flex-direction:row;
`
export const PieceContainer = styled.div`
height: 80px;
width: 430px;
border-radius: 8px;
background-color: #FFFFFF;
box-shadow: 0 8px 20px 0 rgba(142,142,142,0.2);
position:relative;
`
export const PieceTitle = styled.h1`
font-size:0.688rem;
color: #BDBDBD;

font-weight: 700;
text-align: center;
position:absolute;
top:40%;
left:35%;
margin: 0;
`
export const WrappersDiv = styled.div`
align-items: center;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
`
export const LeftDiv = styled.div`
display: inline-block;
padding-right:0.75rem;
`
export const XvLWrapper = styled.button`
background:#FFFFFF;
border:none;
height: 26px;
width: 55px;
border-radius:3px;
margin-bottom:0.625rem;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
  text-transform: uppercase;
  padding-left:4px;
}
`
export const XvpLWrapperDiv = styled.div`
display: inline-block;
`
export const XvpLWrapper = styled.button`
background:#FFFFFF;
border:none;
height:26px;
width:55px;
border-radius:3px;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 25px;
  padding-left:4px;
}
`
export const RightDiv = styled.div`
display: inline-block;
padding-left:0.75rem;
`

export const XvRWrapper = styled.button`
background:#FFFFFF;
border:none;
height:26px;
width:55px;
border-radius:3px;
margin-bottom:0.625rem;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
  padding-left:4px;
  text-transform: uppercase;
}
`
export const XvpRWrapper = styled.button`
background:#FFFFFF;
border:none;
height:26px;
width:55px;
border-radius:3px;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
  padding-left:4px;
  text-transform: uppercase;
}
`
export const TopDiv = styled.div`
display: inline-block;
align-items: center;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content:center;
gap:1.25rem;
margin-bottom:0.75rem;
`
export const XvTWrapper = styled.button`
background:#FFFFFF;
border:none;
height:26px;
width:55px;
border-radius:3px;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
  padding-left:4px;
  text-transform: uppercase;
}
`
export const XvpTWrapper = styled.button`
background:#FFFFFF;
border:none;
height:26px;
width:55px;
border-radius:3px;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
  padding-left:4px;
  text-transform: uppercase;
}
`
export const BottomDiv = styled.div`
display: inline-block;
align-items: center;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content:center;
gap:1.25rem;
margin-top:0.75rem;
`
export const XvBWrapper = styled.button`
background:#FFFFFF;
border:none;
height:26px;
width:55px;
border-radius:3px;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
  padding-left:4px;
  text-transform: uppercase;
}
`
export const XvpBWrapper = styled.button`
background:#FFFFFF;
border:none;
height:26px;
width:55px;
border-radius:3px;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
  padding-left:4px;
  text-transform: uppercase;
}
`
export const SAcabamentoDiv = styled.div`
display: inline-block;
align-items: center;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content:center;
gap:1.25rem;
margin-top:0.75rem;
`
export const SAcabamentoWrapper = styled.button`
background:#FFFFFF;
border:none;
height:26px;
width:130px;
border-radius:3px;
label{
  color:#BDBDBD;
  
  font-size:12px;
  align-self:center;
  font-weight: 700;
  padding-left:4px;
}
`
export const BottomContainer = styled.div`
flex-direction: row;
`
export const LegendaText1 = styled.h1`
color:#BDBDBD;

font-size:12px;
padding-bottom:12px
`
export const LegendaText2 = styled.h1`
color:#BDBDBD;

font-size:12px;
`
export const SbmtButton = styled.button`
height: 40px;
width: 150px;
border:none;
color: #FFF;

font-size: 16px;
background:#2566C8;
border-radius:8px;
&:hover{
  opacity:0.5;
}
`
export const DisabledButton = styled.div`
height: 40px;
width: 150px;
border:none;
color: #FFF;

font-size: 16px;
background:#D2D2D2;
border-radius:8px;
display:flex;
justify-content:center;
align-items:center;
`
export const ButtonDiv = styled.div`
display: flex;
justify-content:space-between;
padding-top: 6.3vh;
width:110rem;

@media(max-width:1200px){
  align-self:center;
}

`
